import React, { Dispatch, SetStateAction } from 'react';
import 'react-chat-widget/lib/styles.css';
import { Worker, Viewer, Button, ScrollMode, SpecialZoomLevel, ViewMode } from '@react-pdf-viewer/core';
import { BorderColor } from '@mui/icons-material';
import {
    highlightPlugin,
    RenderHighlightsProps,
    RenderHighlightTargetProps,
    RenderHighlightContentProps
} from '@react-pdf-viewer/highlight';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import Pagination from '../Pagination';
import { IHighLightPart } from 'views/DocumentViewer/types';
import Zoom from '../Zoom';
import { Box } from '@mui/material';

interface IPdfViewerProps {
    highlightParts?: IHighLightPart[];
    onTriggerHighlight?: Dispatch<SetStateAction<IHighLightPart[]>>;
    rotateInstance: any;
    url: string | null;
}

/**
 * @deprecated Now use the one that belong to RecordView componentF
 */
const PdfViewer = ({ highlightParts, onTriggerHighlight, rotateInstance, url }: IPdfViewerProps) => {
    const fileUrl = url || `${process.env.PUBLIC_URL}/sample.pdf`;
    const renderHighlightTarget = (props: RenderHighlightTargetProps) => (
        <div
            style={{
                background: '#D9D9D9',
                display: 'flex',
                position: 'absolute',
                left: `${props.selectionRegion.left}%`,
                top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
                transform: 'translate(0, 8px)',
                zIndex: 99
            }}
        >
            {/* @ts-ignore */}
            <Button onClick={props.toggle}>
                <BorderColor />
            </Button>
        </div>
    );

    const renderHighlightContent = (props: RenderHighlightContentProps) => {
        if (!highlightParts || !onTriggerHighlight) return <></>;

        const itExist = !!highlightParts.find(
            (area) => JSON.stringify(area.highlightAreas) === JSON.stringify(props.highlightAreas) && area.quote === props.selectedText
        );
        onTriggerHighlight((s) =>
            itExist
                ? s.filter((area) => area.quote !== props.selectedText)
                : [...s, { highlightAreas: props.highlightAreas, quote: props.selectedText }]
        );
        props.cancel();

        return <></>;
    };

    const renderHighlights = (props: RenderHighlightsProps) => (
        <div>
            {highlightParts &&
                highlightParts.map((part) => (
                    <React.Fragment key={part.quote}>
                        {part.highlightAreas
                            // Filter all highlights on the current page
                            .filter((el) => el.pageIndex === props.pageIndex)
                            .map((el, idx) => (
                                <div
                                    key={idx}
                                    style={{
                                        background: 'yellow',
                                        opacity: 0.4,
                                        ...props.getCssProperties(el, props.rotation)
                                    }}
                                />
                            ))}
                    </React.Fragment>
                ))}
        </div>
    );
    const pageNavigationPluginInstance = pageNavigationPlugin();

    const zoomPluginInstance = zoomPlugin();
    const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;
    const { CurrentPageLabel, GoToFirstPage, GoToLastPage, GoToNextPage, GoToPreviousPage, NumberOfPages, jumpToPage } =
        pageNavigationPluginInstance;

    const highlightPluginInstance = highlightPlugin({
        renderHighlightTarget,
        renderHighlights,
        renderHighlightContent
    });

    return (
        <>
            {/* @ts-ignore */}
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <Box
                    sx={(theme) => ({
                        height: 750,
                        marginTop: 10,
                        position: 'relative',
                        '& .rpv-core__inner-page': {
                            backgroundColor: `${theme.palette.primary[300]} !important`
                        }
                    })}
                >
                    {/* @ts-ignore */}
                    <Viewer
                        defaultScale={SpecialZoomLevel.PageFit}
                        scrollMode={ScrollMode.Page}
                        viewMode={ViewMode.SinglePage}
                        plugins={[zoomPluginInstance, highlightPluginInstance, pageNavigationPluginInstance, rotateInstance]}
                        fileUrl={fileUrl}
                    />
                    <Zoom ZoomIn={ZoomIn} ZoomOut={ZoomOut} CurrentScale={CurrentScale} />
                </Box>
            </Worker>
            <Pagination
                CurrentPageLabel={CurrentPageLabel}
                GoToFirstPage={GoToFirstPage}
                GoToLastPage={GoToLastPage}
                GoToNextPage={GoToNextPage}
                GoToPreviousPage={GoToPreviousPage}
                NumberOfPages={NumberOfPages}
                jumpToPage={jumpToPage}
            />
        </>
    );
};

export default PdfViewer;
