import { gql } from '@apollo/client';

export const MUTATION_CREATE_USER_DATA_FOCUS_PREFERENCE = gql`
    mutation CreateUserDataFocusViewPreferences($data: CreateUserDataFocusViewPreferencesInput!) {
        createUserDataFocusViewPreferences(data: $data) {
            id
            dataFocusView {
                id
            }
            recordType {
                id
            }
        }
    }
`;

export const MUTATION_UPDATE_USER_DATA_FOCUS_PREFERENCE = gql`
    mutation UpdateUserDataFocusViewPreferences($data: UpdateUserDataFocusViewPreferencesInput!) {
        updateUserDataFocusViewPreferences(data: $data) {
            id
            dataFocusView {
                id
            }
            recordType {
                id
            }
        }
    }
`;
