import { IRecordFieldsList, IRecordFieldWithFieldName, IRecordHeaders } from 'ui-component/records/types';
import { getFieldName, NON_EDITABLE_FIELDS } from 'ui-component/records/utils';
import { splitHeaders } from 'ui-component/records/utils/headerHelpers';
import { getAditionalFieldsValues } from 'ui-component/RecordView/PropertiesPanel/utils';
import { getFormattedValue } from 'ui-component/RecordView/utils';
import { ObjectProperty } from 'views/backoffice/CustomObjects/types';
import { splitObjectValues } from 'views/Portal/utils';
import { PropertyValuePayload } from 'views/TenantProfileSettings/components/types';
import { FormSection, FormSectionField, FormSectionWithChildren } from './types';
import { S3Uploader } from 'utils/S3Uploader';
import { v4 as uuidv4 } from 'uuid';
import { IUserDataToken } from 'utils/types';

/**
 * returns the array ordered by given attribute if no attirbute is given
 * use 'order' attribute
 *
 * @param arr {Array}
 * @param type {ASC | DESC}
 * @param attribute {keyof arr[number]}
 */
export const orderArrayByAttribute = <T extends Record<string, any>>(
    arr: T & { order: number }[],
    type: 'ASC' | 'DESC' = 'ASC',
    attribute: keyof T = 'order'
) => {
    const orderedArr = [...arr].sort((a, b) => (type === 'ASC' ? a[attribute] - b[attribute] : b[attribute] - a[attribute]));
    return orderedArr;
};

/**
 * return the number of columns to be used in Grid component
 * depending of the given number
 *
 * @param columnNumber {number}
 */
export const getGridColumns = (columnNumber: number) => {
    switch (columnNumber) {
        case 1:
            return 12;
        case 2:
            return 6;
        case 3:
            return 4;
        default:
            return 12;
    }
};

export const generateFormSectionWithChilds = (sections: FormSection[]): FormSectionWithChildren[] => {
    const orderedSections = orderArrayByAttribute(sections);
    const childSections = orderedSections.filter((el) => el.parent?.id);

    const sectionsWithChildrenById: Record<number, FormSectionWithChildren> = orderedSections
        .filter((el) => !el.parent?.id)
        .map((el) => ({ ...el, children: [] }))
        .reduce((acc, el) => ({ ...acc, [+el.id]: el }), {});

    for (const section of childSections) {
        const parentSection = section.parent?.id && sectionsWithChildrenById[+section.parent.id];
        if (section.parent?.id && parentSection)
            sectionsWithChildrenById[+section.parent.id] = { ...parentSection, children: [...(parentSection.children || []), section] };
    }

    return Object.values(sectionsWithChildrenById);
};

/**
 * Generate the object what is used to reset the form
 *
 * @param headers {IRecordFieldsList}
 * @param data {IRecordHeaders}
 */
export const formatDataToForm = (headers?: IRecordFieldsList, data?: IRecordHeaders) => {
    if (!headers || !data) return {};
    const { additionalFieldHeaders, baseFieldHeaders } = splitHeaders(headers);

    const baseObj: Record<string, any> = {};

    const { objectFields, nonObjectFields } = splitObjectValues(data.additionalFields);

    for (const [key, value] of Object.entries(baseFieldHeaders)) {
        if (value?.dataType === 'dropDown' || value?.dataType === 'dropdown') {
            baseObj[key] = +(data[key as keyof IRecordHeaders] as Record<string, any>)?.id || '';
        } else if (key === 'recordFileId') {
            baseObj[key] = +(data['recordFile' as keyof IRecordHeaders] as Record<string, any>)?.id || '';
        } else {
            baseObj[key] = data[key as keyof IRecordHeaders];
        }
    }

    const additionalFields = getAditionalFieldsValues(additionalFieldHeaders, nonObjectFields);

    for (const field of objectFields) {
        let [, , fieldId] = field.tag.split(';');
        fieldId = fieldId || field.tag.split(':')[1].split(';')[1];
        const key = getFieldName(headers, fieldId || fieldId);
        if (field.objectValue?.objectValues)
            for (const objectValue of field.objectValue?.objectValues) {
                const propertyName = objectValue.objectProperty.name;
                if (baseObj[key]) {
                    baseObj[key][propertyName] = objectValue.value;
                } else {
                    baseObj[key] = {
                        [propertyName]: objectValue.value
                    };
                }
            }
    }

    return { ...baseObj, ...additionalFields };
};

export const generateDataFocusViewPayload = async (
    headers?: IRecordFieldsList,
    objectDefinitions?: Record<string, Record<string, ObjectProperty>>,
    data?: Record<string, any>,
    userData?: IUserDataToken
) => {
    if (!headers || !objectDefinitions || !data) return {};
    const objectFields = Object.fromEntries(
        Object.entries(data).filter(([key, val]) => objectDefinitions[key] && Object.values(val).some((el) => el))
    );
    const { baseFieldHeaders, additionalFieldHeaders } = splitHeaders(headers);
    const objectsToCreate: Record<string, PropertyValuePayload[]> = {};

    for (const key in objectFields) {
        if (Object.prototype.hasOwnProperty.call(objectFields, key)) {
            const values: Record<string, string> = objectFields[key];
            const payload: PropertyValuePayload[] = Object.entries(values)
                .filter(([_valueKey, val]) => val)
                .map(([valueKey, value]) => {
                    const propertyId = +objectDefinitions[key][valueKey].id;

                    return { propertyId, value: String(value) };
                });

            objectsToCreate[key] = payload;
        }
    }

    const baseData = Object.fromEntries(
        Object.entries(data)
            .filter(([key]) => !(NON_EDITABLE_FIELDS as ReadonlyArray<string>).includes(key) && Object.keys(baseFieldHeaders).includes(key))
            .map(([key, value]) => {
                const newValue = getFormattedValue(value, baseFieldHeaders[key]);
                return [key, newValue];
            })
    );

    const additionalFields: { tag: string; value: any; objectValuesByProperty?: PropertyValuePayload[] }[] = [];

    const filteredKeys = Object.keys(data)
        .filter(
            (key) =>
                (additionalFieldHeaders[key]?.dataType !== 'object' && !!data[key]) ||
                (additionalFieldHeaders[key]?.dataType === 'object' && !!objectFields[key]) ||
                additionalFieldHeaders[key]?.dataType === 'attachment'
        )
        .filter((key) => Object.keys(additionalFieldHeaders).includes(key))
        .filter((key) => getFormattedValue(data[key], additionalFieldHeaders[key]) !== undefined);

    await Promise.all(
        filteredKeys.map(async (key) => {
            const additionalFieldHeaderId = additionalFieldHeaders[key]?.id;

            if (additionalFieldHeaders[key]?.dataType === 'attachment') {
                if (!data[key]) {
                    additionalFields.push({
                        tag: `;;${additionalFieldHeaderId}`,
                        value: data[key],
                        objectValuesByProperty: undefined
                    });

                    return;
                }

                // Can be array or File
                const value = data[key];
                let newValue;
                if (Array.isArray(value)) {
                    newValue = value;
                } else {
                    const s3Id = await S3Uploader(data[key]);
                    const newRow = {
                        id: uuidv4(),
                        name: value.name,
                        description: data[`tmp_${key}_description`] || '',
                        fileType: `application/${value?.name?.split('.')?.slice(-1)?.[0] || ''}`,
                        fileSize: (value.size / 1024).toFixed(2),
                        createdAt: new Date().toISOString(),
                        createdBy: userData?.userId,
                        fileUrl: s3Id
                    };
                    newValue = value && s3Id ? [newRow] : [];
                }

                delete data[`tmp_${key}_description`];

                additionalFields.push({
                    tag: `;;${additionalFieldHeaderId}`,
                    value: JSON.stringify(newValue),
                    objectValuesByProperty: undefined
                });
            } else {
                additionalFields.push({
                    tag: `;;${additionalFieldHeaderId}`,
                    value: getFormattedValue(data[key], additionalFieldHeaders[key]),
                    objectValuesByProperty: additionalFieldHeaders[key]?.dataType === 'object' ? objectsToCreate[key] : undefined
                });
            }
        })
    );

    return { ...baseData, additionalFields };
};

/**
 * Return all object definition ids of the headers identify with its field name
 *
 * @param headers {Partial<IRecordFieldsList>}
 */
export const getObjectDefinitionIds = (headers?: Partial<IRecordFieldsList>) => {
    if (!headers || !Object.keys(headers).length) return {};

    const ids: Record<string, number> = {};

    for (const key in headers) {
        if (Object.prototype.hasOwnProperty.call(headers, key)) {
            const element = headers[key];
            if (element?.objectDefinition?.id) ids[key] = Number(element.objectDefinition.id);
        }
    }

    return ids;
};

/**
 * Returns and object with array of properties with the field name as key
 *
 * @param properties {ObjectProperty[]}
 * @param objectIdsByField {Object}
 */
export const getObjectDefinitionByFieldName = (properties?: ObjectProperty[], objectIdsByField?: Record<string, number>) => {
    if (!properties || !objectIdsByField || !properties.length) return {};

    const list: Record<string, Record<string, ObjectProperty>> = {};

    for (const property of properties) {
        if (property.objectDefinition && property.objectDefinition?.id) {
            const field = Object.entries(objectIdsByField).find(
                ([key, value]) => property.objectDefinition?.id && value && value === +property.objectDefinition.id
            );
            if (field)
                list[field[0]] = {
                    ...(list[field[0]] || {}),
                    [property.name]: property
                };
        }
    }

    return list;
};

/**
 * Filters if the field exist in the headers for the record type. This is useful when the user
 * disabled or hide some fields but they already exist in Data Focus view configuration
 *
 * @param headers {Object} headers of the record type
 * @param objectPropertiesByFieldName {Object} object of properties ordered By field name
 */
export const filterFieldsEnabledByRecordType =
    (headers: Record<string, IRecordFieldWithFieldName>, objectPropertiesByFieldName?: Record<string, Record<string, ObjectProperty>>) =>
    (field: FormSectionField & { order: number }) => {
        if (!field.enabled) return false;

        const isField = !!field.baseField || !!field.recordAdditionalFieldsByType;

        if (isField) return !!headers[field.baseField || field.recordAdditionalFieldsByType?.name];

        if (!objectPropertiesByFieldName) return false;

        const fieldHeaderOfObject = Object.entries(objectPropertiesByFieldName).find(([, properties]) => {
            const itExist = Object.keys(properties).includes(field.objectProperty.name);
            return itExist;
        });

        return fieldHeaderOfObject && !!headers[fieldHeaderOfObject[0]];
    };
