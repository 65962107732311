import { Box, IconButton, IconButtonProps, Radio, Tooltip, Typography, unstable_composeClasses as composeClasses } from '@mui/material';
import {
    DataGridProProps,
    getDataGridUtilityClass,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridColDef,
    GridGroupingColDefOverride,
    GridGroupNode,
    GridRenderCellParams,
    GridValueFormatterParams,
    GridValueGetterParams,
    useGridApiContext,
    useGridRootProps
} from '@mui/x-data-grid-pro';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { RenderGridHeader } from 'ui-component/grids/components';
import { RenderActions } from './components';
import { RecordWithHierarchy } from '.';
import { IRecordType } from 'types/recordType';
import useIsAllowedByRecordType from 'hooks/useIsAllowedByRecordType';
// import { IUser } from 'views/backoffice/users/types';
import { format } from 'date-fns';
import { IRecordHeaders } from 'ui-component/records/types';

export const MAX_HIERARCHY_LEVEL = 4;

export const getHierarchyColumns: (
    recordId: string,
    onAddChildRecord: (recordType: IRecordType, record: RecordWithHierarchy) => void,
    onLinkParentRecord?: () => void,
    showCheckbox?: boolean
) => GridColDef[] = (recordId, onAddChildRecord, onLinkParentRecord, showCheckbox) => {
    const allColumns: GridColDef[] = [
        // {
        //     field: 'id',
        //     headerName: 'ID',
        //     width: 200,
        //     renderHeader: RenderGridHeader,
        //     valueGetter: (params: GridValueGetterParams<RecordWithHierarchy, string>) => `${capitalize(params.row.recordType.name)}-${params.value}`,
        //     renderCell: (params: GridRenderCellParams<RecordWithHierarchy, string>) => (
        //         <Typography variant="h6" color="secondary" fontWeight={400} fontSize={14} sx={{ textDecoration: 'underline' }}>
        //             {params.value}
        //         </Typography>
        //     )
        // },
        {
            field: 'recordType',
            headerName: 'Record Type',
            width: 200,
            renderHeader: (params) => <RenderGridHeader name={params.colDef.headerName || ''} />,
            valueGetter: (params: GridValueGetterParams<RecordWithHierarchy, string>) => params.row.recordType?.name || ''
        },
        {
            field: 'recordNumber',
            headerName: 'Record Number',
            width: 200,
            renderHeader: (params) => <RenderGridHeader name={params.colDef.headerName || ''} />,
            valueGetter: (params: GridValueGetterParams<RecordWithHierarchy, string>) => params.row.recordNumber || ''
        },
        {
            field: 'createdAt',
            headerName: 'Created on',
            width: 200,
            renderHeader: (params) => <RenderGridHeader name={params.colDef.headerName || ''} />,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                params.value ? format(new Date(params.value), 'MM/dd/yyyy hh:mm a') : ''
        },
        // {
        //     field: 'createdBy',
        //     headerName: 'Created By',
        //     width: 200,
        //     renderHeader: (params) => <RenderGridHeader name={params.colDef.headerName || ''} />,
        //     valueFormatter: (params: GridValueFormatterParams<Pick<IUser, 'id' | 'name'>>) => params.value?.name || ''
        // },
        {
            field: 'updatedAt',
            headerName: 'Updated On',
            width: 200,
            renderHeader: (params) => <RenderGridHeader name={params.colDef.headerName || ''} />,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                params.value ? format(new Date(params.value), 'MM/dd/yyyy hh:mm a') : ''
        },
        // {
        //     field: 'updatedBy',
        //     headerName: 'Last modified by',
        //     width: 200,
        //     renderHeader: (params) => <RenderGridHeader name={params.colDef.headerName || ''} />,
        //     valueFormatter: (params: GridValueFormatterParams<Pick<IUser, 'id' | 'name'>>) => params.value?.name || ''
        // },
        {
            field: 'identifier',
            headerName: 'Identifier',
            width: 150,
            renderHeader: (params) => <RenderGridHeader name={params.colDef.headerName || ''} />,
            valueGetter: (params: GridValueGetterParams<RecordWithHierarchy, string>) =>
                +params.row.id === +recordId ? 'This record' : null,
            renderCell: (params: GridRenderCellParams<RecordWithHierarchy, string>) => (
                <Typography variant="h6" fontWeight={700} fontSize={12}>
                    {params.value}
                </Typography>
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            renderHeader: (params) => <RenderGridHeader name={params.colDef.headerName || ''} />,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            disableReorder: true,
            align: 'center',
            renderCell: ({ row }: GridRenderCellParams<RecordWithHierarchy>) => (
                <RenderActions
                    row={row as IRecordHeaders}
                    onAddChildRecord={(recordType) => onAddChildRecord(recordType, row)}
                    onLinkParentRecord={onLinkParentRecord}
                />
            )
        }
    ];
    if (showCheckbox) {
        allColumns.unshift({
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            renderHeader: () => null,
            renderCell: (params) => <RenderCheckbox {...params} />
        });
    }
    return allColumns;
};

const RenderCheckbox = ({ id, value }: GridRenderCellParams<RecordWithHierarchy>) => {
    const apiRef = useGridApiContext();
    const handleClick = () => {
        apiRef.current.setRowSelectionModel([id]);
    };
    return <Radio checked={value} onClick={handleClick} />;
};

export const hierarchyGridInitialState: GridInitialStatePro = { pinnedColumns: { right: ['identifier', 'actions'] } };

const useUtilityClasses = (ownerState: { classes: DataGridProProps['classes'] }) => {
    const { classes } = ownerState;

    const slots = {
        root: ['treeDataGroupingCell'],
        toggle: ['treeDataGroupingCellToggle']
    };

    return composeClasses(slots, getDataGridUtilityClass, classes);
};

/**
 * Reproduce the behavior of the `GridTreeDataGroupingCell` component in `@mui/x-data-grid-pro`
 * But base the amount of children on a `row.descendantCount` property rather than on the internal lookups.
 */
const GroupingCellWithLazyLoading = (props: GridRenderCellParams<RecordWithHierarchy> & { initialRecordId: number }) => {
    const { id, field, rowNode, row, formattedValue, initialRecordId } = props;

    const canViewRecordType = useIsAllowedByRecordType('allow', +row.recordType?.id);

    const rootProps = useGridRootProps();
    const apiRef = useGridApiContext();
    const classes = useUtilityClasses({ classes: rootProps.classes });

    const Icon = (rowNode as GridGroupNode).childrenExpanded ? ExpandLess : ExpandMore;

    const handleClick: IconButtonProps['onClick'] = (event) => {
        apiRef.current.setRowChildrenExpansion(id, !(rowNode as GridGroupNode).childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };

    // eslint-disable-next-line no-nested-ternary
    const label = formattedValue
        ? canViewRecordType.isAllowed
            ? formattedValue
            : formattedValue.slice(0, formattedValue.indexOf('-') + 1).concat('XXXXX')
        : (rowNode as GridGroupNode).groupingKey;

    return (
        <Box className={classes.root} sx={{ ml: rowNode.depth * 2 }}>
            <div className={classes.toggle}>
                {!row.isLoading && (
                    <IconButton
                        size="small"
                        onClick={handleClick}
                        tabIndex={-1}
                        aria-label={
                            (rowNode as GridGroupNode).childrenExpanded
                                ? apiRef.current.getLocaleText('treeDataCollapse')
                                : apiRef.current.getLocaleText('treeDataExpand')
                        }
                    >
                        <Icon fontSize="inherit" />
                    </IconButton>
                )}
            </div>
            <Tooltip
                disableFocusListener={canViewRecordType.isAllowed}
                disableHoverListener={canViewRecordType.isAllowed}
                disableTouchListener={canViewRecordType.isAllowed}
                title="Your role does not have permissions to view this record, so viewing is disabled"
                placement="top"
            >
                <Typography
                    component={+initialRecordId === +row.id || !canViewRecordType.isAllowed ? 'p' : 'a'}
                    href={`./?recordId=${row.id}&recordType=${row.recordType?.id || ''}`}
                    variant="h6"
                    color="secondary"
                    fontWeight={400}
                    fontSize={14}
                    sx={{ textDecoration: 'underline' }}
                >
                    {label}
                </Typography>
            </Tooltip>
        </Box>
    );
};

export const getCustomGroupingColDef = (initialRecordId: number): GridGroupingColDefOverride => ({
    headerName: 'ID',
    width: 300,
    renderHeader: (params) => <RenderGridHeader name={params.colDef.headerName || ''} />,
    renderCell: (params) => <GroupingCellWithLazyLoading {...params} initialRecordId={initialRecordId} />
});

export const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy;
