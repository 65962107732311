// @ts-nocheck
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import { CurrentPageLabelProps, GoToPageProps, NumberOfPagesProps } from '@react-pdf-viewer/page-navigation';
import React, { ChangeEvent } from 'react';

interface IPagination {
    CurrentPageLabel: (props: CurrentPageLabelProps) => React.ReactElement;
    GoToFirstPage: (props: GoToPageProps) => React.ReactElement;
    GoToLastPage: (props: GoToPageProps) => React.ReactElement;
    GoToNextPage: (props: GoToPageProps) => React.ReactElement;
    GoToPreviousPage: (props: GoToPageProps) => React.ReactElement;
    NumberOfPages: (props: NumberOfPagesProps) => React.ReactElement;
    jumpToPage: (pageIndex: number) => void;
}

/**
 * @deprecated Now we are using the Pagination component from RecordView
 */
const Pagination = ({
    CurrentPageLabel,
    GoToFirstPage,
    GoToLastPage,
    GoToNextPage,
    GoToPreviousPage,
    jumpToPage,
    NumberOfPages
}: IPagination) => (
    <Grid container justifyContent="center" alignItems="center">
        <GoToFirstPage>
            {(props) => (
                <IconButton onClick={props.onClick} disabled={props.isDisabled} color="secondary" size="small" disableFocusRipple>
                    <ChevronLeft style={{ marginRight: -15 }} />
                    <ChevronLeft />
                </IconButton>
            )}
        </GoToFirstPage>
        <GoToPreviousPage>
            {(props) => (
                <IconButton onClick={props.onClick} disabled={props.isDisabled} color="secondary" size="small" disableFocusRipple>
                    <ChevronLeft />
                </IconButton>
            )}
        </GoToPreviousPage>
        <Grid item>
            <CurrentPageLabel>
                {(props) => (
                    <TextField
                        hiddenLabel
                        value={props.currentPage + 1}
                        variant="standard"
                        type="number"
                        InputProps={{
                            disableUnderline: true
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => jumpToPage(Number(e.target.value) - 1)}
                        size="small"
                        sx={{
                            width: 20,
                            height: 20,
                            bgcolor: 'white',
                            mx: 2,
                            '& input': {
                                textAlign: 'center'
                            },
                            '& input::-webkit-outer-spin-button,& input::-webkit-inner-spin-button': {
                                WebkitAppearance: 'none',
                                margin: 0
                            },
                            '& input[type=number]': {
                                MozAppearance: 'textfield'
                            }
                        }}
                    />
                )}
            </CurrentPageLabel>
            <NumberOfPages>{(props) => <Typography variant="caption">de {props.numberOfPages}</Typography>}</NumberOfPages>
        </Grid>
        <GoToNextPage>
            {(props) => (
                <IconButton onClick={props.onClick} disabled={props.isDisabled} color="secondary" size="small" disableFocusRipple>
                    <ChevronRight />
                </IconButton>
            )}
        </GoToNextPage>
        <GoToLastPage>
            {(props) => (
                <IconButton onClick={props.onClick} disabled={props.isDisabled} color="secondary" size="small" disableFocusRipple>
                    <ChevronRight style={{ marginRight: -15 }} />
                    <ChevronRight />
                </IconButton>
            )}
        </GoToLastPage>
    </Grid>
);

export default Pagination;
