import { Menu, MenuItem } from '@mui/material';
import React from 'react';

export type OperatorsMenuProps = {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    onSelect: (operator: string) => void;
    operators: string[];
};
export const OperatorsMenu = ({ open, anchorEl, onClose, onSelect, operators }: OperatorsMenuProps) => {
    const handleClick = (operator: string) => {
        onSelect(operator);
        onClose();
    };
    return (
        <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            PaperProps={{
                sx: {
                    background: '#F5F6F7 !important',
                    borderRadius: '8px',
                    width: '188px',
                    '& ul': { py: 0 }
                }
            }}
            anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            {operators.map((el, idx) => (
                <MenuItem
                    key={el}
                    onClick={() => handleClick(el)}
                    value={el}
                    sx={{
                        background: '#F5F6F7 !important',
                        borderBottom: idx + 1 === operators.length ? undefined : '1px solid #D9D9D9'
                    }}
                >
                    {el}
                </MenuItem>
            ))}
        </Menu>
    );
};
