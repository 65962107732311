import { SvgIcon, SvgIconProps } from '@mui/material';

export const Export = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
            <mask id="mask0_1472_69102" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1472_69102)">
                <path
                    d="M2.66732 13.3333C2.30065 13.3333 1.98687 13.2028 1.72598 12.942C1.46465 12.6806 1.33398 12.3666 1.33398 12V3.99996C1.33398 3.63329 1.46465 3.31951 1.72598 3.05863C1.98687 2.79729 2.30065 2.66663 2.66732 2.66663H8.00065V3.99996H2.66732V12H13.334V10.6666H14.6673V12C14.6673 12.3666 14.5369 12.6806 14.276 12.942C14.0147 13.2028 13.7007 13.3333 13.334 13.3333H2.66732Z"
                    fill="currentColor"
                />
                <path
                    d="M10.2822 8.26465L9.10293 7.08535L11.8774 4.31085L9.90207 4.42883L9.99219 2.6582L15.009 2.3586L14.7094 7.3754L12.9369 7.49687L13.0567 5.49015L10.2822 8.26465Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    </SvgIcon>
);

/*
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <mask id="mask0_1472_69102" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
    <rect width="16" height="16" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_1472_69102)">
    <path d="M2.66732 13.3333C2.30065 13.3333 1.98687 13.2028 1.72598 12.942C1.46465 12.6806 1.33398 12.3666 1.33398 12V3.99996C1.33398 3.63329 1.46465 3.31951 1.72598 3.05863C1.98687 2.79729 2.30065 2.66663 2.66732 2.66663H8.00065V3.99996H2.66732V12H13.334V10.6666H14.6673V12C14.6673 12.3666 14.5369 12.6806 14.276 12.942C14.0147 13.2028 13.7007 13.3333 13.334 13.3333H2.66732Z" fill="#37326E"/>
    <path d="M10.2822 8.26465L9.10293 7.08535L11.8774 4.31085L9.90207 4.42883L9.99219 2.6582L15.009 2.3586L14.7094 7.3754L12.9369 7.49687L13.0567 5.49015L10.2822 8.26465Z" fill="#37326E"/>
  </g>
</svg>

*/
