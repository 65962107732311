import { Bolt, Event } from '@mui/icons-material';
import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from 'react';

export type DateTimeEditCellProps = {
    value: string | null | Date;
    field: string;
    onChange: (newValue: string | null | Date) => void;
    hideLabel?: boolean;
    standard?: boolean;
    inputSx?: SystemStyleObject;
};

export const DateTimeEditCell = (props: DateTimeEditCellProps) => {
    const { value, field, onChange, hideLabel, standard = false, inputSx = {} } = props;
    const [dateValue, setDateValue] = useState<string | Date | null>(value || null);

    const handleChange = async (newValue: string | null | Date) => {
        if (newValue === undefined) return;
        setDateValue(newValue);
        onChange(newValue ?? '');
    };

    useEffect(() => {
        value && setDateValue(value);
    }, [value]);

    return (
        <Box sx={{ minWidth: 149, width: standard ? 'calc(100% - 10px)' : '100%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} localeText={{ todayButtonLabel: 'NOW' }}>
                <DateTimePicker
                    label={!hideLabel && field}
                    value={dateValue}
                    onChange={handleChange}
                    components={{
                        OpenPickerIcon: () => (
                            <Tooltip title="Pick a date">
                                <Event />
                            </Tooltip>
                        )
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{ width: '100%', '& .MuiInputBase-root.MuiInput-root': { height: '29px' }, ...inputSx }}
                            variant={standard ? 'standard' : 'outlined'}
                            size={standard ? 'small' : 'medium'}
                            InputProps={{
                                ...params.InputProps,
                                size: standard ? 'small' : 'medium',
                                endAdornment: (
                                    <>
                                        {!standard && (
                                            <Tooltip title="Now">
                                                <IconButton
                                                    onClick={() => handleChange(new Date())}
                                                    sx={{ transform: 'translateX(15px)', marginLeft: '-20px' }}
                                                >
                                                    <Bolt />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {params.InputProps?.endAdornment}
                                    </>
                                )
                            }}
                        />
                    )}
                    componentsProps={{
                        actionBar: {
                            actions: ['today']
                        }
                    }}
                />
            </LocalizationProvider>
        </Box>
    );
};
