import { SvgIcon, SvgIconProps } from '@mui/material';

export const PinToLeft = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M2 22L7.83333 16.0541M7.83333 16.0541L4.27885 12.7991C3.73686 12.3028 3.89743 11.4109 4.5785 11.1348L9.44986 9.15996C9.66203 9.07395 9.83826 8.91775 9.94911 8.71744L13.0327 3.14551C13.352 2.5686 14.1324 2.45318 14.605 2.913L21.0681 9.20137C21.5387 9.65926 21.4478 10.4388 20.8844 10.7761L15.0782 14.2526C14.8837 14.369 14.7346 14.5481 14.6552 14.7604L13.0745 18.9899C12.8263 19.6539 11.9852 19.8561 11.4624 19.3773L7.83333 16.0541Z"
                stroke="#858585"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    </SvgIcon>
);
