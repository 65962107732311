import { gql } from '@apollo/client';

export const MUTATION_REGISTER_RECORD = gql`
    mutation RegisterRecordHeader($createRecordHeader: RecordHeaderRegisterInput!) {
        registerRecordHeader(createRecordHeader: $createRecordHeader) {
            id
            enabled
            createdBy {
                id
                name
            }
            problemCodeId {
                id
                name
            }
            receivedAt
            receivedBy {
                id
                name
                email
            }
            recordNumber
            recordFile {
                id
            }
            recordType {
                id
                name
            }
            statusId {
                id
                name
            }
            targetTenant
            tenant {
                id
                name
            }
            updatedAt
            createdAt
            assignedTo {
                id
                name
                email
            }
            approvedBy {
                email
                name
                id
            }
            approvedAt
            targetTransmissionTenant {
                id
                name
            }
            isProject
            level
            parent {
                id
                recordType {
                    id
                    name
                }
            }
            children {
                id
                recordType {
                    id
                    name
                }
            }
            additionalFields {
                id
                objectValue {
                    id
                    objectValues {
                        value
                        tag
                        objectProperty {
                            id
                            name
                            isDisplayable
                            dataType
                            order
                        }
                    }
                }
                tag
                value
            }
        }
    }
`;

export const MUTATION_UPLOAD_FILE = gql`
    mutation UploadRecordFile($file: Upload!) {
        uploadRecordFile(file: $file) {
            id
        }
    }
`;

export const MUTATION_SAVE_ANNOTATION = gql`
    mutation AddAnnotation($data: AddAnnotationInput!) {
        addAnnotation(data: $data) {
            id
        }
    }
`;
