import { useMemo } from 'react';
import useMyRole from './useMyRole';
import useMyMenuItems from './useMyMenuItems';

const useIsAllowedByRecordType = (action: string, recordTypeId?: number) => {
    const roleId = useMyRole();
    const { getMenuPermissionsByRecordTypeId, loading } = useMyMenuItems();

    const roleActions = useMemo(() => {
        if (recordTypeId) {
            return getMenuPermissionsByRecordTypeId?.(recordTypeId, (roleId ?? 0) as number);
        }

        return [];
    }, [getMenuPermissionsByRecordTypeId, recordTypeId, roleId]);

    const isAllowed = useMemo(
        () =>
            roleActions
                ?.filter((el) => {
                    const name = el.name.toLowerCase();
                    return name === action;
                })
                ?.some((el) => el.enabled),
        [action, roleActions]
    );

    return {
        isAllowed,
        loading
    };
};

export default useIsAllowedByRecordType;
