import { Tooltip, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const RenderGridHeader = ({ name, required }: { name: string; required?: boolean }) => {
    const defaultMessage = name;

    return (
        <>
            {required ? (
                <Tooltip title="This field is required">
                    <Typography variant="h6" fontWeight={700} fontSize={16} textTransform="capitalize">
                        <FormattedMessage id={name} defaultMessage={defaultMessage} /> <span style={{ color: 'red' }}>{' *'}</span>
                    </Typography>
                </Tooltip>
            ) : (
                <Typography variant="h6" fontWeight={700} fontSize={16} textTransform="capitalize">
                    <FormattedMessage id={name} defaultMessage={defaultMessage} />
                </Typography>
            )}
        </>
    );
};
