import { Grid, Typography, Box, ClickAwayListener, IconButton } from '@mui/material';
import { TextFieldv2 } from '../TextFieldv2';
import { Close } from '@mui/icons-material';
import { useLazyQuery } from '@apollo/client';
import { QUERY_FIND_OBJECT } from 'graphql/queries/customObjects';
import { ObjectProperty, FindObjects, FindObjectsVariables, IObjectRaw } from 'views/backoffice/CustomObjects/types';
import { useEffect } from 'react';
import { InputMaskPhone } from 'ui-component/RecordView/components/InputMaskPhoneCell';

export type ObjectSideInputProps = {
    title: string;
    inputDefinition: Pick<IObjectRaw, 'id' | 'name'>;
    onClose: () => void;
    value: Record<string, any>;
    onChange: (value: Record<string, any>) => void;
    objectFields?: ObjectProperty[];
};

export const ObjectSideInput = ({ title, inputDefinition, onClose, value, objectFields, onChange }: ObjectSideInputProps) => {
    const [findObject, { data: objectData }] = useLazyQuery<FindObjects, FindObjectsVariables>(QUERY_FIND_OBJECT);

    const fields =
        objectFields ||
        [...(objectData?.findObjects[0].objectProperties || [])].sort((a, b) => a.order - b.order).filter((el) => el.enabled);

    // console.log('THIS ORDERED FIELDS', fields);

    const handleChangeProperty = (id: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ ...value, [id]: e.target.value });
    };

    useEffect(() => {
        if (inputDefinition.id && !objectFields) {
            findObject({ variables: { data: { ids: [+inputDefinition.id] } } });
        }
    }, [findObject, inputDefinition.id, objectFields]);

    return (
        <ClickAwayListener onClickAway={onClose}>
            <Box
                component={Grid}
                container
                flexDirection="column"
                sx={(theme) => ({
                    width: '100%',
                    height: '100%',
                    mt: '15px',
                    pt: '12px',
                    pb: '8px',
                    px: '8px',
                    bgcolor: theme.palette.primary[300]
                })}
            >
                <Grid item container xs="auto" alignItems="center" sx={{ mb: '15px' }}>
                    <Grid item xs>
                        <Typography fontSize="16px" fontWeight={500} color="secondary">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <IconButton
                            sx={({ palette }) => ({
                                color: palette.primary[400],
                                backgroundColor: 'white',
                                borderRadius: '30px',
                                width: '40px',
                                height: '40px'
                            })}
                            onClick={onClose}
                            color="primary"
                        >
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>

                {fields.map(({ id, name, isRequired, dataType }, idx) => (
                    <Grid key={id} item container xs="auto" sx={{ mb: '6px' }}>
                        <Grid item xs={12} sx={{ mb: '8px' }}>
                            <Typography fontSize="12px" fontWeight={500} color="#858585" textTransform="capitalize">
                                {name}{' '}
                                {isRequired && (
                                    <Typography component="span" color="error">
                                        *
                                    </Typography>
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: '8px' }}>
                            {/* TODO: delete this name validation once backend change data type for phone number */}
                            {dataType === 'phone' || name.toLowerCase().includes('phone') ? (
                                <InputMaskPhone
                                    TextFieldComponent={TextFieldv2}
                                    autoFocus={idx === 0}
                                    value={value[id] || ''}
                                    onChange={handleChangeProperty(+id)}
                                    countryCodeProps={{
                                        sx: {
                                            height: '100%',
                                            maxHeight: '37px',
                                            background: 'white',
                                            borderRadius: '8px',
                                            mr: '4px'
                                        },
                                        selectedOptionSx: { color: '#212121' }
                                    }}
                                    textFieldSx={{ '& .MuiInputBase-root': { backgroundColor: 'white' } }}
                                />
                            ) : (
                                <TextFieldv2
                                    autoFocus={idx === 0}
                                    value={value[id]}
                                    onChange={handleChangeProperty(+id)}
                                    sx={{ '& .MuiInputBase-root': { backgroundColor: 'white' } }}
                                />
                            )}
                        </Grid>
                    </Grid>
                ))}
            </Box>
        </ClickAwayListener>
    );
};
