import { Avatar, Box, Checkbox, Chip, Tooltip, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@mui/icons-material';
import { GridRenderCellParams } from '@mui/x-data-grid';

export const attachmentLogsColumns = [
    {
        field: 'idWithFilename',
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                Name
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => (
            <Tooltip title={row?.idWithFilename}>
                <Typography
                    sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {row?.idWithFilename}
                </Typography>
            </Tooltip>
        ),
        width: 180,
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'before',
        width: 280,
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                Before
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => {
            if (String(row.before) === 'true')
                return (
                    <Checkbox
                        disabled
                        checked
                        onChange={() => {}}
                        sx={(theme) => ({
                            padding: 0,
                            color: theme.palette.primary[400],
                            cursor: 'default !important',
                            transition: 'none !important',
                            '&.Mui-checked': { color: theme.palette.primary.dark },
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                        })}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        checkedIcon={<CheckBoxOutlined />}
                    />
                );
            if (String(row.before) === 'false')
                return (
                    <Checkbox
                        disabled
                        checked={false}
                        onChange={() => {}}
                        sx={(theme) => ({
                            padding: 0,
                            color: theme.palette.primary[400],
                            cursor: 'default !important',
                            transition: 'none !important',
                            '&.Mui-checked': { color: theme.palette.primary.dark },
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                        })}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        checkedIcon={<CheckBoxOutlined />}
                    />
                );
            return (
                <span
                    style={{
                        wordBreak: 'break-word'
                    }}
                >
                    {row.before || '--'}
                </span>
            );
        },
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'after',
        width: 280,
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                After
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => {
            if (String(row.after) === 'true')
                return (
                    <Checkbox
                        disabled
                        checked
                        onChange={() => {}}
                        sx={(theme) => ({
                            padding: 0,
                            cursor: 'default !important',
                            transition: 'none !important',
                            color: theme.palette.primary[400],
                            '&.Mui-checked': { color: theme.palette.primary.dark },
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                        })}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        checkedIcon={<CheckBoxOutlined />}
                    />
                );
            if (String(row.after) === 'false')
                return (
                    <Checkbox
                        disabled
                        checked={false}
                        onChange={() => {}}
                        sx={(theme) => ({
                            padding: 0,
                            cursor: 'default !important',
                            transition: 'none !important',
                            color: theme.palette.primary[400],
                            '&.Mui-checked': { color: theme.palette.primary.dark },
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                        })}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        checkedIcon={<CheckBoxOutlined />}
                    />
                );
            return (
                <span
                    style={{
                        wordBreak: 'break-word'
                    }}
                >
                    {row.after || '--'}
                </span>
            );
        },
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'type',
        width: 140,
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                Type
            </Typography>
        ),
        renderCell: () => <Typography>Attachments</Typography>,
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'activity',
        width: 100,
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                Activity
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => {
            const color = () => {
                if (row.activity === 'create') {
                    return '#2E7D32';
                }

                if (row.activity === 'update') {
                    return '#FF9800';
                }

                if (row.activity === 'delete') {
                    return '#D32F2F';
                }

                return '#858585';
            };
            const bgcolor = () => {
                if (row.activity === 'create') {
                    return '#E3F8E8';
                }

                if (row.activity === 'update') {
                    return '#FFFDE7';
                }

                if (row.activity === 'delete') {
                    return '#FBE9E7';
                }

                return '#858585';
            };

            return (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Chip
                        label={
                            <Typography
                                textTransform="capitalize"
                                sx={{
                                    color: color(),
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    lineHeight: '19.9px',
                                    letterSpacing: '0.4px',
                                    bgcolor: bgcolor()
                                }}
                            >
                                <FormattedMessage id={row.activity} />
                            </Typography>
                        }
                        sx={{
                            border: `1px solid ${color()}`,
                            backgroundColor: 'transparent',
                            minWidth: '80px',
                            bgcolor: bgcolor()
                        }}
                    />
                </Box>
            );
        },
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'user',
        width: 160,
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                User
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => (
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Box>
                    <Avatar
                        sx={(theme) => ({
                            bgcolor: '#FFF',
                            border: `1px solid ${theme.palette.secondary.main}`,
                            width: 24,
                            height: 24,
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: 'center'
                        })}
                    >
                        {row.user.split('')[0]}
                    </Avatar>
                </Box>
                <Box>
                    <Typography
                        sx={(theme) => ({
                            color: '#54595E',
                            fontWeight: '500',
                            fontSize: '14px',
                            lineHeight: '21.98px',
                            letterSpacing: '0.1px'
                        })}
                    >
                        {`${row.user}`}
                    </Typography>
                </Box>
            </Box>
        ),
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'dateUpdated',
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                Date Updated
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => (
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Box>
                    <Typography
                        sx={(theme) => ({
                            color: '#54595E',
                            fontWeight: '500',
                            fontSize: '14px',
                            lineHeight: '21.98px',
                            letterSpacing: '0.1px'
                        })}
                    >
                        {format(new Date(row.dateUpdated), 'MMM d, y - h:mmaaaa')}
                    </Typography>
                </Box>
            </Box>
        ),
        width: 220,
        hideSortIcons: true,
        sortable: false
    }
];
