import React from 'react';
import {
    CurrentScaleProps,
    RenderCurrentScaleProps,
    RenderZoomInProps,
    RenderZoomOutProps,
    ZoomInProps,
    ZoomOutProps
} from '@react-pdf-viewer/zoom';
import { Grid, IconButton, Typography } from '@mui/material';
import { ZoomInTwoTone, ZoomOutTwoTone } from '@mui/icons-material';

interface IZoom {
    ZoomOut: (props: ZoomOutProps) => JSX.Element;
    ZoomIn: (props: ZoomInProps) => JSX.Element;
    CurrentScale: (props: CurrentScaleProps) => JSX.Element;
}

const Zoom = ({ ZoomOut, ZoomIn, CurrentScale }: IZoom) => {
    const ZoomOutButton = () => (
        <ZoomOut>
            {(props: RenderZoomOutProps) => (
                <IconButton onClick={props.onClick}>
                    <ZoomOutTwoTone />
                </IconButton>
            )}
        </ZoomOut>
    );

    const ZoomInButton = () => (
        <ZoomIn>
            {(props: RenderZoomInProps) => (
                <IconButton onClick={props.onClick}>
                    <ZoomInTwoTone />
                </IconButton>
            )}
        </ZoomIn>
    );
    return (
        <Grid container alignItems="center" sx={{ position: 'absolute', top: -60, left: '85%' }}>
            <Grid item>
                <ZoomInButton />
            </Grid>
            <Grid item>
                <CurrentScale>
                    {(props: RenderCurrentScaleProps) => <Typography variant="body1">{(props.scale * 100).toFixed(0)}%</Typography>}
                </CurrentScale>
            </Grid>
            <Grid item>
                <ZoomOutButton />
            </Grid>
        </Grid>
    );
};

export default Zoom;
