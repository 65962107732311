/* eslint-disable no-nested-ternary */
import { Box, Grid, SxProps, TextField, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FieldValues, UseFormResetField, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { RenderInput } from 'ui-component/RecordView/components';
import { IRecordField } from 'ui-component/records/types';
import { TextFieldv2, TextFieldv2Props } from 'views/Portal/components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export type FormInputProps =
    | {
          label: ReactNode | string;
          sx?: SxProps;
          isRequired?: boolean;
          noWrap?: boolean;
          name: string;
          defaultValue?: any;
      } & (
          | { isFormField?: false | undefined; formComponent: any; disabled?: boolean }
          | {
                name: string;
                isFormField: true;
                fieldDef?: IRecordField;
                disabled?: boolean;
                options?: any[];
                isCustomList?: boolean;
                resetField?: UseFormResetField<FieldValues>;
                defaultValue?: any;
                isObjectProperty?: boolean;
            }
      );

export const FormInlineInput = ({ label, sx, isRequired, noWrap, defaultValue, ...props }: FormInputProps) => {
    const methods = useFormContext();

    const alignToTop =
        props.isFormField &&
        props.fieldDef &&
        (['multiselect-checkbox', 'multiselect'] as Partial<IRecordField['dataType']>[]).includes(props.fieldDef.dataType);
    const errors = methods?.formState.errors;

    const inputComponent = props.isFormField ? (
        props.fieldDef ? (
            <RenderInput
                control={methods?.control}
                options={props.options}
                isCustomList={props.isCustomList}
                errors={errors}
                fieldData={props.fieldDef}
                name={props.name}
                resetField={props.resetField}
                disabled={props.disabled}
                hideTitle
                backgroundAlways
                TextFieldComponent={TextFieldv2}
                defaultValue={defaultValue}
                isObjectProperty={props.isObjectProperty}
                setValue={methods.setValue}
                TextFieldComponentProps={
                    {
                        onClear:
                            props.fieldDef.dataType === 'dropDown' || props.fieldDef.dataType === 'dropdown'
                                ? () => methods.setValue(props.name, '', { shouldValidate: true })
                                : undefined
                    } as TextFieldv2Props
                }
                fromDataFocusView
                ignoreDrawer
            />
        ) : (
            <TextField
                variant="filled"
                size="small"
                InputProps={{ hiddenLabel: true, disableUnderline: true, sx: { borderRadius: '8px' } }}
                fullWidth
            />
        )
    ) : (
        props.formComponent
    );

    return (
        <Grid container alignItems={alignToTop ? 'flex-start' : 'center'} sx={sx} flexWrap={noWrap ? 'nowrap' : 'wrap'}>
            <Grid item xs="auto" sx={{ minWidth: '148px', maxWidth: '148px !important', mt: alignToTop ? '15px' : undefined }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {typeof label === 'string' ? (
                        <Typography fontSize={12} variant="body1" fontWeight={500} color="#858585">
                            <FormattedMessage id={label} defaultMessage={label} />
                            {isRequired && (
                                <Typography
                                    component="span"
                                    fontSize="12px"
                                    fontWeight={500}
                                    color="#EF5350"
                                    sx={{ float: 'right', mt: '3px', mr: '5px' }}
                                >
                                    *
                                </Typography>
                            )}
                        </Typography>
                    ) : (
                        label
                    )}

                    {props?.name && errors?.[props?.name] && (
                        <Tooltip title={errors?.[props?.name]?.message} placement="top">
                            <InfoOutlinedIcon
                                sx={{
                                    fontSize: '1rem',
                                    color: '#EF5350',
                                    mr: '5px',
                                    cursor: 'pointer'
                                }}
                            />
                        </Tooltip>
                    )}
                </Box>
            </Grid>

            <Grid
                item
                xs="auto"
                sx={{
                    minWidth: 'calc(100% - 148px)',
                    maxWidth: noWrap ? 'calc(100% - 148px) !important' : undefined,
                    bgcolor: !props.isFormField && props.disabled ? '#e0e0e0' : undefined
                }}
            >
                {inputComponent}
            </Grid>
        </Grid>
    );
};
