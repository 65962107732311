import { IUserDataToken } from 'utils/types';
import jwt_decode from 'jwt-decode';
import { IGetUsers, IUser } from 'views/backoffice/users/types';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_USERS } from 'graphql/queries/users';
import { useEffect, useState } from 'react';

const useUser = () => {
    const token = localStorage.getItem('backend_jwt') ?? '';
    const userFromToken: IUserDataToken = jwt_decode(token);
    const [currentUser, setCurrentUser] = useState<IUser | null>(null);

    const [getUsers, { data: userData }] = useLazyQuery<IGetUsers>(QUERY_GET_USERS, {});
    useEffect(() => {
        if (userFromToken.userId) {
            getUsers({
                variables: {
                    data: {
                        filter: {
                            id: +userFromToken.userId
                        },
                        pagination: {
                            pageSize: 1,
                            pageNumber: 1
                        }
                    }
                }
            });
        }
    }, [userFromToken?.userId, getUsers]);

    useEffect(() => {
        if (!userData?.getSaasUsers?.length) return;
        setCurrentUser(userData?.getSaasUsers?.find(({ id }) => +id === +userFromToken.userId) as IUser);
    }, [userData?.getSaasUsers, userFromToken.userId]);

    return {
        userFromToken,
        currentUser
    };
};

export default useUser;
