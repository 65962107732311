import React, { FC, useState } from 'react';
import { Box, Typography, Button, Divider, Paper, TextField } from '@mui/material';
import { AttachmentRow } from '../types';

type Props = {
    fileRow?: AttachmentRow | null;
    onChangeDescription?: (description: string) => void;
    handleClose: () => void;
    isAllowedEditable?: boolean;
    doNotShowDescription?: boolean;
};

const AttachmentsPanel: FC<Props> = ({ fileRow, onChangeDescription, handleClose, isAllowedEditable, doNotShowDescription }) => {
    const [description, setDescription] = useState(fileRow?.description);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
        onChangeDescription?.(e.target.value);
        setIsSaveDisabled(false);
    };

    const handleOnSave = () => {
        setIsSaveDisabled(true);
        handleClose();
    };

    const handleOnCancel = () => {
        setDescription(fileRow?.description);
        setIsSaveDisabled(true);
        handleClose();
    };

    return (
        <Paper elevation={0} sx={{ width: 300, height: '100%', borderLeft: 1, borderColor: 'divider' }}>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">Attachments</Typography>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <Typography variant="caption" color="text.secondary">
                        Name:
                    </Typography>
                    <Typography variant="body2">{fileRow?.name}</Typography>
                </Box>

                {!doNotShowDescription && (
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="caption" color="text.secondary">
                            Description:
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={description || ''}
                            onChange={handleDescriptionChange}
                            disabled={!isAllowedEditable}
                        />
                    </Box>
                )}

                <Box sx={{ mb: 2 }}>
                    <Typography variant="caption" color="text.secondary">
                        ID:
                    </Typography>
                    <Typography variant="body2">{fileRow?.id}</Typography>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <Typography variant="caption" color="text.secondary">
                        Created By:
                    </Typography>
                    <Typography variant="body2">{fileRow?.createdByName}</Typography>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <Typography variant="caption" color="text.secondary">
                        Uploaded On:
                    </Typography>
                    <Typography variant="body2">
                        {fileRow?.createdAt
                            ? new Date(fileRow.createdAt).toLocaleString('en-US', {
                                  month: 'short',
                                  day: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: true
                              })
                            : ''}
                    </Typography>
                </Box>

                <Box sx={{ mt: 'auto' }}>
                    <Divider sx={{ my: 2 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="outlined" onClick={handleOnCancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleOnSave} disabled={isSaveDisabled}>
                            Save
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
};

export default AttachmentsPanel;
