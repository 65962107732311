import { ToggleButtonGroup, ToggleButton, styled, ToggleButtonProps } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { HierarchySectionGrid } from 'ui-component/customFormsComponents/HierarchySection/HierarchySectionGrid';
import { IRecordHeaders } from 'ui-component/records/types';
import { SelectRecordOriginEnum } from '../types';

export type HierarchyPreviewProps = {
    selectedRecord: IRecordHeaders | null;
    onSelectRecord: (record: IRecordHeaders, origin?: SelectRecordOriginEnum) => void;
    initialSelectedRecord: IRecordHeaders | null;
    recordToAddParent: IRecordHeaders | null;
    allowedRecordTypeIds: number[];
};

export type HierarchyOptions = 'CURRENT_HIERARCHY' | 'PREVIEW_HIERARCHY';

const CustomToggleButton = styled(ToggleButton)<ToggleButtonProps & { isSelected?: boolean }>(({ theme, isSelected }) => ({
    height: '32px',
    width: '148px',
    padding: '0 !important',
    border: 'none !important',
    background: isSelected ? '#5D599E !important' : 'white !important',
    color: isSelected ? 'white !important' : `${theme.palette.secondary.main} !important`
}));

export const HierarchyPreview = ({
    selectedRecord,
    onSelectRecord,
    initialSelectedRecord,
    recordToAddParent,
    allowedRecordTypeIds
}: HierarchyPreviewProps) => {
    const [selectedHierarchy, setSelectedHierarchy] = useState<HierarchyOptions>('CURRENT_HIERARCHY');
    if (selectedRecord === null || initialSelectedRecord === null || recordToAddParent === null) return null;

    return (
        <>
            <ToggleButtonGroup
                value={selectedHierarchy}
                onChange={(_e, val) => {
                    if (val !== null) {
                        setSelectedHierarchy(val);
                    }
                }}
                exclusive
                sx={{ borderRadius: '8px !important', border: 'solid 1px #5D599E !important' }}
            >
                <CustomToggleButton
                    value="CURRENT_HIERARCHY"
                    isSelected={selectedHierarchy === 'CURRENT_HIERARCHY'}
                    sx={{ borderTopLeftRadius: '8px !important', borderBottomLeftRadius: '8px !important' }}
                >
                    <FormattedMessage id="hierarchy.currentHierarchy" />
                </CustomToggleButton>
                <CustomToggleButton
                    value="PREVIEW_HIERARCHY"
                    isSelected={selectedHierarchy === 'PREVIEW_HIERARCHY'}
                    sx={{ borderTopRightRadius: '8px !important', borderBottomRightRadius: '8px !important' }}
                >
                    <FormattedMessage id="hierarchy.previewHierarchy" />
                </CustomToggleButton>
            </ToggleButtonGroup>
            <div hidden={selectedHierarchy !== 'CURRENT_HIERARCHY'}>
                <HierarchySectionGrid
                    initialRecord={initialSelectedRecord}
                    recordId={initialSelectedRecord.id}
                    selectedRecord={selectedRecord}
                    onSelectRecord={(newRecord) => onSelectRecord(newRecord, 'HIERARCHY')}
                    allowedRecordTypeIds={allowedRecordTypeIds}
                />
            </div>
            <div hidden={selectedHierarchy !== 'PREVIEW_HIERARCHY'}>
                {selectedHierarchy === 'PREVIEW_HIERARCHY' && (
                    <HierarchySectionGrid
                        initialRecord={initialSelectedRecord}
                        recordId={initialSelectedRecord.id}
                        selectedRecord={selectedRecord}
                        previewMode
                        parentRecordId={selectedRecord.id}
                        recordToAdd={recordToAddParent}
                    />
                )}
            </div>
        </>
    );
};
