import { formatInTimeZone } from 'date-fns-tz';
/* eslint-disable no-nested-ternary */
import { BusinessEntity, IRecordField, IRecordFieldsList, TargetTenant } from 'ui-component/records/types';
import { AuditLog, AuditLogRow, IFormatDataValues, IProblemCode, IRecordStatus, ObjectLogRow } from './types';
import { IUser } from 'views/backoffice/users/types';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { isJsonStringPreventNullAndNonObject } from 'utils/stringHelpers';
import { LogReportMode } from './PropertiesPanel/utils';

const DEDUCED_AUDIT_LOG_DATATYPE = {
    EMPTY: 'Empty',
    OBJECT_LIST: 'ObjectList',
    ATTACHMENT: 'Attachment',
    COMMENTS: 'Comments',
    LINE_ITEMS: 'LineItems'
};

export const getFormattedValue = (value: any, fieldData?: Pick<IRecordField, 'id' | 'dataType'>) => {
    if (!fieldData) return value;
    const { dataType, id } = fieldData;
    const isBaseField = Number(id) === 0;
    if (dataType?.toLowerCase() === 'date') {
        if (value === '') return value;
        return Date.parse(value) ? format(new Date(value as string), 'MM/dd/yyyy') : undefined;
    }
    if (dataType?.toLowerCase() === 'datetime') {
        if (value === '') return value;
        return Date.parse(value) ? formatInTimeZone(new Date(value as string), 'Etc/GMT', 'yyyy-MM-dd HH:mmXXX') : undefined;
    }
    if (dataType?.toLowerCase() === 'time') {
        if (value === '') return value;
        return value && Date.parse(value as string) ? new Date(value).toTimeString().slice(0, 5) : undefined;
    }
    if (dataType?.toLowerCase() === 'dropdown') {
        if (isBaseField) return Number(value);
        return String(value) ?? undefined;
    }
    if (dataType?.toLowerCase() === 'object') {
        return '';
    }
    if (dataType?.toLowerCase() === 'number' && isBaseField) return Number(value);
    if (dataType?.toLowerCase() === 'multiselect') {
        if ((Array.isArray(value) && value.length === 0) || value === '[]') {
            return '';
        }
    }

    if (dataType?.toLowerCase() === 'attachment') {
        return value;
    }

    return isBaseField ? value : String(value);
};

export const getDataToUpdate = (
    data: Record<string, any>,
    baseFieldHeaders: Partial<IRecordFieldsList> | null,
    additionalFieldHeader: Partial<IRecordFieldsList> | null,
    additionalFieldRawValues: { id: string; tag: string; value: any }[]
) => {
    if (!additionalFieldHeader || !baseFieldHeaders) return { baseData: data, additionalFields: [] } as IFormatDataValues;
    const baseData = Object.fromEntries(
        Object.entries(data)
            .filter(([key]) => !Object.keys(additionalFieldHeader).includes(key))
            .map(([key, value]) => {
                const newValue = getFormattedValue(value, baseFieldHeaders[key]);
                return [key, newValue];
            })
    );

    const additionalFields = Object.keys(data)
        .filter((key) => Object.keys(additionalFieldHeader).includes(key))
        .map((key) => {
            const aditionalFieldHeaderId = additionalFieldHeader[key]?.id;
            const selectedTag = additionalFieldRawValues.find((el) => {
                let [, , fieldId] = el.tag.split(';');
                fieldId = fieldId || el.tag.split(':')[1].split(';')[1];
                return Number(fieldId) === Number(aditionalFieldHeaderId);
            });
            return {
                tag: selectedTag?.tag || `;;${aditionalFieldHeaderId}`,
                value: getFormattedValue(data[key], additionalFieldHeader[key]) ?? selectedTag?.value ?? '',
                objectValuesByProperty:
                    additionalFieldHeader[key]?.dataType === 'object' && data[key]
                        ? data[key].map((el: any) => ({ propertyId: +el.id, value: el.value }))
                        : undefined
            };
        });

    return { baseData, additionalFields } as IFormatDataValues;
};

/**
 * Generate the payload to be used in register record mutation
 *
 * @param data {Object} data returned from form
 * @param baseFieldHeaders {Partial<IRecordFieldsList> | null}
 * @param aditionalFieldHeader {Partial<IRecordFieldsList> | null}
 * @param objectValues {Record<string, any[]>} List of objects values added with its properties
 */
export const getDataToCreate = (
    data: Record<string, any>,
    baseFieldHeaders: Partial<IRecordFieldsList> | null,
    aditionalFieldHeader: Partial<IRecordFieldsList> | null,
    objectValues: Record<string, any[]>
) => {
    if (!aditionalFieldHeader || !baseFieldHeaders) return { baseData: data, additionalFields: [] } as IFormatDataValues;
    const baseData = Object.fromEntries(
        Object.entries(data)
            .filter(([, value]) => !!value)
            .filter(([key]) => !Object.keys(aditionalFieldHeader).includes(key))
            .map(([key, value]) => {
                const newValue = getFormattedValue(value, baseFieldHeaders[key]);
                return [key, newValue];
            })
    );

    const additionalFields = Object.keys(data)
        .filter((key) => !!data[key] || (aditionalFieldHeader[key]?.dataType === 'object' && objectValues[key]))
        .filter((key) => Object.keys(aditionalFieldHeader).includes(key))
        .filter((key) => getFormattedValue(data[key], aditionalFieldHeader[key]) !== undefined)
        .map((key) => {
            const aditionalFieldHeaderId = aditionalFieldHeader[key]?.id;
            const isObjectAndHaveData = aditionalFieldHeader[key]?.dataType === 'object' && objectValues[key];
            return {
                tag: `;;${aditionalFieldHeaderId}`,
                value: getFormattedValue(data[key], aditionalFieldHeader[key]),
                objectValuesByProperty: isObjectAndHaveData
                    ? objectValues[key].filter((el: any) => !!el.value).map((el: any) => ({ propertyId: +el.id, value: el.value }))
                    : undefined
            };
        });

    return { baseData, additionalFields } as IFormatDataValues;
};

export const downloadPdf = (url: string, fileName: string = 'Document') => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const convertArrayBufferToUrl = (arrayBuffer: any) => {
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
};

/**
 * Returns value formatted to show in Audit Log
 *
 * @param value {String | Number | Boolean}
 */
export const getAuditFormattedValue = (value: string | number | boolean = '') => {
    if (value === 'null') return '';

    if (typeof value === 'boolean') return value ? 'Yes' : 'No';

    try {
        const parsedValue = JSON.parse(String(value));
        if (Array.isArray(parsedValue)) {
            if (parsedValue.every((item: any) => typeof item === 'object' && 'name' in item)) {
                return parsedValue.map((item: any) => item?.name).join(', ');
            }

            return parsedValue.join(', ');
        }
    } catch (e) {
        // console.log(e);
    }

    if (typeof value === 'string' && dayjs(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ').isValid()) {
        console.log('value', value);
        return value.includes('T00:00:00.000Z')
            ? dayjs(value.replace('T00:00:00.000Z', '')).format('MM/DD/YYYY')
            : dayjs(value).format('MMM DD, YYYY - hh:mma');
    }

    if (typeof value === 'string') return value.replace(/\\n/g, '<br/>');

    if (value === null) return '';

    return String(value);
};

/**
 * Deduces the audit log data type based on the provided old and new values.
 *
 * @param {string} oldValue - The old value to consider when deducing the data type
 * @param {string} newValue - The new value to consider when deducing the data type
 * @return {DEDUCED_AUDIT_LOG_DATATYPE} The deduced audit log data type
 */
export const deduceAuditLogDataType = (oldValue: string, newValue: string, logReportMode: LogReportMode) => {
    if (!oldValue && !newValue) return DEDUCED_AUDIT_LOG_DATATYPE.EMPTY;
    let dataTypeDeduced = DEDUCED_AUDIT_LOG_DATATYPE.OBJECT_LIST;

    const parsedValueOld = oldValue ? JSON.parse(oldValue) : null;
    const parsedValueNew = newValue ? JSON.parse(newValue) : null;

    if (
        (parsedValueOld && parsedValueOld?.blocks && Array.isArray(parsedValueOld.blocks)) ||
        (parsedValueNew && parsedValueNew?.blocks && Array.isArray(parsedValueNew.blocks)) ||
        logReportMode === LogReportMode.Comments
    ) {
        dataTypeDeduced = DEDUCED_AUDIT_LOG_DATATYPE.COMMENTS;
    }

    if (logReportMode === LogReportMode.LineItems) {
        dataTypeDeduced = DEDUCED_AUDIT_LOG_DATATYPE.LINE_ITEMS;
    }

    if (logReportMode === LogReportMode.Objects) {
        dataTypeDeduced = DEDUCED_AUDIT_LOG_DATATYPE.OBJECT_LIST;
    }

    if (logReportMode === LogReportMode.Attachments) {
        dataTypeDeduced = DEDUCED_AUDIT_LOG_DATATYPE.ATTACHMENT;
    }

    return dataTypeDeduced;
};

/**
 * Formats the value of an object list from the audit log properties.
 *
 * @param {string} value - The value to be formatted.
 * @return {string} The formatted value.
 */
export const formatObjectListValueFromAuditLogProperties = (value: string) => {
    const parsedValue = value ? JSON.parse(value) : null;

    if (parsedValue && parsedValue?.blocks && Array.isArray(parsedValue.blocks)) {
        return parsedValue?.blocks[0]?.text ?? '';
    }

    const properties: { value: string; displayable: boolean; order: number }[] = [];
    if (!parsedValue) return '';

    Object.keys(parsedValue).forEach((key) => {
        properties.push(parsedValue[key]);
    });

    return properties
        .filter((el) => el.displayable)
        .sort((a, b) => {
            if (a.order < b.order) {
                return -1;
            }
            if (a.order > b.order) {
                return 1;
            }
            return 0;
        })
        .map((el) => el.value)
        .join(', ');
};

/**
 * TO DO: replace this function when the proper UI is implemented
 * Formats the value of an attachment from the audit log properties.
 * This is just meanwhile the attachment objects get the right UI
 *
 *
 * @param {string} value - The value to be formatted.
 * @return {string} The formatted value.
 */
export const formatAttachmentValueFromAuditLogProperties = (value: string) => {
    const parsedValue = JSON.parse(value);
    const returnValues: string[] = [];
    if (!parsedValue) return '';
    parsedValue.forEach((el: any) => {
        returnValues.push(el.name);
    });
    return returnValues.join(', ');
};

/**
 * Formats object list logs to object logs.
 *
 * @param {string} activity - The activity associated with the logs.
 * @param {string} user - The user associated with the logs.
 * @param {string} dateUpdated - The date associated with the logs.
 * @param {string} oldValue - The old value of the object list.
 * @param {string} newValue - The new value of the object list.
 * @return {ObjectLogRow[]} An array of object log rows.
 */
export const formatObjectListLogsToObjectLogs = (
    activity: string,
    user: string,
    dateUpdated: string,
    oldValue: string,
    newValue: string
): ObjectLogRow[] => {
    const returnValues: ObjectLogRow[] = [];
    const keys = oldValue && oldValue !== 'null' ? Object.keys(JSON.parse(oldValue)) : [];
    if (newValue && newValue !== 'null')
        Object.keys(JSON.parse(newValue)).forEach((key) => {
            if (!keys.includes(key)) {
                keys.push(key);
            }
        });
    keys.forEach((key) => {
        const newItem = {
            id: uuidv4(),
            fieldName: key ?? '',
            before: getAuditFormattedValue(JSON.parse(oldValue)?.[key]?.value ?? ''),
            after: getAuditFormattedValue(JSON.parse(newValue)?.[key]?.value ?? ''),
            activity,
            user,
            dateUpdated
        };
        returnValues.push(newItem);
    });
    return returnValues;
};

export const getDropdownOptionList = (
    key:
        | 'recordType'
        | 'statusId'
        | 'createdBy'
        | 'receivedBy'
        | 'approvedBy'
        | 'problemCodeId'
        | 'assignedTo'
        | 'targetTransmissionTenant'
        | 'businessEntity',
    recordTypeList: any,
    recordStatusList: IRecordStatus[],
    problemCodeList: IProblemCode[],
    usersList: IUser[],
    targetTenantList?: TargetTenant[],
    allBusinessEntities?: BusinessEntity[]
) => {
    switch (key) {
        case 'recordType':
            return recordTypeList;
        case 'statusId':
            return recordStatusList;
        case 'problemCodeId':
            return problemCodeList;
        case 'targetTransmissionTenant':
            return targetTenantList?.map((el) => el.targetTenantToTransmit);
        case 'businessEntity':
            return allBusinessEntities?.map((el) => ({
                id: el.id,
                name: el.displayName,
                enabled: el.enabled
            }));
        case 'assignedTo':
        case 'createdBy':
        case 'receivedBy':
        case 'approvedBy':
            return usersList
                .filter((el: any) => Boolean(el.email))
                .sort((a, b) => {
                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
        default:
            return [];
    }
};

export const generateInitialState = (baseFieldHeaders: Partial<IRecordFieldsList>, recordType?: string) => {
    const initialState = Object.keys(baseFieldHeaders)
        .filter((key) => key !== 'id')
        .reduce((acc, key) => ({ ...acc, [key]: '' }), {});

    return { ...initialState, recordType: Number(recordType) || undefined };
};

export const mapRecordLogToAuditLogTable = (recordLog: AuditLog, logReportMode: LogReportMode) => {
    if (
        recordLog?.changes?.length === 0 &&
        (recordLog as AuditLog)?.objectsLogs?.length === 0 &&
        recordLog?.action?.toLowerCase() === 'update'
    ) {
        return [];
    }

    let logs = [
        {
            id: uuidv4(),
            fieldName: '',
            before: '',
            after: '',
            activity: recordLog.action,
            user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
            dateUpdated: recordLog.datetime
        }
    ];

    if (recordLog.changes.length > 0) {
        logs = recordLog.changes.map((el) => {
            if (!isJsonStringPreventNullAndNonObject(String(el.oldValue)) && !isJsonStringPreventNullAndNonObject(String(el.newValue))) {
                return {
                    id: uuidv4(),
                    fieldName: el.what ?? '',
                    before: el.oldValue ?? '',
                    after: el.newValue ?? '',
                    activity: recordLog.action,
                    user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                    dateUpdated: recordLog.datetime,
                    idWithFilename: `${recordLog?.filename} - ${recordLog?.id}`
                };
            }

            const deducedAuditLogDataType = deduceAuditLogDataType(String(el.oldValue), String(el.newValue), logReportMode);
            switch (deducedAuditLogDataType) {
                case DEDUCED_AUDIT_LOG_DATATYPE.OBJECT_LIST:
                    return {
                        id: uuidv4(),
                        fieldName: el.what ?? '',
                        before: formatObjectListValueFromAuditLogProperties(String(el.oldValue)),
                        after: formatObjectListValueFromAuditLogProperties(String(el.newValue)),
                        activity: recordLog.action,
                        user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                        dateUpdated: recordLog.datetime
                    };
                case DEDUCED_AUDIT_LOG_DATATYPE.COMMENTS:
                    return {
                        id: uuidv4(),
                        fieldName: el.what ?? '',
                        before: formatObjectListValueFromAuditLogProperties(el.oldValue ?? ''),
                        after: formatObjectListValueFromAuditLogProperties(el.newValue ?? ''),
                        activity: recordLog.action,
                        user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                        dateUpdated: recordLog.datetime,
                        objectLogs: []
                    };
                case DEDUCED_AUDIT_LOG_DATATYPE.LINE_ITEMS:
                    return {
                        id: uuidv4(),
                        fieldName: el.what ?? '',
                        before: getAuditFormattedValue(el.oldValue),
                        after: getAuditFormattedValue(el.newValue),
                        activity: recordLog.action,
                        user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                        dateUpdated: recordLog.datetime
                    };
                case DEDUCED_AUDIT_LOG_DATATYPE.ATTACHMENT:
                    return {
                        id: uuidv4(),
                        fieldName: el.what ?? '',
                        before: formatAttachmentValueFromAuditLogProperties(String(el.oldValue)),
                        after: formatAttachmentValueFromAuditLogProperties(String(el.newValue)),
                        activity: recordLog.action,
                        user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                        dateUpdated: recordLog.datetime,
                        idWithFilename: `${recordLog?.filename} - ${recordLog?.id}`
                    };
                case DEDUCED_AUDIT_LOG_DATATYPE.EMPTY:
                    return {
                        id: uuidv4(),
                        fieldName: el.what ?? '',
                        before: '',
                        after: '',
                        activity: recordLog.action,
                        user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                        dateUpdated: recordLog.datetime
                    };
                default:
                    return {
                        id: uuidv4(),
                        fieldName: el.what ?? '',
                        before: getAuditFormattedValue(el.oldValue),
                        after: getAuditFormattedValue(el.newValue),
                        activity: recordLog.action,
                        user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                        dateUpdated: recordLog.datetime
                    };
            }
        });
    }

    if (recordLog?.objectsLogs?.length > 0) {
        if (recordLog.changes.length === 0) logs = [];
        recordLog.objectsLogs.forEach((el) => {
            const logsFromObjects = el.changes.map((change) => ({
                id: uuidv4(),
                fieldName: change.what ?? '',
                before: getAuditFormattedValue(change.oldValue),
                after: getAuditFormattedValue(change.newValue),
                activity: recordLog.action,
                user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                dateUpdated: recordLog.datetime
            }));
            logs = [...logs, ...logsFromObjects];
        });
    }

    return logs;
};

export const mapRecordLogToFullAuditLogTable = (recordLog: AuditLog, logReportMode: LogReportMode) => {
    if (recordLog?.changes?.length === 0 && recordLog?.objectsLogs?.length === 0 && recordLog?.action?.toLowerCase() === 'update') {
        return [];
    }

    let logs: AuditLogRow[] = [
        {
            id: uuidv4(),
            fieldName: '',
            before: '',
            after: '',
            activity: recordLog.action,
            user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
            dateUpdated: recordLog.datetime,
            objectLogs: []
        }
    ];

    if (recordLog.changes.length) {
        logs = recordLog.changes.map((el) => {
            if (!isJsonStringPreventNullAndNonObject(String(el.oldValue)) && !isJsonStringPreventNullAndNonObject(String(el.newValue))) {
                return {
                    id: uuidv4(),
                    fieldName: el.what ?? '',
                    before: getAuditFormattedValue(el.oldValue),
                    after: getAuditFormattedValue(el.newValue),
                    activity: recordLog.action,
                    user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                    dateUpdated: recordLog.datetime,
                    objectLogs: [],
                    ...(logReportMode === LogReportMode.Attachments && { idWithFilename: `${recordLog?.filename} - ${recordLog?.id}` })
                };
            }

            const deducedAuditLogDataType = deduceAuditLogDataType(String(el.oldValue), String(el.newValue), logReportMode);

            switch (deducedAuditLogDataType) {
                case DEDUCED_AUDIT_LOG_DATATYPE.OBJECT_LIST:
                    return {
                        id: uuidv4(),
                        fieldName: el.what ?? '',
                        before: formatObjectListValueFromAuditLogProperties(String(el.oldValue)),
                        after: formatObjectListValueFromAuditLogProperties(String(el.newValue)),
                        activity: recordLog.action,
                        user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                        dateUpdated: recordLog.datetime,
                        objectLogs: formatObjectListLogsToObjectLogs(
                            recordLog.action,
                            recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                            recordLog.datetime,
                            String(el.oldValue),
                            String(el.newValue)
                        )
                    };
                case DEDUCED_AUDIT_LOG_DATATYPE.COMMENTS:
                    return {
                        id: uuidv4(),
                        fieldName: el.what ?? '',
                        before: formatObjectListValueFromAuditLogProperties(el.oldValue ?? ''),
                        after: formatObjectListValueFromAuditLogProperties(el.newValue ?? ''),
                        activity: recordLog.action,
                        user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                        dateUpdated: recordLog.datetime,
                        objectLogs: []
                    };
                case DEDUCED_AUDIT_LOG_DATATYPE.ATTACHMENT:
                    return {
                        id: uuidv4(),
                        fieldName: el.what ?? '',
                        before: formatAttachmentValueFromAuditLogProperties(String(el.oldValue)),
                        after: formatAttachmentValueFromAuditLogProperties(String(el.newValue)),
                        activity: recordLog.action,
                        user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                        dateUpdated: recordLog.datetime,
                        objectLogs: [],
                        idWithFilename: `${recordLog?.filename} - ${recordLog?.id}`
                    };
                case DEDUCED_AUDIT_LOG_DATATYPE.EMPTY:
                    return {
                        id: uuidv4(),
                        fieldName: el.what ?? '',
                        before: '',
                        after: '',
                        activity: recordLog.action,
                        user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                        dateUpdated: recordLog.datetime,
                        objectLogs: []
                    };
                default:
                    return {
                        id: uuidv4(),
                        fieldName: el.what ?? '',
                        before: getAuditFormattedValue(el.oldValue),
                        after: getAuditFormattedValue(el.newValue),
                        activity: recordLog.action,
                        user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                        dateUpdated: recordLog.datetime,
                        objectLogs: []
                    };
            }
        });
    }

    if (recordLog?.objectsLogs?.length > 0) {
        recordLog.objectsLogs.forEach((el) => {
            const logsFromObjects = {
                id: uuidv4(),
                fieldName: el.objectName ?? '',
                before: '-',
                after: '-',
                activity: recordLog.action,
                user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                objectLogs: el.changes.map((change) => ({
                    id: uuidv4(),
                    fieldName: change.what ?? '',
                    before: getAuditFormattedValue(change.oldValue),
                    after: getAuditFormattedValue(change.newValue),
                    activity: recordLog.action,
                    user: recordLog.user.name ?? `${recordLog.user.firstName} ${recordLog.user.lastName}`,
                    dateUpdated: recordLog.datetime
                })),
                dateUpdated: recordLog.datetime
            };
            logs = [...logs, ...[logsFromObjects]];
        });
    }

    return logs;
};
