import { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Close, Edit, MoreVert, Save, Visibility } from '@mui/icons-material';
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridRowId, GridRowModes, GridRowModesModel } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { IUser } from 'views/backoffice/users/types';
import { AttachmentRow } from './types';

export type GetListColumnsParamProps = {
    onOpenFile: (row: AttachmentRow) => Promise<void>;
    onDeleteFile: (row: AttachmentRow) => Promise<void>;
    onDownloadFile: (row: AttachmentRow) => Promise<void>;
    onClickEdit: (row: GridRowId) => () => void;
    onClickSave: (row: GridRowId) => () => void;
    onClickCancel: (row: GridRowId) => () => void;
    rowModesModel: GridRowModesModel;
    isAllowedDelete: boolean | undefined;
    userListById: Record<number, IUser>;
};

export const getListColumns = ({
    onOpenFile,
    onDeleteFile,
    onDownloadFile,
    onClickEdit,
    onClickSave,
    onClickCancel,
    rowModesModel,
    isAllowedDelete,
    userListById
}: GetListColumnsParamProps) =>
    [
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            hideable: false,
            renderCell: (params: GridRenderCellParams) => {
                const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
                if (params.row.access === 'editable') {
                    return (
                        <>
                            {isInEditMode ? (
                                <>
                                    <IconButton onClick={onClickSave(params.id)}>
                                        <Save />
                                    </IconButton>
                                    <IconButton onClick={onClickCancel(params.id)}>
                                        <Close />
                                    </IconButton>
                                </>
                            ) : (
                                <IconButton onClick={onClickEdit(params.id)}>
                                    <Edit />
                                </IconButton>
                            )}

                            <IconButton onClick={() => onOpenFile(params.row)}>
                                <Visibility />
                            </IconButton>
                        </>
                    );
                }

                return (
                    <IconButton onClick={() => onOpenFile(params.row)}>
                        <Visibility />
                    </IconButton>
                );
            }
        },
        {
            field: 'name',
            headerName: 'File Name',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Typography variant="body1" fontSize="16px" color="black" fontWeight={700}>
                    {params.colDef.headerName}
                </Typography>
            ),
            minWidth: 150,
            flex: 1,
            editable: true
        },
        {
            field: 'description',
            headerName: 'Description',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Typography variant="body1" fontSize="16px" color="black" fontWeight={700}>
                    {params.colDef.headerName}
                </Typography>
            ),
            width: 150,
            editable: true
        },
        {
            field: 'fileType',
            headerName: 'File Type',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Typography variant="body1" fontSize="16px" color="black" fontWeight={700}>
                    {params.colDef.headerName}
                </Typography>
            ),
            width: 125,
            valueFormatter: (params) => params.value?.split('/')[1]?.toUpperCase() || 'unknown'
        },
        {
            field: 'createdAt',
            type: 'datetime',
            headerName: 'Uploaded At',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Typography variant="body1" fontSize="16px" color="black" fontWeight={700}>
                    {params.colDef.headerName}
                </Typography>
            ),
            width: 150,
            valueFormatter: (params) => format(new Date(params.value as string), 'MMM dd, yyyy HH:mm:ss')
        },
        {
            field: 'createdBy',
            headerName: 'Uploaded By',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Typography variant="body1" fontSize="16px" color="black" fontWeight={700}>
                    {params.colDef.headerName}
                </Typography>
            ),
            width: 150,
            valueFormatter: (params) => userListById[Number(params.value)].name
        },
        {
            field: 'recordFieldName',
            headerName: 'Field Name',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Typography variant="body1" fontSize="16px" color="black" fontWeight={700}>
                    {params.colDef.headerName}
                </Typography>
            ),
            width: 150
        },
        {
            field: 'endActions',
            headerName: 'Actions',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Typography variant="body1" fontSize="16px" color="black" fontWeight={700}>
                    {params.colDef.headerName}
                </Typography>
            ),
            width: 100,
            sortable: false,
            hideable: false,
            renderCell: (props) => (
                <EndActionsComponent
                    {...props}
                    isAllowedDelete={isAllowedDelete}
                    onDeleteFile={onDeleteFile}
                    onDownloadFile={onDownloadFile}
                    isAllowedEditable={props.row.access === 'editable'}
                />
            )
        }
    ] as GridColDef[];

export const EndActionsComponent = ({
    onDownloadFile,
    isAllowedDelete,
    onDeleteFile,
    isAllowedEditable,
    ...params
}: GridRenderCellParams & {
    isAllowedDelete?: boolean;
    isAllowedEditable?: boolean;
    onDownloadFile: (row: AttachmentRow) => Promise<void>;
    onDeleteFile: (row: AttachmentRow) => Promise<void>;
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    return (
        <>
            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                <MoreVert />
            </IconButton>
            <Menu
                id="file-more-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'file-more-menu',
                    role: 'listbox'
                }}
            >
                <MenuItem onClick={() => onDownloadFile(params.row)}>Download</MenuItem>
                {isAllowedDelete && isAllowedEditable && <MenuItem onClick={() => onDeleteFile(params.row)}>Delete</MenuItem>}
            </Menu>
        </>
    );
};
