import { Box, Grid, Tab, Tabs } from '@mui/material';
import { IRecordType } from 'types/recordType';

export type RecordTypesTabsSectionProps = {
    selectedRecordTypeId: number;
    handleChangeTab: (_event: React.SyntheticEvent, newValue: number) => void;
    allowedRecordTypes: IRecordType[];
};

export const RecordTypesTabsSection = ({ selectedRecordTypeId, handleChangeTab, allowedRecordTypes }: RecordTypesTabsSectionProps) => (
    <Grid item xs="auto" sx={{ maxHeight: '100%' }}>
        <Box sx={({ palette }) => ({ width: '140px', height: '100%', bgcolor: palette.primary[300] })}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={selectedRecordTypeId}
                onChange={handleChangeTab}
                sx={{ borderRight: 1, borderColor: 'divider', height: '100%' }}
            >
                {allowedRecordTypes.map(({ id, name }) => (
                    <Tab
                        key={id}
                        label={name}
                        value={+id}
                        sx={{ fontSize: '14px', fontWeight: 300, '&.Mui-selected': { fontWeight: 700 } }}
                    />
                ))}
            </Tabs>
        </Box>
    </Grid>
);
