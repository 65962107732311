import React, { useState } from 'react';
import {
    Box,
    Collapse,
    FormControl,
    FormControlProps,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    NativeSelect,
    Select,
    SelectChangeEvent
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import useMyDropdowns from 'hooks/useMyDropdowns';
import { SystemStyleObject } from '@mui/system';

export interface StatusRecordDropdownProps {
    onChange: (value: string | string[]) => void;
    recordTypeId: number;
    initialValue?: string | string[];
    variant?: FormControlProps['variant'];
    size?: FormControlProps['size'];
    label?: string;
    native?: boolean;
    multiple?: boolean;
    fromFilterPanel?: boolean;
    hideLabel?: boolean;
    inputSx?: SystemStyleObject;
}

export const StatusRecordDropdown = ({
    onChange,
    recordTypeId,
    initialValue,
    native,
    label,
    variant = 'outlined',
    size = 'medium',
    multiple = false,
    fromFilterPanel = false,
    hideLabel = false,
    inputSx = {}
}: StatusRecordDropdownProps) => {
    const [statusSelect, setStatusSelect] = useState<string>('');
    const [statusMultipleSelect, setStatusMultipleSelect] = useState<string[]>([]);

    const { loading, recordStatusList } = useMyDropdowns({
        recordTypeId,
        dropdowns: ['RECORD_STATUS'],
        onCompleted(data) {
            const statusList = data.recordStatusList;
            if (statusList && initialValue) {
                if (fromFilterPanel) {
                    if (Array.isArray(initialValue)) setStatusMultipleSelect(initialValue as string[]);
                    else setStatusSelect(initialValue as string);
                } else if (multiple) {
                    const values = statusList.map((el) => String(el.id)).filter((el) => initialValue?.includes(el));
                    setStatusMultipleSelect(values || []);
                } else if (!Number(initialValue)) {
                    const value = statusList.find(({ name }) => (initialValue as string) === name)?.id;
                    setStatusSelect(String(value) || '');
                } else {
                    setStatusSelect(String(initialValue) || '');
                }
            }
        }
    });

    const handleValueChange = (event: SelectChangeEvent<string | string[]> | React.ChangeEvent<{ value: string }>) => {
        const newValue = event.target.value;
        if (multiple) setStatusMultipleSelect(newValue as string[]);
        else setStatusSelect(newValue as string);
        onChange(newValue);
    };

    const handleClearClick = () => {
        const newValue = multiple ? ['0'] : '0';
        if (multiple) setStatusMultipleSelect(newValue as string[]);
        else setStatusSelect(newValue as string);
        onChange(newValue);
    };

    return (
        <Box className="status-record-dropdown-container" sx={{ minWidth: 149 }}>
            <FormControl fullWidth disabled={loading}>
                {label && !hideLabel && (
                    <InputLabel id="record-status-select" shrink>
                        {label}
                    </InputLabel>
                )}
                {native ? (
                    <NativeSelect value={statusSelect} onChange={handleValueChange} sx={inputSx}>
                        <option value=""> &nbsp;</option>
                        {recordStatusList?.map((el) => (
                            <option key={el.id} value={el.id}>
                                {el.name}
                            </option>
                        ))}
                    </NativeSelect>
                ) : (
                    <Select
                        value={multiple ? statusMultipleSelect : statusSelect}
                        onChange={handleValueChange}
                        multiple={multiple}
                        size={size}
                        variant={variant}
                        sx={{ pt: '3px !important', ...inputSx }}
                        endAdornment={
                            <IconButton
                                sx={{
                                    display: !multiple && statusSelect && statusSelect !== '0' ? '' : 'none'
                                }}
                                onClick={handleClearClick}
                            >
                                <Clear />
                            </IconButton>
                        }
                        {...(!multiple && statusSelect && statusSelect !== '0' && { IconComponent: () => null })}
                    >
                        {recordStatusList?.map((el) => (
                            <MenuItem key={el.id} value={el.id}>
                                {el.name}
                            </MenuItem>
                        ))}
                    </Select>
                )}
                <Collapse in={loading}>
                    <LinearProgress />
                </Collapse>
            </FormControl>
        </Box>
    );
};
