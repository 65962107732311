import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_FILTER_AUDIT_LOGS_FOR_RECORDS } from 'graphql/queries/records';
import { useEffect, useState } from 'react';
import {
    AuditLog,
    CommentLog,
    GetFilterAuditLogsVariables,
    LineItemTypes,
    QueryGetFilterAuditLogs,
    QueryGetFilterCommentsLogs,
    QueryGetFilterLineItemsLogs
} from 'ui-component/RecordView/types';
import { LogReportMode } from '../../utils';
import { QUERY_GET_FILTER_AUDIT_LOGS_FOR_LINE_ITEMS } from 'graphql/queries/lineItems';
import { QUERY_GET_COMMENTS_LOGS } from 'graphql/queries/comments';
import { useDocumentViewer } from 'views/DocumentViewer/hooks/useDocumentViewer';
import { transformAuditLogData } from 'ui-component/RecordView/Attachments/utils';
import { useSearchParams } from 'react-router-dom';
import { useRecordHeaders } from 'views/DynamicPortals/hooks';

function useAuditLogs(logReportMode: LogReportMode, recordId: number, tenantId: number) {
    const { lineItem, comment, attachment } = useDocumentViewer();
    const [logs, setLogs] = useState<AuditLog[] | CommentLog[] | LineItemTypes[] | undefined>([]);
    const [total, setTotal] = useState(0);
    const [searchParams] = useSearchParams();
    const recordTypeId = searchParams.get('recordType') || '';
    const { headers: fieldsData, loading: loadingHeaderData } = useRecordHeaders(+recordTypeId);
    const [findAuditLogsRecords, { loading: loadingRecords, data: dataRecords, fetchMore: fetchMoreLogsRecords }] = useLazyQuery<
        QueryGetFilterAuditLogs,
        GetFilterAuditLogsVariables
    >(QUERY_GET_FILTER_AUDIT_LOGS_FOR_RECORDS, {
        fetchPolicy: 'no-cache',
        variables: {
            data: { filterInput: { recordId, tenantId }, pagination: { limit: 50, offset: 0 } }
        }
    });

    const [findAuditLogsComments, { loading: loadingComments, data: dataComments, fetchMore: fetchMoreLogsComments }] = useLazyQuery<
        QueryGetFilterCommentsLogs,
        GetFilterAuditLogsVariables
    >(QUERY_GET_COMMENTS_LOGS, {
        fetchPolicy: 'no-cache',
        variables: {
            data: { filterInput: { recordId, tenantId }, pagination: { limit: 50, offset: 0 } }
        }
    });

    const [findAuditLogLineItems, { loading: loadingLineItems, data: dataLineItems, fetchMore: fetchMoreLogsLineItems }] = useLazyQuery<
        QueryGetFilterLineItemsLogs,
        GetFilterAuditLogsVariables
    >(QUERY_GET_FILTER_AUDIT_LOGS_FOR_LINE_ITEMS, {
        fetchPolicy: 'no-cache',
        variables: {
            data: { filterInput: { recordId, tenantId }, pagination: { limit: 50, offset: 0 } }
        }
    });

    useEffect(() => {
        if ((recordId && [LogReportMode.All, LogReportMode.Attachments].includes(logReportMode)) || attachment) {
            findAuditLogsRecords();
        }

        if ((recordId && logReportMode === LogReportMode.Comments) || comment) {
            findAuditLogsComments();
        }

        if ((recordId && logReportMode === LogReportMode.LineItems) || lineItem) {
            findAuditLogLineItems();
        }
    }, [recordId, logReportMode, lineItem, comment, attachment, findAuditLogsRecords, findAuditLogsComments, findAuditLogLineItems]);

    useEffect(() => {
        if ([LogReportMode.All, LogReportMode.Objects].includes(logReportMode) && fieldsData) {
            // we need to seprate the attachment logs from the normal record logs because they would appear in a different view as line items or comments appears
            const filteredLogs =
                dataRecords?.filterAuditLogsForRecord?.logs.filter(
                    (log) => log.changes.length > 0 && fieldsData?.[log.changes.at(0)?.what || '']?.dataType !== 'attachment'
                ) || [];
            setLogs(filteredLogs);
            setTotal(filteredLogs?.length || 0);
        }

        if (logReportMode === LogReportMode.Attachments && fieldsData) {
            // now we only need the attachment logs
            const filteredLogs =
                dataRecords?.filterAuditLogsForRecord?.logs
                    .map((log) => ({
                        ...log,
                        changes: log.changes.filter((change) => fieldsData?.[change?.what || '']?.dataType === 'attachment')
                    }))
                    .filter((log) => log.changes.length > 0) || [];
            // this trasform method simply transforms the attachment audit log data to normal audit log data so that it can be rendered in the existing views
            setLogs(transformAuditLogData(filteredLogs));
            setTotal(filteredLogs?.length || 0);
        }

        if (logReportMode === LogReportMode.Comments) {
            setLogs(dataComments?.filterCommentLogsByRecordHeader?.logs || []);
            setTotal(dataComments?.filterCommentLogsByRecordHeader?.total || 0);
        }

        if (logReportMode === LogReportMode.LineItems) {
            const lineItemsLogs =
                dataLineItems?.filterAuditLogsForLineItems?.logs?.map((log) => ({
                    ...log,
                    action: log.rowAction
                })) || [];

            setLogs(lineItemsLogs as LineItemTypes[]);
            setTotal(dataLineItems?.filterAuditLogsForLineItems?.total || 0);
        }
    }, [
        dataRecords?.filterAuditLogsForRecord?.logs,
        dataComments?.filterCommentLogsByRecordHeader?.logs,
        dataLineItems?.filterAuditLogsForLineItems?.logs,
        dataRecords?.filterAuditLogsForRecord?.total,
        dataComments?.filterCommentLogsByRecordHeader?.total,
        dataLineItems?.filterAuditLogsForLineItems?.total,
        logReportMode,
        fieldsData
    ]);

    return {
        loading: loadingRecords || loadingComments || loadingLineItems || loadingHeaderData,
        logs,
        total,
        fetchMoreLogsRecords,
        fetchMoreLogsComments,
        fetchMoreLogsLineItems
    };
}

export default useAuditLogs;
