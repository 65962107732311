import { CancelOutlined } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { HierarchyCore } from './Core';
import { IRecordHeaders } from 'ui-component/records/types';

export type HierarchyDialogProps = {
    open: boolean;
    onClose: () => void;
    recordId: string;
    initialRecord: IRecordHeaders;
};

export const HierarchyDialog = ({ open, onClose, recordId, initialRecord }: HierarchyDialogProps) => {
    const { palette } = useTheme();
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" PaperProps={{ sx: { borderRadius: '8px', py: 0 } }}>
            <DialogTitle sx={{ height: '64px', bgcolor: palette.primary[300] }}>
                <Grid container justifyContent="space-between">
                    <Grid item xs>
                        <Typography fontSize="24px" fontWeight={500} color="black">
                            <FormattedMessage id="hierarchy" />
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <IconButton color="secondary" onClick={() => onClose()}>
                            <CancelOutlined />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent sx={{ overflowY: 'hidden', height: '60vh', p: 0 }}>
                <HierarchyCore recordId={recordId} initialRecord={initialRecord} hideBreadcrumb containerSx={{ height: '100%' }} />
            </DialogContent>
            <DialogActions sx={{ px: '24px', display: 'flex', justifyContent: 'flex-end', height: '64px', bgcolor: palette.primary[300] }}>
                <Button
                    onClick={onClose}
                    sx={{ width: '102px', height: '32px', p: 0, borderRadius: '8px' }}
                    color="secondary"
                    variant="contained"
                >
                    <Typography fontSize="14px">
                        <FormattedMessage id="close" />
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};
