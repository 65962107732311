import { MouseEvent, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { Breadcrumbs, Button, FilledInput, FormControl, Grid, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { IconChevronDown, IconPin } from '@tabler/icons';
import { useDispatch, useSelector } from 'store';
import { setDefaultPreference, setSelectedDataFocusView } from 'store/slices/dataFocusView';
import Loader from 'ui-component/Loader';
import { useDataFocusViewData, useDataFocusViewMutations } from 'views/DataFocusView/hooks';
import { openSnackbar } from 'store/slices/snackbar';
import { IUserDataToken } from 'utils/types';
import { updateSectionName } from 'store/slices/section';

export type DataFocusViewHeaderProps = {
    recordTypeId: string;
    poNumber?: string;
};

export const DataFocusViewHeader = ({ recordTypeId }: DataFocusViewHeaderProps) => {
    const tenantId = localStorage.getItem('tenant_id') || '';
    const token = localStorage.getItem('backend_jwt') || '';
    const userData: IUserDataToken = jwt_decode(token);

    const dispatch = useDispatch();
    const selectedView = useSelector((store) => store.dataFocusView.selected);
    const defaultPreference = useSelector((store) => store.dataFocusView.defaultPreference);

    const { loading, dataFocusViewList, dataFocusViewListById, defaultDataFocusViewPreference, recordType } =
        useDataFocusViewData(recordTypeId);

    const { loadingPreference, createUserDataFocusPreference, updateUserDataFocusPreference } = useDataFocusViewMutations(recordTypeId);

    const handleChangeView = (id: string) => {
        dispatch(setSelectedDataFocusView(dataFocusViewListById[id]));
    };

    const handleChangeDefaultView = (id: string) => async (e: MouseEvent) => {
        e.stopPropagation();
        try {
            const isEdit = !!defaultPreference;
            if (isEdit) await updateUserDataFocusPreference({ variables: { data: { id: +defaultPreference.id, dataFocusViewId: +id } } });
            else
                await createUserDataFocusPreference({
                    variables: {
                        data: {
                            dataFocusViewId: +id,
                            recordTypeId: +recordTypeId,
                            tenantId: +tenantId,
                            userId: +userData.userId
                        }
                    }
                });

            handleChangeView(id);
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Default data focus view saved',
                    variant: 'alert',
                    alert: { color: 'success' },
                    close: false
                })
            );
        } catch (error: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: `Save default Data focus view failed: ${error.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
        }
    };

    useEffect(() => {
        dispatch(
            updateSectionName({
                sectionName: (
                    <Grid item xs="auto">
                        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                            <Typography fontSize="20px" fontWeight={300} sx={({ palette }) => ({ color: palette.primary[400] })}>
                                {recordType?.name}
                            </Typography>
                        </Breadcrumbs>
                    </Grid>
                )
            })
        );
    }, [dispatch, recordType?.name]);

    useEffect(() => {
        if (!loading && dataFocusViewList.length) {
            const defaultView = dataFocusViewList.find(({ id }) => id === defaultDataFocusViewPreference?.dataFocusView.id);
            dispatch(setDefaultPreference(defaultDataFocusViewPreference));
            dispatch(setSelectedDataFocusView(defaultView || dataFocusViewList[0]));
        }
    }, [dataFocusViewList, dataFocusViewListById, defaultDataFocusViewPreference, dispatch, loading]);

    if (loading) <Loader />;

    return dataFocusViewList.length ? (
        <FormControl sx={{ minWidth: 300, ml: 2 }}>
            <Select
                value={selectedView?.id || ''}
                onChange={(e) => handleChangeView(e.target.value)}
                input={<FilledInput id="dashboard-selection-input" disableUnderline hiddenLabel size="small" />}
                sx={{
                    borderRadius: '100px',
                    '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiFilledInput-input': {
                        color: '#54595E !important'
                    }
                }}
                renderValue={(selected) => dataFocusViewListById[selected].name}
                IconComponent={(props) => <IconChevronDown style={{ margin: '-5px 0px', color: '#37326E' }} {...props} />}
                MenuProps={{
                    sx: { '& ul': { p: '0 !important' } },
                    PaperProps: { sx: { borderRadius: '10px', maxHeight: '300px' } }
                }}
            >
                {dataFocusViewList.map((option) => (
                    <MenuItem key={option.id} value={option.id} sx={{ bgcolor: option.id === selectedView?.id ? '#EBEEFE' : '#F5F6F7' }}>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item xs={9}>
                                <ListItemText
                                    primary={option.name}
                                    sx={{
                                        '& span': {
                                            color: '#54595E !important',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs="auto">
                                <Button
                                    sx={(theme) => ({
                                        borderRadius: '8px',
                                        minWidth: '30px',
                                        height: '30px',
                                        bgcolor: option.id === selectedView?.id ? theme.palette.primary.main : '#FFFFFF',
                                        boxShadow: 'none',
                                        '&:hover svg': { stroke: '#FFFFFF !important' }
                                    })}
                                    disabled={loadingPreference}
                                    onClick={option.id === selectedView?.id ? undefined : handleChangeDefaultView(option.id)}
                                    variant="contained"
                                    size="small"
                                >
                                    <IconPin size={16} color={option.id === selectedView?.id ? '#FFFFFF' : '#37326E'} />
                                </Button>
                            </Grid>
                        </Grid>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    ) : null;
};
