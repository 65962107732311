import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import jwt_decode from 'jwt-decode';
import * as yup from 'yup';
import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { QUERY_GET_OBJECT_PROPERTIES } from 'graphql/queries/customObjects';
import { QUERY_FIND_FORM_SECTIONS } from 'graphql/queries/dataFocusView';
import { useCustomListValues } from 'hooks/useCustomListValues';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { IRecordFieldsList, IRecordHeaders } from 'ui-component/records/types';
import { getListIdsFromHeaders } from 'ui-component/records/utils/headerHelpers';
import { FindObjectProperty, FindObjectPropertyVariables, ObjectProperty } from 'views/backoffice/CustomObjects/types';
import { useDataFocusViewData } from 'views/DataFocusView/hooks';
import { FindFormSections, FindFormSectionsVariables, FormSectionField } from 'views/DataFocusView/types';
import { orderArrayByAttribute, filterFieldsEnabledByRecordType, generateDataFocusViewPayload } from 'views/DataFocusView/utils';
import { getObjectDefinitionIds, getObjectDefinitionByFieldName, formatDataToForm } from 'views/Portal/utils';
import { SectionRender } from 'views/DataFocusView/components';
import { useRecordData } from 'views/RecordDetailsViewer/hooks';
import { IRecordType } from 'types/recordType';
import { CancelOutlined } from '@mui/icons-material';
import { IRegisterRecord, RegisterRecordVariablesShape } from 'views/CreateRecord/types';
import { MUTATION_REGISTER_RECORD } from 'graphql/mutations/records';
import { IUserDataToken } from 'utils/types';
import SideBar from 'ui-component/RecordView/PropertiesPanel/SideBar';
import { LogReportMode, PanelTitle } from 'ui-component/RecordView/PropertiesPanel/utils';
import { FlexibleArea, MainArea } from 'ui-component/RecordView/RecordViewCore/components';
import { LineItemsGrid } from 'ui-component/records/LineItems';
import { LayoutPositionEnum } from 'views/DocumentViewer/types';
import {
    CreateOrUpdateManyLineItems,
    CreateOrUpdateManyLineItemsVariables,
    FindLineItemVariables,
    IFindAllLineItemHeaders,
    IFindLineItems,
    ILineItems
} from 'ui-component/records/LineItems/types';
import { QUERY_FIND_ALL_LINE_ITEMS_HEADERS, QUERY_FIND_LINE_ITEMS } from 'graphql/queries/lineItems';
import { getLayoutPositionHeight } from 'views/DocumentViewer/utils';
import { MUTATION_REGISTER_UPDATE_MANY_LINE_ITEMS } from 'graphql/mutations/lineItems';
import { LoadingButton } from '@mui/lab';

export type DataFocusViewDialogProps = {
    recordType: IRecordType;
    parent: IRecordHeaders;
    open: boolean;
    onClose: (newRecord?: IRecordHeaders, errorMsg?: string) => void;
};

export const DataFocusViewDialog = ({ recordType, open, onClose, parent }: DataFocusViewDialogProps) => {
    const recordTypeId = recordType.id;
    const parentName = parent.recordType.name;
    const recordId = useRef<number | null>(null);
    const { loading, dataFocusViewListById } = useDataFocusViewData(recordTypeId);
    const token = localStorage.getItem('backend_jwt') || '';
    const userData: IUserDataToken = jwt_decode(token);

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<'CREATE_AND_GO_BACK' | 'CREATE_AND_GO_FORWARD' | false>(false);
    const [layoutPosition, setLayoutPosition] = useState<LayoutPositionEnum>('ONLY_RECORD');
    const [lineItemsToCreate, setLineItemsToCreate] = useState<any[]>([]);

    const {
        data: recordData,
        headers,
        headersWithFieldName,
        allowedFieldHeaders,
        fieldAccess: fieldAccessList
    } = useRecordData({ recordTypeId: +recordTypeId });

    const listIds = getListIdsFromHeaders(headers);

    const { listValuesByListId } = useCustomListValues(listIds);
    const [registerRecord] = useMutation<IRegisterRecord, RegisterRecordVariablesShape>(MUTATION_REGISTER_RECORD);
    const [createOrUpdateByRow] = useMutation<CreateOrUpdateManyLineItems, CreateOrUpdateManyLineItemsVariables>(
        MUTATION_REGISTER_UPDATE_MANY_LINE_ITEMS,
        {
            update(cache, { data }) {
                const updated = data?.createOrUpdateManyLineItems;
                const allFields = cache.readQuery<IFindLineItems, FindLineItemVariables>({
                    query: QUERY_FIND_LINE_ITEMS,
                    variables: {
                        data: {
                            recordHeadersIds: [recordId.current as number]
                        }
                    }
                });
                if (!allFields || !updated) return;
                const allUpdatedLineItems: Record<string, ILineItems> = {};

                for (const item of updated) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { recordHeader, lineItemsByTypeFileds, ...rest } = item;
                    allUpdatedLineItems[rest.id] = rest;
                }

                const newsLineItems = updated
                    .filter((el) => !allFields.findLineItems.some((item) => +item.id === +el.id))
                    .map(({ lineItemsByTypeFileds, recordHeader, ...rest }) => rest);

                const updatedLineItems = allFields.findLineItems.map((item) => {
                    const itWasUpdated = allUpdatedLineItems[item.id];
                    return itWasUpdated || item;
                });

                cache.writeQuery<IFindLineItems, FindLineItemVariables>({
                    query: QUERY_FIND_LINE_ITEMS,
                    variables: {
                        data: {
                            recordHeadersIds: [recordId.current as number]
                        }
                    },
                    data: {
                        findLineItems: [...updatedLineItems, ...newsLineItems]
                    }
                });
            }
        }
    );

    const [getObjectProperties, { data: propertiesData }] = useLazyQuery<FindObjectProperty, FindObjectPropertyVariables>(
        QUERY_GET_OBJECT_PROPERTIES
    );

    const [findFormSections, { data: formSectionsData }] = useLazyQuery<FindFormSections, FindFormSectionsVariables>(
        QUERY_FIND_FORM_SECTIONS,
        {
            onCompleted(data) {
                if (data.findFormSectionsMany.length) {
                    const allFields = data.findFormSectionsMany.reduce<FormSectionField[]>(
                        (acc, { sectionFields }) => [...acc, ...sectionFields],
                        []
                    );
                    const allObjectPropertyIds = allFields
                        .filter(({ objectProperty }) => objectProperty)
                        .map(({ objectProperty }) => +objectProperty.id);

                    getObjectProperties({ variables: { data: { ids: allObjectPropertyIds } } });
                }
            }
        }
    );

    const { data: lineItemsHeaderData } = useQuery<IFindAllLineItemHeaders>(QUERY_FIND_ALL_LINE_ITEMS_HEADERS);

    const lineItemHeaders = useMemo(
        () =>
            lineItemsHeaderData?.findAllLineItemsByType.find((data) => {
                const recordTypesArr = data.recordType.map((el) => Number(el.id));
                return recordTypesArr.includes(Number(recordTypeId));
            }) || null,
        [lineItemsHeaderData, recordTypeId]
    );

    const formSections = orderArrayByAttribute(formSectionsData?.findFormSectionsMany || []);

    const objectPropertiesById =
        propertiesData?.findObjectProperty.reduce<Record<number, ObjectProperty>>((acc, el) => ({ ...acc, [+el.id]: el }), {}) || {};
    const objectDefinitionIds = useMemo(() => getObjectDefinitionIds(headers), [headers]);
    const objectPropertiesByFieldName = useMemo(
        () => getObjectDefinitionByFieldName(propertiesData?.findObjectProperty, objectDefinitionIds),
        [objectDefinitionIds, propertiesData?.findObjectProperty]
    );

    const getSchema = () => {
        const allFields = formSections.reduce<FormSectionField[]>(
            (acc, { sectionFields }) => [
                ...acc,
                ...sectionFields.filter(filterFieldsEnabledByRecordType(headersWithFieldName || {}, objectPropertiesByFieldName))
            ],
            []
        );
        const sectionBaseFields = allFields.filter(({ baseField }) => !!baseField);
        const sectionAdditionalFields = allFields.filter(({ recordAdditionalFieldsByType }) => !!recordAdditionalFieldsByType);
        const sectionObjectPropertyFields = allFields.filter(({ objectProperty }) => !!objectProperty);

        const requiredBySectionNonObjectPropertyFieldsName = allFields
            .filter((el) => el.isRequired && (el.baseField || el.recordAdditionalFieldsByType?.name))
            .map((el) => el.baseField || el.recordAdditionalFieldsByType.name);

        const requiredFieldObj: Record<string, any> = {};

        for (const name of requiredBySectionNonObjectPropertyFieldsName) requiredFieldObj[name] = yup.string().trim().required('');

        for (const field of sectionBaseFields) {
            const fieldName = field.baseField;
            const fieldDef = headers?.[fieldName];
            if (fieldDef?.required || field.isRequired) requiredFieldObj[fieldName] = yup.string().trim().required('');
        }

        for (const field of sectionAdditionalFields) {
            const fieldName = field.recordAdditionalFieldsByType.name;
            const fieldDef = headers?.[fieldName];
            if (fieldDef?.required || field.isRequired) requiredFieldObj[fieldName] = yup.string().trim().required('');
        }

        const objectsToSchema: Record<string, string[]> = {};

        for (const field of sectionObjectPropertyFields) {
            const propertyDef = objectPropertiesById[+field.objectProperty.id];
            if (propertyDef) {
                const objectName = propertyDef.objectDefinition.name;
                if (propertyDef?.isRequired) {
                    const itExist = objectsToSchema[objectName];
                    if (itExist) objectsToSchema[objectName].push(propertyDef.name);
                    else objectsToSchema[objectName] = [propertyDef.name];
                }
            }
        }

        for (const objectName in objectsToSchema) {
            if (Object.prototype.hasOwnProperty.call(objectsToSchema, objectName)) {
                const propertiesAsSchema = objectsToSchema[objectName].reduce<Record<string, any>>(
                    (acc, el) => ({ ...acc, [el]: yup.string().trim().required('') }),
                    {}
                );
                requiredFieldObj[objectName] = yup.object(propertiesAsSchema);
            }
        }

        const schema = yup.object(requiredFieldObj).required();
        return schema;
    };

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(getSchema())
    });

    const handleSubmit = async () => {
        const data = methods.getValues();
        const payload = generateDataFocusViewPayload(allowedFieldHeaders as IRecordFieldsList, objectPropertiesByFieldName, data);
        const tenant = Number(localStorage.getItem('tenant_id'));
        try {
            const { data: newRecordData } = await registerRecord({
                variables: {
                    createRecordHeader: {
                        ...payload,
                        recordType: +recordType.id,
                        enabled: true,
                        parent: +parent.id,
                        createdBy: Number(userData.userId),
                        tenant
                    }
                }
            });

            const itemsToCreate = lineItemsToCreate.map((item) =>
                createOrUpdateByRow({
                    variables: { data: item }
                })
            );

            await Promise.all(itemsToCreate);

            dispatch(
                openSnackbar({
                    open: true,
                    message: `Update successful`,
                    variant: 'alert',
                    alert: { color: 'success' },
                    close: true
                })
            );
            return newRecordData?.registerRecordHeader;
        } catch (error: any) {
            console.log('error updating the record', error);
            dispatch(
                openSnackbar({
                    open: true,
                    message: `Save changes failed: ${error.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
            return null;
        }
    };

    const handleClickAndGoBack = async () => {
        setIsLoading('CREATE_AND_GO_BACK');
        const record = await handleSubmit();
        setIsLoading(false);
        if (record) onClose(record);
    };

    const handleClickAndGoToRecord = async () => {
        setIsLoading('CREATE_AND_GO_FORWARD');
        const record: any = await handleSubmit();
        setIsLoading(false);
        if (record) {
            const tenantUrl = localStorage.getItem('tenantPath');
            window.location.href = `${tenantUrl}/data-focus-view/?recordId=${record.id}&recordType=${recordType.id}`;
        }
    };

    useEffect(() => {
        if (!recordData || !open) return;
        const data = formatDataToForm(allowedFieldHeaders as IRecordFieldsList, recordData);
        methods.reset(data);
    }, [allowedFieldHeaders, methods, recordData, open]);

    useEffect(() => {
        methods.clearErrors();
        methods.trigger();
    }, [methods]);

    useEffect(() => {
        if (open) {
            if (Object.values(dataFocusViewListById).length > 0) {
                const selected = Object.values(dataFocusViewListById)[0];
                if (selected) {
                    findFormSections({ variables: { data: { formId: +selected.form.id } } });
                }
            } else {
                onClose(undefined, "There's no forms available for this record type");
            }
        }
    }, [findFormSections, dataFocusViewListById, open, dispatch, onClose]);

    if (loading) return null;

    return (
        <Dialog open={open} onClose={() => onClose()} maxWidth="lg" sx={{ '& .MuiDialog-paper': { py: 0 } }} fullWidth>
            <DialogTitle component={Grid} container sx={{ px: '24px', height: '64px', bgcolor: '#f5f6f7' }}>
                <Grid item xs>
                    <Breadcrumbs separator=">">
                        <Typography fontSize="20px" fontWeight={300}>
                            {parentName}
                        </Typography>
                        <Typography color="secondary" fontSize="20px" fontWeight={500}>
                            {recordType.name}
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs="auto">
                    <IconButton color="secondary" onClick={() => onClose()}>
                        <CancelOutlined />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent sx={{ overflowY: 'hidden', height: '70vh', px: 0 }}>
                <Grid container flexWrap="nowrap" sx={{ height: '100%', width: '100%' }}>
                    <Grid item xs sx={{ height: '100%', width: 'calc(100% - 50px)' }}>
                        <MainArea showSearchSidebard={false} height="100%" sx={{ display: 'block' }} transparent fullWidth>
                            <Grid
                                item
                                xs
                                sx={{
                                    height: getLayoutPositionHeight('RECORD', layoutPosition),
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    position: 'relative',
                                    display: 'block'
                                }}
                            >
                                <FormProvider {...methods}>
                                    {formSections
                                        .filter(
                                            ({ sectionFields }) =>
                                                sectionFields.filter(
                                                    filterFieldsEnabledByRecordType(headersWithFieldName || {}, objectPropertiesByFieldName)
                                                ).length
                                        )
                                        .map((section) => (
                                            <SectionRender
                                                fieldAccessList={fieldAccessList}
                                                key={section.id}
                                                section={section}
                                                headers={headersWithFieldName || {}}
                                                recordTypeId={recordTypeId}
                                                propertiesById={objectPropertiesById}
                                                propertyByFieldName={objectPropertiesByFieldName}
                                                listValuesByListId={listValuesByListId}
                                            />
                                        ))}
                                </FormProvider>
                            </Grid>
                            {!!lineItemHeaders && (layoutPosition === 'SPLIT_VIEW' || layoutPosition === 'ONLY_ADDITIONAL_INFO') && (
                                <FlexibleArea
                                    title="Line Items"
                                    toggleFullHeight={() =>
                                        setLayoutPosition(layoutPosition === 'SPLIT_VIEW' ? 'ONLY_ADDITIONAL_INFO' : 'SPLIT_VIEW')
                                    }
                                    fullHeight={layoutPosition === 'ONLY_ADDITIONAL_INFO'}
                                    height={getLayoutPositionHeight('ADDITIONAL_INFO', layoutPosition)}
                                    showDivider={layoutPosition === 'SPLIT_VIEW'}
                                    onClose={() => {
                                        setLayoutPosition('ONLY_RECORD');
                                    }}
                                    contentSx={{ pt: 1 }}
                                >
                                    <LineItemsGrid
                                        isOpen={(['SPLIT_VIEW', 'ONLY_LINE_ITEMS'] as LayoutPositionEnum[]).includes(layoutPosition)}
                                        recordId={0}
                                        recordTypeId={Number(recordTypeId)}
                                        showCustomNoRowsOverlay={layoutPosition === 'ONLY_ADDITIONAL_INFO'}
                                        headers={lineItemHeaders}
                                        onChangeLineItems={(newVal) => setLineItemsToCreate((s) => [...s, newVal])}
                                        onDeleteLineItem={(index) => setLineItemsToCreate((s) => s.filter((el) => el.index !== index))}
                                    />
                                </FlexibleArea>
                            )}
                        </MainArea>
                    </Grid>

                    <Grid item xs="auto" sx={{ height: '100%' }}>
                        <Box width="50px" height="100%">
                            <SideBar
                                changePanel={PanelTitle.Edit}
                                logReportMode={LogReportMode.All}
                                toggleLogReport={() => {}}
                                onToggleLineItems={() => {
                                    setLayoutPosition(layoutPosition === 'SPLIT_VIEW' ? 'ONLY_RECORD' : 'SPLIT_VIEW');
                                }}
                                hideButtons
                                hideLogReport
                                onGoBack={() => {}}
                                recordTypeId={Number(recordType.id)}
                                isAttachmentFieldAllowed={false}
                                handleClickCancel={() => {}}
                                handleClickSave={async () => {}}
                                loading={loading}
                                toggleEditForm={() => {}}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ px: '24px', display: 'flex', justifyContent: 'space-between', height: '64px', bgcolor: '#f5f6f7' }}>
                <Grid item xs="auto">
                    <Button
                        variant="outlined"
                        onClick={() => onClose()}
                        color="secondary"
                        sx={{ width: '80px', height: '32px', borderRadius: '8px' }}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item container xs="auto" spacing={2}>
                    <Grid item xs="auto">
                        <LoadingButton
                            loading={isLoading === 'CREATE_AND_GO_BACK'}
                            onClick={handleClickAndGoBack}
                            disabled={!!Object.values(methods.formState.errors).length || isLoading === 'CREATE_AND_GO_FORWARD'}
                            variant="outlined"
                            color="secondary"
                            sx={{ height: '32px', borderRadius: '8px' }}
                        >
                            Create and Return to Project
                        </LoadingButton>
                    </Grid>
                    <Grid item xs="auto">
                        <LoadingButton
                            loading={isLoading === 'CREATE_AND_GO_FORWARD'}
                            onClick={handleClickAndGoToRecord}
                            disabled={!!Object.values(methods.formState.errors).length || isLoading === 'CREATE_AND_GO_BACK'}
                            variant="contained"
                            color="secondary"
                            sx={{ height: '32px', borderRadius: '8px' }}
                        >
                            Create and go to record
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
