import { gql } from '@apollo/client';

export const QUERY_FIND_ALL_FORM_PORTALS = gql`
    query FindAllFormPortals {
        findAllFormPortals {
            name
            enabled
            description
            url
        }
    }
`;

export const QUERY_FIND_PORTAL_FORM_SECTIONS = gql`
    query FindPortalFormSectionsMany($data: FindPortalFormSectionInput!) {
        findPortalFormSectionsMany(data: $data) {
            id
            name
            portalByFormByPortalFormSection {
                id
            }
        }
    }
`;

export const QUERY_FIND_PORTAL_FROM_SECTIONS = gql`
    query FindPortalFormSectionFormPortalsMany($data: FindPortalFormSectionFormPortalInput!) {
        findPortalFormSectionFormPortalsMany(data: $data) {
            id
            portalFormSection {
                id
            }
            form {
                id
                name
                recordType {
                    id
                }
            }
        }
    }
`;

export const QUERY_FIND_FORMS_MANY = gql`
    query FindFormsMany($data: FindFormInput!) {
        findFormsMany(data: $data) {
            name
            recordType {
                id
            }
            id
        }
    }
`;
