import { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Grid, List, Typography } from '@mui/material';
import { AuditLog, CommentLog, LineItemTypes, LogReportType } from 'ui-component/RecordView/types';
import { LogItem, LogReportSelector, SkeletonLoader } from './components';
import { ButtonTypeLog, LogReportMode } from '../utils';
import useAuditLogs from './hooks/useAuditLogs';
import DetailHistoryItem from 'views/DocumentViewer/components/DetailHistoryItem/DetailHistoryItem';
import DetailHistoryItemName from 'views/DocumentViewer/components/DetailHistoryItemName/DetailHistoryItemName';
import { LineItemLog } from 'ui-component/records/LineItems/types';
import { DetailsDialog, FullHistoryDialog } from 'ui-component/RecordView/Dialogs';
import LineItemHistoryDialog from 'ui-component/records/LineItems/Dialogs/LineItemHistoryDialog';

export type LogReportPanelProps = {
    recordId: number;
    showFullHistory: boolean;
    onCloseFullHistory: () => void;
    logReportMode: LogReportMode;
    striped?: boolean;
    setButtonTypeLog?: (newSelection: LogReportType) => void;
    buttonTypeLog?: LogReportType;
    toggleLogReport?: () => void;
} & ({ showSearch?: false } | { showSearch: true; onChangeSelection?: (newSelection: LogReportMode) => void });

export const LogReportPanel = ({
    recordId,
    showFullHistory,
    onCloseFullHistory,
    logReportMode = LogReportMode.All,
    setButtonTypeLog,
    buttonTypeLog = ButtonTypeLog.Detail,
    striped,
    toggleLogReport,
    ...props
}: LogReportPanelProps) => {
    const tenantId = Number(localStorage.getItem('tenant_id'));
    const [selectedLog, setSelectedLog] = useState<AuditLog | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchInput, setSearchInput] = useState('');
    const [currentPosition, setCurrentPosition] = useState<number | null>(null);

    const { loading, logs, total, fetchMoreLogsRecords, fetchMoreLogsComments, fetchMoreLogsLineItems } = useAuditLogs(
        logReportMode,
        recordId,
        tenantId
    );

    const [filteredLogs, setFilteredLogs] = useState<AuditLog[] | CommentLog[] | LineItemTypes[] | undefined>(logs);

    useEffect(() => {
        const filtered = logs?.filter(
            ({ user, action, changes }) =>
                user.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                action.toLowerCase().includes(searchInput.toLowerCase()) ||
                changes.some(
                    (change: { oldValue?: string; newValue?: string }) =>
                        change.oldValue?.toLowerCase().includes(searchInput.toLowerCase()) ||
                        change.newValue?.toLowerCase().includes(searchInput.toLowerCase())
                )
        );

        setFilteredLogs(filtered as AuditLog[] | CommentLog[] | LineItemTypes[]);
    }, [logs, searchInput]);

    const handleFetchMore = () => {
        if (logReportMode === LogReportMode.Objects) {
            fetchMoreLogsRecords({
                variables: {
                    data: {
                        filterInput: { recordId, tenantId },
                        pagination: { limit: 25, offset: logs?.length }
                    }
                }
            });
        } else if (logReportMode === LogReportMode.Comments) {
            fetchMoreLogsComments({
                variables: {
                    data: {
                        filterInput: { recordId, tenantId },
                        pagination: { limit: 25, offset: logs?.length }
                    }
                }
            });
        } else if (logReportMode === LogReportMode.LineItems) {
            fetchMoreLogsLineItems({
                variables: {
                    data: {
                        filterInput: { recordId, tenantId },
                        pagination: { limit: 25, offset: logs?.length }
                    }
                }
            });
        }
    };

    const handleSelectLog = useCallback(
        (log: AuditLog) => () => {
            setSelectedLog(log);
            setCurrentPosition(logs?.indexOf(log as unknown as any) ?? null);
        },
        [logs]
    );

    const handleOnCloseFullHistory = () => {
        onCloseFullHistory();
    };

    const handleOnCloseDetails = () => {
        setSelectedLog(null);
        setCurrentPosition(null);
    };

    const getFullHistory = () => {
        switch (logReportMode) {
            case LogReportMode.Objects:
                return ((filteredLogs as unknown as AuditLog[]) ?? []).filter((log) => log?.objectsLogs?.length > 0);
            default:
                return filteredLogs;
        }
    };

    const reportsNoObjects = useMemo(
        () =>
            [LogReportMode.All, LogReportMode.Comments, LogReportMode.LineItems, LogReportMode.Attachments].includes(logReportMode) && (
                <>
                    {filteredLogs?.map((log, idx) => (
                        <LogItem
                            key={idx}
                            item={log}
                            onOpen={handleSelectLog(log as AuditLog)}
                            striped={striped ? !!((idx + 1) % 2) : false}
                            position={idx}
                            currentPosition={currentPosition}
                        />
                    ))}
                </>
            ),
        [logReportMode, filteredLogs, handleSelectLog, striped, currentPosition]
    );

    const reportObjects = useMemo(
        () =>
            logReportMode === LogReportMode.Objects && (
                <>
                    {((filteredLogs as unknown as AuditLog[]) ?? [])
                        .filter((log) => log?.objectsLogs?.length > 0)
                        .map((log, idx) => (
                            <LogItem key={idx} item={log} onOpen={handleSelectLog(log)} position={idx} currentPosition={currentPosition} />
                        ))}
                </>
            ),

        [logReportMode, filteredLogs, handleSelectLog, currentPosition]
    );

    return (
        <Box sx={{ height: '100%', width: '100%', overflowY: 'scroll', overflowX: 'scroll' }}>
            {props.showSearch && (
                <Grid container alignItems="center" sx={{ mb: '12px', width: '100%' }}>
                    {props.onChangeSelection && (
                        <Grid item xs sx={{ ml: '4px' }}>
                            <LogReportSelector
                                buttonTypeLog={buttonTypeLog}
                                setButtonTypeLog={setButtonTypeLog}
                                onChangeSelection={props.onChangeSelection}
                                toggleLogReport={toggleLogReport}
                                setSearchInput={setSearchInput}
                            />
                        </Grid>
                    )}
                </Grid>
            )}

            {buttonTypeLog === ButtonTypeLog.Detail && logReportMode === LogReportMode.LineItems && (
                <DetailHistoryItem items={filteredLogs} loading={loading} />
            )}

            {buttonTypeLog === ButtonTypeLog.Detail && logReportMode === LogReportMode.Objects && (
                <DetailHistoryItemName
                    items={((filteredLogs as AuditLog[]) ?? []).filter((log) => log?.objectsLogs?.length > 0)}
                    loading={loading}
                    logReportMode={logReportMode}
                />
            )}

            {buttonTypeLog === ButtonTypeLog.Detail &&
                [LogReportMode.All, LogReportMode.Comments, LogReportMode.Attachments].includes(logReportMode) && (
                    <DetailHistoryItemName
                        items={filteredLogs as LineItemLog[] | AuditLog[]}
                        loading={loading}
                        logReportMode={logReportMode}
                    />
                )}

            {buttonTypeLog === ButtonTypeLog.Summary && (
                <>
                    <List id="scrollable-list">
                        <InfiniteScroll
                            dataLength={filteredLogs?.length || 25}
                            next={handleFetchMore}
                            hasMore={filteredLogs?.length !== total}
                            loader={loading ? <SkeletonLoader /> : null}
                            endMessage={
                                <Typography
                                    align="center"
                                    variant="caption"
                                    sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                                >
                                    No more data to load.
                                </Typography>
                            }
                            scrollableTarget="scrollable-list"
                        >
                            <>
                                {reportsNoObjects}

                                {reportObjects}
                            </>
                        </InfiniteScroll>
                    </List>
                    <DetailsDialog item={selectedLog} onClose={handleOnCloseDetails} logReportMode={logReportMode} />
                </>
            )}

            {showFullHistory && (
                <>
                    {logReportMode === LogReportMode.LineItems ? (
                        <LineItemHistoryDialog
                            items={getFullHistory()}
                            open={showFullHistory}
                            onClose={handleOnCloseFullHistory}
                            onFetchMore={handleFetchMore}
                            total={total}
                            logReportMode={logReportMode}
                        />
                    ) : (
                        <FullHistoryDialog
                            items={getFullHistory()}
                            open={showFullHistory}
                            onClose={handleOnCloseFullHistory}
                            onFetchMore={handleFetchMore}
                            total={total}
                            logReportMode={logReportMode}
                        />
                    )}
                </>
            )}
        </Box>
    );
};
