/* eslint no-nested-ternary: 0 */
import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { useState } from 'react';
import { ChildrenRecordTypeList } from '../../Core';
import { useDispatch } from 'store';
import { useLazyQuery } from '@apollo/client';
import { FindRecordType, FindRecordTypeVariables, IRecordType } from 'types/recordType';
import { QUERY_FIND_RECORD_TYPES } from 'graphql/queries/recordTypes';
import { openSnackbar } from 'store/slices/snackbar';
import { IRecordHeaders } from 'ui-component/records/types';
import { MAX_HIERARCHY_LEVEL } from '../../assets';
import useIsAllowedByRecordType from 'hooks/useIsAllowedByRecordType';
import { FormattedMessage } from 'react-intl';

export type RenderActionsProps = {
    onAddChildRecord: (recordType: IRecordType) => void;
    onLinkParentRecord?: () => void;
    row: IRecordHeaders;
};

export const RenderActions = ({ onAddChildRecord, onLinkParentRecord, row }: RenderActionsProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [childrenRecordTypeMenuAnchor, setChildrenRecordTypeMenuAnchor] = useState<HTMLElement | null>(null);
    const [selectedRecordChildren, setSelectedRecordChildren] = useState<IRecordType[]>([]);

    const dispatch = useDispatch();
    const [findRecordTypes] = useLazyQuery<FindRecordType, FindRecordTypeVariables>(QUERY_FIND_RECORD_TYPES);
    const { isAllowed: isAllowedToCreateRecords, loading: loadingCreateRecordsAccess } = useIsAllowedByRecordType(
        'create records',
        Number(row?.recordType?.id)
    );
    const handleOpenRecordTypeList = (recordTypeId: number) => async (currentTarget: HTMLElement) => {
        try {
            const { data } = await findRecordTypes({ variables: { data: { id: recordTypeId, enabled: true } } });
            if (data?.findRecordType[0].children?.length) {
                setSelectedRecordChildren(data.findRecordType[0].children);
                setChildrenRecordTypeMenuAnchor(currentTarget);
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `This record does not have any children record types`,
                        variant: 'alert',
                        alert: { color: 'error' },
                        close: false
                    })
                );
            }
        } catch (error: any) {
            console.log('error adding child record', error);
            dispatch(
                openSnackbar({
                    open: true,
                    message: `Failed to show child records: ${error.message}`,
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: false
                })
            );
        }
    };

    const handleAddChildRecord = (recordType: IRecordType) => {
        onAddChildRecord(recordType);
        setAnchorEl(null);
    };

    const handleClick = (fn: (currentTarget?: any) => void, currentTarget?: any) => () => {
        fn(currentTarget);
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                <MoreVert />
            </IconButton>
            <Menu
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{ sx: ({ palette }) => ({ borderRadius: '8px', marginTop: '20px', backgroundColor: palette.primary[300] }) }}
            >
                <Tooltip
                    title={
                        !isAllowedToCreateRecords ? (
                            <FormattedMessage id="notAllowedToCreateRecords" />
                        ) : row?.level >= MAX_HIERARCHY_LEVEL ? (
                            <FormattedMessage id="maxHierarchyLevelReached" />
                        ) : (
                            ''
                        )
                    }
                >
                    <span>
                        <MenuItem
                            onClick={(e) => handleOpenRecordTypeList(+row.recordType.id)(e.currentTarget)}
                            disabled={!isAllowedToCreateRecords || loadingCreateRecordsAccess || row?.level >= MAX_HIERARCHY_LEVEL}
                        >
                            Create Child Record
                        </MenuItem>
                    </span>
                </Tooltip>
                {!!onLinkParentRecord && <MenuItem onClick={handleClick(onLinkParentRecord)}>Link Parent Record</MenuItem>}
            </Menu>
            <ChildrenRecordTypeList
                anchorEl={childrenRecordTypeMenuAnchor}
                list={selectedRecordChildren}
                onClose={() => setChildrenRecordTypeMenuAnchor(null)}
                onOpenChildRecord={handleAddChildRecord}
            />
        </>
    );
};
