/* eslint-disable no-case-declarations */
import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSelector } from 'store';
import useMyRole from 'hooks/useMyRole';
import { Box, IconButton, SelectChangeEvent, Tooltip } from '@mui/material';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import {
    MUTATION_CREATE_USER_PREFERENCES,
    MUTATION_REGISTER_USER_GRID_PREFERENCES_DEFAULT,
    MUTATION_UPDATE_USER_GRID_PREFERENCES_DEFAULT,
    MUTATION_UPDATE_USER_PREFERENCES
} from 'graphql/mutations/bills';
import { GET_USER_DEFAULT_GRID_PREFERENCES, PREFERENCES_BACKOFFICE } from 'graphql/queries/bills';
import { openSnackbar } from 'store/slices/snackbar';
import { IUserDataToken } from 'utils/types';
import {
    GetDefaultUserGridPreference,
    GetDefaultUserGridPreferenceVariables,
    // IGridOption,
    IGridPreference,
    IRegisterUserPreference,
    ISaasMyGridPreference,
    IUpdateGridPreference,
    RegisterUserGridPreferencesDefault,
    RegisterUserGridPreferencesDefaultVariables,
    UpdateUserGridPreferencesDefault,
    UpdateUserGridPreferencesDefaultVariables,
    UserGridPreferencesRegisterVariables,
    UserGridPreferencesUpdateVariables
} from '../types';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import SaveNameDialog from '../SaveNameDialog';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { generateGridOptionsToSave } from '../utils/saveGridHelpers';
import { PreferenceSelect, SearchBox } from './components';
import { ChangeListType, checkIfMoreThan200, getDefaultGrid, getUpdatedPreferenceList } from './utils';
import { useConfirmationModalContext } from 'hooks/useConfirmationModal';
import { Search } from '@mui/icons-material';

// import { useQueryParams } from 'hooks/useQueryParams';

export interface IGridOptionsComponent {
    onPreferenceSave: () => void;
    showSaveChanges: boolean;
    onLoading: (loading: boolean) => void;
    setDefaultGrid: (preference: IGridPreference | null, applyQueryParams?: boolean) => void;
    inputValue: string;
    onChangeInputValue: (value: string) => void;
    apiRef?: React.MutableRefObject<GridApiPro>;
    densityFactor: React.MutableRefObject<number>;
    createOrder: () => void;
    canCreateNewRecord: boolean;
    recordTypeId: number;
    loadPreferences?: boolean;
    isSuperRecordType?: boolean;
    [key: string]: any;
}

export const GridOptions = ({
    onPreferenceSave,
    showSaveChanges,
    inputValue,
    onChangeInputValue,
    onLoading,
    setDefaultGrid,
    gridName,
    apiRef,
    densityFactor,
    createOrder,
    recordTypeId,
    canCreateNewRecord = false,
    loadPreferences = false,
    isSuperRecordType = false
}: IGridOptionsComponent) => {
    // const searchQueryParams = useQueryParams() as Record<string, string>;
    const tenantId = localStorage.getItem('tenant_id');
    const token = localStorage.getItem('backend_jwt') || '';
    const storeDispatch = useDispatch();
    const userData: IUserDataToken = jwt_decode(token);

    const { actionData, selectedItem } = useSelector((store) => store.menu);
    const myRole = useMyRole();

    const canConfigureGridPreference = useMemo(
        () =>
            actionData
                .find((el) => Number(el.menuItem.id) === Number(selectedItem) && Number(el.role.id) === (myRole as number))
                ?.menuItemActions.find((el) => el.name.toLowerCase().includes('grid preferences'))?.enabled,
        [actionData, myRole, selectedItem]
    );

    const [preferenceList, setPreferenceList] = useState<IGridPreference[]>([]);
    const [selectedGridId, setSelectedGridId] = useState('');
    const [appliedGridId, setAppliedGridId] = useState<string | null>(null);
    const [defaultGridPreferenceId, setDefaultGridPreferenceId] = useState('');
    const [editGridPreference, setEditGridPreference] = useState(false);
    const [createdDefaultGridId, setCreatedDefaultGridId] = useState<number | null>(null);

    const [isOpenSaveDialog, setIsOpenSaveDialog] = useState(false);
    const [showSearchInput, setShowSearchInput] = useState(true);

    const modal = useConfirmationModalContext();

    const updateTypeRef = useRef<ChangeListType>('CHANGE_DEFAULT');

    const [getUserPreferences, { loading: userPreferencesLoading, data: userPreferencesData }] = useLazyQuery<ISaasMyGridPreference>(
        PREFERENCES_BACKOFFICE,
        {
            fetchPolicy: 'no-cache',
            variables: { gridName: gridName || 'bills' },
            async onCompleted(data) {
                if (!data || !data.SaasMyGridPreferences?.length) {
                    setDefaultGrid(null);
                } else {
                    const newDefaultGridPreferenceId = await handleGetDefaultUserGrid();
                    setCreatedDefaultGridId(newDefaultGridPreferenceId ? +newDefaultGridPreferenceId.id : null);
                    handleChangeGridPreferences(
                        data.SaasMyGridPreferences,
                        newDefaultGridPreferenceId
                            ? +newDefaultGridPreferenceId.roleGridPreference?.id ||
                                  +newDefaultGridPreferenceId.userGridPreference?.id ||
                                  null
                            : null,
                        newDefaultGridPreferenceId
                            ? +newDefaultGridPreferenceId.roleGridPreference?.id ||
                                  +newDefaultGridPreferenceId.userGridPreference?.id ||
                                  null
                            : null
                    );
                }
            },
            onError(error) {
                storeDispatch(
                    openSnackbar({
                        open: true,
                        message: `Failed to fetch grid preferences: ${error.message.replaceAll(':', ',')}`,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
            }
        }
    );
    const [getUserDefaultGridPreferences] = useLazyQuery<GetDefaultUserGridPreference, GetDefaultUserGridPreferenceVariables>(
        GET_USER_DEFAULT_GRID_PREFERENCES,
        { variables: { recordTypeId } }
    );
    const [createUserPreferences] = useMutation<IRegisterUserPreference, UserGridPreferencesRegisterVariables>(
        MUTATION_CREATE_USER_PREFERENCES,
        {
            update(_cache, { data }) {
                const newPreference = data?.registerUserGridPreferences;

                if (newPreference) {
                    const newPreferenceList = getUpdatedPreferenceList(preferenceList, newPreference, 'NEW');
                    handleChangeGridPreferences(newPreferenceList, +newPreference.id, +newPreference.id);
                }
            }
        }
    );
    const [updateUserPreferences] = useMutation<IUpdateGridPreference, UserGridPreferencesUpdateVariables>(
        MUTATION_UPDATE_USER_PREFERENCES,
        {
            update(_cache, { data }) {
                const updatedPreference = data?.updateUserGridPreferences;

                if (updatedPreference) {
                    const newPreferenceList = getUpdatedPreferenceList(preferenceList, updatedPreference, updateTypeRef.current);
                    handleChangeGridPreferences(newPreferenceList, +updatedPreference.id);
                }
            }
        }
    );

    const [createUserDefaultGridPreference] = useMutation<RegisterUserGridPreferencesDefault, RegisterUserGridPreferencesDefaultVariables>(
        MUTATION_REGISTER_USER_GRID_PREFERENCES_DEFAULT
    );

    const [updateUserDefaultGridPreference] = useMutation<UpdateUserGridPreferencesDefault, UpdateUserGridPreferencesDefaultVariables>(
        MUTATION_UPDATE_USER_GRID_PREFERENCES_DEFAULT
    );

    const preferenceSelected = useMemo(
        () => preferenceList.find((el) => Number(el.id) === Number(selectedGridId)),
        [preferenceList, selectedGridId]
    );

    const isAdminSelectedGrid = useMemo(() => !!preferenceSelected?.role, [preferenceSelected]);

    const handleSaveNewPreference = async (name?: string) => {
        setIsOpenSaveDialog(false);
        try {
            if (!apiRef?.current) throw Error('apiRef is not defined');
            const actualState = apiRef.current.exportState();
            const gridOptions = generateGridOptionsToSave(actualState, densityFactor.current || 1);
            const savedName = name || '';

            updateTypeRef.current = 'NEW';

            const { data } = await createUserPreferences({
                variables: {
                    data: {
                        isDefault: true,
                        enabled: true,
                        userId: userData.userId,
                        tenantId: 1,
                        gridName,
                        savedName,
                        gridOptions
                    }
                }
            });
            const preference = data?.registerUserGridPreferences as IGridPreference;

            await handleChangeDefaultPreference(String(preference.id));
            setDefaultGrid(preference);
            setAppliedGridId(String(preference.id));
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: 'Changes Saved!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            onPreferenceSave();
        } catch (err: any) {
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Save changes failed: ${err.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
            console.log(err);
        }
    };

    const handleRenamePreference = async (namePreference: string) => {
        try {
            if (!apiRef?.current) throw Error('apiRef is not defined');

            updateTypeRef.current = 'UPDATE_GRID';

            const { data } = await updateUserPreferences({
                variables: { data: { id: Number(selectedGridId), savedName: namePreference } }
            });
            const preference = data?.updateUserGridPreferences as IGridPreference;
            await handleChangeDefaultPreference(String(preference.id));
            // setDefaultGrid(preference);
            // setAppliedGridId(String(preference.id));
            setEditGridPreference(false);
            setIsOpenSaveDialog(false);

            storeDispatch(
                openSnackbar({
                    open: true,
                    message: 'Changes Saved!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } catch (err: any) {
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Save changes failed: ${err.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
            console.log(err);
        }
    };

    const handleDeletePreference = async () => {
        try {
            updateTypeRef.current = 'DELETE';

            await updateUserPreferences({
                variables: { data: { id: Number(selectedGridId), enabled: false } }
            });
            setEditGridPreference(false);
            onPreferenceSave();
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: 'Changes Saved!',
                    variant: 'alert',
                    alert: { color: 'success' },
                    close: false
                })
            );
        } catch (err: any) {
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Save changes failed: ${err.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
            console.log(err);
        }
    };

    const handleUpdatePreference = async () => {
        try {
            if (isAdminSelectedGrid) throw Error('Admin preferences cannot be edited. You should save as new.');
            if (!apiRef?.current) throw Error('apiRef is not defined');
            const actualState = apiRef.current.exportState();
            const gridOptions = generateGridOptionsToSave(actualState, densityFactor.current || 1);

            updateTypeRef.current = 'UPDATE_GRID';

            await updateUserPreferences({
                variables: {
                    data: {
                        id: Number(appliedGridId),
                        gridOptions
                    }
                }
            });
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: 'Changes Saved!',
                    variant: 'alert',
                    alert: { color: 'success' },
                    close: false
                })
            );
            onPreferenceSave();
        } catch (err: any) {
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Save changes failed: ${err.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
            console.log(err);
        }
    };

    const handleChangeDefaultPreference = async (id: string, list: IGridPreference[] = preferenceList) => {
        const selected = list.find((el) => Number(el.id) === Number(id));

        if (!selected) return;

        // const IsMoreThan200 = checkIfMoreThan200(selected);
        // const gridOptions: IGridOption[] = IsMoreThan200 ? [{ ...selected.gridOptions[0], pageSize: 200 }] : selected.gridOptions;

        updateTypeRef.current = 'CHANGE_DEFAULT';

        try {
            handleChangeGridPreferences(list, +selected.id);
            if (createdDefaultGridId) {
                await updateUserDefaultGridPreference({
                    variables: {
                        data: {
                            id: createdDefaultGridId,
                            roleGridPreferenceId: selected.role ? +selected.id : undefined,
                            userGridPreferenceId: selected.role ? undefined : +selected.id
                        }
                    }
                });
            } else {
                const { data: createDefaultGridData } = await createUserDefaultGridPreference({
                    variables: {
                        data: {
                            recordTypeId,
                            tenantId: +(tenantId as string),
                            userId: userData.userId,
                            userGridPreferenceId: selected.role ? undefined : +selected.id,
                            roleGridPreferenceId: selected.role ? +selected.id : undefined,
                            enabled: true
                        }
                    }
                });
                if (createDefaultGridData?.registerUserGridPreferencesDefault)
                    setCreatedDefaultGridId(+createDefaultGridData.registerUserGridPreferencesDefault.id);
            }

            storeDispatch(
                openSnackbar({
                    open: true,
                    message: 'Grid preference changed!',
                    variant: 'alert',
                    alert: { color: 'success' },
                    close: false
                })
            );
        } catch (err: any) {
            console.log(err);
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Save changes failed: ${err.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
        }
    };

    const handleChangeGridPreferences = (list: IGridPreference[], selectedId: number | null, defaultId?: number | null) => {
        const gridToApply = getDefaultGrid(list, selectedId);
        if (checkIfMoreThan200(gridToApply)) {
            handleChangeDefaultPreference(String(gridToApply.id), list);
            return;
        }
        densityFactor.current = gridToApply.gridOptions[0].densityFactor ?? 1;
        localStorage.setItem('grid_density_factor', String(gridToApply.gridOptions[0].densityFactor ?? 1));
        setPreferenceList(list);
        setDefaultGrid(gridToApply);
        if (defaultId) {
            setDefaultGridPreferenceId(String(defaultId));
        }
        setSelectedGridId(String(gridToApply.id));
        setAppliedGridId(String(gridToApply.id));

        updateTypeRef.current = 'CHANGE_DEFAULT';
    };

    const handleGetDefaultUserGrid = useCallback(async () => {
        try {
            const { data: userDefaultData } = await getUserDefaultGridPreferences();
            return userDefaultData?.getDefaultUserGridPreference || null;
        } catch (err: any) {
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Failed to fetch default grid preferences: ${err.message}`,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return null;
        }
    }, [getUserDefaultGridPreferences, storeDispatch]);

    // Handle user events

    const handleUpdateButton = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (isAdminSelectedGrid)
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: 'Admin preferences cannot be edited. You should save as new.',
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
        else await handleShowSaveChangesConfirmationDialog();
    };

    const handleSubmitEdit = async (namePreference: string) => {
        await handleShowChangeNameConfirmationDialog(namePreference);
        onPreferenceSave();
    };

    const handleChangePreferenceSelect = (event: SelectChangeEvent) => {
        setSelectedGridId(event.target.value);
        setDefaultGridPreferenceId(event.target.value);
        handleChangeDefaultPreference(event.target.value);
    };

    const openEditDialog = () => {
        setIsOpenSaveDialog(true);
        setEditGridPreference(true);
    };

    const openDeleteDialog = () => {
        handleShowDeleteConfirmationDialog();
        setEditGridPreference(false);
    };

    useEffect(() => {
        onLoading(userPreferencesLoading);
    }, [userPreferencesLoading, onLoading]);

    useEffect(() => {
        if (!loadPreferences) return;
        getUserPreferences();
    }, [getUserPreferences, loadPreferences]);

    const handleShowSaveChangesConfirmationDialog = async () => {
        await modal.showConfirmation({
            title: 'Keep Changes?',
            content: `Are you sure you want to save the changes?`,
            loadingText: 'Updating',
            forwardButtonText: 'Yes, Confirm',
            actionButtonText: 'No, Cancel',
            onForward: handleUpdatePreference,
            onAction: null,
            showCheckbox: true,
            actionType: 'edit',
            sectionTitle: 'grid_preferences',
            recordType: gridName.toLowerCase().replaceAll(' ', '_')
        });
    };

    const handleShowChangeNameConfirmationDialog = async (namePreference: string) => {
        await modal.showConfirmation({
            title: 'Keep Changes?',
            content: `Are you sure you want to save the changes?`,
            loadingText: 'Updating',
            forwardButtonText: 'Yes, Confirm',
            actionButtonText: 'No, Cancel',
            onForward: async () => {
                handleRenamePreference(namePreference);
            },
            onAction: null,
            showCheckbox: true,
            actionType: 'edit',
            sectionTitle: 'grid_preferences',
            recordType: gridName.toLowerCase().replaceAll(' ', '_')
        });
    };

    const handleShowDeleteConfirmationDialog = async () => {
        await modal.showConfirmation({
            title: 'Keep Changes?',
            content: `Are you sure you want to delete this grid preference?`,
            loadingText: 'Updating',
            forwardButtonText: 'Yes, Confirm',
            actionButtonText: 'No, Cancel',
            onForward: handleDeletePreference,
            onAction: null,
            showCheckbox: false,
            actionType: 'delete',
            sectionTitle: 'grid_preferences',
            recordType: gridName.toLowerCase().replaceAll(' ', '_')
        });
    };

    return (
        <Box data-testid="grid-options" sx={{ padding: '0.5rem 0.4rem', paddingBottom: 0, display: 'flex' }}>
            <PreferenceSelect
                value={selectedGridId}
                defaultValue={defaultGridPreferenceId}
                disabled={!loadPreferences}
                onChange={(newVal) => handleChangeGridPreferences(preferenceList, +newVal.target.value)}
                onChangeDefault={handleChangePreferenceSelect}
                preferenceList={preferenceList || []}
                isAdminSelectedGrid={isAdminSelectedGrid}
                userPreferencesLoading={userPreferencesLoading}
                userPreferencesData={userPreferencesData}
                canConfigureGridPreference={canConfigureGridPreference ?? false}
                openEditDialog={openEditDialog}
                openDeleteDialog={openDeleteDialog}
                showSaveChanges={showSaveChanges}
                onSaveChanges={handleUpdateButton}
                onSaveAsNew={() => setIsOpenSaveDialog(true)}
            />
            <Box sx={{ margin: '0 0.5rem' }}>
                {!showSearchInput && (
                    <Tooltip title="Open Searchbar">
                        <IconButton
                            onClick={() => setShowSearchInput(true)}
                            size="large"
                            sx={(theme) => ({ backgroundColor: theme.palette.primary[300] })}
                        >
                            <Search sx={(theme) => ({ color: theme.palette.primary[400] })} />
                        </IconButton>
                    </Tooltip>
                )}
                {showSearchInput && (
                    <SearchBox
                        value={inputValue}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInputValue(e.target.value as string)}
                        onClear={() => onChangeInputValue('')}
                    />
                )}
            </Box>
            {!isSuperRecordType && (
                <Box sx={{ margin: '0 0.5rem', marginLeft: 'auto' }}>
                    <Tooltip title="New Record">
                        <IconButton
                            onClick={createOrder}
                            size="large"
                            disabled={!canCreateNewRecord}
                            sx={
                                canCreateNewRecord
                                    ? (theme) => ({
                                          '&:hover': { color: theme.palette.secondary.main, backgroundColor: theme.palette.secondary.light }
                                      })
                                    : {}
                            }
                        >
                            <AddCircleIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}

            <SaveNameDialog
                open={isOpenSaveDialog}
                handleClose={() => setIsOpenSaveDialog(false)}
                handleSubmit={!editGridPreference ? handleSaveNewPreference : handleSubmitEdit}
                isEdit={editGridPreference}
                defaultValue={editGridPreference && preferenceSelected ? preferenceSelected.savedName : ''}
            />
        </Box>
    );
};
