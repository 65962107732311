import { useMutation } from '@apollo/client';
import jwt_decode from 'jwt-decode';
import { MUTATION_CREATE_USER_DATA_FOCUS_PREFERENCE, MUTATION_UPDATE_USER_DATA_FOCUS_PREFERENCE } from 'graphql/mutations/dataFocusView';
import {
    CreateUserDataFocusViewPreference,
    CreateUserDataFocusViewPreferenceVariables,
    FindUserDataFocusViewPreference,
    FindUserDataFocusViewPreferenceVariables,
    UpdateUserDataFocusViewPreference,
    UpdateUserDataFocusViewPreferenceVariables
} from '../types';
import { QUERY_FIND_USER_DATA_FOCUS_VIEW_PREFERENCE } from 'graphql/queries/dataFocusView';
import { IUserDataToken } from 'utils/types';

export const useDataFocusViewMutations = (recordTypeId: string) => {
    const tenantId = localStorage.getItem('tenant_id') || '';
    const token = localStorage.getItem('backend_jwt') || '';
    const userData: IUserDataToken = jwt_decode(token);

    const [createUserDataFocusPreference, { loading: loadingCreatePreference }] = useMutation<
        CreateUserDataFocusViewPreference,
        CreateUserDataFocusViewPreferenceVariables
    >(MUTATION_CREATE_USER_DATA_FOCUS_PREFERENCE, {
        update(cache, { data }) {
            const newDefault = data?.createUserDataFocusViewPreferences;
            const list = cache.readQuery<FindUserDataFocusViewPreference, FindUserDataFocusViewPreferenceVariables>({
                query: QUERY_FIND_USER_DATA_FOCUS_VIEW_PREFERENCE,
                variables: { data: { recordTypeId: +recordTypeId, userId: userData.userId, tenantId: +tenantId } }
            });
            if (!list || !newDefault) return;

            cache.writeQuery<FindUserDataFocusViewPreference, FindUserDataFocusViewPreferenceVariables>({
                query: QUERY_FIND_USER_DATA_FOCUS_VIEW_PREFERENCE,
                data: { findUserDataFocusViewPreferencesMany: [newDefault] }
            });
        }
    });

    const [updateUserDataFocusPreference, { loading: loadingUpdatePreference }] = useMutation<
        UpdateUserDataFocusViewPreference,
        UpdateUserDataFocusViewPreferenceVariables
    >(MUTATION_UPDATE_USER_DATA_FOCUS_PREFERENCE, {
        update(cache, { data }) {
            const newDefault = data?.updateUserDataFocusViewPreferences;
            const list = cache.readQuery<FindUserDataFocusViewPreference, FindUserDataFocusViewPreferenceVariables>({
                query: QUERY_FIND_USER_DATA_FOCUS_VIEW_PREFERENCE,
                variables: { data: { recordTypeId: +recordTypeId, userId: userData.userId, tenantId: +tenantId } }
            });
            if (!list || !newDefault) return;

            cache.writeQuery<FindUserDataFocusViewPreference, FindUserDataFocusViewPreferenceVariables>({
                query: QUERY_FIND_USER_DATA_FOCUS_VIEW_PREFERENCE,
                data: { findUserDataFocusViewPreferencesMany: [newDefault] }
            });
        }
    });

    return {
        loadingPreference: loadingCreatePreference || loadingUpdatePreference,
        createUserDataFocusPreference,
        updateUserDataFocusPreference
    };
};
