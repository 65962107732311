/* eslint-disable react/destructuring-assignment */
import { NonPdfDialog } from './NonPdfDialog';
import { PdfViewerDialog } from './PdfViewerDialog';
import { AttachmentRow } from '../types';

export type FileViewerProps = {
    open: boolean;
    fileRow: AttachmentRow | null;
    handleClose: () => void;
    onDownload: (fileRow: AttachmentRow | null) => void;
    onDelete?: () => void;
    signedUrl?: string;
    openRightPanel?: boolean;
    onChangeDescription?: (description: string) => void;
    isAllowedEditable?: boolean;
    doNotShowDescription?: boolean;
};

export const FileViewer = (props: FileViewerProps) =>
    props.fileRow?.fileType === 'application/pdf' ? <PdfViewerDialog {...props} /> : <NonPdfDialog {...props} />;
