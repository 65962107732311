import { IconButton, Popover, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { Hierarchy } from 'ui-component/icons';
import { IRecordHeaders } from 'ui-component/records/types';
import { HierarchyDialog } from './HierarchyDialog';
import { HierarchyQuickView } from './components';

export type HierarchyGridPopoverProps = {
    record: IRecordHeaders;
    hasChildrenOrParent: boolean;
};

export const HierarchyGridPopover = ({ record, hasChildrenOrParent }: HierarchyGridPopoverProps) => {
    const { palette } = useTheme();
    const iconButtonRef = useRef<HTMLButtonElement>(null);

    const [isOpenPopover, setIsOpenPopover] = useState(false);
    const [isOpenFullHierarchy, setIsOpenFullHierarchy] = useState(false);

    const handleViewFullHierarchy = () => {
        setIsOpenFullHierarchy(true);
    };

    return (
        <>
            <IconButton ref={iconButtonRef} disabled={!hasChildrenOrParent} onClick={() => setIsOpenPopover(true)}>
                <Hierarchy sx={{ fontSize: '20px' }} />
            </IconButton>
            <Popover
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: '405px',
                        minHeight: '108px',
                        borderRadius: '8px',
                        border: 'solid 1px',
                        borderColor: palette.primary[500]
                    }
                }}
                open={isOpenPopover}
                anchorEl={iconButtonRef.current}
                onClose={() => setIsOpenPopover(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <HierarchyQuickView record={record} onOpenFullHierarchy={handleViewFullHierarchy} />
            </Popover>
            {isOpenFullHierarchy && (
                <HierarchyDialog
                    open={isOpenFullHierarchy}
                    onClose={() => setIsOpenFullHierarchy(false)}
                    recordId={record.id}
                    initialRecord={record}
                />
            )}
        </>
    );
};
