import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_GET_RECORD_FIELDS } from 'graphql/queries/bills';
import { IGetRecordFields, IRecordFieldWithFieldName } from 'ui-component/records/types';

export const useRecordHeaders = (recordTypeId: number) => {
    const { data: headerData, loading: loadingHeaderData } = useQuery<IGetRecordFields>(QUERY_GET_RECORD_FIELDS, {
        variables: { recordType: Number(recordTypeId) },
        fetchPolicy: 'no-cache'
    });

    const loading = loadingHeaderData;

    const headers = useMemo(() => headerData?.getSaasRecordFieldsByRecordTypes, [headerData?.getSaasRecordFieldsByRecordTypes]);

    const headersWithFieldName: Record<string, IRecordFieldWithFieldName> | undefined = useMemo(
        () =>
            headers ? Object.fromEntries(Object.entries(headers).map(([key, value]) => [key, { ...value, fieldName: key }])) : undefined,
        [headers]
    );

    return { loading, headers, headersWithFieldName };
};
