import { useLazyQuery } from '@apollo/client';
import { QUERY_FIND_ROLE_ACCESS } from 'graphql/queries/bills';
import { useEffect, useMemo } from 'react';
import { FindRoleAccessVariables, IFindRoleAccess } from 'ui-component/records/types';

export const useAccessAttachments = (recordTypeId: number | null, attachmentFieldIds: number | number[], roleId: number) => {
    const [getFieldAccess, { data: fieldAccessData }] = useLazyQuery<IFindRoleAccess, FindRoleAccessVariables>(QUERY_FIND_ROLE_ACCESS, {
        variables: {
            data: {
                recordTypes: [Number(recordTypeId)],
                roles: [roleId as number],
                recordFields: Array.isArray(attachmentFieldIds) ? attachmentFieldIds : [attachmentFieldIds]
            }
        },
        fetchPolicy: 'cache-first'
    });

    const fieldAccess =
        useMemo(
            () => fieldAccessData?.findRoleAccessToRecordFields?.map((field) => ({ ...field, fieldId: +field.recordField.id })),
            [fieldAccessData]
        ) ?? null;

    useEffect(() => {
        if (recordTypeId && attachmentFieldIds && roleId) {
            getFieldAccess();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordTypeId, attachmentFieldIds, roleId]);

    const fieldToUpload = useMemo(
        () => fieldAccess && fieldAccess?.find((field) => field.access?.toLocaleLowerCase() === 'editable'),
        [fieldAccess]
    );

    return { fieldToUpload, fieldAccess };
};
