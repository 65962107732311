/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { Box, CircularProgress, Grid, IconButton, InputAdornment, OutlinedInput, TextField, Tooltip, Typography } from '@mui/material';
import { Clear, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Match, RenderSearchProps, SearchPlugin } from '@react-pdf-viewer/search';
import { IconSearch } from '@tabler/icons';

enum SearchStatus {
    NotSearchedYet,
    Searching,
    FoundResults
}

const useStyles = makeStyles((theme) => ({
    jumpControls: {
        '&:hover': {
            backgroundColor: 'transparent !important',
            borderRadius: '0 !important'
        },
        '&:disabled > svg': {
            color: 'rgba(0, 0, 0, .5)'
        }
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    inactiveMatch: {
        cursor: 'pointer',
        border: '1px solid rgba(0, 0, 0, .2)',
        borderRadius: '5px',
        padding: '1rem',
        overflowWrap: 'break-word'
    },
    activeMatch: {
        cursor: 'pointer',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '5px',
        backgroundColor: '#FFF',
        padding: '1rem',
        overflowWrap: 'break-word',
        color: '#000'
    }
}));

interface SearchSidebarProps {
    searchPluginInstance: SearchPlugin;
    setShowSearchSidebard: (value: boolean) => void;
    handleClearHighlights: () => void;
}

const SearchSidebar = ({ searchPluginInstance, setShowSearchSidebard, handleClearHighlights }: SearchSidebarProps) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState(1 as number | null);
    const [searchStatus, setSearchStatus] = React.useState(SearchStatus.NotSearchedYet);
    const [matches, setMatches] = React.useState<Match[]>([]);
    const { Search } = searchPluginInstance;

    const renderMatchSample = (match: Match) => {
        const wordsBefore = match.pageText.substring(0, match.startIndex);
        const words = wordsBefore.split(' ');
        const previousFive = `...${words.splice(-5).toString().replaceAll(',', ' ')} `;

        return (
            <Box>
                {previousFive}
                <span style={{ backgroundColor: '#4066bf', fontWeight: 'bold', color: '#000' }}>
                    {match.pageText.substring(match.startIndex, match.endIndex)}
                </span>
                ...
            </Box>
        );
    };

    return (
        <Search>
            {(renderSearchProps: RenderSearchProps) => {
                const { currentMatch, keyword, setKeyword, jumpToMatch, jumpToNextMatch, jumpToPreviousMatch, search } = renderSearchProps;

                const handleSearchKeyDown = (e: React.KeyboardEvent) => {
                    if (e.key === 'Enter' && keyword) {
                        setSearchStatus(SearchStatus.Searching);
                        search().then((wordMatches) => {
                            setSearchStatus(SearchStatus.FoundResults);
                            setMatches(wordMatches);
                        });
                    }
                };
                return (
                    <Box className={classes.mainContainer}>
                        <Grid container justifyContent="flex-start" alignItems="center" sx={{ marginBottom: '1.5rem' }}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <OutlinedInput
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                    onKeyDown={handleSearchKeyDown}
                                    style={{ marginRight: 5 }}
                                    id="input-search-list-style1"
                                    placeholder="Search in document"
                                    fullWidth
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <IconSearch stroke={1.5} size="16px" />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment
                                            position="end"
                                            onClick={() => {
                                                setShowSearchSidebard(false);
                                                handleClearHighlights();
                                            }}
                                        >
                                            <Tooltip title="Close Searchbar">
                                                <IconButton>
                                                    <Clear />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                    size="small"
                                />
                            </Grid>
                        </Grid>
                        {searchStatus === SearchStatus.NotSearchedYet && (
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <Grid item xs={12}>
                                    <Typography style={{ fontWeight: 400, fontSize: '15px' }} color="secondary">
                                        Please introduce the search terms and press enter
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        {searchStatus === SearchStatus.FoundResults && !matches.length && (
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <Grid item xs={12}>
                                    <Typography style={{ fontWeight: 400, fontSize: '15px' }} color="secondary">
                                        <b>No Result Found.</b> Please introduce another search terms and press enter
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        {searchStatus === SearchStatus.Searching && (
                            <Grid container justifyContent="center" alignItems="center" display="flex">
                                <Grid item xs={12} justifyContent="center" alignItems="center" display="flex">
                                    <CircularProgress color="secondary" />
                                </Grid>
                            </Grid>
                        )}
                        {searchStatus === SearchStatus.FoundResults && matches.length > 0 && (
                            <>
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    sx={{
                                        borderBottom: '1px solid rgba(0, 0, 0, .2)',
                                        boxShadow: '0px 5px 2px 0px rgba(0,0,0,0.1)',
                                        paddingBottom: '1rem'
                                    }}
                                >
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: '15px' }} color="secondary">
                                            Found Results {matches.length}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <IconButton
                                            className={classes.jumpControls}
                                            size="small"
                                            disabled={currentMatch === 1}
                                            onClick={() => {
                                                jumpToPreviousMatch();
                                                setInputValue(Number(inputValue) - 1);
                                            }}
                                        >
                                            <KeyboardArrowUp
                                                sx={(theme) => ({
                                                    fontSize: '1rem',
                                                    fontWeight: '500',
                                                    color: theme.palette.secondary.main,
                                                    marginLeft: '4px'
                                                })}
                                            />
                                        </IconButton>
                                        <TextField
                                            value={inputValue}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                if (event.target.value) {
                                                    if (Number(event.target.value) === 0) {
                                                        jumpToMatch(1);
                                                        setInputValue(1);
                                                    } else if (Number(event.target.value) > matches.length) {
                                                        jumpToMatch(Number(matches.length));
                                                        setInputValue(Number(matches.length));
                                                    } else {
                                                        jumpToMatch(Number(event.target.value));
                                                        setInputValue(Number(event.target.value));
                                                    }
                                                } else {
                                                    setInputValue(null);
                                                }
                                            }}
                                            type="number"
                                            size="small"
                                            sx={{ maxWidth: '63px' }}
                                            InputProps={{ inputProps: { min: 1, max: matches.length } }}
                                        />
                                        <span style={{ marginLeft: '.5rem' }}>of {matches.length}</span>
                                        <IconButton
                                            className={classes.jumpControls}
                                            size="small"
                                            disabled={currentMatch === matches.length}
                                            onClick={() => {
                                                jumpToNextMatch();
                                                setInputValue(Number(inputValue) + 1);
                                            }}
                                        >
                                            <KeyboardArrowDown
                                                sx={(theme) => ({
                                                    fontSize: '1rem',
                                                    fontWeight: '500',
                                                    color: theme.palette.secondary.main,
                                                    marginLeft: '4px'
                                                })}
                                            />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Box
                                    style={{
                                        flex: 1,
                                        overflow: 'auto',
                                        padding: '.5rem 1rem',
                                        backgroundColor: '#F5F5F5'
                                    }}
                                >
                                    {matches.map((match, index) => (
                                        <Box key={index} style={{ margin: '1rem 0' }}>
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    marginBottom: '.5rem'
                                                }}
                                            >
                                                <Typography>#{index + 1} Result</Typography>
                                                <Typography
                                                    style={{
                                                        color: 'rgba(0, 0, 0, .5)',
                                                        fontSize: '.8rem',
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    Page {match.pageIndex + 1}
                                                </Typography>
                                            </Box>
                                            <Box
                                                className={currentMatch === index + 1 ? classes.activeMatch : classes.inactiveMatch}
                                                onClick={() => {
                                                    setInputValue(index + 1);
                                                    jumpToMatch(index + 1);
                                                }}
                                            >
                                                {renderMatchSample(match)}
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </>
                        )}
                    </Box>
                );
            }}
        </Search>
    );
};
export default SearchSidebar;
