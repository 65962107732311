import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_LIST_VALUES } from 'graphql/queries/customLists';
import { FindListValues, FindListValuesVariables } from 'views/backoffice/CustomLists/types';
import { orderListValuesById } from 'views/backoffice/CustomLists/utils';
import { getListValueIdsFromData } from 'ui-component/records/utils/headerHelpers';
import { IRecordFieldsList, IRecordHeaders } from 'ui-component/records/types';

export const useSelectedCustomListValues = (headers?: IRecordFieldsList, list?: IRecordHeaders[]) => {
    const [isLoading, setIsLoading] = useState(true);
    const [findListValue, { loading: loadingListValueData, data: listValueData }] = useLazyQuery<FindListValues, FindListValuesVariables>(
        QUERY_GET_LIST_VALUES,
        {
            fetchPolicy: 'no-cache',
            onCompleted() {
                setIsLoading(false);
            }
        }
    );

    const listValuesByListId = useMemo(
        () => (listValueData?.findListValues ? orderListValuesById(listValueData?.findListValues) : []),
        [listValueData?.findListValues]
    );

    const rowsValuesIds = useMemo(() => getListValueIdsFromData(headers, list), [headers, list]);

    useEffect(() => {
        if (headers && list && rowsValuesIds)
            findListValue({
                variables: { data: { ids: rowsValuesIds } }
            });
        else setIsLoading(false);
    }, [findListValue, headers, list, rowsValuesIds]);

    return { loading: isLoading || loadingListValueData, listValuesByListId };
};
