import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { IUserDataToken } from 'utils/types';
import { S3Uploader } from 'utils/S3Uploader';
import { IAditionalFields } from 'ui-component/records/types';
import { List, NoFilesIcon, UploadBanner } from './components';
import { AttachmentRow } from './types';
import { IFormatDataValues } from '../types';
import { useAccessAttachments } from './hooks/useAccessAttachments';
import { isJsonString } from 'utils/stringHelpers';
import { useDocumentViewer } from 'views/DocumentViewer/hooks/useDocumentViewer';

export type AttachmentsProps = {
    fullHeight?: boolean;
    attachmentFieldIds?: number[] | undefined;
    attachmentsData?: IAditionalFields[] | undefined;
    onUpdateAttachment: (payload: IFormatDataValues) => Promise<boolean>;
    recordTypeId?: string;
    roleId?: number | null;
};

export const Attachments = ({
    fullHeight,
    attachmentFieldIds,
    attachmentsData,
    onUpdateAttachment,
    recordTypeId,
    roleId
}: AttachmentsProps) => {
    const token = localStorage.getItem('backend_jwt') ?? '';
    const userData: IUserDataToken = jwt_decode(token);
    const dispatch = useDispatch();
    const [rows, setRows] = useState<AttachmentRow[]>([]);
    const { handleSetAttachmentUpdated } = useDocumentViewer();
    const { fieldToUpload, fieldAccess } = useAccessAttachments(Number(recordTypeId), attachmentFieldIds || [], Number(roleId));

    const handleSelectFile = async (file: File) => {
        try {
            const key = await S3Uploader(file);

            const newRow = {
                id: uuidv4(),
                name: file.name,
                description: '',
                fileType: file.type || `file/${file.name.split('.')?.slice(-1)?.[0] || ''}`,
                fileSize: (file.size / 1024).toFixed(2),
                createdAt: new Date().toISOString(),
                createdBy: userData.userId,
                fileUrl: key
            };

            const newValue = [...rows.filter(({ fieldId }) => fieldId === fieldToUpload?.fieldId), newRow];
            const attachmentRow = attachmentsData?.find(({ fieldId }) => fieldId === fieldToUpload?.fieldId);
            const payload = attachmentRow
                ? {
                      tag: attachmentRow.tag,
                      value: JSON.stringify(newValue)
                  }
                : {
                      tag: `;;${attachmentFieldIds?.find((fieldId) => fieldId === fieldToUpload?.fieldId)}`,
                      value: JSON.stringify(newValue),
                      objectValuesByProperty: undefined
                  };

            const res = await onUpdateAttachment({ baseData: {}, additionalFields: [payload] } as IFormatDataValues);
            if (res) {
                setRows(newValue);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'File Uploaded',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
                handleSetAttachmentUpdated();
            } else throw new Error('Save attachment failed');
        } catch (err) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Save attachment failed',
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
        }
    };

    const handleUpdateRow = async (newRow: AttachmentRow) => {
        if (!attachmentsData) return;
        const newValue = rows.filter(({ fieldId }) => fieldId === newRow.fieldId).map((row) => (row.id === newRow.id ? newRow : row));
        const payload = { tag: attachmentsData.find(({ fieldId }) => fieldId === newRow.fieldId)?.tag, value: JSON.stringify(newValue) };

        const res = await onUpdateAttachment({ baseData: {}, additionalFields: [payload] } as IFormatDataValues);

        if (res) {
            setRows(newValue);

            dispatch(
                openSnackbar({
                    open: true,
                    message: 'File Updated',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            handleSetAttachmentUpdated();
        } else
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Update file failed',
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
    };

    const handleDeleteRow = async (row: AttachmentRow) => {
        if (!attachmentsData) return;
        const newValue = rows.filter(({ fieldId }) => fieldId === row.fieldId).filter((r) => r.id !== row.id);
        const payload = { tag: attachmentsData.find(({ fieldId }) => fieldId === row.fieldId)?.tag, value: JSON.stringify(newValue) };

        const res = await onUpdateAttachment({ baseData: {}, additionalFields: [payload] } as IFormatDataValues);

        if (res) {
            setRows(newValue);

            dispatch(
                openSnackbar({
                    open: true,
                    message: 'File Deleted',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            handleSetAttachmentUpdated();
        } else
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Delete file failed',
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
    };

    useEffect(() => {
        if (attachmentsData && fieldAccess)
            setRows(
                attachmentsData
                    .filter(
                        (data) =>
                            fieldAccess?.find((val) => val.fieldId === data.fieldId)?.access.toLocaleLowerCase() !== 'notallowed' &&
                            isJsonString(data.value)
                    )
                    .map((data) => {
                        const field = fieldAccess?.find((val) => val.fieldId === data.fieldId);
                        return JSON.parse(data.value).map((val: any) => ({
                            ...val,
                            access: field?.access.toLocaleLowerCase(),
                            recordFieldName: field?.recordFieldName,
                            fieldId: data.fieldId
                        }));
                    })
                    .flat()
            );
    }, [attachmentsData, fieldAccess]);

    return (
        <section id="record-attachment-section" style={{ height: 'calc(100% - 60px)' }}>
            {fieldToUpload && <UploadBanner onFileChange={handleSelectFile} />}
            <Grid container justifyContent="center" alignItems="center" sx={{ height: 'calc(100% - 94px)' }}>
                {!rows.length && (
                    <Grid item xs="auto" sx={{ textAlign: 'center' }}>
                        {fullHeight && <NoFilesIcon />}
                        <Typography variant="h5" color="#54595E" fontSize="20px" fontWeight={400}>
                            <FormattedMessage id="attachment.noFiles" />
                        </Typography>
                    </Grid>
                )}
                {!!rows.length && (
                    <Grid item xs={12} height="100%">
                        <List rows={rows} onDeleteRow={handleDeleteRow} onUpdateRow={handleUpdateRow} />
                    </Grid>
                )}
            </Grid>
        </section>
    );
};
