/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@mui/styles';
import { Add, ArrowDropDown, Bolt, CheckBoxOutlineBlankOutlined, CheckBoxOutlined, Clear, Event } from '@mui/icons-material';
import {
    Checkbox,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    useTheme,
    styled,
    TextFieldProps,
    Button,
    CircularProgress
} from '@mui/material';
import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller, UseFormSetValue } from 'react-hook-form';
import { useSelector } from 'store';
import { FormattedMessage } from 'react-intl';
import { IRecordField, IRecordFieldWithFieldName } from 'ui-component/records/types';
import { PanelFieldSection, PanelFieldSectionInline } from 'views/CreateRecord/components';
import { getDateByHour } from 'ui-component/records/utils';
import { IUser } from 'views/backoffice/users/types';
import { isJsonString } from 'utils/stringHelpers';
import { ObjectProperty } from 'views/backoffice/CustomObjects/types';
import { DROPDOWN_COLUMNS, DROPDOWN_COLUMNS_SINGLE } from 'ui-component/records/utils/columnHelpers';
import { GridSingleSelect, InlineOptionPicker, ObjectListAutocomplete } from 'ui-component/formComponents';
import { DynamicObject } from 'views/TenantProfileSettings/components/types';
import { ListValue } from 'views/backoffice/CustomLists/types';
import { generateInlineObject } from '../PropertiesPanel/utils';
import { CustomMultiSelect } from 'ui-component/formComponents/CustomMultiSelect';
import InputMask from './InputMaskPhone';
import { AttachmentRow } from '../Attachments/types';
import { IconPlus } from '@tabler/icons';
import FileCard from './FileCard';
import { QUERY_GET_MY_RECORDS_FILE_BY_ID } from 'graphql/queries/records';
import { IGetRecordFileById } from '../types';
import { useLazyQuery } from '@apollo/client';

export interface renderInputProps {
    fieldData: IRecordFieldWithFieldName;
    resetField: any;
    name: string;
    control: any;
    errors: any;
    options?: any[];
    isCustomList?: boolean;
    disabled?: boolean;
    isRequired?: boolean;
    setValue?: UseFormSetValue<any>;
    handleOpenViewObjectPanel?: (
        field: IRecordField,
        fieldName: string,
        editable: boolean,
        selectedObject?: Pick<DynamicObject, 'id' | 'objectValues' | 'objectDefinition'> | null
    ) => void;
    inMemoryObjectProperties?: { [key: string]: (ObjectProperty & { value: string })[] };
    hideTitle?: boolean;
    TextFieldComponent?: (props: TextFieldProps) => JSX.Element;
    TextFieldComponentProps?: Omit<TextFieldProps, 'ref' | 'defaultValue' | 'type'>;
    ignoreDrawer?: boolean;
    label?: string;
    backgroundAlways?: boolean;
    defaultValue?: string;
    isObjectProperty?: boolean;
    fromDataFocusView?: boolean;
    fromRecordView?: boolean;
}

const useStyles = makeStyles((theme) => ({
    textareaVResize: {
        '& textarea': { resize: 'vertical' }
    }
}));

export const BaseTextField = styled(TextField)(({ theme }) => ({
    '& fieldset': {
        border: 'none !important'
    },

    '& :focus-visible, :focus, :active, .MuiInputBase-root .Mui-focused': {
        backgroundColor: `${theme.palette.secondary.light} !important`,
        color: `${theme.palette.secondary.main} !important`,
        '&::placeholder': { color: 'transparent !important' },
        '& svg': {
            color: `${theme.palette.secondary.main} !important`,
            opacity: 0.5
        }
    }
}));

const hardCodeRecordFieldId = 'recordFileId';

export const RenderInput = ({
    fieldData,
    name,
    control,
    errors,
    options = [],
    isCustomList,
    disabled,
    isRequired,
    resetField,
    setValue,
    handleOpenViewObjectPanel,
    inMemoryObjectProperties,
    hideTitle,
    TextFieldComponent = BaseTextField as (props: TextFieldProps) => JSX.Element,
    TextFieldComponentProps = {},
    ignoreDrawer = false,
    label,
    backgroundAlways = false,
    defaultValue = '',
    isObjectProperty,
    fromDataFocusView,
    fromRecordView
}: renderInputProps) => {
    const themeSys = useTheme();
    const classes = useStyles();
    const [inputFocused, setInputFocused] = useState(false);
    const docRef = React.useRef<HTMLInputElement | null>(null);
    const [localErrorMessage, setLocalErrorMessage] = useState<string>('');
    const { drawerOpen } = useSelector((state) => state.menu);

    const [inputFile, setInputFile] = useState<File | null>(null);
    const [fileKey, setFileKey] = useState('');
    const [chargingFile, setChargingFile] = useState(false);
    const [isNewFile, setIsNewFile] = useState(false);
    const [description, setDescription] = useState('');
    const [fileRecordId, setFileRecordId] = useState(null);

    const getErrorObj = () => {
        if (!isObjectProperty) return errors[name];

        const [objectName, objectProperty] = name.split('.');
        return errors[objectName]?.[objectProperty];
    };

    const errorObj = getErrorObj();

    const [getRecordFile, { loading: docLoading, data: docData }] = useLazyQuery<IGetRecordFileById>(QUERY_GET_MY_RECORDS_FILE_BY_ID, {
        fetchPolicy: 'no-cache'
    });

    const mapObjectPropertiesToValue = (properties: (ObjectProperty & { value: string })[] | undefined) => {
        const displayValue: string[] = [];
        if (properties !== undefined) {
            properties
                .sort((a, b) => {
                    const orderA = a.order; // ignore upper and lowercase
                    const orderB = b.order; // ignore upper and lowercase
                    if (orderA < orderB) {
                        return -1;
                    }
                    if (orderA > orderB) {
                        return 1;
                    }
                    return 0;
                })
                .forEach((val) => {
                    if (val.isDisplayable) {
                        displayValue.push(val.value);
                    }
                });
            return displayValue.join(', ');
        }
        return '';
    };

    const isRequiredName =
        fieldData.required || isRequired ? (
            <>
                <FormattedMessage id={label || name} defaultMessage={label || name} />
                <span style={{ color: 'red' }}>{' *'}</span>
            </>
        ) : (
            <FormattedMessage id={label || name} defaultMessage={label || name} />
        );

    useEffect(() => {
        if (docData?.getMyRecordFileById && !docLoading) {
            setFileKey(docData.getMyRecordFileById.originalFileName);
        }
    }, [docData?.getMyRecordFileById, docLoading]);

    useEffect(() => {
        if (!fileRecordId) return;
        getRecordFile({
            variables: {
                recordFileId: Number(fileRecordId)
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileRecordId]);

    const handleFileUpload = async (e: any) => {
        setChargingFile(true);
        const file = e.target.files[0];
        setInputFile(file);
        setValue?.(name, file);
        setChargingFile(false);
        setIsNewFile(true);
        setFileKey(URL.createObjectURL(file));
    };

    const handleClearInput = (inputRef: HTMLInputElement) => {
        inputRef.value = '';
        setFileKey('');
        setInputFile(null);
        setDescription('');
        setValue?.(name, '');
        resetField?.(name, '');
    };

    if (fieldData.dataType === 'object')
        return (
            <Grid item xs={12} sx={{ py: 0 }}>
                <Grid container alignItems="center">
                    <Grid item xs={drawerOpen || ignoreDrawer ? 12 : 4}>
                        <Typography
                            style={{
                                paddingTop: 18,
                                paddingLeft: 25,
                                fontWeight: 600,
                                fontSize: '13px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                color: '#616161'
                            }}
                        >
                            {isRequiredName}
                        </Typography>
                    </Grid>
                    <Grid item xs={drawerOpen || ignoreDrawer ? 12 : 8} sx={{ textAlign: 'right', paddingRight: '10px' }}>
                        <Grid style={{ paddingTop: 18, paddingLeft: drawerOpen || ignoreDrawer ? 25 : 10 }}>
                            <Controller
                                name={name}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <>
                                        {mapObjectPropertiesToValue(inMemoryObjectProperties?.[name]) || field.value ? (
                                            <Typography
                                                className="gridObjectValue"
                                                onClick={() => {
                                                    handleOpenViewObjectPanel?.(fieldData, name, !disabled);
                                                }}
                                                sx={(theme) => ({
                                                    color: theme.palette.secondary.main,
                                                    cursor: 'pointer',
                                                    textDecoration: 'underline',
                                                    textAlign: 'start'
                                                })}
                                            >
                                                {mapObjectPropertiesToValue(inMemoryObjectProperties?.[name]) || field.value}
                                            </Typography>
                                        ) : (
                                            <Typography
                                                className="gridObjectValue"
                                                onClick={() => {
                                                    handleOpenViewObjectPanel?.(fieldData, name, !disabled);
                                                }}
                                                sx={(theme) => ({
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '6px',
                                                    color: theme.palette.primary[400],
                                                    cursor: 'pointer',
                                                    textDecoration: 'underline'
                                                })}
                                            >
                                                <Add fontSize="small" /> Add Data
                                            </Typography>
                                        )}
                                    </>
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );

    if (DROPDOWN_COLUMNS_SINGLE.includes(name as (typeof DROPDOWN_COLUMNS_SINGLE)[number])) {
        const formattedOptions = options.sort((a, b) => a.order - b.order);

        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={defaultValue ?? 0}
                        render={({ field }) => (
                            <PanelFieldSection
                                isRecordViewer
                                noPadding
                                title={isRequiredName}
                                hideTitle={hideTitle}
                                isFocused={inputFocused}
                            >
                                <GridSingleSelect
                                    width="100%"
                                    onChange={field.onChange}
                                    options={formattedOptions}
                                    value={field.value}
                                    leftIcon={<ArrowDropDown />}
                                    sx={{
                                        borderRadius: '8px',
                                        bgcolor: 'rgb(250, 250, 250)',
                                        height: 50,
                                        position: 'relative'
                                    }}
                                />
                            </PanelFieldSection>
                        )}
                    />
                ) : (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                            <PanelFieldSectionInline noPadding hideTitle={hideTitle} title={isRequiredName} isFocused={inputFocused}>
                                <GridSingleSelect
                                    width="100%"
                                    onChange={field.onChange}
                                    options={formattedOptions}
                                    value={field.value}
                                    leftIcon={<ArrowDropDown />}
                                    sx={{
                                        borderRadius: '8px',
                                        bgcolor: 'rgb(250, 250, 250)',
                                        height: 50,
                                        position: 'relative'
                                    }}
                                />
                            </PanelFieldSectionInline>
                        )}
                    />
                )}
            </>
        );
    }

    if (fieldData.dataType.toLowerCase() === 'dropdown' || DROPDOWN_COLUMNS.includes(name as (typeof DROPDOWN_COLUMNS)[number])) {
        const isListOfObjects = fieldData.listType?.dataType === 'Object';
        const formattedOptions = isListOfObjects
            ? options
                  .map((el) => ({ ...el, label: generateInlineObject(el.dynamicObjectValue.objectValues) }))
                  .sort((a, b) => a.order - b.order)
            : options.sort((a, b) => a.order - b.order);

        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={defaultValue ?? 0}
                        render={({ field }) =>
                            isListOfObjects ? (
                                <PanelFieldSection
                                    isRecordViewer
                                    noPadding
                                    title={isRequiredName}
                                    hideTitle={hideTitle || fromRecordView}
                                    isFocused={inputFocused}
                                >
                                    <ObjectListAutocomplete
                                        onClickSelectedOption={() => {
                                            const selectedObjectListValue: ListValue = formattedOptions.find(
                                                (el) => +el.id === +field.value
                                            );
                                            handleOpenViewObjectPanel?.(fieldData, name, false, selectedObjectListValue.dynamicObjectValue);
                                        }}
                                        value={field.value}
                                        onChange={field.onChange}
                                        options={formattedOptions}
                                        width="100%"
                                        label={fromDataFocusView ? undefined : isRequiredName}
                                        variant={fromDataFocusView ? 'focusview' : 'outlined'}
                                        noMargin={!fromRecordView}
                                        containerSx={{ margin: fromRecordView ? '18px auto 0' : undefined }}
                                    />
                                </PanelFieldSection>
                            ) : (
                                <PanelFieldSection
                                    isRecordViewer
                                    noPadding
                                    title={isRequiredName}
                                    hideTitle={hideTitle}
                                    isFocused={inputFocused}
                                >
                                    <TextFieldComponent
                                        variant="outlined"
                                        select
                                        error={!!errorObj}
                                        helperText={errorObj?.message}
                                        fullWidth
                                        {...field}
                                        disabled={disabled}
                                        {...TextFieldComponentProps}
                                        {...(field.value &&
                                            field.value !== '0' &&
                                            !disabled && {
                                                InputProps: {
                                                    ...TextFieldComponentProps.InputProps,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="remove selected option"
                                                                data-test-id="remove-selected-option"
                                                                data-test-input={`test-id-${field.name}`}
                                                                onClick={() => {
                                                                    resetField(field.name, { defaultValue: '0' });
                                                                }}
                                                                edge="end"
                                                            >
                                                                <Clear />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }
                                                // SelectProps: { IconComponent: () => null }
                                            })}
                                        onFocus={() => {
                                            setInputFocused(true);
                                        }}
                                        onBlur={() => {
                                            setInputFocused(false);
                                        }}
                                        sx={{
                                            '& .MuiSelect-select': {
                                                backgroundColor: inputFocused ? themeSys.palette.secondary.light : 'transparent'
                                            },
                                            '& .MuiInputAdornment-root': { position: 'absolute', right: '40px', marginRight: '0' },
                                            '& .MuiInputBase-root': { paddingRight: '0' }
                                        }}
                                    >
                                        {options
                                            .filter((el) => !el.enabled && +el.id === +field.value)
                                            .map((option: { id: string; name?: string; userValue?: IUser; value?: string }) => (
                                                <MenuItem disabled key={option.id} value={isCustomList ? option.id : Number(option.id)}>
                                                    (Disabled){' '}
                                                    <em>{isCustomList ? option.userValue?.name || option.value : option.name}</em>
                                                </MenuItem>
                                            ))}
                                        {options
                                            .filter((el) => el.enabled)
                                            .map((option: { id: string; name?: string; userValue?: IUser; value?: string }) => (
                                                <MenuItem key={option.id} value={isCustomList ? option.id : Number(option.id)}>
                                                    <em>{isCustomList ? option.userValue?.name || option.value : option.name}</em>
                                                </MenuItem>
                                            ))}
                                    </TextFieldComponent>
                                </PanelFieldSection>
                            )
                        }
                    />
                ) : (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={0}
                        render={({ field }) =>
                            isListOfObjects ? (
                                <PanelFieldSectionInline
                                    noPadding
                                    hideTitle={hideTitle || fromRecordView}
                                    title={isRequiredName}
                                    isFocused={inputFocused}
                                >
                                    <ObjectListAutocomplete
                                        onClickSelectedOption={() => {
                                            const selectedObject = formattedOptions.find((el) => +el.id === +field.value);
                                            handleOpenViewObjectPanel?.(fieldData, name, false, selectedObject.dynamicObjectValue);
                                        }}
                                        value={field.value}
                                        onChange={field.onChange}
                                        options={formattedOptions}
                                        width="100%"
                                        label={fromDataFocusView ? undefined : isRequiredName}
                                        variant={fromDataFocusView ? 'focusview' : 'outlined'}
                                        noMargin={!fromRecordView}
                                        containerSx={{ margin: fromRecordView ? '18px auto 0' : undefined }}
                                    />
                                </PanelFieldSectionInline>
                            ) : (
                                <PanelFieldSectionInline noPadding hideTitle={hideTitle} title={isRequiredName} isFocused={inputFocused}>
                                    <TextFieldComponent
                                        id={`test-selector-${field.name}`}
                                        variant="outlined"
                                        select
                                        error={!!errorObj}
                                        helperText={errorObj?.message}
                                        fullWidth
                                        {...field}
                                        disabled={disabled}
                                        {...TextFieldComponentProps}
                                        {...(field.value &&
                                            field.value !== '0' &&
                                            !disabled && {
                                                InputProps: {
                                                    ...TextFieldComponentProps.InputProps,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="remove selected option"
                                                                data-test-id="remove-selected-option"
                                                                data-test-input={`test-id-${field.name}`}
                                                                onClick={() => {
                                                                    resetField(field.name, { defaultValue: '0' });
                                                                }}
                                                                edge="end"
                                                            >
                                                                <Clear />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                },
                                                SelectProps: { IconComponent: () => null }
                                            })}
                                        onFocus={() => {
                                            setInputFocused(true);
                                        }}
                                        onBlur={() => {
                                            setInputFocused(false);
                                        }}
                                        sx={{
                                            '& .MuiSelect-select': {
                                                backgroundColor: inputFocused ? themeSys.palette.secondary.light : 'transparent'
                                            }
                                        }}
                                    >
                                        {options
                                            .filter((el) => !el.enabled && +el.id === +field.value)
                                            .map((option: { id: string; name?: string; userValue?: IUser; value?: string }) => (
                                                <MenuItem disabled key={option.id} value={isCustomList ? option.id : Number(option.id)}>
                                                    (Disabled){' '}
                                                    <em>{isCustomList ? option.userValue?.name || option.value : option.name}</em>
                                                </MenuItem>
                                            ))}
                                        {options
                                            .filter((el) => el.enabled)
                                            .map((option: { id: string; name?: string; userValue?: IUser; value?: string }) => (
                                                <MenuItem key={option.id} value={isCustomList ? option.id : Number(option.id)}>
                                                    <em>{isCustomList ? option.userValue?.name || option.value : option.name}</em>
                                                </MenuItem>
                                            ))}
                                    </TextFieldComponent>
                                </PanelFieldSectionInline>
                            )
                        }
                    />
                )}
            </>
        );
    }

    if (fieldData.dataType === 'string' || fieldData.dataType === 'url' || fieldData.dataType === 'textarea')
        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection isRecordViewer noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextFieldComponent
                                    multiline={fieldData.dataType === 'textarea'}
                                    className={fieldData.dataType === 'textarea' ? classes.textareaVResize : undefined}
                                    variant="outlined"
                                    error={!!errorObj}
                                    helperText={errorObj?.message}
                                    fullWidth
                                    {...field}
                                    onFocus={() => {
                                        setInputFocused(true);
                                    }}
                                    onBlur={() => {
                                        setInputFocused(false);
                                    }}
                                    disabled={disabled}
                                    {...TextFieldComponentProps}
                                />
                            )}
                        />
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline noPadding title={isRequiredName} isFocused={inputFocused}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    sx={{
                                        '& input, textarea': {
                                            background: `${themeSys.palette.primary[300]} !important`
                                        },
                                        '& fieldset': {
                                            border: 'none !important'
                                        },
                                        '& :focus-visible, :focus, :active, .MuiInputBase-root .Mui-focused': {
                                            backgroundColor: `${themeSys.palette.secondary.light} !important`,
                                            color: `${themeSys.palette.secondary.main} !important`,
                                            '&::placeholder': { color: 'transparent !important' },
                                            '& svg': {
                                                color: `${themeSys.palette.secondary.main} !important`,
                                                opacity: 0.5
                                            }
                                        }
                                    }}
                                    multiline={fieldData.dataType === 'textarea'}
                                    className={fieldData.dataType === 'textarea' ? classes.textareaVResize : undefined}
                                    variant="outlined"
                                    error={!!errorObj}
                                    helperText={errorObj?.message}
                                    fullWidth
                                    {...field}
                                    onFocus={() => {
                                        setInputFocused(true);
                                    }}
                                    onBlur={() => {
                                        setInputFocused(false);
                                    }}
                                    disabled={disabled}
                                    {...TextFieldComponentProps}
                                />
                            )}
                        />
                    </PanelFieldSectionInline>
                )}
            </>
        );

    if (fieldData.dataType.toLowerCase() === 'email') {
        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection isRecordViewer noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextFieldComponent
                                    variant="outlined"
                                    error={!!errorObj}
                                    fullWidth
                                    {...field}
                                    onFocus={() => {
                                        setInputFocused(true);
                                    }}
                                    onBlur={() => {
                                        setInputFocused(false);
                                    }}
                                    disabled={disabled}
                                    {...TextFieldComponentProps}
                                />
                            )}
                        />
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline noPadding title={isRequiredName} isFocused={inputFocused}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    sx={{
                                        '& input': {
                                            background: `${themeSys.palette.primary[300]} !important`
                                        },
                                        '& fieldset': {
                                            border: 'none !important'
                                        },
                                        '& :focus-visible, :focus, :active, .MuiInputBase-root .Mui-focused': {
                                            backgroundColor: `${themeSys.palette.secondary.light} !important`,
                                            color: `${themeSys.palette.secondary.main} !important`,
                                            '&::placeholder': { color: 'transparent !important' },
                                            '& svg': {
                                                color: `${themeSys.palette.secondary.main} !important`,
                                                opacity: 0.5
                                            }
                                        }
                                    }}
                                    variant="outlined"
                                    error={!!errorObj}
                                    fullWidth
                                    {...field}
                                    onFocus={() => {
                                        setInputFocused(true);
                                    }}
                                    onBlur={() => {
                                        setInputFocused(false);
                                    }}
                                    disabled={disabled}
                                    {...TextFieldComponentProps}
                                />
                            )}
                        />
                    </PanelFieldSectionInline>
                )}
            </>
        );
    }

    if (fieldData.dataType.toLowerCase() === 'phone') {
        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection isRecordViewer noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <InputMask
                                    sx={{
                                        '& input': {
                                            background: `${themeSys.palette.primary[300]} !important`,
                                            padding: '0.5rem 0.2rem',
                                            borderRadius: '8px'
                                        },
                                        '& fieldset': {
                                            border: 'none !important'
                                        }
                                    }}
                                    {...field}
                                    variant="outlined"
                                    fullWidth
                                    error={!!errorObj}
                                    onFocus={() => {
                                        setInputFocused(true);
                                    }}
                                    onBlur={() => {
                                        setInputFocused(false);
                                    }}
                                    disabled={disabled}
                                    {...TextFieldComponentProps}
                                />
                            )}
                        />
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline noPadding title={isRequiredName} isFocused={inputFocused}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <InputMask
                                    sx={{
                                        '& input': {
                                            background: `${themeSys.palette.primary[300]} !important`
                                        },
                                        '& fieldset': {
                                            border: 'none !important'
                                        }
                                    }}
                                    {...field}
                                    variant="outlined"
                                    fullWidth
                                    error={!!errorObj}
                                    onFocus={() => {
                                        setInputFocused(true);
                                    }}
                                    onBlur={() => {
                                        setInputFocused(false);
                                    }}
                                    disabled={disabled}
                                    {...TextFieldComponentProps}
                                />
                            )}
                        />
                    </PanelFieldSectionInline>
                )}
            </>
        );
    }

    if (fieldData.dataType.toLowerCase() === 'single-select') {
        const formattedOptions = options
            .sort((a, b) => a.order - b.order)
            .map((option) => ({
                label: option.userValue ? option.userValue.name : option.value,
                value: option.id
            }));

        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection isRecordViewer noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={defaultValue ?? 0}
                            render={({ field }) => (
                                <InlineOptionPicker
                                    value={field.value}
                                    onChange={field.onChange}
                                    options={formattedOptions}
                                    disabled={disabled}
                                    fullWidth
                                    containerSx={{
                                        width: '68.4vw',
                                        overflow: 'scroll'
                                    }}
                                />
                            )}
                        />
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline noPadding title={isRequiredName} isFocused={inputFocused}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={0}
                            render={({ field }) => (
                                <InlineOptionPicker
                                    value={field.value}
                                    onChange={field.onChange}
                                    options={formattedOptions}
                                    fullWidth
                                    containerSx={{
                                        width: '68.4vw',
                                        borderRadius: '8px'
                                    }}
                                />
                            )}
                        />
                    </PanelFieldSectionInline>
                )}
            </>
        );
    }

    if (fieldData.dataType === 'currency' || (fieldData.dataType === 'number' && fieldData.fieldName !== hardCodeRecordFieldId))
        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection isRecordViewer noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <NumberFormat
                                    customInput={TextFieldComponent}
                                    fixedDecimalScale={fieldData.dataType === 'currency'}
                                    thousandSeparator={fieldData.dataType === 'currency' ? ',' : ''}
                                    decimalScale={fieldData.dataType === 'currency' ? 2 : 0}
                                    variant="outlined"
                                    error={!!errorObj}
                                    helperText={errorObj?.message}
                                    fullWidth
                                    {...field}
                                    disabled={disabled}
                                    {...TextFieldComponentProps}
                                    onFocus={() => {
                                        setInputFocused(true);
                                    }}
                                    onBlur={() => {
                                        setInputFocused(false);
                                    }}
                                />
                            )}
                        />
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline noPadding title={isRequiredName} isFocused={inputFocused}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <NumberFormat
                                    customInput={TextFieldComponent}
                                    fixedDecimalScale={fieldData.dataType === 'currency'}
                                    thousandSeparator={fieldData.dataType === 'currency' ? ',' : ''}
                                    decimalScale={fieldData.dataType === 'currency' ? 2 : 0}
                                    variant="outlined"
                                    error={!!errorObj}
                                    helperText={errorObj?.message}
                                    fullWidth
                                    {...field}
                                    disabled={disabled}
                                    {...TextFieldComponentProps}
                                    onFocus={() => {
                                        setInputFocused(true);
                                    }}
                                    onBlur={() => {
                                        setInputFocused(false);
                                    }}
                                />
                            )}
                        />
                    </PanelFieldSectionInline>
                )}
            </>
        );

    if (fieldData.dataType.toLowerCase() === 'multiselect') {
        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection
                        isRecordViewer
                        noPadding
                        title={isRequiredName}
                        isFocused={inputFocused}
                        hideTitle={hideTitle || fromRecordView}
                    >
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={0}
                            render={({ field }) => (
                                <>
                                    <CustomMultiSelect
                                        disabled={disabled}
                                        value={options
                                            .filter((option: any) =>
                                                String(field.value) !== '0' &&
                                                isJsonString(field.value) &&
                                                Array.isArray(JSON.parse(field.value))
                                                    ? JSON.parse(field.value || '[]').includes(+option.id)
                                                    : false
                                            )
                                            .map((option: any) => +option.id)}
                                        onChange={(value: number[]) => {
                                            setValue?.(name, value.length === 0 ? '0' : JSON.stringify(value), { shouldValidate: true });
                                        }}
                                        options={options
                                            .filter((option: any) => {
                                                let currentValue = [];
                                                if (
                                                    String(field.value) !== '0' &&
                                                    isJsonString(field.value) &&
                                                    Array.isArray(JSON.parse(field.value))
                                                ) {
                                                    currentValue = JSON.parse(field.value || '[]');
                                                }
                                                return option.enabled || currentValue.includes(+option.id);
                                            })
                                            .sort((a, b) => a.order - b.order)
                                            .map((option: any) => ({
                                                id: +option.id,
                                                label: option.userValue ? option.userValue.name : option.value,
                                                enabled: option.enabled
                                            }))}
                                        containerSx={{
                                            margin: fromRecordView ? '18px auto 0' : undefined,
                                            width: fromRecordView ? '91%' : '100%'
                                        }}
                                        width={fromRecordView ? '91%' : '100%'}
                                        label={fromDataFocusView ? undefined : isRequiredName}
                                        variant="outlined"
                                        sx={{
                                            justifyContent: 'space-between'
                                        }}
                                    />
                                    {errorObj && (
                                        <FormHelperText error sx={{ paddingLeft: '1rem' }}>
                                            {errorObj?.message}
                                        </FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline
                        noPadding
                        title={isRequiredName}
                        isFocused={inputFocused}
                        hideTitle={hideTitle || fromRecordView}
                    >
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={0}
                            render={({ field }) => (
                                <>
                                    <CustomMultiSelect
                                        disabled={disabled}
                                        value={options
                                            .filter((option: any) =>
                                                String(field.value) !== '0' &&
                                                isJsonString(field.value) &&
                                                Array.isArray(JSON.parse(field.value))
                                                    ? JSON.parse(field.value || '[]').includes(+option.id)
                                                    : false
                                            )
                                            .map((option: any) => +option.id)}
                                        onChange={(value: number[]) => {
                                            setValue?.(name, value.length === 0 ? '0' : JSON.stringify(value), { shouldValidate: true });
                                        }}
                                        options={options
                                            .sort((a, b) => a.order - b.order)
                                            .filter((option: any) => {
                                                let currentValue = [];
                                                if (
                                                    String(field.value) !== '0' &&
                                                    isJsonString(field.value) &&
                                                    Array.isArray(JSON.parse(field.value))
                                                ) {
                                                    currentValue = JSON.parse(field.value || '[]');
                                                }
                                                return option.enabled || currentValue.includes(+option.id);
                                            })
                                            .map((option: any) => ({
                                                id: +option.id,
                                                label: option.userValue ? option.userValue.name : option.value,
                                                enabled: option.enabled
                                            }))}
                                        containerSx={{
                                            margin: fromRecordView ? '18px auto 0' : undefined,
                                            width: fromRecordView ? '91%' : '100%'
                                        }}
                                        width={fromRecordView ? '91%' : '100%'}
                                        label={fromDataFocusView ? undefined : isRequiredName}
                                        variant="outlined"
                                        sx={{
                                            justifyContent: 'space-between'
                                        }}
                                    />
                                    {errorObj && (
                                        <FormHelperText error sx={{ paddingLeft: '1rem' }}>
                                            {errorObj?.message}
                                        </FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </PanelFieldSectionInline>
                )}
            </>
        );
    }

    if (fieldData.dataType === 'date')
        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection isRecordViewer noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value: datePicker, ref } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        onChange={(newValue) => {
                                            if (newValue === null) {
                                                onChange('');
                                                setLocalErrorMessage('');
                                            } else {
                                                const isValidDatetime = Date.parse(newValue);
                                                setLocalErrorMessage(isValidDatetime ? '' : 'Invalid date format');
                                                onChange(newValue);
                                            }
                                        }}
                                        inputRef={ref}
                                        value={datePicker}
                                        disabled={disabled}
                                        renderInput={(params) => (
                                            <TextFieldComponent
                                                onFocus={() => {
                                                    setInputFocused(true);
                                                }}
                                                onBlur={() => {
                                                    setInputFocused(false);
                                                }}
                                                fullWidth
                                                {...params}
                                                InputProps={{
                                                    ...TextFieldComponentProps.InputProps,
                                                    ...params.InputProps
                                                }}
                                                error={!!errorObj || !!localErrorMessage}
                                                helperText={errors.dueDate?.message || localErrorMessage}
                                                variant={TextFieldComponentProps.variant || params.variant}
                                                size={TextFieldComponentProps.size || params.size}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value: datePicker, ref } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        onChange={(newValue) => {
                                            if (newValue === null) {
                                                onChange('');
                                                setLocalErrorMessage('');
                                            } else {
                                                const isValidDatetime = Date.parse(newValue);
                                                setLocalErrorMessage(isValidDatetime ? '' : 'Invalid date format');
                                                onChange(newValue);
                                            }
                                        }}
                                        inputRef={ref}
                                        value={datePicker}
                                        disabled={disabled}
                                        renderInput={(params) => (
                                            <TextFieldComponent
                                                onFocus={() => {
                                                    setInputFocused(true);
                                                }}
                                                onBlur={() => {
                                                    setInputFocused(false);
                                                }}
                                                fullWidth
                                                {...params}
                                                InputProps={{
                                                    ...TextFieldComponentProps.InputProps,
                                                    ...params.InputProps
                                                }}
                                                error={!!errorObj || !!localErrorMessage}
                                                helperText={errors.dueDate?.message || localErrorMessage}
                                                variant={TextFieldComponentProps.variant || params.variant}
                                                size={TextFieldComponentProps.size || params.size}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </PanelFieldSectionInline>
                )}
            </>
        );

    if (fieldData.dataType === 'datetime') {
        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection isRecordViewer noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value: datePicker, ref } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ todayButtonLabel: 'NOW' }}>
                                    <DateTimePicker
                                        onChange={(newValue) => {
                                            if (newValue === null) {
                                                onChange('');
                                                setLocalErrorMessage('');
                                            } else {
                                                const isValidDatetime = Date.parse(newValue);
                                                setLocalErrorMessage(isValidDatetime ? '' : 'Invalid date format');
                                                onChange(newValue);
                                            }
                                        }}
                                        value={datePicker}
                                        inputRef={ref}
                                        disabled={disabled}
                                        components={{
                                            OpenPickerIcon: () => (
                                                <Tooltip title="Pick a date">
                                                    <Event />
                                                </Tooltip>
                                            )
                                        }}
                                        componentsProps={{
                                            actionBar: {
                                                actions: ['today']
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextFieldComponent
                                                onFocus={() => {
                                                    setInputFocused(true);
                                                }}
                                                onBlur={() => {
                                                    setInputFocused(false);
                                                }}
                                                {...params}
                                                {...TextFieldComponentProps}
                                                InputProps={{
                                                    ...TextFieldComponentProps.InputProps,
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            <Tooltip title="Now">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setLocalErrorMessage('');
                                                                        onChange(new Date());
                                                                    }}
                                                                    disabled={disabled}
                                                                    sx={{ transform: 'translateX(15px)', marginLeft: '-20px' }}
                                                                >
                                                                    <Bolt />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {params.InputProps?.endAdornment}
                                                        </>
                                                    )
                                                }}
                                                error={!!errorObj || !!localErrorMessage}
                                                helperText={errors.dueDate?.message || localErrorMessage}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value: datePicker, ref } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ todayButtonLabel: 'NOW' }}>
                                    <DateTimePicker
                                        onChange={(newValue) => {
                                            if (newValue === null) {
                                                onChange('');
                                                setLocalErrorMessage('');
                                            } else {
                                                const isValidDatetime = Date.parse(newValue);
                                                setLocalErrorMessage(isValidDatetime ? '' : 'Invalid date format');
                                                onChange(newValue);
                                            }
                                        }}
                                        value={datePicker}
                                        inputRef={ref}
                                        disabled={disabled}
                                        components={{
                                            OpenPickerIcon: () => (
                                                <Tooltip title="Pick a date">
                                                    <Event />
                                                </Tooltip>
                                            )
                                        }}
                                        componentsProps={{
                                            actionBar: {
                                                actions: ['today']
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextFieldComponent
                                                onFocus={() => {
                                                    setInputFocused(true);
                                                }}
                                                onBlur={() => {
                                                    setInputFocused(false);
                                                }}
                                                {...params}
                                                {...TextFieldComponentProps}
                                                InputProps={{
                                                    ...TextFieldComponentProps.InputProps,
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            <Tooltip title="Now">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setLocalErrorMessage('');
                                                                        onChange(new Date());
                                                                    }}
                                                                    disabled={disabled}
                                                                    sx={{ transform: 'translateX(15px)', marginLeft: '-20px' }}
                                                                >
                                                                    <Bolt />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {params.InputProps?.endAdornment}
                                                        </>
                                                    )
                                                }}
                                                error={!!errorObj || !!localErrorMessage}
                                                helperText={errors.dueDate?.message || localErrorMessage}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </PanelFieldSectionInline>
                )}
            </>
        );
    }

    if (fieldData.dataType === 'time') {
        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection isRecordViewer noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value: datePicker, ref } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        onChange={(newValue) => {
                                            const isValidDatetime = Date.parse(newValue);
                                            if (isValidDatetime) {
                                                onChange(newValue);
                                            } else {
                                                onChange('');
                                            }
                                        }}
                                        value={!Date.parse(datePicker) ? getDateByHour(datePicker) : datePicker}
                                        inputRef={ref}
                                        disabled={disabled}
                                        renderInput={(params) => (
                                            <TextFieldComponent
                                                onFocus={() => {
                                                    setInputFocused(true);
                                                }}
                                                onBlur={() => {
                                                    setInputFocused(false);
                                                }}
                                                fullWidth
                                                {...params}
                                                error={!!errorObj}
                                                helperText={errors.dueDate?.message}
                                                {...TextFieldComponentProps}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline noPadding title={isRequiredName} isFocused={inputFocused}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value: datePicker, ref } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        onChange={(newValue) => {
                                            const isValidDatetime = Date.parse(newValue);
                                            if (isValidDatetime) {
                                                onChange(newValue);
                                            } else {
                                                onChange('');
                                            }
                                        }}
                                        value={!Date.parse(datePicker) ? getDateByHour(datePicker) : datePicker}
                                        inputRef={ref}
                                        disabled={disabled}
                                        renderInput={(params) => (
                                            <TextFieldComponent
                                                onFocus={() => {
                                                    setInputFocused(true);
                                                }}
                                                onBlur={() => {
                                                    setInputFocused(false);
                                                }}
                                                fullWidth
                                                {...params}
                                                error={!!errorObj}
                                                helperText={errors.dueDate?.message}
                                                {...TextFieldComponentProps}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </PanelFieldSectionInline>
                )}
            </>
        );
    }

    if (fieldData.dataType === 'checkbox') {
        return (
            <Grid
                item
                container
                justifyContent={backgroundAlways ? 'center' : 'flex-start'}
                alignItems={backgroundAlways ? 'center' : 'flex-start'}
                xs={12}
                sx={{
                    padding: 0,
                    pt: backgroundAlways ? '0' : '10px',
                    pl: backgroundAlways ? '0' : '25px',
                    borderRadius: '8px',
                    height: '38px',
                    bgcolor: (theme) => (backgroundAlways ? theme.palette.primary[300] : 'inherit')
                }}
            >
                <Controller
                    name={name}
                    control={control}
                    defaultValue=""
                    render={({ field }) =>
                        hideTitle ? (
                            <Checkbox
                                defaultChecked
                                checked={!!field.value && field.value === 'true'}
                                disabled={disabled}
                                onChange={(event) => {
                                    setValue?.(name, String(event.target.checked), { shouldValidate: true });
                                }}
                                sx={(theme) => ({
                                    padding: 0,
                                    color: theme.palette.primary[400],
                                    '&.Mui-checked': { color: theme.palette.primary.dark },
                                    '& .MuiSvgIcon-root': { fontSize: 30 }
                                })}
                                icon={<CheckBoxOutlineBlankOutlined />}
                                checkedIcon={<CheckBoxOutlined />}
                            />
                        ) : (
                            <>
                                <FormGroup sx={{ width: '100%' }}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={drawerOpen && ignoreDrawer ? 12 : 6}>
                                            <Typography style={{ fontWeight: 600, fontSize: '13px' }}>{name}</Typography>
                                        </Grid>
                                        <Grid xs={drawerOpen && ignoreDrawer ? 12 : 6} sx={{ display: 'flex' }}>
                                            <Grid xs={4} sx={{ textAlign: 'left' }}>
                                                <Checkbox
                                                    defaultChecked
                                                    checked={!!field.value && field.value === 'true'}
                                                    disabled={disabled}
                                                    onChange={(event) => {
                                                        setValue?.(name, String(event.target.checked), { shouldValidate: true });
                                                    }}
                                                    sx={(theme) => ({
                                                        padding: 0,
                                                        color: theme.palette.primary[400],
                                                        '&.Mui-checked': { color: theme.palette.primary.dark },
                                                        '& .MuiSvgIcon-root': { fontSize: 30 }
                                                    })}
                                                    icon={<CheckBoxOutlineBlankOutlined />}
                                                    checkedIcon={<CheckBoxOutlined />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                                {errorObj && (
                                    <FormHelperText error sx={{ paddingLeft: '1rem' }}>
                                        {errorObj?.message}
                                    </FormHelperText>
                                )}
                            </>
                        )
                    }
                />
            </Grid>
        );
    }

    if (fieldData.dataType === 'multiselect-checkbox') {
        return (
            <Grid item xs={12} sx={{ py: '10px', paddingLeft: '25px' }}>
                {!hideTitle && <Typography style={{ paddingTop: 18, fontWeight: 600, fontSize: '13px' }}>{isRequiredName}</Typography>}
                <Controller
                    name={name}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <FormGroup>
                            {options
                                .filter((el) => el.enabled)
                                .map((option) => (
                                    <>
                                        <FormGroup style={{ padding: '5px 10px' }}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={6}>
                                                    <Typography style={{ fontWeight: 400, fontSize: '13px' }}>
                                                        {option.userValue?.name || option.value}
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={6} sx={{ display: 'flex' }}>
                                                    <Grid xs={4} sx={{ textAlign: 'left' }}>
                                                        <Checkbox
                                                            defaultChecked
                                                            checked={
                                                                !!field.value &&
                                                                isJsonString(field.value) &&
                                                                Array.isArray(JSON.parse(field.value)) &&
                                                                JSON.parse(field.value).some(
                                                                    (x: string | number) => String(x) === String(option.id)
                                                                )
                                                            }
                                                            disabled={disabled}
                                                            onChange={(event) => {
                                                                let currentValue = isJsonString(field.value)
                                                                    ? JSON.parse(field.value || '[]')
                                                                    : [];
                                                                if (!Array.isArray(currentValue)) {
                                                                    currentValue = [];
                                                                }
                                                                if (event.target.checked) {
                                                                    currentValue.push(+option.id);
                                                                } else {
                                                                    currentValue.splice(currentValue.indexOf(+option.id), 1);
                                                                }
                                                                setValue?.(name, currentValue.length ? JSON.stringify(currentValue) : '0', {
                                                                    shouldValidate: true
                                                                });
                                                            }}
                                                            sx={(theme) => ({
                                                                padding: 0,
                                                                color: theme.palette.primary[400],
                                                                '&.Mui-checked': { color: theme.palette.primary.dark },
                                                                '& .MuiSvgIcon-root': { fontSize: 30 }
                                                            })}
                                                            icon={<CheckBoxOutlineBlankOutlined />}
                                                            checkedIcon={<CheckBoxOutlined />}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </FormGroup>
                                        {errorObj && (
                                            <FormHelperText error sx={{ paddingLeft: '1rem' }}>
                                                {errorObj?.message}
                                            </FormHelperText>
                                        )}
                                    </>
                                ))}
                        </FormGroup>
                    )}
                />
            </Grid>
        );
    }

    if (fieldData.dataType === 'attachment' || fieldData.fieldName === hardCodeRecordFieldId) {
        const initValue = (() => {
            try {
                const parsedValue = defaultValue ? JSON.parse(defaultValue) : '';
                return parsedValue && typeof parsedValue === 'object' && 'value' in parsedValue ? parsedValue.value : defaultValue;
            } catch (e) {
                return defaultValue;
            }
        })();

        if (
            !localStorage.getItem('defaultValueAttachment') ||
            (localStorage.getItem('defaultValueAttachment') &&
                localStorage.getItem('defaultValueAttachment') !== initValue &&
                fieldData.dataType === 'attachment')
        ) {
            localStorage.setItem('defaultValueAttachment', initValue);
        }

        let defaultValueForAttachment: string | null = '';
        if (fieldData.dataType === 'attachment' && !!localStorage.getItem('defaultValueAttachment')) {
            defaultValueForAttachment = localStorage.getItem('defaultValueAttachment')
                ? localStorage.getItem('defaultValueAttachment')
                : '';
        }

        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection isRecordViewer noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => {
                                if (!isNewFile && fieldData.dataType === 'attachment') {
                                    try {
                                        const attachment = JSON.parse(field.value) as AttachmentRow[];
                                        if (attachment[0]?.name) {
                                            setFileKey(attachment[0]?.name);
                                            setValue?.(name, attachment);
                                            setDescription?.(attachment[0]?.description || '');
                                        }
                                    } catch (e) {
                                        console.error('Error parsing attachment JSON:', e);
                                    }
                                }

                                if (fieldData.fieldName === hardCodeRecordFieldId && !!field.value) {
                                    setFileRecordId(field.value);
                                }

                                return (
                                    <>
                                        <input type="file" ref={docRef} onChange={(e) => handleFileUpload(e)} style={{ display: 'none' }} />
                                        <Button
                                            onClick={() => (docRef.current ? docRef.current.click() : null)}
                                            fullWidth
                                            sx={{ height: '40px', borderRadius: '8px' }}
                                            variant="outlined"
                                            color="secondary"
                                            disabled={disabled || !!inputFile || chargingFile || !!fileKey}
                                        >
                                            <IconPlus width={18} height={22} />
                                            <Typography fontWeight={500} fontSize="14px" marginRight={2}>
                                                Add Attachments
                                            </Typography>
                                            {chargingFile && <CircularProgress size={20} color="inherit" />}
                                        </Button>
                                    </>
                                );
                            }}
                        />

                        {(fileKey || inputFile) && (
                            <FileCard
                                fileKeyS3={fileKey}
                                file={inputFile}
                                onRemove={() => handleClearInput(docRef.current!)}
                                description={
                                    fieldData.fieldName === hardCodeRecordFieldId
                                        ? ''
                                        : description || defaultValueForAttachment || fieldData.description || ''
                                }
                                isNewFile={isNewFile}
                                onChangeDescription={(newDescription: string) => {
                                    setValue?.(`tmp_${name}_description`, newDescription);
                                    setDescription?.(newDescription);
                                }}
                                doNotShowDescription={fieldData.fieldName === hardCodeRecordFieldId}
                            />
                        )}
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline noPadding title={isRequiredName} isFocused={inputFocused}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => {
                                if (!isNewFile && fieldData.dataType === 'attachment') {
                                    try {
                                        const attachment = JSON.parse(field.value) as AttachmentRow[];
                                        if (attachment[0]?.name) {
                                            setFileKey(attachment[0]?.name);
                                            setValue?.(name, attachment);
                                            setDescription?.(attachment[0]?.description || '');
                                        }
                                    } catch (e) {
                                        console.error('Error parsing attachment JSON:', e);
                                    }
                                }

                                if (fieldData.fieldName === hardCodeRecordFieldId && !!field.value) {
                                    setFileRecordId(field.value);
                                }

                                return (
                                    <>
                                        <input type="file" ref={docRef} onChange={(e) => handleFileUpload(e)} style={{ display: 'none' }} />
                                        <Button
                                            onClick={() => (docRef.current ? docRef.current.click() : null)}
                                            fullWidth
                                            sx={{ height: '40px', borderRadius: '8px' }}
                                            variant="outlined"
                                            color="secondary"
                                            disabled={disabled || !!inputFile || chargingFile || !!fileKey}
                                        >
                                            <Typography fontWeight={500} fontSize="14px" marginRight={2}>
                                                Add Attachments
                                            </Typography>
                                            {chargingFile && <CircularProgress size={20} color="inherit" />}
                                        </Button>
                                    </>
                                );
                            }}
                        />

                        {(fileKey || inputFile) && (
                            <FileCard
                                fileKeyS3={fileKey}
                                file={inputFile}
                                onRemove={() => handleClearInput(docRef.current!)}
                                description={
                                    fieldData.fieldName === hardCodeRecordFieldId
                                        ? ''
                                        : description || defaultValueForAttachment || fieldData.description || ''
                                }
                                isNewFile={isNewFile}
                                onChangeDescription={(newDescription: string) => {
                                    setValue?.(`tmp_${name}_description`, newDescription);
                                    setDescription?.(newDescription);
                                }}
                                doNotShowDescription={fieldData.fieldName === hardCodeRecordFieldId}
                            />
                        )}
                    </PanelFieldSectionInline>
                )}
            </>
        );
    }

    return <></>;
};

export default RenderInput;
