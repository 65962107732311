import React, { useState } from 'react';
import { Box, Typography, IconButton, Popover, Button, Divider, Tooltip, CircularProgress, Alert } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import RemoveCircleOutlineOutlined from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { InsertDriveFileOutlined } from '@mui/icons-material';
import { AttachmentRow } from 'ui-component/RecordView/Attachments/types';
import { v4 as uuidv4 } from 'uuid';
import useUser from 'hooks/useUser';
import { S3UrlGetter, S3Uploader } from 'utils/S3Uploader';
import { IFormatDataValues } from 'ui-component/RecordView/types';
import { useUpdateRecord } from 'views/DataFocusView/hooks/useUpdateRecord';
import { FileViewer } from 'ui-component/RecordView/Attachments/components';
import { useConfirmationModalContext } from 'hooks/useConfirmationModal';
import useMyRole from 'hooks/useMyRole';
import { useAccessAttachments } from 'ui-component/RecordView/Attachments/hooks/useAccessAttachments';

type FileListDisplayProps = {
    files: AttachmentRow[];
    recordId: string;
    recordTypeId: string;
    additionalFieldId: number;
};

const FileListDisplay = ({ files = [], recordId, recordTypeId, additionalFieldId }: FileListDisplayProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [internalFiles, setInternalFiles] = useState<AttachmentRow[]>(files);
    const [updating, setUpdating] = useState(false);
    const open = Boolean(anchorEl);
    const [isOpenPreview, setIsOpenPreview] = useState(false);
    const [selectedRow, setSelectedRow] = useState<AttachmentRow | null>(null);
    const [fileSignedUrl, setFileSignedUrl] = useState('');
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [currentPosition, setCurrentPosition] = useState<number | null>(null);
    const [currentDescription, setCurrentDescription] = useState<string | null>('');
    const roleId = useMyRole();

    const dispatch = useDispatch();
    const { userFromToken, currentUser } = useUser();
    const { updateRecord, updatingRecord } = useUpdateRecord(recordId, recordTypeId);
    const modal = useConfirmationModalContext();
    const { fieldToUpload: isAllowedEditable } = useAccessAttachments(Number(recordTypeId), additionalFieldId, Number(roleId));
    const { getRootProps, getInputProps } = useDropzone({
        multiple: true,
        accept: {
            'image/*': [],
            'application/pdf': []
        },
        onDrop: async (acceptedFiles) => {
            const oversizedFiles = acceptedFiles.filter((file) => file.size > 100 * 1024 * 1024); // 100 MB
            if (oversizedFiles.length > 0) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Warning',
                        subtitle: 'File size exceeds the limit of 100 MB.Please upload a smaller file.',
                        variant: 'alert',
                        alert: {
                            color: 'warning'
                        },
                        close: false
                    })
                );
            } else {
                setUpdating(true);
                const newFilesUploaded = await Promise.all(
                    acceptedFiles.map(async (file) => {
                        const id = uuidv4();
                        const key = await S3Uploader(file);

                        return {
                            id,
                            name: file.name,
                            description: '',
                            fileType: file.type || `file/${file.name.split('.')?.slice(-1)?.[0] || ''}`,
                            fileSize: (file.size / (1024 * 1024)).toFixed(2), // Convert to MB
                            createdAt: new Date().toISOString(),
                            createdBy: userFromToken.userId,
                            createdByName: currentUser?.name,
                            fileUrl: key,
                            additionalFieldId
                        } as AttachmentRow;
                    })
                );

                const newFiles = [...internalFiles, ...newFilesUploaded];

                const payload = {
                    tag: `;;${additionalFieldId}`,
                    value: JSON.stringify(newFiles),
                    objectValuesByProperty: undefined
                };

                const res = await onSubmitFile({ baseData: {}, additionalFields: [payload] } as IFormatDataValues);
                if (res.data?.updateRecordHeader) {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Success!',
                            subtitle: 'Files uploaded',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    setInternalFiles(newFiles);
                } else {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Error!',
                            subtitle: 'Files uploaded failed',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                }

                setUpdating(false);
            }
        },
        maxSize: 100 * 1024 * 1024 // 100 MB
    });

    const handleAttachmentClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickSubmitToDeleteFile = async (id: string, fromPreview = false) => {
        await modal.showConfirmation({
            title: 'Do you want to delete this file?',
            content: (
                <Alert
                    variant="filled"
                    icon={false}
                    sx={{
                        bgcolor: '#FB9000',
                        color: 'white',
                        borderRadius: '8px !important',
                        height: '32px',
                        fontSize: '14px',
                        width: 'fit-content',
                        padding: '0 16px !important',
                        fontWeight: 500
                    }}
                >
                    This is an irreversible action and will permanently remove the file.
                </Alert>
            ),
            forwardButtonText: 'Yes',
            actionButtonText: 'No',
            actionType: 'submit',
            showCheckbox: false,
            onAction: async () => {},
            onForward: () => handleDeleteFile(id, fromPreview),
            recordType: 'purchase_order',
            sectionTitle: 'portal_request'
        });
    };

    const handleDeleteFile = async (id: string, fromPreview: boolean = false) => {
        const noRemovedFiles = internalFiles.filter((file) => file.id !== id);
        setInternalFiles(noRemovedFiles);

        const payload = {
            tag: `;;${additionalFieldId}`,
            value: JSON.stringify(noRemovedFiles),
            objectValuesByProperty: undefined
        };

        onSubmitFile({ baseData: {}, additionalFields: [payload] } as IFormatDataValues);

        if (fromPreview) {
            handleClose();
            setIsOpenPreview(false);
            setSelectedRow(null);
            setFileSignedUrl('');
            setCurrentPosition(null);
        }
    };

    const handleClosePreview = () => {
        if (currentPosition !== null && currentDescription) {
            const updatedFilesDescription = internalFiles.map((file, index) => {
                if (index === currentPosition) {
                    return { ...file, description: currentDescription };
                }
                return file;
            });
            setInternalFiles(updatedFilesDescription);

            const payload = {
                tag: `;;${additionalFieldId}`,
                value: JSON.stringify(updatedFilesDescription),
                objectValuesByProperty: undefined
            };

            onSubmitFile({ baseData: {}, additionalFields: [payload] } as IFormatDataValues);
        }

        setIsOpenPreview(false);
        setSelectedRow(null);
        setCurrentDescription(null);
        setFileSignedUrl('');
        setCurrentPosition(null);
    };

    const handleOnChangeDescription = (description: string) => {
        setCurrentDescription(description);
    };

    const handleOnOpenPreview = async (attachment: AttachmentRow, position: number) => {
        const { name, description, createdByName, id } = attachment;
        setIsFileLoading(true);
        setCurrentPosition(position);
        const url = await S3UrlGetter(name);
        const response = await fetch(url);
        const blob = await response.blob();
        const fileFromUrl = new File([blob], name, { type: blob.type });

        if (!fileFromUrl) return;

        const fileType = `application/${fileFromUrl.name.split('.')?.slice(-1)?.[0] || ''}`;

        setSelectedRow({
            id,
            name: fileFromUrl.name,
            description: description || '',
            fileType,
            fileSize: (fileFromUrl.size / (1024 * 1024)).toFixed(2), // Convert to MB
            createdAt: new Date().toISOString(),
            createdBy: userFromToken?.userId,
            fileUrl: name,
            createdByName: createdByName || ''
        });

        setFileSignedUrl(url);

        setIsOpenPreview(true);
        setIsFileLoading(false);
    };

    const handleOnDownload = async (row: AttachmentRow | null) => {
        if (!row || !row.fileUrl) return;
        const urlToDownload = await S3UrlGetter(row.fileUrl);
        const link = document.createElement('a');

        link.href = urlToDownload;
        link.download = row?.name;
        link.click();
    };

    const onSubmitFile = async (data: IFormatDataValues) => {
        let newFileId;
        const { additionalFields, baseData } = data;
        const formattedData = {
            ...baseData,
            additionalFields,
            id: Number(recordId)
        };

        return updateRecord({
            variables: { data: { ...formattedData, recordFileId: Number(newFileId) || undefined } }
        });
    };

    const valueFiles = React.useMemo(() => internalFiles.map((internalFile) => internalFile.name).join(', '), [internalFiles]);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                }}
            >
                <Box display="flex" alignItems="center">
                    {internalFiles.length > 0 && (
                        <Typography variant="body1" color="text.secondary" fontWeight="medium">
                            {internalFiles.length}
                        </Typography>
                    )}
                    <Typography
                        variant="body1"
                        sx={{
                            ml: 1,
                            width: 180,
                            color: 'text.primary',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {valueFiles}
                    </Typography>
                </Box>

                <IconButton size="small" edge="end" sx={{ color: 'text.secondary' }} onClick={handleAttachmentClick}>
                    <AttachFileIcon sx={{ transform: 'rotate(45deg)' }} />
                </IconButton>

                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    PaperProps={{
                        sx: {
                            borderRadius: 2,
                            boxShadow: 3,
                            width: 497
                        }
                    }}
                >
                    {!!isAllowedEditable && (
                        <Box
                            {...getRootProps()}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <input {...getInputProps()} />
                            <Button
                                variant="outlined"
                                startIcon={<FileUploadIcon />}
                                sx={{
                                    borderRadius: 8,
                                    color: 'primary.main',
                                    border: '1px solid #37326E',
                                    m: 0.5,
                                    width: 'calc(100% - 8px)'
                                }}
                            >
                                Upload File
                            </Button>
                        </Box>
                    )}

                    <Box sx={internalFiles.length ? { height: 300, overflowY: 'scroll' } : {}}>
                        {(updatingRecord || updating) && (
                            <Box
                                sx={{
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <CircularProgress color="inherit" size={25} />
                            </Box>
                        )}
                        {internalFiles.map((file, index) => (
                            <Box sx={{ border: '1px dashed #aaa', m: 1, borderRadius: 8 }} key={file.id}>
                                <Box sx={{ margin: '0.2', padding: 0, borderRadius: 8, bgcolor: 'background.paper', px: 1 }}>
                                    {index > 0 && <Divider sx={{ borderStyle: 'dashed' }} />}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {isFileLoading && currentPosition === index ? (
                                                <CircularProgress color="inherit" size={14} sx={{ color: 'primary.dark', mr: 1 }} />
                                            ) : (
                                                <InsertDriveFileOutlined sx={{ color: 'primary.dark', mr: 1 }} />
                                            )}
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'start'
                                                }}
                                            >
                                                <Tooltip title={file.name} arrow>
                                                    <Typography
                                                        variant="body1"
                                                        fontWeight={500}
                                                        color="primary.dark"
                                                        fontSize={14}
                                                        width={file.description ? '100px' : '300px'}
                                                        sx={{
                                                            flex: 1,
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            cursor: 'pointer',
                                                            mr: file.description ? 0.1 : 0
                                                        }}
                                                        onClick={() => handleOnOpenPreview(file, index)}
                                                    >
                                                        {file.name}
                                                    </Typography>
                                                </Tooltip>
                                                {file.description && (
                                                    <Tooltip title={file.description}>
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            fontSize={12}
                                                            width="200px"
                                                            sx={{
                                                                flex: 1,
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'
                                                            }}
                                                        >
                                                            {file.description}
                                                        </Typography>
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
                                                {file.fileSize} MB
                                            </Typography>
                                            {isAllowedEditable && (
                                                <IconButton
                                                    size="small"
                                                    sx={{ color: 'error.light' }}
                                                    onClick={() => handleClickSubmitToDeleteFile(file.id)}
                                                >
                                                    <RemoveCircleOutlineOutlined sx={{ color: '#EF5350' }} />
                                                </IconButton>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Popover>
            </Box>
            {isOpenPreview && selectedRow && fileSignedUrl && (
                <FileViewer
                    open={isOpenPreview}
                    fileRow={selectedRow}
                    signedUrl={fileSignedUrl}
                    handleClose={handleClosePreview}
                    onDownload={handleOnDownload}
                    openRightPanel
                    onChangeDescription={handleOnChangeDescription}
                    onDelete={() => handleClickSubmitToDeleteFile(selectedRow.id, true)}
                    isAllowedEditable={!!isAllowedEditable}
                />
            )}
        </>
    );
};

export default FileListDisplay;
