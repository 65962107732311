import { useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
    FindPortalFormSection,
    FindPortalFormSectionVariables,
    FindPortalFromSection,
    FindPortalFromSectionVariables,
    PortalFullFormSection
} from '../types';
import { QUERY_FIND_PORTAL_FORM_SECTIONS, QUERY_FIND_PORTAL_FROM_SECTIONS } from 'graphql/queries/dynamicPortals';

export const usePortalFormData = (portalUrl: string) => {
    const [portalSections, setPortalSections] = useState<PortalFullFormSection[]>([]);

    const { loading: loadingSections, data: sectionsData } = useQuery<FindPortalFormSection, FindPortalFormSectionVariables>(
        QUERY_FIND_PORTAL_FORM_SECTIONS,
        {
            variables: { data: { portalUrl } },
            onCompleted(data) {
                if (data.findPortalFormSectionsMany.length) {
                    const sectionIds = data.findPortalFormSectionsMany.reduce<number[]>(
                        (acc, el) => [...acc, ...el.portalByFormByPortalFormSection.map(({ id }) => +id)],
                        []
                    );

                    getPortalFromSections({ variables: { data: { ids: sectionIds } } });
                }
            }
        }
    );

    const [getPortalFromSections, { loading: loadingPortalFromSections }] = useLazyQuery<
        FindPortalFromSection,
        FindPortalFromSectionVariables
    >(QUERY_FIND_PORTAL_FROM_SECTIONS, {
        onCompleted(data) {
            if (data.findPortalFormSectionFormPortalsMany.length && sectionsData?.findPortalFormSectionsMany) {
                const sections = sectionsData.findPortalFormSectionsMany.map((el) => ({
                    ...el,
                    portalByFormByPortalFormSection: []
                })) as PortalFullFormSection[];

                for (const portalFromSection of data.findPortalFormSectionFormPortalsMany) {
                    const sectionIdx = sections.findIndex(({ id }) => id === portalFromSection.portalFormSection.id);

                    if (sectionIdx !== -1) sections[sectionIdx].portalByFormByPortalFormSection.push(portalFromSection);
                }

                const orderedSectionAndForms = sections
                    .filter((el) => el.portalByFormByPortalFormSection.length)
                    .map((section) => ({
                        ...section,
                        portalByFormByPortalFormSection: section.portalByFormByPortalFormSection.sort((a, b) =>
                            a.form.name.trim().toLowerCase().localeCompare(b.form.name.trim().toLowerCase())
                        )
                    }));

                setPortalSections(
                    [...orderedSectionAndForms].sort((a, b) => a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase()))
                );
            }
        }
    });

    const loading = loadingSections || loadingPortalFromSections;
    return { loading, portalSections };
};
