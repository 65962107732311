import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { QUERY_FIND_ALL_DATA_FOCUS_VIEW, QUERY_FIND_ALL_USER_DATA_FOCUS_VIEW_PREFERENCE } from 'graphql/queries/dataFocusView';
import { QUERY_FIND_RECORD_TYPES } from 'graphql/queries/recordTypes';
import { FindRecordType, FindRecordTypeVariables } from 'types/recordType';
import { DataFocusView, FindAllDataFocusView, FindAllUserDataFocusViewPreference } from '../types';

export const useDataFocusViewData = (recordTypeId: string) => {
    // const tenantId = localStorage.getItem('tenant_id') || '';
    // const token = localStorage.getItem('backend_jwt') || '';
    // const userData: IUserDataToken = jwt_decode(token);

    const navigate = useNavigate();

    const { loading: loadingDataFocusView, data: dataFocusViewData } = useQuery<FindAllDataFocusView>(QUERY_FIND_ALL_DATA_FOCUS_VIEW, {
        onCompleted(data) {
            const focusviewForRecordType = data.findAllDataFocusViewsSaas.map((el) => +el.form.recordType.id === +recordTypeId);
            if (!data.findAllDataFocusViewsSaas.length || !focusviewForRecordType.length) navigate('../dashboard');
        }
    });

    const { loading: loadingFindAllUserDataFocusViewPreference, data: findAllDataFocusViewsPreferenceData } =
        useQuery<FindAllUserDataFocusViewPreference>(QUERY_FIND_ALL_USER_DATA_FOCUS_VIEW_PREFERENCE);

    // const [getUserDataFocusViewPreference, { loading: loadingUserDataFocusViewPreference, data: userDataFocusViewPreference }] =
    //     useLazyQuery<FindUserDataFocusViewPreference, FindUserDataFocusViewPreferenceVariables>(
    //         QUERY_FIND_USER_DATA_FOCUS_VIEW_PREFERENCE,
    //         {
    //             variables: { data: { recordTypeId: +recordTypeId, userId: userData.userId, tenantId: +tenantId } }
    //         }
    //     );

    // TODO: Fix the backend to
    // const [findDataFocusViews, { loading: loadingDataFocusView, data: dataFocusViewData }] = useLazyQuery<
    //     FindManyDataFocusView,
    //     FindManyDataFocusViewVariables
    // >(QUERY_FIND_MANY_DATA_FOCUS_VIEW, {
    //     variables: { data: { rolesId: +(roleId || 0), tenantId: +(tenantId || '') } },
    //     onCompleted(data) {
    //         if (!data.findDataFocusViewsMany.length) navigate('../dashboard');
    //     }
    // });

    const [findRecordTypes, { loading: loadingRecordType, data: recordTypeData }] = useLazyQuery<FindRecordType, FindRecordTypeVariables>(
        QUERY_FIND_RECORD_TYPES,
        {
            variables: { data: { id: +recordTypeId, enabled: true } },
            onCompleted(data) {
                if (!data.findRecordType.length) navigate('../dashboard');
            }
        }
    );

    const dataFocusViewList = useMemo(
        () =>
            (dataFocusViewData?.findAllDataFocusViewsSaas || []).filter(
                ({ form, enabled }) => enabled && +form.recordType.id === +recordTypeId
            ),
        [dataFocusViewData?.findAllDataFocusViewsSaas, recordTypeId]
    );

    const dataFocusViewListById = useMemo(
        () => dataFocusViewList.reduce<Record<string, DataFocusView>>((acc, el) => ({ ...acc, [el.id]: el }), {}),
        [dataFocusViewList]
    );

    const defaultDataFocusViewPreference = useMemo(() => {
        const res = findAllDataFocusViewsPreferenceData?.findAllUserDataFocusViewPreferences.find(
            ({ recordType }) => +recordType.id === +recordTypeId
        );

        return res;
    }, [findAllDataFocusViewsPreferenceData?.findAllUserDataFocusViewPreferences, recordTypeId]);

    useEffect(() => {
        if (+recordTypeId) {
            findRecordTypes();
        }
    }, [findRecordTypes, recordTypeId]);

    const recordType = recordTypeData?.findRecordType[0] || undefined;

    const loading = loadingDataFocusView || loadingFindAllUserDataFocusViewPreference || loadingRecordType;

    return { loading, dataFocusViewList, dataFocusViewListById, defaultDataFocusViewPreference, recordType };
};
