import { useLazyQuery, useQuery } from '@apollo/client';
import { ExpandMore } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Grid, Radio } from '@mui/material';
import {
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridAlignment,
    GridColDef,
    GridFilterModel,
    gridFilterModelSelector,
    GridRowSelectionModel,
    GridSortModel,
    gridSortModelSelector,
    GridToolbarContainer,
    GridToolbarFilterButton,
    useGridApiContext,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import { PREFERENCES_BACKOFFICE, QUERY_FIND_RECORD_HEADERS_WITH_FILTERING, QUERY_GET_RECORD_FIELDS } from 'graphql/queries/bills';
import { useCustomListValues } from 'hooks/useCustomListValues';
import _, { capitalize } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { IRecordType } from 'types/recordType';
import { PaginationFooter, PaginationFooterProps } from 'ui-component/records/RecordGrid/components/PaginationFooter';
import {
    FilterRecordHeaderInput,
    FindRecordHeaderWithFilteringVariables,
    IGetRecordFields,
    IGetRecordHeadersFiltered,
    IRecordFieldsList,
    IRecordHeaders,
    ISaasMyGridPreference
} from 'ui-component/records/types';
import { generateGridState, getRenderCell, getValueFormatter, getValueGetter } from 'ui-component/records/utils';
import {
    checkFilterValue,
    generateFilterPayloads,
    getFilterOperator,
    NON_FILTERABLE_OR_SORTABLE_FIELDS,
    NON_SORTABLE_DATA_TYPES
} from 'ui-component/records/utils/filterHelpers';
import { adjustHeaders, getListIdsFromHeaders } from 'ui-component/records/utils/headerHelpers';
import { SearchBar } from 'ui-component/SearchBar';
import { StripedDataGrid } from 'views/backoffice/CustomLists/components';
import { FilterPanel, FilterPanelProps } from 'ui-component/records/RecordGrid/components';
import { RenderGridHeader } from 'ui-component/grids/components';

export type RecordGridWithSelectionProps = {
    recordType: IRecordType | null;
    onSelectRecord: (record: IRecordHeaders | null) => void;
    selectedRecord: IRecordHeaders | null;
    height: string | number;
};

export const WIDER_FIELDS = ['datetime', 'multiselect', 'multiselect-checkbox', 'dropdown'];

const useStyles = makeStyles(() => ({
    gridToolbarCustomFont: { fontSize: '0.8125rem', fontWeight: '500', color: '#858585', marginLeft: '4px' },
    gridToolbarCustomFontBlack: { fontSize: '0.8125rem', fontWeight: '500', color: '#000', marginLeft: '4px' },
    paginationItems: { display: 'flex', justifyContent: 'flex-end' },
    paginationArrow: { fontSize: '0.6875', fontWeight: '500', color: '#858585', marginLeft: '4px' },
    paginationControl: {
        '&:hover': { backgroundColor: 'transparent !important', borderRadius: '0 !important' },
        '&:disabled': { '& > svg': { color: '#C3C3C3 !important' } }
    },
    disabledRow: { opacity: 0.7 }
}));

export const RecordGridWithSelection = ({ recordType, onSelectRecord, selectedRecord, height }: RecordGridWithSelectionProps) => {
    const intl = useIntl();
    const apiRef = useGridApiRef();
    const classes = useStyles();

    const [isLoadingPage, setIsLoadingPage] = useState(false);
    const [filters, setFilters] = useState<FilterRecordHeaderInput>();
    const [newFilterModel, setNewFilterModel] = useState<GridFilterModel>();
    const [appliedFilterModel, setAppliedFilterModel] = useState<GridFilterModel>();
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageSize, setCurrentPageSize] = useState(25);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [recordList, setRecordList] = useState<IRecordHeaders[]>([]);
    const [gridColumns, setGridColumns] = useState<GridColDef[]>([]);

    const [getUserPreferences, { loading: userPreferencesLoading }] = useLazyQuery<ISaasMyGridPreference>(PREFERENCES_BACKOFFICE);
    const { loading: loadingHeaders, data: headersData } = useQuery<IGetRecordFields>(QUERY_GET_RECORD_FIELDS, {
        variables: { recordType: recordType ? +recordType.id : '' }
    });
    const [getRecords, { loading: listLoading, fetchMore }] = useLazyQuery<
        IGetRecordHeadersFiltered,
        FindRecordHeaderWithFilteringVariables
    >(QUERY_FIND_RECORD_HEADERS_WITH_FILTERING, {
        fetchPolicy: 'no-cache',
        onCompleted(data) {
            setRecordList(data.filterAndSortRecordHeader.records || []);
            if (!isSameFilterModel) setAppliedFilterModel(newFilterModel);
        }
    });

    const headerList = useMemo(() => headersData?.getSaasRecordFieldsByRecordTypes, [headersData]);
    const headerListIds = useMemo(() => getListIdsFromHeaders(headerList), [headerList]);
    const { listValuesByListId, loading: loadingListsValues } = useCustomListValues(headerListIds);
    const isSameFilterModel = useMemo(
        () => _.isEqual(_.omit(newFilterModel, ['id']), _.omit(appliedFilterModel, ['id'])),
        [newFilterModel, appliedFilterModel]
    );

    const formatHeaderName = useCallback(
        (key: string) => {
            const updatedKey = adjustHeaders(key);
            return updatedKey.includes(' ') ? updatedKey : intl.formatMessage({ id: updatedKey, defaultMessage: updatedKey });
        },
        [intl]
    );

    const generateGridColumns = useCallback(
        (orderedFields?: string[]) => {
            if (!orderedFields || !headerList || !recordType) return [];

            const fieldsColDef = orderedFields
                .filter((key) => headerList[key as keyof IRecordFieldsList])
                // eslint-disable-next-line arrow-body-style
                .map(
                    (key) =>
                        ({
                            field: key,
                            numeric: ['decimal', 'float', 'number'].includes(headerList[key as keyof IRecordFieldsList].dataType),
                            headerName: formatHeaderName(key),
                            renderHeader: (params) => <RenderGridHeader name={params.colDef.headerName || ''} />,
                            headerClassName: `${capitalize(key)} 'bills-header'`,
                            align: 'left' as GridAlignment,
                            width: WIDER_FIELDS.includes(headerList[key as keyof IRecordFieldsList].dataType) ? 250 : 150,
                            filterable: !NON_FILTERABLE_OR_SORTABLE_FIELDS.includes(key),
                            sortable:
                                !NON_FILTERABLE_OR_SORTABLE_FIELDS.includes(key) &&
                                !NON_SORTABLE_DATA_TYPES.includes(headerList[key as keyof IRecordFieldsList].dataType),
                            valueGetter: getValueGetter(key),
                            renderCell: getRenderCell(
                                key,
                                false,
                                undefined,
                                headerList[key as keyof IRecordFieldsList],
                                headerList[key as keyof IRecordFieldsList]?.listType?.id
                                    ? listValuesByListId[Number(headerList[key].listType?.id)]
                                    : undefined
                            ),
                            filterOperators: getFilterOperator(key, +recordType.id, headerList[key as keyof IRecordFieldsList]),
                            valueFormatter: getValueFormatter(
                                key,
                                headerList[key as keyof IRecordFieldsList].dataType,
                                headerList[key as keyof IRecordFieldsList],
                                headerList[key as keyof IRecordFieldsList]?.listType?.id
                                    ? listValuesByListId[Number(headerList[key].listType?.id)]
                                    : undefined
                            )
                        } as GridColDef)
                );

            return [
                {
                    ...GRID_CHECKBOX_SELECTION_COL_DEF,
                    renderHeader: () => null,
                    renderCell: (params) => {
                        const handleClick = () => {
                            apiRef.current.setRowSelectionModel([params.id]);
                        };
                        return <Radio checked={params.value} onClick={handleClick} />;
                    }
                },
                ...fieldsColDef
            ] as GridColDef[];
        },
        [apiRef, formatHeaderName, headerList, listValuesByListId, recordType]
    );

    const getRecordsList = useCallback(
        async (
            initialSortModel: GridSortModel,
            initialFilterModel: GridFilterModel,
            getDataFn: (options: any) => Promise<any> = fetchMore,
            limit: number = currentPageSize
        ) => {
            const filterModel = initialFilterModel;
            const sortModel = initialSortModel;
            // By Default sort by created date if theres no sort model
            if (sortModel.length === 0) sortModel.push({ field: 'createdAt', sort: 'desc' });

            const generatedFilters = generateFilterPayloads(filterModel, sortModel, headerList);
            setFilters(generatedFilters);
            const { data } = await getDataFn({
                variables: {
                    data: {
                        tenantId: Number(localStorage.getItem('tenant_id')),
                        enabled: true,
                        recordTypeId: recordType ? +recordType.id : 0,
                        filters: generatedFilters
                    },
                    pagination: {
                        offset: 0,
                        limit
                    }
                }
            });
            setRowCountState((prev) => data?.filterAndSortRecordHeader.total ?? prev);
            setRecordList(data?.filterAndSortRecordHeader.records || []);
        },
        [currentPageSize, fetchMore, headerList, recordType]
    );

    const handleFilterChange = async (model: GridFilterModel) => {
        setNewFilterModel(model);
    };

    const handleSortChange = async (model: GridSortModel) => {
        const filterModel = gridFilterModelSelector(apiRef);
        await getRecordsList(model, filterModel);
    };

    const handleClickApply = async () => {
        const sortModel = gridSortModelSelector(apiRef);
        const filterModel = gridFilterModelSelector(apiRef);

        setAppliedFilterModel(filterModel);

        await getRecordsList(sortModel, filterModel, getRecords);
    };

    const handleRowSelectionModelChange = (newSelection: GridRowSelectionModel) => {
        const selectedRecordId = selectedRecord && newSelection.length === 2 ? +newSelection[1] : +newSelection[0];
        const record = recordList.find((el) => +el.id === +selectedRecordId);
        if (record) {
            onSelectRecord(record);
        } else {
            onSelectRecord(null);
        }
    };

    // Pagination
    const handlePageChange = async (page: number) => {
        setIsLoadingPage(true);
        const { data } = await getRecords({
            variables: {
                data: {
                    recordTypeId: recordType ? +recordType.id : 0,
                    tenantId: Number(localStorage.getItem('tenant_id')),
                    enabled: true,
                    filters
                },
                pagination: {
                    offset: page * currentPageSize,
                    limit: currentPageSize
                }
            }
        });
        if (data) {
            setRowCountState((prev) => data.filterAndSortRecordHeader.total ?? prev);
            setRecordList(data.filterAndSortRecordHeader.records);
        }
        setIsLoadingPage(false);
        setCurrentPage(page);
    };

    const handlePageSizeChange = async (pageSize: number) => {
        setCurrentPage(0);
        setIsLoadingPage(true);
        setCurrentPageSize(pageSize);
        const { data } = await getRecords({
            variables: {
                data: {
                    recordTypeId: recordType ? +recordType.id : 0,
                    tenantId: Number(localStorage.getItem('tenant_id')),
                    enabled: true,
                    filters
                },
                pagination: {
                    offset: 0,
                    limit: pageSize
                }
            }
        });
        if (data) {
            setRowCountState((prev) => data.filterAndSortRecordHeader.total ?? prev);
            setRecordList(data.filterAndSortRecordHeader.records);
        }
        setIsLoadingPage(false);
    };

    const initData = useCallback(async () => {
        if (!recordType) return;
        try {
            const { data: preferenceData } = await getUserPreferences({ variables: { gridName: recordType.name } });

            if (!preferenceData?.SaasMyGridPreferences.length || !headerList) return;

            const defaultPreference =
                preferenceData.SaasMyGridPreferences.find((el) => el.isDefault) || preferenceData.SaasMyGridPreferences[0];

            const state = generateGridState(defaultPreference, headerList, undefined, listValuesByListId);
            const newColumns = generateGridColumns(state.columns?.orderedFields?.slice(2));
            const initialFilterModel = state.filter?.filterModel || { items: [] };
            const initialSortModel = state.sorting?.sortModel || [{ field: 'createdAt', sort: 'desc' }];
            const initialPageSize = defaultPreference.gridOptions[0].pageSize || 25;

            setAppliedFilterModel(initialFilterModel);
            setGridColumns(newColumns);

            getRecordsList(initialSortModel, initialFilterModel, getRecords, initialPageSize);

            apiRef.current?.restoreState(state);
        } catch (error) {
            console.error('error', error);
        }
    }, [apiRef, generateGridColumns, getRecords, getRecordsList, getUserPreferences, headerList, listValuesByListId, recordType]);

    useEffect(() => {
        if (recordType && !loadingListsValues && !loadingHeaders && !userPreferencesLoading) {
            initData();
        }
    }, [recordType, initData, loadingListsValues, loadingHeaders, userPreferencesLoading]);

    return (
        <Grid item xs={12} sx={{ height, overflow: height === 0 ? 'hidden' : 'auto' }}>
            <Grid item xs={12} container justifyContent="center" sx={{ height: '100%' }}>
                <Grid item container xs={12} sx={{ height: '100%' }}>
                    <StripedDataGrid
                        apiRef={apiRef}
                        loading={listLoading || isLoadingPage}
                        sx={(theme) => ({
                            '& .MuiDataGrid-pinnedColumnHeaders, & .MuiDataGrid-pinnedColumns': { boxShadow: 'none' },
                            '& .MuiDataGrid-columnHeaderCheckbox .MuiCheck-root': {
                                '& svg[data-testid="CheckBoxIcon"]': {
                                    color: `${theme.palette.primary.dark} !important`,
                                    backgroundColor: 'transparent !important'
                                }
                            },
                            '& .MuiDataGrid-columnHeaders': { borderBottom: '2.5px solid #E0E0E0' },
                            '& .MuiDataGrid-withBorderColor': {
                                borderRight: '1px solid #E0E0E0',
                                borderBottom: '1px solid #E0E0E0'
                            },
                            '& .MuiDataGrid-columnHeader': { borderRight: '1px solid transparent' },
                            '& .MuiDataGrid-cellCheckbox > svg[data-testid="CheckBoxIcon"]': {
                                color: `${theme.palette.primary.dark} !important`
                            },
                            '& .Mui-selected': {
                                backgroundColor: `#EBEEFE !important`,
                                color: `#54595E !important`,
                                '& div button, div a, div p': { color: '#54595E' },
                                '& div button': { color: '#54595E' },
                                '& svg[data-testid="CheckBoxIcon"], svg[data-testid="CheckBoxOutlineBlankIcon"]': {
                                    color: '#FFF !important',
                                    backgroundColor: 'transparent !important'
                                }
                            },
                            '& .MuiDataGrid-footerContainer': {
                                border: 'none !important',
                                '& .MuiDataGrid-selectedRowCount': { display: 'none !important' }
                            }
                        })}
                        rows={recordList}
                        columns={gridColumns}
                        rowCount={rowCountState}
                        onSortModelChange={handleSortChange}
                        onFilterModelChange={handleFilterChange}
                        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                        paginationModel={{ page: currentPage, pageSize: currentPageSize }}
                        slots={{ toolbar: CustomToolbar, pagination: PaginationFooter, filterPanel: FilterPanel }}
                        slotProps={{
                            filterPanel: {
                                isApplied: isSameFilterModel,
                                isLoading: isLoadingPage,
                                onClickApply: handleClickApply,
                                disableApply: !checkFilterValue(newFilterModel || ({} as GridFilterModel)),
                                filterModel: apiRef?.current?.state?.filter ? gridFilterModelSelector(apiRef) : undefined
                            } as FilterPanelProps,
                            pagination: {
                                classes,
                                rowCountState,
                                currentPage,
                                currentPageSize,
                                handlePageChange,
                                handlePageSizeChange
                            } as PaginationFooterProps
                        }}
                        density="compact"
                        paginationMode="server"
                        filterMode="server"
                        sortingMode="server"
                        pagination
                        showColumnVerticalBorder
                        showCellVerticalBorder
                        checkboxSelection
                        disableMultipleRowSelection
                        rowSelectionModel={selectedRecord ? [selectedRecord.id] : []}
                        onRowSelectionModelChange={handleRowSelectionModelChange}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

const CustomToolbar = () => {
    const apiRef = useGridApiContext();

    const [searchInput, setSearchInput] = useState('');

    const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newVal = e.target.value;
        setSearchInput(newVal);
        apiRef.current.setQuickFilterValues([newVal]);
    };

    return (
        <GridToolbarContainer sx={{ gap: 0.5 }}>
            <SearchBar placeholder="Find in current page" size="small" value={searchInput} onChange={handleChangeSearch} />
            <GridToolbarFilterButton
                sx={({ palette }) => ({
                    color: palette.primary[400],
                    backgroundColor: palette.primary[300],
                    borderRadius: '30px',
                    padding: '8px 16px'
                })}
                componentsProps={{ button: { endIcon: <ExpandMore /> } }}
            />
        </GridToolbarContainer>
    );
};
