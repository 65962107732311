import { IRecordHeaders } from 'ui-component/records/types';
import { SectionContainer } from '../SectionContainer';
import { HierarchyCore } from './Core';

export type HierarchySectionProps = {
    recordId: string;
    initialRecord: IRecordHeaders;
};

export const HierarchySection = ({ recordId, initialRecord }: HierarchySectionProps) => (
    <SectionContainer title="Hierarchy">
        <HierarchyCore recordId={recordId} initialRecord={initialRecord} />
    </SectionContainer>
);
