import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';

interface ConfirmationDialogProps {
    open: boolean;
    loading: boolean;
    onClose: () => void;
    onSubmit: () => void;
    loadingText: string;
    dialogTitle: JSX.Element;
    children: React.ReactChild[];
}

/**
 * @deprecated Now it should be used the ConfirmationModalContext
 */
export const ConfirmationDialog = ({ open, loading, onClose, onSubmit, dialogTitle, loadingText, children }: ConfirmationDialogProps) => (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiPaper-root': { padding: 0 } }}>
        {loading && <LinearProgress color="secondary" />}
        <DialogTitle component="div" sx={{ paddingX: 1 }}>
            <Grid container alignItems="center">
                {dialogTitle}
                <IconButton onClick={onClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Grid>
        </DialogTitle>
        <DialogContent sx={{ marginY: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 'normal', color: 'black' }} component="div">
                {children}
            </Typography>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button disabled={loading} variant="outlined" color="secondary" onClick={onClose}>
                Cancel
            </Button>
            <LoadingButton loading={loading} color="secondary" onClick={onSubmit} loadingIndicator={loadingText} variant="contained">
                Confirm
            </LoadingButton>
        </DialogActions>
    </Dialog>
);

export default ConfirmationDialog;
