import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, IconButton, PopoverOrigin, Theme, Typography } from '@mui/material';
import { SxProps, SystemStyleObject } from '@mui/system';
import React, { ChangeEvent, MouseEvent, ReactNode, useRef, useState } from 'react';
import { List } from './components';

export type GridSingleSelectProps = {
    options: GridSingleSelectOption[];
    value: number | string;
    onChange: (newId: number | string) => void;
    leftButtonLabel?: ReactNode;
    leftIcon?: ReactNode;
    listFitContent?: boolean;
    width?: string;
    error?: boolean;
    sx?: SxProps;
    disabled?: boolean;
    PopoverSx?: SxProps<Theme> | undefined;
    PopoverPropsAnchorOrigin?: PopoverOrigin | undefined;
    PopoverPropsTransformOrigin?: PopoverOrigin | undefined;
    selectedOptionSx?: SystemStyleObject;
};

export type GridSingleSelectOption = {
    id: number | string;
    name: string;
    key?: string;
    renderName?: ReactNode;
    valuesToFilter?: string[];
};

export const GridSingleSelect = ({
    options,
    value,
    onChange,
    leftButtonLabel,
    leftIcon,
    listFitContent,
    width,
    error,
    sx,
    disabled,
    PopoverSx,
    PopoverPropsAnchorOrigin,
    PopoverPropsTransformOrigin,
    selectedOptionSx = {}
}: GridSingleSelectProps) => {
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchText, setSearchText] = useState('');

    const filteredOptions = options.filter(
        (el) =>
            el.name?.toLowerCase().includes(searchText.toLowerCase()) ||
            el.valuesToFilter?.some((val) => val.toLowerCase().includes(searchText.toLowerCase()))
    );
    const open = Boolean(anchorEl);

    const selectedOption = value ? options.find((el) => el.id === value) || { name: '', id: undefined } : null;

    const handleOpenOptions = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setTimeout(() => {
            if (searchInputRef.current) searchInputRef.current.focus();
        }, 100);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const newVal = e.target.value;
        setSearchText(newVal);
    };

    const handleSelectOption = (selectedId: number | string) => () => {
        onChange(selectedId);
        handleClose();
    };

    return (
        <Box
            ref={containerRef}
            sx={{
                display: 'flex',
                alignItems: 'center',
                height: '40px',
                width: width || '200px',
                outline: error ? 'solid 1px #EF5350' : 'none',
                ...sx
            }}
        >
            {leftButtonLabel || (
                <Typography
                    fontSize="14px"
                    sx={{
                        ml: '10px',
                        maxHeight: '100%',
                        flexGrow: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        ...selectedOptionSx
                    }}
                >
                    {selectedOption?.name ?? ''}
                </Typography>
            )}
            <IconButton disableRipple disabled={disabled} onClick={handleOpenOptions} sx={{ ml: 'auto' }}>
                {leftIcon || (open ? <ExpandLess htmlColor="#54595E" /> : <ExpandMore htmlColor="#54595E" />)}
            </IconButton>
            <List
                open={open}
                anchorEl={anchorEl}
                searchInputRef={searchInputRef}
                containerRef={containerRef}
                searchText={searchText}
                options={filteredOptions}
                onClose={handleClose}
                onChangeSearch={handleChangeSearch}
                onSelectOption={handleSelectOption}
                onClearSearch={() => setSearchText('')}
                fitContent={listFitContent}
                PopoverSx={PopoverSx}
                PopoverPropsAnchorOrigin={PopoverPropsAnchorOrigin}
                PopoverPropsTransformOrigin={PopoverPropsTransformOrigin}
            />
        </Box>
    );
};
