import { GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { IRecordField, IRecordFieldsList, IRecordHeaders } from '../types';
import { format } from 'date-fns';
import { getTimeValidator } from './getDateByHour';
import { getDateWithoutTZ } from './dateHelpers';
import { ListValue } from 'views/backoffice/CustomLists/types';
import { isJsonString } from 'utils/stringHelpers';
import dayjs from 'dayjs';

export const valueFormatterList: Record<keyof IRecordFieldsList, any> = {
    enabled: ({ value }: GridValueFormatterParams) => (value ? 'Active' : 'Deleted'),
    date: (params: GridValueFormatterParams) => {
        if (!params.value || !Date.parse(params.value)) return '';
        return format(getDateWithoutTZ(params.value), 'MM/dd/yyyy');
    },
    datetime: (params: GridValueFormatterParams) =>
        params.value && Date.parse(params.value) ? format(new Date(params.value), 'MM/dd/yyyy hh:mm a') : '',
    time: (params: GridValueFormatterParams) => getTimeValidator(params.value),
    dropdowns: (listValues: ListValue[], isListObject: boolean) => (params: GridValueFormatterParams) => {
        if (!params.value) return '';
        const selectedOption = listValues?.find((item) => +item.id === +params.value);
        if (isListObject) {
            if (!selectedOption) return '';
            return (
                (
                    selectedOption.dynamicObjectValue?.objectValues
                        .filter((item: any) => item.objectProperty.isDisplayable)
                        .sort((a: any, b: any) => +(a.objectProperty.order || 0) - +(b.objectProperty.order || 0)) || []
                )
                    .map((item: any) => {
                        if (item.objectProperty.dataType === 'date') return dayjs(item.value).format('YYYY-MM-DD');
                        if (item.objectProperty.dataType === 'datetime') return dayjs(item.value).format('YYYY-MM-DD hh:mm a');
                        return item.value;
                    })
                    .join(', ') || 'No Display Value Setted'
            );
        }
        return selectedOption?.userValue?.name || selectedOption?.value || '';
    },
    multiselect: (listValues: ListValue[]) => (params: GridValueFormatterParams) => {
        const { value } = params;
        if (value && isJsonString(value) && value !== '0') {
            const selectedOptions = JSON.parse(value).map((item: any, _index: number) => {
                const selectedOption = listValues?.find((listItem) => +item === +listItem.id);
                return selectedOption?.userValue?.name || selectedOption?.value || '';
            });
            return selectedOptions.join(', ');
        }
        return '';
    },
    object: (props: GridValueFormatterParams) => {
        const { value } = props;
        const row = props.api.getRow(props.id ?? 0) as IRecordHeaders;
        if (row) {
            const { additionalFields } = row;
            const displayValue: string[] = [];
            const currentField = additionalFields.find((field: any) => field.objectValue?.id === value);
            if (!currentField) return '';
            if (currentField.objectValue) {
                const {
                    objectValue: { objectValues }
                } = currentField;
                [...objectValues]
                    ?.sort((a, b) => {
                        const orderA = a.objectProperty.order; // ignore upper and lowercase
                        const orderB = b.objectProperty.order; // ignore upper and lowercase
                        if (orderA < orderB) {
                            return -1;
                        }
                        if (orderA > orderB) {
                            return 1;
                        }
                        return 0;
                    })
                    .forEach((val) => {
                        if (val.objectProperty.isDisplayable) {
                            let { value: objValue } = val;
                            if (val.objectProperty.dataType === 'date') objValue = dayjs(objValue).format('YYYY-MM-DD');
                            if (val.objectProperty.dataType === 'datetime') objValue = dayjs(objValue).format('YYYY-MM-DD hh:mm a');
                            displayValue.push(objValue);
                        }
                    });
            }
            if (displayValue.length !== 0) return displayValue.join(', ') || 'No Display Value Setted';
        }

        return value;
    },
    checkbox: (params: GridValueFormatterParams) => (params.value && params.value === 'true' ? 'Yes' : 'No')
};

export const getValueFormatter = (
    key: string,
    dataType: IRecordField['dataType'],
    recordField?: IRecordField,
    listValues?: ListValue[]
) => {
    if (valueFormatterList[key]) return valueFormatterList[key];
    if (dataType === 'date') return valueFormatterList.date;
    if (dataType === 'datetime') return valueFormatterList.datetime;
    if (dataType === 'time') return valueFormatterList.time;
    if (dataType === 'dropdown' && recordField?.listType)
        return valueFormatterList.dropdowns(listValues, !!recordField.listType?.objectDefinition);
    if ((dataType === 'multiselect' || dataType === 'multiselect-checkbox') && recordField?.listType)
        return valueFormatterList.multiselect(listValues);
    if (dataType === 'checkbox') return valueFormatterList.checkbox;
    if (dataType === 'object') return valueFormatterList.object;

    return undefined;
};
