import AWS from 'aws-sdk';
import { AWS_API } from 'config';
import { getTemporaryAWSCredentials } from './loginHelpers';
import { IRecordFile } from 'ui-component/RecordView/types';

export type CSVOpportunity = {
    opportunityName?: string;
    description?: string;
    spaOppNumber?: string;
    account?: string;
    accountType?: string;
    spaStatus?: string;
    contactName?: {
        last?: string;
        first?: string;
    };
    contactEmail?: string;
    estimatedClose?: string;
    totalNet?: string;
    customerNumber?: string;
    productList?: string;
    createDate?: string;
    spaApprovalDate?: string;
    owner?: string;
    accountManager?: string;
    city?: string;
    state?: string;
    status?: string;
    federal?: string;
    repIndividualUserID?: string;
    customer?: string;
};

export type CSVDealerOption = {
    opportunityId: string;
    customer: string;
    option: string;
    tenant_id: string;
};

/**
 * @function S3Uploader
 * @description Upload a file to AWS S3.
 * @param {File} file - The file to upload.
 * @returns {Promise<string>} - The key of the file in S3.
 */
export const S3Uploader = async (file: File): Promise<string> => {
    const credentials = await getTemporaryAWSCredentials();
    // AWS S3 Configuration
    AWS.config.update({
        sessionToken: credentials.sessionToken,
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey
    });
    const myBucket = new AWS.S3({
        params: { Bucket: AWS_API.s3Bucket },
        region: AWS_API.region
    });
    const params = {
        Body: file,
        Bucket: AWS_API.s3Bucket,
        Key: file.name
    };

    await myBucket.putObject(params).send((err, data) => {
        if (err) console.error('error uploading file: ', err);
    });

    return params.Key;
};

/**
 * @function S3UrlGetter
 * @description Get the image URL from AWS S3.
 * @param {string} key - The key of the image in AWS S3.
 * @returns {Promise<string>} - The URL of the image.
 */
export const S3UrlGetter = async (key: string): Promise<string> => {
    const credentials = await getTemporaryAWSCredentials();
    // AWS S3 Configuration
    AWS.config.update({
        sessionToken: credentials.sessionToken,
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey
    });
    const myBucket = new AWS.S3({
        params: { Bucket: AWS_API.s3Bucket },
        region: AWS_API.region
    });

    const result = await myBucket.getSignedUrlPromise('getObject', { Bucket: AWS_API.s3Bucket, Key: key });
    return result;
};

/**
 * Retrieves a CSV file from S3 and maps its contents to an array of CSVOpportunity objects.
 *
 * @return {Promise<CSVOpportunity[]>} - A promise that resolves to an array of CSVOpportunity objects.
 */
export const S3CSVOpportunityMapper = async (): Promise<CSVOpportunity[]> => {
    const tenantId = localStorage.getItem('tenant_id');
    const fileUrl = await S3UrlGetter(`ais/${tenantId}.csv`);
    const file = await fetch(fileUrl);

    // USE THIS ONLY FOR TESTING PURPOSES OR IF S3 IS DOWN
    // const file = await fetch('/AllOpportunitiesNew.csv');
    // const file = await fetch('/AllOpportunitiesNew.csv');

    const blob = await file.text();
    const opportunities: CSVOpportunity[] = [];
    blob.split('\n').forEach((line, index) => {
        if (index > 0) {
            // Get all qouted parts in order to prevent comma injection
            const re = /"(.*?)"/g;
            const result: string[] = [];
            let current;
            while ((current = re.exec(line))) {
                result.push(current?.pop() ?? '');
            }
            let lineParsed = line;
            result.forEach((part) => {
                lineParsed = lineParsed.replaceAll(part, part.replaceAll(',', ';'));
            });
            const opportunity = lineParsed.split(',');
            const contactName = opportunity[4]?.split(';') ?? ['', ''];
            opportunities.push({
                opportunityName: opportunity[0]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                spaOppNumber: opportunity[1]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                account: opportunity[2]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                accountType: opportunity[3]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                contactName: {
                    last: contactName[0]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                    first: contactName[1]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? ''
                },
                spaStatus: opportunity[6]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                accountManager: opportunity[7]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                totalNet: opportunity[8]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                customerNumber: opportunity[9]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                productList: opportunity[10]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                owner: opportunity[13]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                city: opportunity[10]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                state: opportunity[11]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                status: opportunity[12]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                federal: opportunity[18]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                repIndividualUserID: opportunity[19]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                customer: opportunity[20]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? ''
            });
        }
    });

    return opportunities;
};

/**
 * Retrieves a CSV file from S3 and maps its contents to an array of CSVDealerOption objects.
 *
 * @return {Promise<CSVOpportunity[]>} - A promise that resolves to an array of CSVOpportunity objects.
 */
export const S3CSVDealerOptionsMapper = async (): Promise<CSVDealerOption[]> => {
    const tenantId = localStorage.getItem('tenant_id');
    const fileUrl = await S3UrlGetter(`ais/${tenantId}-dealer-options.csv`);
    const file = await fetch(fileUrl);

    // USE THIS ONLY FOR TESTING PURPOSES OR IF S3 IS DOWN
    // const file = await fetch('/67-dealer-options.csv');

    const blob = await file.text();
    const list: CSVDealerOption[] = [];
    blob.split('\n').forEach((line, index) => {
        if (index > 0) {
            // Get all qouted parts in order to prevent comma injection
            const re = /"(.*?)"/g;
            const result: string[] = [];
            let current;
            while ((current = re.exec(line))) {
                result.push(current?.pop() ?? '');
            }
            let lineParsed = line;
            result.forEach((part) => {
                lineParsed = lineParsed.replaceAll(part, part.replaceAll(',', ';'));
            });
            const option = lineParsed.split(',');
            list.push({
                opportunityId: option[0]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                customer: option[1]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                option: option[2]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                tenant_id: option[3]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? ''
            });
        }
    });

    return list;
};

export const getDocumentFileUrl = (recordFile: IRecordFile) => {
    try {
        const buffer = Buffer.from(recordFile.fileEncoded, 'base64');
        const blob = new Blob([buffer], { type: 'application/pdf' });

        return URL.createObjectURL(blob);
    } catch (err) {
        console.log(err);
        return '';
    }
};
export const getDocumentFileBlob = (recordFile: IRecordFile) => {
    try {
        const buffer = Buffer.from(recordFile.fileEncoded, 'base64');
        return new Blob([buffer], { type: 'application/pdf' });
    } catch (err) {
        console.log(err);
        return null;
    }
};
