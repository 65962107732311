/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import { isJsonString } from 'utils/stringHelpers';
import { Comment } from '../../RecordView/CommentsPanel/types';

export const getLastComment = (rowsCommentsData: { [key: string]: Comment[] }, rowId: string, defaultComment: string): string => {
    let lastComment = '';
    const rowComments = rowsCommentsData[rowId];
    if (!rowComments) return 'Could not load comments for this record.';
    if (rowComments && rowComments.length > 0) {
        const firstComment = rowComments.at(0);
        if (!firstComment?.parentComment) {
            lastComment = isJsonString(firstComment?.content || '') && JSON.parse(firstComment?.content || '')?.blocks?.at(0)?.text;
        } else {
            const parentComment = rowComments.find((comment) => comment._id === firstComment?.parentComment);
            const parentCommentText =
                parentComment && isJsonString(parentComment?.content) && JSON.parse(parentComment?.content)?.blocks?.at(0).text;
            const childCommentText =
                isJsonString(firstComment?.content || '') && JSON.parse(firstComment?.content || '')?.blocks?.at(0).text;
            lastComment = parentCommentText && `${parentCommentText}${childCommentText ? `, Reply: ${childCommentText}` : ''}`;
        }
    }
    return lastComment
        ? lastComment.length > 200
            ? `${lastComment.slice(0, 200)}... (View full comment in the system)`
            : lastComment
        : defaultComment;
};
