/* eslint-disable no-nested-ternary */
import { Grid } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { PropsWithChildren } from 'react';

export type MainAreaProps = {
    showSearchSidebard?: boolean;
    fullWidth?: boolean;
    height?: string;
    drawerOpen?: boolean;
    transparent?: boolean;
    sx?: SystemStyleObject;
};

export const MainArea = ({
    showSearchSidebard,
    fullWidth,
    height,
    children,
    drawerOpen,
    transparent,
    sx
}: PropsWithChildren<MainAreaProps>) => (
    <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        xs={drawerOpen ? 8.6 : fullWidth ? 12 : showSearchSidebard ? 7 : 9}
        sx={{ bgcolor: transparent ? 'transparent' : '#D9D9D9', position: 'relative', height, borderWith: 1, ...sx }}
    >
        {children}
    </Grid>
);
