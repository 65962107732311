import { useGridApiContext, GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { UsersDropdown } from '../UsersDropdown';
import { StatusRecordDropdown } from '../StatusRecordDropdown';
import { ProblemCodeDropdown } from '../ProblemCodeDropdown';
import { ListDropdown } from '../ListDropdown';
import { FindListValues, FindListValuesVariables, ListValue } from 'views/backoffice/CustomLists/types';
import { DateEditCell } from '../DateEditCell';
import { DateTimeEditCell } from '../DateTimeEditCell';
import { TimeEditCell } from '../TimeEditCell';
import { NumberEditCell } from '../NumberEditCell';
import { TargetTenantDropdown } from '../TargetTenantDropdown';
import { CustomMultiSelect } from 'ui-component/formComponents/CustomMultiSelect';
import { isJsonString } from 'utils/stringHelpers';
import { EntitiesAssociatedDropdown } from '../EntitiesAssociatedDropdown';
import { SingleSelectDropdown } from '../SingleSelectDropdown';
import { EmailEditCell } from '../EmailEditCell';
import { PhoneEditCell } from '../PhoneEditCell';
import { useCallback, useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_LIST_VALUES } from 'graphql/queries/customLists';

export const UsersEditDropdown = (props: GridRenderEditCellParams) => {
    const { id, field, row, value } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string | string[]) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    // If the value is a number, it means that the row was edited and the value is the new id
    const wasEdited = typeof value === 'number';
    return <UsersDropdown onChange={handleValueChange} initialValue={wasEdited ? value : row[field]} fieldName={field} />;
};

export const StatusEditDropdown = (props: GridRenderEditCellParams & { recordTypeId: number }) => {
    const { id, field, recordTypeId, row, value } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string | string[]) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const wasEdited = typeof value === 'number';

    return <StatusRecordDropdown onChange={handleValueChange} initialValue={wasEdited ? value : row[field]} recordTypeId={recordTypeId} />;
};

export const ProblemCodeEditDropdown = (props: GridRenderEditCellParams & { recordTypeId: number }) => {
    const { id, field, recordTypeId, row, value } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string | string[]) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const wasEdited = typeof value === 'number';
    return <ProblemCodeDropdown onChange={handleValueChange} initialValue={wasEdited ? value : row[field]} recordTypeId={recordTypeId} />;
};

export const TargetTenantEditDropdown = (props: GridRenderEditCellParams & { recordTypeId: number }) => {
    const { id, field, recordTypeId, row, value } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string | string[]) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const wasEdited = typeof value === 'number';
    return <TargetTenantDropdown onChange={handleValueChange} initialValue={wasEdited ? value : row[field]} recordTypeId={recordTypeId} />;
};

export const BusinessEntityEditDropdown = (props: GridRenderEditCellParams & { recordTypeId: number }) => {
    const { id, field, recordTypeId, row, value } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string | string[]) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const wasEdited = typeof value === 'number';
    return (
        <EntitiesAssociatedDropdown
            onChange={handleValueChange}
            initialValue={wasEdited ? value : row[field]}
            recordTypeId={recordTypeId}
        />
    );
};

export const SingleSelectEditDropdown = (props: GridRenderEditCellParams & { listId?: number }) => {
    const { id, field, row, value, listId } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string | string[]) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const wasEdited = typeof value === 'number';
    return <SingleSelectDropdown onChange={handleValueChange} initialValue={wasEdited ? value : row[field]} listId={listId} />;
};

export const ListEditDropdown = (
    props: GridRenderEditCellParams & { listOfObjects?: boolean; listId?: number; initialListValues?: ListValue[] }
) => {
    const { id, field, listId, value, initialListValues, listOfObjects } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string | string[]) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
        <ListDropdown
            key={id}
            onChange={handleValueChange}
            initialValue={value}
            listId={listId}
            initialListValues={initialListValues}
            isListOfObjects={listOfObjects}
        />
    );
};

export const MultiSelectEditDropdown = (props: GridRenderEditCellParams & { listId: number }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, field, listId, value } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const [getListValues, { data: listValuesData, loading: loadingListValuesData }] = useLazyQuery<FindListValues, FindListValuesVariables>(
        QUERY_GET_LIST_VALUES
    );

    const mapOptionsFromValues = useCallback(
        (values: ListValue[]) => {
            const optionsMapped =
                values
                    .filter((el) => {
                        const isEnabled = el.enabled;
                        const valueIsInInitialValue =
                            (typeof value === 'string' && +el.id === +value) ||
                            (Array.isArray(value) && value.map((val) => +val).includes(+el.id));

                        return isEnabled || !isEnabled || valueIsInInitialValue;
                    })

                    .sort((a, b) => a.order - b.order) || [];
            return optionsMapped;
        },
        [value]
    );

    const optionList = useMemo(() => {
        if (listValuesData) {
            return mapOptionsFromValues(listValuesData.findListValues);
        }

        return [] as ListValue[];
    }, [listValuesData, mapOptionsFromValues]);

    // TODO: fix this memory leak: Can't perform a React state update on an unmounted component.
    const handleGetListValues = useCallback(async () => {
        try {
            if (listId) {
                await getListValues({ variables: { data: { listId } } });
            }
        } catch (error) {
            console.log('error', error);
        }
    }, [getListValues, listId]);

    useEffect(() => {
        handleGetListValues();
    }, [handleGetListValues]);

    if (loadingListValuesData) return null;

    return (
        <CustomMultiSelect
            value={(
                optionList.filter((option) =>
                    String(value) !== '0' && isJsonString(value) && Array.isArray(JSON.parse(value))
                        ? JSON.parse(value || '[]').includes(+option.id)
                        : false
                ) ?? []
            ).map((option: any) => +option.id)}
            onChange={(newValue: number[]) => {
                handleValueChange(newValue.length === 0 ? '0' : JSON.stringify(newValue));
            }}
            options={optionList
                .filter((option: any) => {
                    let currentValue = [];
                    if (String(value) !== '0' && isJsonString(value) && Array.isArray(JSON.parse(value))) {
                        currentValue = JSON.parse(value || '[]');
                    }
                    return option.enabled || currentValue.includes(+option.id);
                })
                .sort((a, b) => a.order - b.order)
                .map((option: any) => ({
                    id: +option.id,
                    label: option.userValue ? option.userValue.name : option.value,
                    enabled: option.enabled
                }))}
            width="100%"
            variant="standalone"
            sx={{ paddingX: '12px' }}
        />
    );
};

export const DateEditCellDropdown = (props: GridRenderEditCellParams & { hideLabel?: boolean }) => {
    const { id, field, value, hideLabel } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <DateEditCell key={id} onChange={handleValueChange} value={value} field={field} hideLabel={hideLabel} />;
};

export const DateTimeEditCellDropdown = (props: GridRenderEditCellParams & { hideLabel?: boolean }) => {
    const { id, field, value, hideLabel } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string | null | Date) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <DateTimeEditCell key={id} onChange={handleValueChange} value={value} field={field} hideLabel={hideLabel} />;
};

export const TimeEditCellDropdown = (props: GridRenderEditCellParams & { hideLabel?: boolean }) => {
    const { id, field, value, hideLabel } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <TimeEditCell key={id} onChange={handleValueChange} value={value} field={field} hideLabel={hideLabel} />;
};

export const NumberEditCellDropdown = (props: GridRenderEditCellParams & { isCurrency: boolean }) => {
    const { id, field, value, isCurrency } = props;
    const apiRef = useGridApiContext();
    const handleValueChange = (newValue: string) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <NumberEditCell key={id} onChange={handleValueChange} value={value} isCurrency={isCurrency} />;
};

export const PhoneEditCellDropdown = (props: GridRenderEditCellParams) => {
    const { id, field, value } = props;
    const apiRef = useGridApiContext();
    const handleValueChange = (newValue: string) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <PhoneEditCell key={id} onChange={handleValueChange} value={value} />;
};

export const EmailEditCellDropdown = (props: GridRenderEditCellParams) => {
    const { id, field, value } = props;

    const apiRef = useGridApiContext();
    const handleValueChange = (newValue: string) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <EmailEditCell key={id} onChange={handleValueChange} value={value} />;
};
