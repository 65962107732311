import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

export type SectionContainerProps = {
    title?: string;
    removeContentContainer?: boolean;
};

export const SectionContainer = ({ title, removeContentContainer, children }: PropsWithChildren<SectionContainerProps>) => (
    <Box sx={{ my: '12px', px: '24px', py: '8px', borderRadius: '8px', border: 'solid 1px #EBEEFE' }}>
        {title && (
            <Typography fontSize="20px" fontWeight={500} sx={{ lineHeight: '160%', mb: '8px' }} color="secondary">
                <FormattedMessage id={title} defaultMessage={title} />
            </Typography>
        )}
        {removeContentContainer ? (
            children
        ) : (
            <Grid container spacing={2} sx={{ width: '100%' }}>
                {children}
            </Grid>
        )}
    </Box>
);
