import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { CancelOutlined, Download, Rotate90DegreesCcw, Print as PrintIcon, Delete } from '@mui/icons-material';
import { RotateDirection } from '@react-pdf-viewer/core';
import { RenderPrintProps, printPlugin } from '@react-pdf-viewer/print';
import { RenderRotateProps, rotatePlugin } from '@react-pdf-viewer/rotate';
import { PdfViewer } from 'ui-component/RecordView/PdfViewer';
import { AttachmentRow } from '../types';
import AttachmentsPanel from './AttachmentsPanel';

export type PdfViewerDialogProps = {
    open: boolean;
    fileRow: AttachmentRow | null;
    handleClose: () => void;
    onDownload: (fileRow: AttachmentRow | null) => void;
    onDelete?: () => void;
    signedUrl?: string;
    openRightPanel?: boolean;
    onChangeDescription?: (description: string) => void;
    isAllowedEditable?: boolean;
    doNotShowDescription?: boolean;
};

export const PdfViewerDialog = ({
    open,
    fileRow,
    handleClose,
    onDownload,
    onDelete,
    signedUrl,
    openRightPanel = false,
    onChangeDescription,
    isAllowedEditable,
    doNotShowDescription
}: PdfViewerDialogProps) => {
    const rotatePluginInstance = rotatePlugin();
    const { Rotate } = rotatePluginInstance;

    const printPluginInstance = printPlugin();
    const { Print } = printPluginInstance;

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle id="alert-dialog-title">
                <Grid container justifyContent="space-between">
                    <Grid item xs={11}>
                        <Typography variant="h4" color="black" fontSize="24px" fontWeight={500}>
                            {fileRow?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} container justifyContent="flex-end">
                        <IconButton color="secondary" onClick={handleClose}>
                            <CancelOutlined />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent sx={{ bgcolor: '#F5F6F7', height: '80vh', pb: 0, px: 0, position: 'relative' }}>
                {fileRow && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                        <Box sx={{ height: '80vh', width: '96%', mt: 1 }}>
                            <PdfViewer
                                url={signedUrl as string}
                                rotateInstance={rotatePluginInstance}
                                printInstance={printPluginInstance}
                                removeHighlight
                            />
                        </Box>
                        <Box
                            sx={{
                                background: 'transparent',
                                zIndex: '999',
                                height: '200px',
                                width: '4%',
                                marginRight: '20px',
                                mt: 1
                            }}
                        >
                            <Stack spacing={2}>
                                <Rotate direction={RotateDirection.Backward}>
                                    {(props: RenderRotateProps) => (
                                        <Tooltip title="Rotate">
                                            <IconButton
                                                sx={(theme) => ({
                                                    color: theme.palette.primary[400],
                                                    backgroundColor: '#FFF',
                                                    fontWeight: 600,
                                                    '&:hover': { color: theme.palette.primary[400], backgroundColor: '#FFF' }
                                                })}
                                                onClick={props.onClick}
                                                color="secondary"
                                            >
                                                <Rotate90DegreesCcw />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Rotate>

                                <Tooltip title="Download">
                                    <IconButton
                                        sx={(theme) => ({
                                            color: theme.palette.primary[400],
                                            backgroundColor: '#FFF',
                                            fontWeight: 600,
                                            '&:hover': { color: theme.palette.primary[400], backgroundColor: '#FFF' }
                                        })}
                                        onClick={() => onDownload(fileRow)}
                                    >
                                        <Download />
                                    </IconButton>
                                </Tooltip>
                                <Print>
                                    {(props: RenderPrintProps) => (
                                        <Tooltip title="Print">
                                            <IconButton
                                                sx={(theme) => ({
                                                    color: theme.palette.primary[400],
                                                    backgroundColor: '#FFF',
                                                    fontWeight: 600,
                                                    '&:hover': { color: theme.palette.primary[400], backgroundColor: '#FFF' }
                                                })}
                                                onClick={props.onClick}
                                                color="secondary"
                                            >
                                                <PrintIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Print>
                                {onDelete && isAllowedEditable && (
                                    <Tooltip title="Delete">
                                        <IconButton
                                            sx={(theme) => ({
                                                color: theme.palette.primary[400],
                                                backgroundColor: '#FFF',
                                                fontWeight: 600,
                                                '&:hover': { color: theme.palette.primary[400], backgroundColor: '#FFF' }
                                            })}
                                            color="secondary"
                                            onClick={() => onDelete()}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Stack>
                        </Box>
                        {openRightPanel && (
                            <AttachmentsPanel
                                fileRow={fileRow}
                                onChangeDescription={onChangeDescription}
                                handleClose={handleClose}
                                isAllowedEditable={isAllowedEditable}
                                doNotShowDescription={doNotShowDescription}
                            />
                        )}
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};
