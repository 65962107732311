import { ArrowDownward, ArrowUpward, FilterList } from '@mui/icons-material';
import { Button, Grid, List, ListItemButton, ListItemIcon, ListItemText, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import {
    GridColDef,
    GridFilterItem,
    gridFilterModelSelector,
    GridPinnedPosition,
    GridSortDirection,
    useGridApiContext
} from '@mui/x-data-grid-pro';
import { IconAdjustmentsHorizontal } from '@tabler/icons';
import { JSXElementConstructor, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EyeClosed, PinToLeft, PinToRight } from 'ui-component/icons';
import { OperatorsMenu } from './components';
import { OperatorValue } from './types';
import { BaseInputValueProps } from 'ui-component/records/utils/filterHelpers';
import { FieldDataTypeEnum, IRecordFieldsList } from 'ui-component/records/types';
import { STRING_LIKE_OR_NUMBER_DATA_TYPE_CONTAINER_SX } from './assets';
import { isStringLikeDatatype } from './utils';

const CustomListItem = styled(ListItemButton)(() => ({
    background: '#F5F6F7 !important',
    height: '32px',
    px: '10px',
    py: '3px',
    cursor: 'default',
    '&:hover': { background: '#F5F6F7 !important' },
    '&:hover .MuiListItemIcon-root': { color: '#858585 !important' }
}));

const CustomListItemText = styled(ListItemText)(() => ({
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '& span': {
        display: 'inline !important'
    }
}));

export type CustomColumnMenuProps = {
    colDef: GridColDef & { computedWidth: number };
    hideMenu: () => void;
    fieldHeaders: IRecordFieldsList;
    onApplyFilters: () => void;
};

export const CustomColumnMenu = ({ colDef, hideMenu, fieldHeaders, onApplyFilters }: CustomColumnMenuProps) => {
    const apiRef = useGridApiContext();
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const currentFilterModel = useMemo(() => {
        const allFilterModel = gridFilterModelSelector(apiRef.current.state);
        const itemsForMyColumn = allFilterModel.items
            .filter((item) => item.field === colDef.field)
            .filter((el) => el.operator !== 'isEmpty' && el.operator !== 'isNotEmpty');

        return itemsForMyColumn;
    }, [apiRef, colDef.field]);
    const fieldDef = fieldHeaders[colDef.field];

    const showFilterListItem = !(['boolean', 'checkbox'] as Partial<FieldDataTypeEnum>[]).includes(fieldDef.dataType);
    const isPinned = apiRef.current.isColumnPinned(colDef.field);
    const filterOperators = colDef.filterOperators?.filter?.((el) => el.label !== 'isEmpty' && el.label !== 'isNotEmpty') || [];

    const [selectedFilterOperatorLabel, setSelectedFilterOperatorLabel] = useState(
        currentFilterModel.length ? currentFilterModel[0].operator : filterOperators[0]?.label || 'contains'
    );
    const [filterValue, setFilterValue] = useState<OperatorValue>(
        currentFilterModel.length ? { value: currentFilterModel[0].value } : { value: '' }
    );
    const [operatorMenuAnchor, setOperatorMenuAnchor] = useState<HTMLElement | null>(null);

    const selectedOperator = filterOperators.find((el) => el.label === selectedFilterOperatorLabel);
    const OperatorInputComponent: JSXElementConstructor<BaseInputValueProps> | undefined = selectedOperator?.InputComponent;
    const OperatorInputComponentProps = selectedOperator?.InputComponentProps;

    const handleOpenOperatorsMenu = (anchorEl: HTMLElement) => {
        setOperatorMenuAnchor(anchorEl);
    };

    const handleChangeOperator = (operator: string) => {
        setSelectedFilterOperatorLabel(operator);
        if (operator === 'isAnyOf' && isStringLikeDatatype(fieldDef.dataType)) {
            setFilterValue({ value: [] });
        } else {
            setFilterValue({ value: '' });
        }
    };

    const handleOrderByColumn = (direction: GridSortDirection) => {
        apiRef.current.sortColumn(colDef, direction);
        hideMenu();
    };

    const handlePinColumn = (side: GridPinnedPosition) => {
        apiRef.current.pinColumn(colDef.field, side);
        hideMenu();
    };

    const handleUnpinColumn = () => {
        apiRef.current.unpinColumn(colDef.field);
        hideMenu();
    };

    const handleOpenFilterPanel = () => {
        apiRef.current.showFilterPanel(colDef.field);
        hideMenu();
    };

    const handleHideColumn = () => {
        apiRef.current.setColumnVisibility(colDef.field, false);
    };

    const handleApplyValue = (value: GridFilterItem) => {
        setFilterValue(value as unknown as OperatorValue);
    };

    const handleApplyFilter = useCallback(() => {
        const allFilterModel = gridFilterModelSelector(apiRef.current.state);
        const { value } = filterValue;

        if (currentFilterModel.length && value !== currentFilterModel[0].value) {
            const firstItem = { ...currentFilterModel[0] };
            firstItem.value = value;
            firstItem.operator = selectedFilterOperatorLabel;
            apiRef.current.setFilterModel({
                ...allFilterModel,
                items: allFilterModel.items.map((el) => (el.id === firstItem.id ? firstItem : el))
            });
            onApplyFilters();
            hideMenu();
        } else if (!currentFilterModel.length && value.length) {
            apiRef.current.setFilterModel({
                ...allFilterModel,
                items: [...allFilterModel.items, { field: colDef.field, operator: selectedFilterOperatorLabel, value }]
            });
            onApplyFilters();
            hideMenu();
        }
    }, [apiRef, colDef.field, currentFilterModel, filterValue, hideMenu, onApplyFilters, selectedFilterOperatorLabel]);

    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            handleApplyFilter();
            timerRef.current = null;
        }, 3000);

        // eslint-disable-next-line consistent-return
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue]);

    return (
        <>
            <List sx={{ width: colDef.computedWidth - 2, py: 0, borderRadius: '8px' }} component={Paper}>
                {showFilterListItem && (
                    <CustomListItem
                        disableRipple
                        disableTouchRipple
                        sx={{ minHeight: '66px', borderRadius: '8px 8px 0 0', borderBottom: '1px solid #D9D9D9' }}
                    >
                        <Grid container>
                            <Grid item container xs={12} alignItems="center">
                                <Grid item xs>
                                    <Typography fontSize="12px" fontWeight={500} color="#858585">
                                        Filter ({selectedFilterOperatorLabel})
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto" sx={{ pb: '8px' }}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={(e) => handleOpenOperatorsMenu(e.currentTarget)}
                                        sx={{ p: 0, borderRadius: '8px', minWidth: '20px', width: '20px', height: '20px' }}
                                    >
                                        <IconAdjustmentsHorizontal width={14} height={18} />
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={
                                    isStringLikeDatatype(fieldDef.dataType)
                                        ? STRING_LIKE_OR_NUMBER_DATA_TYPE_CONTAINER_SX
                                        : {
                                              '& .status-record-dropdown-container': { minWidth: 'unset !important' },
                                              '& .problem-code-dropdown-container': { minWidth: 'unset !important' },
                                              '& .users-dropdown-container': { minWidth: 'unset !important' },
                                              '& .target-tenant-dropdown-container': { minWidth: 'unset !important' }
                                          }
                                }
                            >
                                {OperatorInputComponent ? (
                                    <OperatorInputComponent
                                        apiRef={apiRef}
                                        {...OperatorInputComponentProps}
                                        item={filterValue as unknown as GridFilterItem}
                                        applyValue={handleApplyValue}
                                        hideDisableValueToggle
                                        hideLabel
                                        inputSx={{
                                            height: '32px',
                                            background: 'white !important',
                                            borderRadius: '8px',
                                            '&::before': { border: 'none !important' },
                                            '&::after': { border: 'none !important' },
                                            '& select': { background: 'white !important', pl: '10px !important' },
                                            '& .MuiInputBase-root': {
                                                mt: '0 !important',
                                                background: fieldDef.dataType === 'currency' ? 'white !important' : undefined,
                                                borderRadius: fieldDef.dataType === 'currency' ? '8px' : undefined,
                                                height: fieldDef.dataType === 'currency' ? '32px' : undefined
                                            },
                                            '& .MuiInputBase-root::before': (
                                                ['date', 'datetime', 'time', 'currency'] as Partial<FieldDataTypeEnum>[]
                                            ).includes(fieldDef.dataType)
                                                ? { border: 'none !important' }
                                                : undefined,
                                            '& .MuiInputBase-root::after': (
                                                ['date', 'datetime', 'time', 'currency'] as Partial<FieldDataTypeEnum>[]
                                            ).includes(fieldDef.dataType)
                                                ? { border: 'none !important' }
                                                : undefined,
                                            '& .MuiIconButton-root.MuiIconButton-edgeEnd': {
                                                transform: fieldDef.dataType === 'datetime' ? 'translateX(-10px)' : undefined
                                            },
                                            '& input': (['datetime', 'time'] as Partial<FieldDataTypeEnum>[]).includes(fieldDef.dataType)
                                                ? { marginLeft: '10px', height: '100%', paddingBottom: 0 }
                                                : undefined
                                        }}
                                    />
                                ) : (
                                    <TextField
                                        size="small"
                                        InputProps={{ sx: { height: '32px', borderRadius: '8px', background: '#EBEEFE' } }}
                                        fullWidth
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CustomListItem>
                )}
                <CustomListItem onClick={() => handleOrderByColumn('asc')} sx={{ borderBottom: '1px solid #D9D9D9' }}>
                    <ListItemIcon sx={{ color: '#858585' }}>
                        <ArrowUpward />
                    </ListItemIcon>
                    <CustomListItemText primary="Sort ascending" />
                </CustomListItem>
                <CustomListItem onClick={() => handleOrderByColumn('desc')} sx={{ borderBottom: '1px solid #D9D9D9' }}>
                    <ListItemIcon sx={{ color: '#858585' }}>
                        <ArrowDownward />
                    </ListItemIcon>
                    <CustomListItemText primary="Sort descending" />
                </CustomListItem>
                {isPinned ? (
                    <CustomListItem onClick={handleUnpinColumn} sx={{ borderBottom: '1px solid #D9D9D9' }}>
                        <ListItemIcon sx={{ color: '#858585' }}>
                            <PinToLeft />
                        </ListItemIcon>
                        <CustomListItemText primary="Unpin" />
                    </CustomListItem>
                ) : (
                    <>
                        <CustomListItem onClick={() => handlePinColumn(GridPinnedPosition.left)} sx={{ borderBottom: '1px solid #D9D9D9' }}>
                            <ListItemIcon sx={{ color: '#858585' }}>
                                <PinToLeft />
                            </ListItemIcon>
                            <CustomListItemText primary="Pin to left" />
                        </CustomListItem>
                        <CustomListItem
                            onClick={() => handlePinColumn(GridPinnedPosition.right)}
                            sx={{ borderBottom: '1px solid #D9D9D9' }}
                        >
                            <ListItemIcon sx={{ color: '#858585' }}>
                                <PinToRight />
                            </ListItemIcon>
                            <CustomListItemText primary="Pin to right" />
                        </CustomListItem>
                    </>
                )}
                <CustomListItem onClick={handleOpenFilterPanel} sx={{ borderBottom: '1px solid #D9D9D9' }}>
                    <ListItemIcon sx={{ color: '#858585' }}>
                        <FilterList />
                    </ListItemIcon>
                    <CustomListItemText primary="Filter" />
                </CustomListItem>
                <CustomListItem onClick={handleHideColumn} sx={{ borderBottom: '1px solid #D9D9D9' }}>
                    <ListItemIcon sx={{ color: '#858585' }}>
                        <EyeClosed />
                    </ListItemIcon>
                    <CustomListItemText primary="Hide Column" />
                </CustomListItem>
                {/* <CustomListItem sx={{ borderRadius: '0 0 8px 8px' }}>
                <ListItemIcon sx={{ color: '#858585' }}>
                    <ViewColumnOutlined />
                </ListItemIcon>
                <CustomListItemText primary="Manage Columns" />
            </CustomListItem> */}
            </List>
            {operatorMenuAnchor && (
                <OperatorsMenu
                    open={Boolean(operatorMenuAnchor)}
                    anchorEl={operatorMenuAnchor}
                    onClose={() => setOperatorMenuAnchor(null)}
                    onSelect={handleChangeOperator}
                    operators={filterOperators.filter((el) => !!el.label).map((el) => el.label as string)}
                />
            )}
        </>
    );
};
