import { Select } from '@mui/material';
import { styled } from '@mui/system';

export const CustomSelect = styled(Select)(() => ({
    maxHeight: '40px',
    width: '300px',
    background: 'transparent !important',
    '& > .MuiSelect-select': {
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& > span': { display: 'block', width: '80%', textOverflow: 'ellipsis', overflow: 'hidden' },
        '& #controlButtons': { display: 'block !important', textAlign: 'right' },
        '& #grid-preference-pin-container': { display: 'none' },
        '& #grid-preference-default-icon-container': { display: 'none' }
    },
    '& > fieldset': { border: 'none', height: '40px' }
}));
