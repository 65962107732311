/* eslint-disable no-nested-ternary */
import { Grid, Paper, Stack } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { useEffect, useRef, useState } from 'react';
import { ILineItemHeader } from '../LineItems/types';
import { LineItemsGrid, LineItemsGridRef } from '../LineItems';
import { DocumentViewerProvider } from 'views/DocumentViewer/context/DocumentViewerProvider';

export interface CustomDetailPanelProps {
    isOpen: boolean;
    recordId: number;
    recordTypeId: number;
    headers: ILineItemHeader;
    onActiveEditing: any;
}

const CustomDetailPanel = ({ isOpen, recordId, recordTypeId, headers, onActiveEditing }: CustomDetailPanelProps) => {
    const gridRef = useRef<LineItemsGridRef | null>(null);
    const apiRef = useGridApiContext();
    const [panelWidth, setPanelWidth] = useState(apiRef.current.rootElementRef?.current?.clientWidth || 0);

    // To resize the panel
    useEffect(() => {
        let observer: null | ResizeObserver = null;

        if (apiRef?.current?.rootElementRef?.current) {
            observer = new ResizeObserver((e) => {
                setPanelWidth(e[0].target.clientWidth);
            });

            observer.observe(apiRef.current.rootElementRef.current);
        }

        return () => {
            if (observer) observer.disconnect();
        };
    }, [apiRef]);

    return (
        <DocumentViewerProvider>
            <Stack sx={{ display: 'flex', height: '100%', maxWidth: panelWidth || 'inherit' }}>
                <Paper elevation={1} component={Grid} container sx={{ m: '5px', p: '10px', flexGrow: 1 }}>
                    <Grid xs={12} container spacing={1}>
                        <Grid item xs={12} sx={{ height: '100%' }}>
                            <LineItemsGrid
                                ref={gridRef}
                                isOpen={isOpen}
                                recordId={recordId}
                                headers={headers}
                                recordTypeId={recordTypeId}
                                height="100%"
                                rightPosition={10}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Stack>
        </DocumentViewerProvider>
    );
};

export default CustomDetailPanel;
