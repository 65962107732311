import { Grid, Typography, Button, Box, Tooltip } from '@mui/material';
import useIsAllowedByRecordType from 'hooks/useIsAllowedByRecordType';
import { FormattedMessage } from 'react-intl';
import { CloudSad } from 'ui-component/icons';

export type OneRowOverlayProps = {
    recordTypeId: number;
    onAddChildRecord: (currentTarget: HTMLButtonElement) => void;
    onLinkParentRecord?: () => void;
};

export const OneRowOverlay = ({ onAddChildRecord, onLinkParentRecord, recordTypeId }: OneRowOverlayProps) => {
    const { isAllowed: isAllowedToCreateRecords, loading: loadingCreateRecordsAccess } = useIsAllowedByRecordType(
        'create records',
        recordTypeId
    );
    return (
        <Box
            component={Grid}
            container
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ position: 'absolute', width: '100%', top: '123px', height: 'calc(100% - 140px)' }}
        >
            <Grid item xs="auto">
                <CloudSad />
            </Grid>
            <Grid item xs="auto" sx={{ mb: '10px' }}>
                <Typography variant="h5" color="#54595E" fontSize={20} fontWeight={400}>
                    This record is not associated with any other records
                </Typography>
            </Grid>

            <Grid item xs="auto">
                <Tooltip title={!isAllowedToCreateRecords ? <FormattedMessage id="notAllowedToCreateRecords" /> : ''}>
                    <span>
                        <Button
                            onClick={(e) => onAddChildRecord(e.currentTarget)}
                            variant="contained"
                            color="secondary"
                            sx={{ width: '168px', height: '28px', px: '12px !important', borderRadius: '8px', fontSize: '14px' }}
                            disabled={!isAllowedToCreateRecords || loadingCreateRecordsAccess}
                        >
                            Create Child Record
                        </Button>
                    </span>
                </Tooltip>
            </Grid>
            {onLinkParentRecord && (
                <Grid item xs="auto">
                    <Button
                        onClick={onLinkParentRecord}
                        color="secondary"
                        sx={{ width: '168px', height: '28px', mt: '3px', px: '12px !important', borderRadius: '8px', fontSize: '14px' }}
                    >
                        Link Parent Record
                    </Button>
                </Grid>
            )}
        </Box>
    );
};
