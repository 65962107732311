import { ExpandMore } from '@mui/icons-material';
// import { IconButton, Typography } from '@mui/material';
import { GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { SearchBar } from 'ui-component/SearchBar';

export type HierarchyGridToolbarProps = {
    onToggleCollapse: () => void;
};

export const HierarchyGridToolbar = ({ onToggleCollapse }: HierarchyGridToolbarProps) => {
    const apiRef = useGridApiContext();
    const [searchText, setSearchText] = useState('');
    // const [isCollapsed, setIsCollapsed] = useState(true);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newVal = e.target.value;
        setSearchText(newVal);
        apiRef.current.setQuickFilterValues([newVal]);
    };

    return (
        <GridToolbarContainer sx={{ gap: 0.5 }}>
            <SearchBar
                value={searchText}
                onChange={handleSearch}
                placeholder="Find in current page"
                size="small"
                sx={{ width: '300px' }}
                InputProps={{
                    sx: { height: '32px', '& .input-hot-keys': { height: '24px !important' } }
                }}
            />
            <GridToolbarFilterButton
                sx={({ palette }) => ({
                    color: palette.primary[400],
                    backgroundColor: palette.primary[300],
                    borderRadius: '30px',
                    padding: '4px 16px'
                })}
                componentsProps={{ button: { endIcon: <ExpandMore /> } }}
            />

            {/* <IconButton
                color="primary"
                onClick={(e) => {
                    e.preventDefault();
                    setIsCollapsed(!isCollapsed);
                    onToggleCollapse();
                }}
                sx={({ palette }) => ({
                    ml: 'auto',
                    padding: '7px 16px',
                    color: palette.primary[400],
                    backgroundColor: palette.primary[300],
                    borderRadius: '30px'
                })}
            >
                <Typography
                    variant="body1"
                    fontSize="14px"
                    fontWeight={500}
                    sx={({ palette }) => ({ color: palette.primary[400], marginLeft: '4px' })}
                    component="div"
                >
                    {isCollapsed ? 'Expand all' : 'Collapse all'}
                </Typography>
            </IconButton> */}
        </GridToolbarContainer>
    );
};
