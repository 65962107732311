import { Clear } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { PropsWithChildren } from 'react';

export type TextFieldv2Props = TextFieldProps & { onClear?: () => void; hidePlaceholder?: boolean };

export const TextFieldv2 = ({ error, children, ...props }: PropsWithChildren<TextFieldv2Props>) => (
    <TextField
        {...props}
        variant="filled"
        size="small"
        SelectProps={{
            ...(props.SelectProps || {}),
            endAdornment:
                props.value && props.onClear ? (
                    <InputAdornment position="end" sx={{ mr: 3 }}>
                        <IconButton
                            edge="end"
                            onClick={(e) => {
                                e.preventDefault();
                                props.onClear?.();
                            }}
                        >
                            <Clear />
                        </IconButton>
                    </InputAdornment>
                ) : undefined
        }}
        InputProps={{
            ...(props.InputProps || {}),
            hiddenLabel: true,
            disableUnderline: true,
            sx: { borderRadius: '8px', background: '#f5f6f7', outline: error ? 'solid 1px #EF5350' : 'none' },
            placeholder: error && !props.hidePlaceholder ? 'Required Field' : ''
        }}
        fullWidth
    >
        {children}
    </TextField>
);
