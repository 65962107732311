import { Computer, ExpandMore } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Button, FormControl, Grid, IconButton, ListItemText, MenuItem, SelectChangeEvent, Tooltip } from '@mui/material';
import { ReactComponent as SaveIcon } from 'assets/images/icons/save.svg';
import { ReactComponent as SaveNewIcon } from 'assets/images/icons/savenew.svg';
import { IGridPreference, ISaasMyGridPreference } from 'ui-component/records/types';
import { MouseEvent } from 'react';
import { IconPin } from '@tabler/icons';
import { CustomSelect } from './styled';

export type PreferenceSelectProps = {
    disabled: boolean;
    value: string;
    defaultValue: string;
    onChange: (e: SelectChangeEvent) => void;
    onChangeDefault: (e: SelectChangeEvent) => void;
    preferenceList: IGridPreference[];
    isAdminSelectedGrid: boolean;
    userPreferencesLoading: boolean;
    userPreferencesData: ISaasMyGridPreference | undefined;
    canConfigureGridPreference: boolean;
    openEditDialog: () => void;
    openDeleteDialog: () => void;
} & (
    | { showSaveChanges?: false }
    | { showSaveChanges: true; onSaveChanges: (e: MouseEvent<HTMLButtonElement>) => Promise<void>; onSaveAsNew: () => void }
);

export const PreferenceSelect = ({
    disabled,
    onChange,
    onChangeDefault,
    preferenceList,
    value,
    defaultValue,
    isAdminSelectedGrid,
    userPreferencesLoading,
    userPreferencesData,
    canConfigureGridPreference,
    openEditDialog,
    openDeleteDialog,
    ...otherProps
}: PreferenceSelectProps) => {
    const options = [...(preferenceList || [])]
        .sort((a, b) => Number(!!b.role) - Number(!!a.role))
        .map((preference) => {
            const isDefaultSelected = +defaultValue === +preference.id;
            return (
                <MenuItem
                    key={preference.id}
                    value={String(preference.id)}
                    sx={{
                        justifyContent: 'space-between',
                        bgcolor: isDefaultSelected ? '#EBEEFE' : '#F5F6F7',
                        '& > span': {
                            display: 'block',
                            width: '80%',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden'
                        }
                    }}
                >
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs>
                            <ListItemText
                                primary={preference.savedName}
                                sx={{
                                    '& span': {
                                        color: '#54595E !important',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            {!isAdminSelectedGrid && !userPreferencesLoading && userPreferencesData && canConfigureGridPreference && (
                                <span style={{ display: 'none' }} id="controlButtons">
                                    <Tooltip title="Edit">
                                        <IconButton
                                            size="small"
                                            onMouseDown={(e) => {
                                                e.stopPropagation();
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openEditDialog();
                                            }}
                                            sx={(theme) => ({
                                                '&:hover': {
                                                    backgroundColor: `rgba(0,0,0,0.04) !important`,
                                                    color: `${theme.palette.primary[400]} !important`
                                                }
                                            })}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton
                                            size="small"
                                            onMouseDown={(e) => {
                                                e.stopPropagation();
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openDeleteDialog();
                                            }}
                                            sx={(theme) => ({
                                                '&:hover': {
                                                    backgroundColor: `rgba(0,0,0,0.04) !important`,
                                                    color: `${theme.palette.primary[400]} !important`
                                                }
                                            })}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </span>
                            )}
                        </Grid>
                        <Grid item xs="auto" id="grid-preference-default-icon-container">
                            {preference.role && <Computer color="secondary" />}
                        </Grid>

                        <Grid item xs="auto" id="grid-preference-pin-container">
                            <Button
                                sx={(theme) => ({
                                    borderRadius: '8px',
                                    minWidth: '30px',
                                    height: '30px',
                                    bgcolor: isDefaultSelected ? theme.palette.primary.main : '#FFFFFF',
                                    boxShadow: 'none',
                                    '&:hover svg': { stroke: '#FFFFFF !important' },
                                    cursor: isDefaultSelected ? 'default' : 'pointer'
                                })}
                                onClick={
                                    isDefaultSelected
                                        ? undefined
                                        : () => onChangeDefault({ target: { value: preference.id } } as SelectChangeEvent)
                                }
                                disableRipple={isDefaultSelected}
                                disableElevation={isDefaultSelected}
                                variant="contained"
                                size="small"
                            >
                                <IconPin size={16} color={isDefaultSelected ? '#FFFFFF' : '#37326E'} />
                            </Button>
                        </Grid>
                    </Grid>
                </MenuItem>
            );
        });

    const additionalOptions = otherProps.showSaveChanges && (
        <>
            {!isAdminSelectedGrid && canConfigureGridPreference && (
                <Tooltip title="Save">
                    <IconButton onClick={otherProps.onSaveChanges} size="large">
                        <SaveIcon />
                    </IconButton>
                </Tooltip>
            )}
            {canConfigureGridPreference && (
                <Tooltip title="Save as New">
                    <IconButton onClick={otherProps.onSaveAsNew} size="large">
                        <SaveNewIcon />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );

    return (
        <Box
            sx={(theme) => ({
                border: `1px solid #D9D9D9`,
                borderRadius: '30px',
                backgroundColor: theme.palette.primary[300],
                height: '40px',
                display: 'flex'
            })}
        >
            <FormControl size="small" disabled={disabled}>
                <CustomSelect
                    labelId="demo-preference-select-label"
                    data-testid="preference-select"
                    value={value}
                    label="Grid Preferences"
                    onChange={onChange as any}
                    notched={false}
                    IconComponent={ExpandMore}
                >
                    {options}
                </CustomSelect>
            </FormControl>
            {additionalOptions}
        </Box>
    );
};
