import React, { Dispatch, PropsWithChildren } from 'react';
import { Box, Divider, Fab, Grid, Paper, SxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { IconArrowsMaximize, IconArrowsMinimize } from '@tabler/icons';
import { SystemStyleObject } from '@mui/system';
import { Close, History } from '@mui/icons-material';

export type FlexibleAreaProps = {
    height: string | number;
    toggleFullHeight?: () => void;
    onClose?: () => void;
    fullHeight?: boolean;
    title?: string;
    showDivider?: boolean;
    hideTitle?: boolean;
    contentSx?: SxProps;
    fullHeightButtonSx?: SxProps;
    paperSx?: SxProps;
    setToggleHistoryPanel?: Dispatch<React.SetStateAction<boolean>>;
    toggleHistoryPanel?: boolean;
    showHistoryPanel?: boolean;
};

export const FlexibleArea = ({
    children,
    height,
    toggleFullHeight,
    onClose,
    fullHeight,
    title,
    showDivider,
    hideTitle,
    contentSx = {},
    fullHeightButtonSx = {},
    paperSx = {},
    setToggleHistoryPanel,
    toggleHistoryPanel,
    showHistoryPanel
}: PropsWithChildren<FlexibleAreaProps>) => {
    const theme = useTheme();
    const handleOnHistoryPanel = () => {
        setToggleHistoryPanel?.((value) => !value);
    };

    return (
        <Paper
            sx={{
                height,
                display: height === 0 ? 'none' : 'block',
                width: '100%',
                pl: '25px',
                bgcolor: 'white',
                '& > .MuiGrid-root ~ *': contentSx as SystemStyleObject,
                ...paperSx
            }}
        >
            {showDivider && (
                <Divider
                    sx={{ height: '4px', width: '100%', pb: '5px', color: theme.palette.primary.main, bgcolor: theme.palette.primary.main }}
                />
            )}
            {!hideTitle && (
                <Grid container justifyContent="space-between" sx={{ pb: '18px', ...contentSx }}>
                    <Grid item xs="auto">
                        <Typography variant="h4" fontSize="20px" fontWeight={500} color="primary">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item container xs="auto">
                        <Box sx={{ display: 'flex', gap: '8px' }}>
                            {showHistoryPanel && (
                                <Fab
                                    size="small"
                                    sx={{
                                        boxShadow: 'none !important',
                                        bgcolor: toggleHistoryPanel
                                            ? theme.palette.secondary.light
                                            : `${theme.palette.primary[300]} !important`
                                    }}
                                    onClick={handleOnHistoryPanel}
                                >
                                    <History
                                        sx={
                                            toggleHistoryPanel
                                                ? { color: theme.palette.secondary.main }
                                                : { color: theme.palette.primary[400] }
                                        }
                                    />
                                </Fab>
                            )}
                            <Fab
                                size="small"
                                sx={{
                                    boxShadow: 'none !important',
                                    bgcolor: fullHeight ? theme.palette.secondary.light : `${theme.palette.primary[300]} !important`,
                                    ...fullHeightButtonSx
                                }}
                                onClick={toggleFullHeight}
                            >
                                {fullHeight ? (
                                    <IconArrowsMinimize color={theme.palette.secondary.main} />
                                ) : (
                                    <IconArrowsMaximize color={theme.palette.primary[400]} />
                                )}
                            </Fab>
                            {onClose && (
                                <Fab
                                    size="small"
                                    sx={{
                                        boxShadow: 'none !important',
                                        bgcolor: `${theme.palette.primary[300]} !important`
                                    }}
                                    onClick={onClose}
                                >
                                    <Close sx={{ color: theme.palette.primary[400] }} />
                                </Fab>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            )}
            {children}
        </Paper>
    );
};
