/* eslint no-continue: 0 */
/* eslint no-nested-ternary: 0 */
import { v4 as uuidv4 } from 'uuid';
import { IAditionalFields, IRecordField, IRecordFieldsList } from 'ui-component/records/types';
import { isJsonString } from 'utils/stringHelpers';
import { AuditLog, Changes } from '../types';
import { getAuditFormattedValue } from '../utils';
import { LineItemLogRow } from 'ui-component/records/LineItems/types';
import { AttachmentField } from './types';

const EXCLUDED_KEYS_ATTACHMENTLOGS = ['fileId'];

/**
 * Returns the value of the attachments if exist
 *
 * @param additionalFieldsArr {IAditionalFields[]}
 * @param attachmentFieldId {String}
 */
export const getAttachmentFields = (attachmentFieldId?: number[], additionalFieldsArr?: IAditionalFields[]) => {
    if (!attachmentFieldId || !additionalFieldsArr) return undefined;
    const attachmentsData = [];
    for (const field of additionalFieldsArr) {
        let [, , fieldId] = field.tag.split(';');
        fieldId = fieldId || field.tag.split(':')[1].split(';')[1];

        if (attachmentFieldId.includes(Number(fieldId))) {
            attachmentsData.push({ ...field, fieldId: Number(fieldId) });
        }
    }
    return attachmentsData.length > 0 ? attachmentsData : undefined;
};

/**
 * Returns all the ids of the fields that has attachment as dataType.
 *
 * @param headers {IRecordFieldList}
 */
export const getAttachmentFieldIds = (headers?: IRecordFieldsList) => {
    if (!headers) return undefined;
    const fieldIds = Object.values(headers)
        .filter((val) => val.dataType === ('attachment' as unknown as IRecordField['dataType']))
        .map(({ id }) => Number(id));
    return fieldIds.length > 0 ? fieldIds : undefined;
};

const getAction = (oldValue: AttachmentField, newValue: AttachmentField, defaultVal: string): string => {
    if (!oldValue && newValue) return 'create';
    if (oldValue && !newValue) return 'delete';
    return defaultVal;
};

const getRowId = ({ newValue, oldValue }: { newValue: AttachmentField; oldValue: AttachmentField }): string => {
    if (newValue && newValue?.fileId) return newValue.fileId;
    if (oldValue && oldValue?.fileId) return oldValue.fileId;
    return uuidv4();
};

const getRowName = ({ newValue, oldValue }: { newValue: AttachmentField; oldValue: AttachmentField }): string => {
    if (newValue && newValue?.filename) return newValue.filename;
    if (oldValue && oldValue?.filename) return oldValue.filename;
    return ''; // could only happen with the older records not having the file name in the audit logs -
};

/**
 * Transforms the attachment audit logs to the normal audit logs
 *
 * @param allItems {AuditLog[]}
 * @returns An array of the attachment logs formatted in the format of normal fields audit logs
 *
 * Below are the steps, the function performs
 * 1. Converts all the stringified values to the actual JSON objects
 * 2. Iterates through the logs array and performs the following
 *  2.1 Convert the changes array to format - {newValue, oldValue, what, action}
 *  2.2 Constructs a new object with the format {user, datetime, changes, id, filename, action, objectLogs}
 *  2.3 Push the newly created object to the array (container)
 * 3. Returns the array (container) which holds the reshaped objects
 */
export const transformAuditLogData = (allItems: AuditLog[]): AuditLog[] => {
    // convert all the json strings into actual js objects at once
    const allLogs = allItems.map((log) => ({
        ...log,
        changes: log.changes.map(({ newValue, oldValue, ...rem }) => ({
            ...rem,
            newValue: newValue && isJsonString(newValue) ? JSON.parse(newValue) : null,
            oldValue: oldValue && isJsonString(oldValue) ? JSON.parse(oldValue) : null
        }))
    }));

    const rows: AuditLog[] = [];
    for (const log of allLogs) {
        const changes: Changes[] = [];
        const action =
            log.changes?.length === 1 ? getAction(log.changes.at(-1)?.oldValue, log.changes.at(-1)?.newValue, log.action) : log.action;
        for (const change of log.changes) {
            if (!change.newValue && !change.oldValue) continue;
            const nonNullKey = change.newValue ? 'newValue' : 'oldValue';
            for (const [key, value] of Object.entries(change[nonNullKey]).filter(
                ([_key]) => !EXCLUDED_KEYS_ATTACHMENTLOGS.includes(_key)
            )) {
                const nVal = nonNullKey === 'newValue' ? value : '';
                const oVal = nonNullKey === 'newValue' ? change.oldValue?.[key] || '' : value;
                if (nVal === oVal) continue;
                const changeRow = {
                    newValue: nVal as string,
                    oldValue: oVal as string,
                    what: key,
                    action
                };
                const checkIfAlreadyExists = changes.some(
                    (chng) => chng.newValue === changeRow.newValue && chng.oldValue === changeRow.oldValue && chng.what === changeRow.what
                );
                !checkIfAlreadyExists && changes.push(changeRow);
            }
        }
        const row = {
            user: { ...log.user },
            datetime: log.datetime,
            changes,
            id: getRowId({ newValue: log.changes.at(-1)?.newValue, oldValue: log.changes.at(-1)?.oldValue }),
            filename: getRowName({ newValue: log.changes.at(-1)?.newValue, oldValue: log.changes.at(-1)?.oldValue }),
            action,
            objectsLogs: log.objectsLogs
        };
        rows.push(row);
    }
    return rows;
};

/**
 * Maps a list of attachment audit logs to table format (type: LineItemLogRow) suitable for audit logs.
 * Similar to method: 'mapRecordLogToLineItemAuditLogTable' but does not groups the elements and also not calculate the objectLogs
 *
 * @param attachmentLogs {AuditLog[]}
 * @returns An array of the attachment logs (type: LineItemLogRow)
 *
 */
export const mapAuditLogToAttachmentAuditLogTable = (attachmentLogs: AuditLog[]): LineItemLogRow[] => {
    const logsRow: LineItemLogRow[] = [];
    attachmentLogs?.map((attachmentLog) => {
        const logRow: LineItemLogRow = {
            id: attachmentLog?.id || '',
            before: attachmentLog.changes.reduce(
                (acc, value) => (acc ? `${acc}, ${value.oldValue}` : value.oldValue ? getAuditFormattedValue(value.oldValue) : ''),
                ''
            ),
            after: attachmentLog.changes.reduce(
                (acc, value) => (acc ? `${acc}, ${value.newValue}` : value.newValue ? getAuditFormattedValue(value.newValue) : ''),
                ''
            ),
            activity: attachmentLog.action,
            user: attachmentLog.user.name || `${attachmentLog.user.firstName} ${attachmentLog.user.lastName}`,
            dateUpdated: attachmentLog.datetime,
            name: '',
            filename: attachmentLog.filename || '',
            objectLogs: []
        };
        return logsRow.push(logRow);
    });
    return logsRow;
};
