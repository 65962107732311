import { Avatar, Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export interface PortalItemProps {
    name: string;
    profilePic?: string;
    cardBg?: string;
    slogan: string;
    address: string;
    tenantType: string;
    onClick: (name: string) => void;
    removeMargin?: boolean;
}

const useStyles = makeStyles(() => ({
    mainContainer: {
        boxShadow: '2px 2px 4px 2px rgba(0,0,0,0.1)',
        borderRadius: '10px',
        minHeight: '210px',
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '2px 2px 4px 2px rgba(0,0,0,0.3)'
        }
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '70px !important'
    }
}));

export const PortalItem = ({ name, profilePic, cardBg, slogan, address, tenantType, onClick, removeMargin }: PortalItemProps) => {
    const classes = useStyles();
    return (
        <Box
            data-testid="tenant-contact-card"
            className={classes.mainContainer}
            sx={{ margin: removeMargin ? 0 : '2rem' }}
            onClick={(e) => {
                e.stopPropagation();
                onClick(name);
            }}
        >
            <Card>
                {cardBg && <CardMedia component="img" alt="green iguana" height="83" image={cardBg} />}
                <Avatar
                    src={profilePic}
                    sx={{
                        width: 100,
                        height: 100,
                        border: '2px solid #fff',
                        m: '-70px auto',
                        '& > img': {
                            margin: 0
                        }
                    }}
                >
                    {profilePic ? null : name[0]}
                </Avatar>
                <CardContent className={classes.cardContent}>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{
                            fontWeight: '600',
                            fontSize: '22px',
                            color: '#000',
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block',
                            width: '90%'
                        }}
                    >
                        {name}
                    </Typography>
                    <Typography
                        gutterBottom
                        color="black"
                        component="div"
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            display: 'block',
                            width: '90%',
                            fontWeight: '400',
                            fontSize: '12px'
                        }}
                    >
                        {slogan}
                    </Typography>
                    <Typography
                        gutterBottom
                        color="#858585"
                        component="div"
                        fontSize="12px"
                        fontWeight={300}
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            display: 'block',
                            width: '90%'
                        }}
                    >
                        {tenantType}
                    </Typography>
                    <Typography
                        gutterBottom
                        color="#858585"
                        component="div"
                        fontSize="12px"
                        fontWeight={300}
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            display: 'block',
                            width: '90%'
                        }}
                    >
                        {address}
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
};
