import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Autocomplete,
    Box,
    Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    FormControlProps,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    NativeSelect,
    Select,
    SelectChangeEvent,
    Switch,
    TextField
} from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { Clear, ExpandMore } from '@mui/icons-material';
import { FindListValues, FindListValuesVariables, ListValue } from 'views/backoffice/CustomLists/types';
import { QUERY_GET_LIST_VALUES } from 'graphql/queries/customLists';
import { useIntl } from 'react-intl';
import { generateObjectString } from 'views/backoffice/CustomLists/utils';
import { SystemStyleObject } from '@mui/system';

export interface ListDropdownProps {
    onChange: (value: string | string[]) => void;
    listId?: number;
    initialListValues?: ListValue[];
    initialValue?: string | string[];
    variant?: FormControlProps['variant'];
    size?: FormControlProps['size'];
    label?: string;
    native?: boolean;
    multiple?: boolean;
    fromFilterPanel?: boolean;
    isListOfObjects?: boolean;
    hideDisableValueToggle?: boolean;
    hideLabel?: boolean;
    inputSx?: SystemStyleObject;
}

export const ListDropdown = ({
    onChange,
    listId,
    initialValue,
    native,
    label,
    variant = 'outlined',
    size = 'medium',
    multiple = false,
    fromFilterPanel = false,
    isListOfObjects = false,
    hideDisableValueToggle = false,
    hideLabel = false,
    inputSx = {}
}: ListDropdownProps) => {
    const intl = useIntl();
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOptionMultipleSelected, setSelectedOptionMultipleSelected] = useState<string[]>([]);
    const [includeDisabledValues, setIncludeDisabledValues] = useState(false);
    const [getListValues, { data: listValuesData, loading }] = useLazyQuery<FindListValues, FindListValuesVariables>(QUERY_GET_LIST_VALUES);

    const mapOptionsFromValues = useCallback(
        (values: ListValue[]) => {
            const optionsMapped =
                values
                    .filter((el) => {
                        const isEnabled = el.enabled;
                        const valueIsInInitialValue =
                            (typeof initialValue === 'string' && +el.id === +initialValue) ||
                            (Array.isArray(initialValue) && initialValue.map((val) => +val).includes(+el.id));

                        return isEnabled || (!isEnabled && includeDisabledValues) || valueIsInInitialValue;
                    })
                    .map((opt) =>
                        isListOfObjects && opt.dynamicObjectValue ? { ...opt, value: generateObjectString(opt.dynamicObjectValue) } : opt
                    )
                    .sort((a, b) => a.order - b.order) || [];
            return optionsMapped;
        },
        [includeDisabledValues, initialValue, isListOfObjects]
    );

    const optionList = useMemo(() => {
        if (listValuesData) {
            return mapOptionsFromValues(listValuesData.findListValues);
        }

        return [] as ListValue[];
    }, [listValuesData, mapOptionsFromValues]);

    const selectedValuesAutocomplete = React.useMemo(
        () => optionList.find((v) => v.id === selectedOption || v.id === initialValue),
        [initialValue, optionList, selectedOption]
    );

    const optionsAsObjectWithIds: Record<number, ListValue> = useMemo(
        () => optionList.reduce((acc, el) => ({ ...acc, [el.id]: el }), {}),
        [optionList]
    );

    const disabledSelectedValuesList = optionList.filter(
        (el) =>
            !el.enabled &&
            ((typeof initialValue === 'string' && +initialValue === +el.id) ||
                (Array.isArray(initialValue) && initialValue.includes(el.id)))
    );

    const handleValueChange = (event: SelectChangeEvent<string | string[]> | React.ChangeEvent<{ value: string }>) => {
        const newValue = event.target.value;
        if (multiple) setSelectedOptionMultipleSelected(newValue as string[]);
        else setSelectedOption(newValue as string);
        onChange(newValue);
    };

    const handleClearClick = () => {
        const newValue = multiple ? ['0'] : '0';
        if (multiple) setSelectedOptionMultipleSelected([newValue] as string[]);
        else setSelectedOption(newValue as string);
        onChange(newValue);
    };

    // TODO: fix this memory leak: Can't perform a React state update on an unmounted component.
    const handleGetListValues = useCallback(async () => {
        try {
            let list: ListValue[] = [];
            if (listId) {
                const { data } = await getListValues({ variables: { data: { listId } } });
                if (data?.findListValues) list = data.findListValues;
            }
            if (list.length) {
                if (multiple) {
                    const values = list.filter((el) => initialValue?.includes(el.id));
                    setSelectedOptionMultipleSelected(values?.map((val) => val.id || val.userValue?.id) || []);
                }
                if (!multiple) {
                    const value = list.find((el) => el.id === initialValue);
                    if (value) {
                        setSelectedOption(String(value?.id) || '');
                    } else {
                        setSelectedOption('');
                    }
                }
            }
        } catch (error) {
            console.log('error', error);
        }
    }, [getListValues, initialValue, listId, multiple]);

    useEffect(() => {
        handleGetListValues();
    }, [handleGetListValues]);

    return (
        <Box sx={{ minWidth: 149, width: '100%' }}>
            <FormControl fullWidth disabled={loading} variant="filled">
                {label && !hideLabel && (
                    <InputLabel id={`"list-${label}-select"`} shrink>
                        {label}
                    </InputLabel>
                )}
                {native ? (
                    <NativeSelect value={selectedOption} onChange={handleValueChange} sx={inputSx}>
                        <option value=""> &nbsp;</option>
                        {optionList.map((el) => (
                            <option key={el.id} value={el.id}>
                                {!el.enabled && '(Disabled)'} {el.userValue?.name || el.value}
                            </option>
                        ))}
                    </NativeSelect>
                ) : (
                    <>
                        {isListOfObjects && !fromFilterPanel ? (
                            <Box sx={{ minWidth: 250 }}>
                                <FormControl fullWidth disabled={loading}>
                                    <Autocomplete
                                        popupIcon={<ExpandMore />}
                                        openOnFocus
                                        defaultValue={selectedValuesAutocomplete}
                                        value={selectedValuesAutocomplete}
                                        sx={{ width: 250 }}
                                        options={optionList}
                                        onChange={(event, value) => {
                                            if (value) {
                                                setSelectedOption(value.id as string);
                                                onChange(value.id);
                                            } else {
                                                setSelectedOption('0');
                                                onChange('0');
                                            }
                                        }}
                                        autoHighlight
                                        getOptionLabel={(option) => option.value}
                                        renderOption={(props, option) => (
                                            <>
                                                <Box key={option.id} component="li" {...props}>
                                                    {option.enabled ? '' : '(Disabled) '} {option.value}
                                                </Box>
                                                <Divider />
                                            </>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password' // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                    <Collapse in={loading}>
                                        <LinearProgress />
                                    </Collapse>
                                </FormControl>
                            </Box>
                        ) : (
                            <Select
                                value={multiple ? selectedOptionMultipleSelected : selectedOption}
                                onChange={handleValueChange}
                                variant={variant}
                                size={size}
                                sx={{ pt: '3px !important', '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, ...inputSx }}
                                multiple={multiple}
                                renderValue={(val) => {
                                    if (typeof val === 'string') {
                                        if (!optionsAsObjectWithIds[+val]) return '';
                                        return optionsAsObjectWithIds[+val].userValue?.name || optionsAsObjectWithIds[+val].value;
                                    }
                                    return val
                                        .map((id) => optionsAsObjectWithIds[+id].userValue?.name || optionsAsObjectWithIds[+id].value)
                                        .join(', ');
                                }}
                                endAdornment={
                                    <IconButton
                                        sx={{
                                            display: !multiple && selectedOption && selectedOption !== '0' ? '' : 'none'
                                        }}
                                        onClick={handleClearClick}
                                    >
                                        <Clear />
                                    </IconButton>
                                }
                                {...(!multiple && selectedOption && selectedOption !== '0' && { IconComponent: () => null })}
                            >
                                {disabledSelectedValuesList.map((el) => (
                                    <MenuItem disabled={!fromFilterPanel} key={el.id} value={el.id}>
                                        {!el.enabled && '(Disabled)'} &nbsp;
                                        {el.userValue?.name || el.value}
                                    </MenuItem>
                                ))}
                                {optionList
                                    .filter((option) => disabledSelectedValuesList.every((el) => +el.id !== +option.id))
                                    .map((el, index) => (
                                        <MenuItem key={el.id} value={el.id} divider={index !== optionList.length - 1}>
                                            {!el.enabled && '(Disabled)'} &nbsp;
                                            {el.userValue?.name || el.value}
                                        </MenuItem>
                                    ))}
                            </Select>
                        )}
                    </>
                )}
                <Collapse in={loading}>
                    <LinearProgress />
                </Collapse>
            </FormControl>
            {fromFilterPanel && !hideDisableValueToggle && (
                <FormControlLabel
                    control={
                        <Switch
                            color="secondary"
                            checked={includeDisabledValues}
                            onChange={(e) => setIncludeDisabledValues(e.target.checked)}
                            size="small"
                        />
                    }
                    sx={{ pl: '4px', '& span': { fontSize: '13px' } }}
                    label={intl.formatMessage({ id: 'showDisabledValues' })}
                />
            )}
        </Box>
    );
};
