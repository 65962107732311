/* eslint-disable no-nested-ternary */
import {
    GRID_ACTIONS_COLUMN_TYPE,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GRID_DETAIL_PANEL_TOGGLE_FIELD,
    GridColumnVisibilityModel,
    GridFilterItem,
    GridFilterModel,
    GridLogicOperator,
    GridPinnedColumns
} from '@mui/x-data-grid-pro';
import {
    FieldDataTypeEnum,
    FilterCheckboxCriteriaEnum,
    FilterDateCriteriaEnum,
    FilterNumberCriteriaEnum,
    FilterObjectCriteriaEnum,
    FilterStringCriteriaEnum,
    IFilterModel,
    IGridPreference,
    IRecordField,
    IRecordFieldsList,
    filterOperatorEnum
} from '../types';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';

import { ListValue } from 'views/backoffice/CustomLists/types';
import {
    CUSTOM_FILTER_GRID_OPERATORS,
    customDateOperatorsParser,
    customUserOperatorsParser,
    getCustomFilterType
} from './filterHelpers/customFilterParser';

// This have to change in the future when backend modify the preference value
/**
 * Operators of the grid that are arrays
 */
export const ARRAY_OPERATORS = ['isAnyOf'] as const;

/**
 * Operators to be set in Backoffice and replace with correct value in the filter model
 */
export const CUSTOM_USERS_FILTER_OPERATORS = ['@username'] as const;
export const CUSTOM_DATE_FILTER_OPERATORS = ['@today', '@yesterday', '@lastweek', '@last30days'] as const;

export type CUSTOM_FILTER_OPERATORS = (typeof CUSTOM_USERS_FILTER_OPERATORS)[number] | (typeof CUSTOM_DATE_FILTER_OPERATORS)[number];

// TODO: define better users filters depending on the content of values
const CUSTOM_FILTER_OPERATOR_BY_DATATYPE = {
    date: CUSTOM_DATE_FILTER_OPERATORS,
    datetime: CUSTOM_DATE_FILTER_OPERATORS,
    dropDown: CUSTOM_USERS_FILTER_OPERATORS,
    dropdown: CUSTOM_USERS_FILTER_OPERATORS
} as const;

// columns that are not part of standard record headers can be added to the below array
const EXPLICITLY_ADDED_COLS: string[] = ['recordViewerUrl', 'dataFocusViewUrl', 'lastComment'];

// columns that are added explicitly can be made invisible by adding them in the array below
export const DEFAULT_HIDDEN_FIELDS: string[] = ['recordViewerUrl', 'dataFocusViewUrl', 'lastComment'];

export const FIELDS_FORCE_TO_EXPORT = ['recordViewerUrl', 'dataFocusViewUrl', 'lastComment'] as const;

/**
 * Return the Grid State for the Data Grid Component
 *
 * @param preference {IGridPreference}
 * @param fields {IRecordFieldsList}
 * @param showExpand {Boolean}
 * @param listValues {Record<number, ListValue[]>}
 * @param queryParamsObj {Record<string, string>}
 * @returns
 */
export const generateGridState = (
    preference: IGridPreference,
    fields: Partial<IRecordFieldsList>,
    showExpand: boolean = false,
    listValues?: Record<number, ListValue[]>,
    queryParamsObj?: Record<string, string>
): GridInitialStatePro => {
    const fieldsKeys = Object.keys(fields);
    const { orderedFields, ...states } = preference.gridOptions[0];

    const columnVisibilityModel = [...fieldsKeys, ...EXPLICITLY_ADDED_COLS].reduce<Record<keyof IRecordFieldsList, boolean>>(
        (acc: any, el: keyof IRecordFieldsList) => {
            const hiddenFields = [...(states.hiddenFields || []), ...DEFAULT_HIDDEN_FIELDS];

            const shouldBeVisible = !hiddenFields.includes(el as string);

            return {
                ...acc,
                [el]: shouldBeVisible
            };
        },
        {}
    ) as GridColumnVisibilityModel;

    const filterModel: GridFilterModel = generateFilterModel(states.filterModel, fields, listValues, queryParamsObj);

    const orderedFieldsWithouthGridColDefs = orderedFields.filter(
        (field) => ![GRID_CHECKBOX_SELECTION_COL_DEF.field, GRID_ACTIONS_COLUMN_TYPE, GRID_DETAIL_PANEL_TOGGLE_FIELD].includes(field)
    );

    const orderedFieldsWithGridDefs = showExpand
        ? [
              GRID_CHECKBOX_SELECTION_COL_DEF.field,
              GRID_ACTIONS_COLUMN_TYPE,
              GRID_DETAIL_PANEL_TOGGLE_FIELD,
              ...new Set(orderedFieldsWithouthGridColDefs)
          ]
        : [GRID_CHECKBOX_SELECTION_COL_DEF.field, GRID_ACTIONS_COLUMN_TYPE, ...new Set(orderedFieldsWithouthGridColDefs)];

    // Pin checkbox actions and detail panel columns by default
    const pinnedColumns: GridPinnedColumns = {
        left: ['__check__', 'actions', '__detail_panel_toggle__', ...(states.pinnedColumns?.left || [])],
        right: states.pinnedColumns?.left || []
    };

    const obj: GridInitialStatePro = {
        pinnedColumns: pinnedColumns as GridPinnedColumns,
        columns: {
            dimensions: {},
            columnVisibilityModel,
            orderedFields: orderedFieldsWithGridDefs
        },
        preferencePanel: {
            open: false
        },
        filter: {
            filterModel
        },
        sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }]
        },
        pagination: {
            paginationModel: {
                pageSize: states.pageSize || 25,
                page: 0
            }
        }
    };

    return obj;
};

/**
 * Return the link operator if this exist in operator value array for each item
 * otherwise return AND operator
 *
 * @param filterModel {IFilterModel[]}
 * @returns
 */
export const getLinkOperator = (filterModel?: IFilterModel[] & Partial<GridFilterItem>[]) => {
    if (!filterModel || !filterModel.length || filterModel[0].operatorValue?.length === 1) return filterOperatorEnum.AND.toLowerCase();
    return filterModel[0].operatorValue?.[0].toLowerCase() || filterOperatorEnum.AND.toLowerCase();
};

/**
 * Parse the value for the filter model taking in considerations if have custom
 * filter operators
 *
 * @param item {IFilterModel}
 * @param listValuesByField {ListValue[]}
 * @param fieldDef {IRecordField}
 */
export const getFilterModelValue = (item: IFilterModel, listValuesByField: ListValue[], fieldDef?: IRecordField) => {
    if (!item.value) return null;

    const isBaseField = +(fieldDef?.id || 0) === 0;
    const isArrayValue =
        item.operatorValue && ARRAY_OPERATORS.includes(item.operatorValue.slice(-1)[0] as (typeof ARRAY_OPERATORS)[number]);

    const customOperators = getCustomFilterOperators(item.value[0], fieldDef?.dataType);

    if (isArrayValue) {
        return item.value;
    }

    if (customOperators) {
        const filterType = getCustomFilterType(fieldDef, item.columnField);
        if (filterType === 'USER') return customUserOperatorsParser(item.value, isBaseField, listValuesByField);
        if (filterType === 'DATE') return customDateOperatorsParser(item.value);
    }

    return item.value[0];
};

const defaultOperatorsByDataType: Record<Exclude<FieldDataTypeEnum, 'attachment' | 'object'>, string> = {
    string: FilterStringCriteriaEnum.contains,
    currency: FilterNumberCriteriaEnum.equals,
    url: FilterStringCriteriaEnum.contains,
    textarea: FilterStringCriteriaEnum.contains,
    number: FilterNumberCriteriaEnum.equals,
    date: FilterDateCriteriaEnum.is,
    datetime: FilterDateCriteriaEnum.is,
    dropDown: FilterObjectCriteriaEnum.is,
    dropdown: FilterObjectCriteriaEnum.is,
    multiselect: FilterObjectCriteriaEnum.is,
    time: FilterDateCriteriaEnum.is,
    'multiselect-checkbox': FilterObjectCriteriaEnum.is,
    checkbox: FilterCheckboxCriteriaEnum.isChecked,
    email: FilterStringCriteriaEnum.contains,
    phone: FilterStringCriteriaEnum.contains,
    'single-select': FilterObjectCriteriaEnum.is
};

export const generateFilterModel = (
    filterModel: IFilterModel[] & Partial<GridFilterItem>[],
    fields: Partial<IRecordFieldsList>,
    listValues?: Record<number, ListValue[]>,
    URLSearchParams?: Record<string, string>
) => {
    const additionalfieldsById = Object.entries(fields)
        .filter(([, value]) => +(value?.id || 0) !== 0)
        .reduce<any>((acc, [key, value]) => ({ ...acc, [+(value as IRecordField).id as number]: { ...value, fieldName: key } }), {});

    const itemsByURL: any = URLSearchParams
        ? Object.keys(URLSearchParams).reduce<any>((acc, key, idx) => {
              const [, fieldId] = key.split('-');
              const fieldDef: IRecordField = fieldId ? additionalfieldsById[fieldId] : fields[key];

              if (!fieldDef || ([' attachment', 'object'] as FieldDataTypeEnum[]).includes(fieldDef.dataType)) return acc;

              const fieldName = fieldId ? additionalfieldsById[fieldId].fieldName : key;
              const operator =
                  // For checkbox with value false we overwrite the default operator
                  fieldDef.dataType === 'checkbox' && URLSearchParams[key] === 'false'
                      ? FilterCheckboxCriteriaEnum.isNotChecked
                      : defaultOperatorsByDataType[fieldDef.dataType as Exclude<FieldDataTypeEnum, 'attachment' | 'object'>];
              const value =
                  fieldDef.dataType === 'time'
                      ? `01/01/2000 ${URLSearchParams[key]}`
                      : fieldDef.dataType === 'datetime'
                      ? new Date(URLSearchParams[key]).toISOString()
                      : URLSearchParams[key];

              return [
                  ...acc,
                  {
                      id: `${fieldName}-${idx}`,
                      field: fieldName,
                      operator: operator || 'contains',
                      value
                  }
              ];
          }, [])
        : [];

    const itemsByFilterModel = (filterModel ?? []).map((item, key) => {
        const selectedField = fields[item.columnField as keyof IRecordFieldsList];

        const listValuesByField = selectedField?.listType?.id && listValues ? listValues[+selectedField.listType.id] : [];

        const customGridOperatorKey = Object.keys(CUSTOM_FILTER_GRID_OPERATORS).find((el) => {
            if (item.value && item.value[0]) {
                return item.value[0]?.toLowerCase().trim().includes(el);
            }
            return false;
        });

        return {
            ...item,
            id: key,
            field: item.columnField,
            operator: customGridOperatorKey
                ? CUSTOM_FILTER_GRID_OPERATORS[customGridOperatorKey as keyof typeof CUSTOM_FILTER_GRID_OPERATORS]
                : item.operatorValue?.slice(-1)[0] || 'contains',
            value: getFilterModelValue(item, listValuesByField, selectedField)
        };
    });

    return {
        items: [...itemsByFilterModel, ...itemsByURL],
        logicOperator: getLinkOperator(filterModel) as GridLogicOperator
    };
};

/**
 * Returns the operator for given data type if value includes
 * an operator
 *
 * @param value {String}
 * @param dataType {Extract<FieldDataTypeEnum, 'date' | 'datetime' | 'dropDown' | 'dropdown'>}
 */
const getCustomFilterOperators = (value: string, dataType?: FieldDataTypeEnum) => {
    if (!dataType) return undefined;

    const operators = CUSTOM_FILTER_OPERATOR_BY_DATATYPE[dataType as keyof typeof CUSTOM_FILTER_OPERATOR_BY_DATATYPE];

    if (operators?.some((operator) => value.toLowerCase().trim().includes(operator))) return operators;

    return undefined;
};
