import { Box } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { PortalTabsEnum } from '../types';

export type TabPanelProps = {
    active: boolean;
    value: PortalTabsEnum;
};

export const CustomTabContent = (props: PropsWithChildren<TabPanelProps>) => {
    const { children, active, value, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={!active}
            style={{ flexGrow: 1 }}
            id={`portal-tabpanel-${value.toLowerCase()}`}
            aria-labelledby={`portal-tab-${value.toLowerCase()}`}
            {...other}
        >
            {active && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};
