import { Grid, Menu, MenuItem } from '@mui/material';
import { DataGridPro, GridEventListener, GridGroupNode, useGridApiRef } from '@mui/x-data-grid-pro';
import { FindRecordHeaderWithFilteringVariables, IGetRecordHeadersFiltered, IRecordHeaders } from 'ui-component/records/types';
import { HierarchyBreadcrumb, HierarchyBreadcrumbLevel, HierarchyGridToolbar, LinkParentRecordDialog, OneRowOverlay } from './components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getCustomGroupingColDef, getHierarchyColumns, getTreeDataPath, hierarchyGridInitialState } from './assets';
import { useDispatch } from 'store';
import { updateSectionName } from 'store/slices/section';
import { capitalize } from 'utils/stringHelpers';
import { useLazyQuery } from '@apollo/client';
import { QUERY_FIND_RECORD_HEADERS_WITH_FILTERING } from 'graphql/queries/bills';
import { openSnackbar } from 'store/slices/snackbar';
import { FindRecordType, FindRecordTypeVariables, IRecordType } from 'types/recordType';
import { QUERY_FIND_RECORD_TYPES } from 'graphql/queries/recordTypes';
import { DataFocusViewDialog } from 'ui-component/dialogs';
import { SystemStyleObject } from '@mui/system';

export type HierarchyCoreProps = {
    recordId: string;
    initialRecord: IRecordHeaders;
    hideBreadcrumb?: boolean;
    containerSx?: SystemStyleObject;
};

export type RecordWithHierarchy = Pick<IRecordHeaders, 'id' | 'children' | 'recordType'> &
    Partial<IRecordHeaders> & {
        childrenFetched: boolean;
        hierarchy: string[];
        isLoading?: boolean;
    };

export const HierarchyCore = ({ recordId, initialRecord, hideBreadcrumb, containerSx = {} }: HierarchyCoreProps) => {
    const isProject = !!initialRecord.isProject;
    const tenantId = localStorage.getItem('tenant_id');

    const apiRef = useGridApiRef();
    const dispatch = useDispatch();
    const recordTypeName = useMemo(() => initialRecord.recordType.name, [initialRecord.recordType.name]);
    const [breadcrumbLevels, setBreadcrumbLevels] = useState<HierarchyBreadcrumbLevel[]>([
        {
            id: initialRecord.id,
            name: `${capitalize(recordTypeName)}-${initialRecord.id}`,
            recordTypeId: +initialRecord.recordType.id,
            recordTypeName: initialRecord.recordType.name
        }
    ]);
    const [rows, setRows] = useState<RecordWithHierarchy[]>([]);
    const [initialRecordChildren] = useState<any[]>(initialRecord.children || []);
    const [recordToAddChild, setRecordToAddChild] = useState<RecordWithHierarchy | null>(null);
    const [recordToAddChildHierarchy, setRecordToAddChildHierarchy] = useState<string[]>([]);

    const [selectedRecordTypeToCreate, setSelectedRecordTypeToCreate] = useState<IRecordType | null>(null);
    const [childrenRecordTypeMenuAnchor, setChildrenRecordTypeMenuAnchor] = useState<HTMLElement | null>(null);
    const [selectedRecordChildren, setSelectedRecordChildren] = useState<IRecordType[]>([]);
    const [isOpenLinkParentRecord, setIsOpenLinkParentRecord] = useState(false);

    const [getRecordData, { loading: loadingRecords }] = useLazyQuery<IGetRecordHeadersFiltered, FindRecordHeaderWithFilteringVariables>(
        QUERY_FIND_RECORD_HEADERS_WITH_FILTERING,
        { fetchPolicy: 'no-cache' }
    );

    const [findRecordTypes] = useLazyQuery<FindRecordType, FindRecordTypeVariables>(QUERY_FIND_RECORD_TYPES);

    const handleAddChildRecord =
        (recordTypeId: number = +initialRecord.recordType.id) =>
        async (currentTarget: HTMLButtonElement) => {
            try {
                const { data } = await findRecordTypes({ variables: { data: { id: recordTypeId, enabled: true } } });
                if (data?.findRecordType[0].children?.length) {
                    setSelectedRecordChildren(data.findRecordType[0].children);
                    setChildrenRecordTypeMenuAnchor(currentTarget);
                } else {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: `This record does not have any children record types`,
                            variant: 'alert',
                            alert: { color: 'error' },
                            close: false
                        })
                    );
                }
            } catch (error: any) {
                console.log('error adding child record', error);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `Failed to show child records: ${error.message}`,
                        variant: 'alert',
                        alert: { color: 'error' },
                        close: false
                    })
                );
            }
        };

    const handleOpenChildForm = (recordType: IRecordType, selectedRecord?: RecordWithHierarchy) => {
        setSelectedRecordTypeToCreate(recordType);

        const record: RecordWithHierarchy = selectedRecord || {
            ...initialRecord,
            hierarchy: [`${capitalize(recordTypeName)}-${initialRecord.id}`],
            childrenFetched: true,
            isLoading: false
        };

        setRecordToAddChild(record);
        setRecordToAddChildHierarchy(record.hierarchy);
    };

    const handleLinkParentRecord = () => {
        setIsOpenLinkParentRecord(true);
    };

    const getAndFormatRecord = useCallback(
        async (id: number, recordType: number, previousHierarchy?: string[]): Promise<RecordWithHierarchy | undefined> => {
            const res = await getRecordData({
                variables: {
                    pagination: { offset: 0, limit: 1 },
                    data: { recordTypeId: recordType, tenantId: +(tenantId || 0), filters: { ids: [id] } }
                }
            });
            if (res.data?.filterAndSortRecordHeader.records.length) {
                const record = res.data.filterAndSortRecordHeader.records[0];
                return {
                    ...record,
                    hierarchy: [...(previousHierarchy || []), `${capitalize(record.recordType.name)}-${id}`],
                    childrenFetched: false
                };
            }
            return undefined;
        },
        [getRecordData, tenantId]
    );

    const getChildren = useCallback(
        async (children?: Pick<IRecordHeaders, 'id' | 'recordType'> | null, hierarchy?: string[]) => {
            if (!children || children.id === initialRecord.id || children.id === initialRecord.parent?.id) return [];

            try {
                const res = await getRecordData({
                    variables: {
                        pagination: { offset: 0, limit: 1 },
                        data: {
                            recordTypeId: +(children.recordType?.id || initialRecord.recordType.id),
                            tenantId: +(tenantId || 0),
                            filters: { ids: [+children.id] }
                        }
                    }
                });

                if (res.data?.filterAndSortRecordHeader.records.length) {
                    const record = res.data.filterAndSortRecordHeader.records[0];
                    if (record.children?.length) {
                        const childrenRecords = record.children.map(
                            (child) =>
                                ({
                                    ...child,
                                    hierarchy: [...(hierarchy || []), `${capitalize(child.recordType.name)}-${child.id}`],
                                    childrenFetched: false
                                } as RecordWithHierarchy)
                        );

                        // if (childrenRecords.length) {
                        //     setRows((prev) => [...prev, ...childrenRecords]);
                        // }

                        return [
                            {
                                id: `placeholder-children-${record.id}`,
                                hierarchy: hierarchy || [],
                                childrenFetched: true
                            },
                            ...childrenRecords.filter((child) => child)
                        ];
                    }
                    // const childWithValues = childrenRecords
                    //     .filter((child) => child.status === 'fulfilled')
                    //     .map((child) => (child as PromiseFulfilledResult<RecordWithHierarchy | undefined>).value as RecordWithHierarchy);

                    // if (childrenRecords.length) {
                    //     setRows((prev) => [...prev, ...childWithValues]);
                    // }
                }
            } catch (error: any) {
                console.log('error getting children', error);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `Failed to fetch children: ${error.message}`,
                        variant: 'alert',
                        alert: { color: 'error' },
                        close: false
                    })
                );
            }

            return [];
        },
        [getRecordData, initialRecord, tenantId, dispatch]
    );

    const handleCloseDataFocusViewDialog = (newRecord?: IRecordHeaders, errorMessage?: string) => {
        setSelectedRecordTypeToCreate(null);
        if (newRecord) {
            // setInitialRecordChildren((s) => [...s, newRecord]);
            const newRecordCreated: RecordWithHierarchy = {
                ...newRecord,
                hierarchy: [...recordToAddChildHierarchy, `${capitalize(newRecord.recordType.name)}-${newRecord.id}`],
                childrenFetched: false
            };
            const newRecordAlreadyExist = rows.find((r) => r.id === newRecordCreated.id);
            if (!newRecordAlreadyExist) setRows((p) => [...p, newRecordCreated]);
        }

        if (errorMessage) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: errorMessage,
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
        }
    };

    const handleCloseLinkParent = (wasParentLinked?: boolean) => {
        setIsOpenLinkParentRecord(false);
        if (wasParentLinked) {
            initData();
            dispatch(
                openSnackbar({
                    open: true,
                    message: `Parent Linked`,
                    variant: 'alert',
                    alert: { color: 'success' },
                    close: true
                })
            );
        }
    };

    const initData = useCallback(
        async (newRecordCreated?: RecordWithHierarchy) => {
            try {
                const newRowsToAdd: RecordWithHierarchy[] = [];
                // debugger;

                if (initialRecord.parent) {
                    const parentRecord = await getAndFormatRecord(
                        +initialRecord.parent.id,
                        +(initialRecord.parent.recordType?.id || initialRecord.recordType.id)
                    );

                    if (parentRecord) {
                        // debugger;
                        const siblings: RecordWithHierarchy[] =
                            parentRecord.children
                                ?.filter(({ id }) => id !== initialRecord.id)
                                .map(
                                    (el) =>
                                        ({
                                            ...el,
                                            hierarchy: [
                                                ...parentRecord.hierarchy,
                                                `${capitalize(el.recordType?.name || recordTypeName)}-${el.id}`
                                            ],
                                            childrenFetched: false,
                                            recordType: el.recordType || initialRecord.recordType,
                                            children: []
                                        } as RecordWithHierarchy)
                                ) || [];

                        setBreadcrumbLevels([
                            {
                                id: parentRecord.id,
                                name: `${capitalize(parentRecord.recordType.name)}-${parentRecord.id}`,
                                recordTypeId: +parentRecord.recordType.id,
                                recordTypeName: parentRecord.recordType.name
                            },
                            {
                                id: initialRecord.id,
                                name: `${capitalize(recordTypeName)}-${initialRecord.id}`,
                                recordTypeId: +initialRecord.recordType.id,
                                recordTypeName: initialRecord.recordType.name
                            }
                        ]);

                        const siblingChildren: RecordWithHierarchy[] = siblings.map((child) => ({
                            id: `placeholder-children-${child.id}`,
                            hierarchy: [...child.hierarchy, ''],
                            childrenFetched: true,
                            children: [],
                            recordType: child.recordType
                        }));

                        newRowsToAdd.push(
                            ...[
                                parentRecord,
                                {
                                    ...initialRecord,
                                    hierarchy: [...parentRecord.hierarchy, `${capitalize(recordTypeName)}-${initialRecord.id}`],
                                    childrenFetched: true
                                },
                                ...siblings,
                                ...siblingChildren
                            ]
                        );
                    }
                } else {
                    newRowsToAdd.push(
                        ...[{ ...initialRecord, hierarchy: [`${capitalize(recordTypeName)}-${initialRecord.id}`], childrenFetched: true }]
                    );
                }

                if (initialRecordChildren.length) {
                    const childrenToRow: RecordWithHierarchy[] = initialRecordChildren.map((child) => {
                        const hierarchy = [
                            `${capitalize(recordTypeName)}-${initialRecord.id}`,
                            `${capitalize(child.recordType.name)}-${child.id}`
                        ];
                        if (initialRecord.parent) {
                            hierarchy.unshift(
                                `${capitalize(initialRecord.parent?.recordType?.name || recordTypeName)}-${initialRecord.parent?.id}`
                            );
                        }
                        return {
                            ...child,
                            childrenFetched: false,
                            children: [],
                            hierarchy
                        };
                    });

                    // Placeholders are needed to be able to expand the row
                    const placeholderChildren: RecordWithHierarchy[] = childrenToRow.map((child) => ({
                        id: `placeholder-children-${child.id}`,
                        hierarchy: [...child.hierarchy, ''],
                        childrenFetched: true,
                        children: [],
                        recordType: child.recordType
                    }));

                    newRowsToAdd.push(...[...childrenToRow, ...placeholderChildren]);
                }

                setRows(newRowsToAdd);
            } catch (error: any) {
                console.log('error building the tree', error);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `Failed to load the tree: ${error.message}`,
                        variant: 'alert',
                        alert: { color: 'error' },
                        close: false
                    })
                );
            }
        },
        [initialRecord, initialRecordChildren, getAndFormatRecord, recordTypeName, dispatch]
    );

    // Watch the expand event to fetch the children of the expanded row
    useEffect(() => {
        const handleRowExpansionChange: GridEventListener<'rowExpansionChange'> = async (node) => {
            const row = apiRef.current.getRow(node.id) as RecordWithHierarchy | null;

            if (!node.childrenExpanded || !row || row.childrenFetched) {
                return;
            }

            apiRef.current.updateRows([
                {
                    id: `placeholder-children-${node.id}`,
                    hierarchy: [...row.hierarchy, '']
                },
                { id: node.id, isLoading: true }
            ]);

            const childrenRows = await getChildren(row, row.hierarchy);
            // debugger;
            // console.log('children of', row.id, childrenRows);
            // apiRef.current.updateRows([
            //     // ...childrenRows,
            //     // { id: node.id, childrenFetched: true, isLoading: false },
            //     { id: `placeholder-children-${node.id}`, _action: 'delete' }
            // ]);

            setRows((prev) => [
                ...(childrenRows.slice(1) as RecordWithHierarchy[]),
                ...prev.map((r) => (r.id === node.id ? { ...r, childrenFetched: true } : r))
            ]);

            if (childrenRows.length) {
                apiRef.current.setRowChildrenExpansion(node.id, true);
            }
        };

        /**
         * By default, the grid does not toggle the expansion of rows with 0 children
         * We need to override the `cellKeyDown` event listener to force the expansion if there are children on the server
         */
        const handleCellKeyDown: GridEventListener<'cellKeyDown'> = (params, event) => {
            const cellParams = apiRef.current.getCellParams(params.id, params.field);
            if (cellParams.colDef.type === 'treeDataGroup' && event.key === ' ') {
                event.stopPropagation();
                event.preventDefault();
                event.defaultMuiPrevented = true;

                apiRef.current.setRowChildrenExpansion(params.id, !(params.rowNode as GridGroupNode).childrenExpanded);
            }
        };

        apiRef.current.subscribeEvent('rowExpansionChange', handleRowExpansionChange);
        apiRef.current.subscribeEvent('cellKeyDown', handleCellKeyDown, { isFirst: true });
    }, [apiRef, getChildren]);

    // Dispatch the section name with the breadcrumb
    useEffect(() => {
        if (!hideBreadcrumb)
            dispatch(
                updateSectionName({
                    sectionName: <HierarchyBreadcrumb recordTypeName={capitalize(recordTypeName)} levels={breadcrumbLevels} />
                })
            );
    }, [breadcrumbLevels, dispatch, hideBreadcrumb, recordTypeName]);

    // Fetch the initial data
    useEffect(() => {
        initData();
    }, [initData]);

    return (
        <Grid item xs={12} sx={{ minHeight: '360px', position: 'relative', ...containerSx }}>
            <DataGridPro
                apiRef={apiRef}
                loading={loadingRecords}
                initialState={hierarchyGridInitialState}
                columns={getHierarchyColumns(recordId, handleOpenChildForm, isProject ? undefined : handleLinkParentRecord)}
                rows={rows}
                density="compact"
                slots={{ toolbar: HierarchyGridToolbar }}
                getTreeDataPath={getTreeDataPath}
                groupingColDef={getCustomGroupingColDef(+initialRecord.id)}
                sx={{
                    '& .MuiDataGrid-cell:focus': { outline: 'none' },
                    "& [data-id^='placeholder-children']": { display: 'none !important' }
                }}
                defaultGroupingExpansionDepth={1}
                disableChildrenFiltering
                disableRowSelectionOnClick
                hideFooter
                treeData
            />
            {!initialRecord.parent && !initialRecord.children?.length && (
                <OneRowOverlay
                    recordTypeId={Number(initialRecord?.recordType?.id)}
                    onAddChildRecord={handleAddChildRecord()}
                    onLinkParentRecord={isProject ? undefined : handleLinkParentRecord}
                />
            )}
            <ChildrenRecordTypeList
                anchorEl={childrenRecordTypeMenuAnchor}
                list={selectedRecordChildren}
                onClose={() => setChildrenRecordTypeMenuAnchor(null)}
                onOpenChildRecord={handleOpenChildForm}
            />
            {!!selectedRecordTypeToCreate && !!recordToAddChild && (
                <DataFocusViewDialog
                    open={!!selectedRecordTypeToCreate}
                    onClose={handleCloseDataFocusViewDialog}
                    recordType={selectedRecordTypeToCreate}
                    parent={recordToAddChild as IRecordHeaders}
                />
            )}
            {isOpenLinkParentRecord && (
                <LinkParentRecordDialog
                    open={isOpenLinkParentRecord}
                    onClose={handleCloseLinkParent}
                    recordType={initialRecord.recordType}
                    recordToAddParent={initialRecord}
                />
            )}
        </Grid>
    );
};

export type ChildrenRecordTypeListProps = {
    list: IRecordType[];
    anchorEl: HTMLElement | null;
    onClose: () => void;
    onOpenChildRecord: (recordType: IRecordType) => void;
};

export const ChildrenRecordTypeList = ({ list, anchorEl, onClose, onOpenChildRecord }: ChildrenRecordTypeListProps) => {
    const handleOpenAddChildRecord = (selected: IRecordType) => {
        onOpenChildRecord(selected);
        onClose();
    };

    return (
        <Menu
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: ({ palette }) => ({
                    borderRadius: '8px',
                    marginTop: '20px',
                    backgroundColor: palette.primary[300],
                    maxHeight: '400px'
                })
            }}
        >
            {[...list]
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                .map((child) => (
                    <MenuItem key={child.id} onClick={() => handleOpenAddChildRecord(child)}>
                        {child.name}
                    </MenuItem>
                ))}
        </Menu>
    );
};
