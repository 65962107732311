import { Alert, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { RecordViewCore, RecordViewCoreRef } from '../RecordViewCore';
import { FormattedMessage } from 'react-intl';
import { Close, Warning } from '@mui/icons-material';
import { Export } from 'ui-component/icons';
import { IRecordHeaders } from 'ui-component/records/types';
import { useRef, useState } from 'react';
import { useConfirmationModalContext } from 'hooks/useConfirmationModal';
import { LoadingButton } from '@mui/lab';

export type RecordViewDialogProps = {
    open: boolean;
    onClose: () => void;
    recordData: IRecordHeaders;
};

export const RecordViewDialog = ({ open, onClose, recordData }: RecordViewDialogProps) => {
    const recordViewCoreRef = useRef<RecordViewCoreRef | null>(null);
    const modal = useConfirmationModalContext();

    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleOpenRecordView = () => {
        const tenantUrl = localStorage.getItem('tenantPath');
        window.open(`${tenantUrl}/accounting/doc-viewer/?recordId=${recordData.id}&recordType=${recordData.recordType.id}`, '_blank');
    };

    const handleClickClose = async () => {
        await modal.showConfirmation({
            title: (
                <Typography fontSize="20px" fontWeight={300} color="#000000" sx={{ ml: 1 }}>
                    Are you sure you want to discard your changes?
                </Typography>
            ),
            icon: <Warning color="warning" sx={{ marginLeft: '1rem' }} fontSize="medium" />,
            content: (
                <Alert
                    variant="filled"
                    icon={false}
                    sx={{
                        bgcolor: '#FB9000',
                        color: 'white',
                        borderRadius: '8px !important',
                        height: '32px',
                        fontSize: '14px',
                        width: 'fit-content',
                        padding: '0 16px !important',
                        fontWeight: 500
                    }}
                >
                    This is an irreversible action and will permanently remove your work.
                </Alert>
            ),
            loadingText: 'Deleting',
            forwardButtonText: 'Save changes',
            onForward: handleClickSubmit,
            showActionButton: true,
            actionButtonText: 'Discard changes',
            onAction: async () => onClose(),
            showCheckbox: false,
            actionType: 'discard_changes',
            sectionTitle: 'record_view_dialog',
            recordType: '',
            hideCloseBtn: true
        });
    };

    const handleClickSubmit = async () => {
        if (recordViewCoreRef.current) {
            setIsLoading(true);
            const res = await recordViewCoreRef.current.handleSubmit();

            if (res) {
                onClose();
            }
            setIsLoading(false);
        }
    };

    const breadcrumbLevels = [
        <Typography fontSize="20px" fontWeight={300} sx={({ palette }) => ({ color: palette.primary[400] })}>
            {recordData.recordType.name}
        </Typography>,
        <Typography fontSize="20px" fontWeight={500} color="secondary">
            {recordData.recordType.name}-{recordData.id}
        </Typography>
    ];

    return (
        <Dialog open={open} onClose={isLoading ? undefined : onClose} maxWidth="lg" fullWidth>
            <DialogTitle component={Grid} sx={{ height: '64px' }} container>
                <Grid item xs>
                    <Breadcrumbs separator=">">{breadcrumbLevels}</Breadcrumbs>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        onClick={handleOpenRecordView}
                        variant="outlined"
                        color="secondary"
                        sx={{ alignItems: 'center', maxWidth: '193px', borderRadius: '8px', height: '28px' }}
                    >
                        <Export sx={{ width: '16px !important', height: '16px !important' }} />
                        <FormattedMessage id="recordView.openRecordView" />
                    </Button>
                </Grid>
                <Grid item xs="auto" sx={{ ml: 1 }}>
                    <IconButton
                        onClick={handleClickClose}
                        disableRipple
                        sx={{ width: '40px', height: '32px', borderRadius: '100px', bgcolor: '#F5F6F7' }}
                    >
                        <Close htmlColor="#858585" />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent sx={{ padding: 0, height: '70vh' }}>
                {open && (
                    <RecordViewCore
                        ref={recordViewCoreRef}
                        editMode
                        modalMode
                        components={['LINE_ITEMS']}
                        onChangeDisabledSubmit={setDisabledSubmit}
                    />
                )}
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', height: '64px' }}>
                <Button
                    disabled={isLoading}
                    onClick={handleClickClose}
                    variant="outlined"
                    color="secondary"
                    sx={{ width: '80px', height: '32px', borderRadius: '8px' }}
                >
                    Cancel
                </Button>
                <LoadingButton
                    loading={isLoading}
                    disabled={disabledSubmit}
                    onClick={handleClickSubmit}
                    variant="contained"
                    color="secondary"
                    sx={{ width: '80px', height: '32px', borderRadius: '8px' }}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
