import { IAditionalFields, IRecordFieldsList, IRecordHeaders } from 'ui-component/records/types';
import { getFieldName } from 'ui-component/records/utils';
import { splitHeaders } from 'ui-component/records/utils/headerHelpers';
import { getAditionalFieldsValues } from 'ui-component/RecordView/PropertiesPanel/utils';
import { getFormattedValue } from 'ui-component/RecordView/utils';
import { ObjectProperty } from 'views/backoffice/CustomObjects/types';
import { PropertyValuePayload } from 'views/TenantProfileSettings/components/types';

/**
 * Return all object definition ids of the headers identify with its field name
 *
 * @param headers {Partial<IRecordFieldsList>}
 */
export const getObjectDefinitionIds = (headers?: Partial<IRecordFieldsList>) => {
    if (!headers || !Object.keys(headers).length) return {};

    const ids: Record<string, number> = {};

    for (const key in headers) {
        if (Object.prototype.hasOwnProperty.call(headers, key)) {
            const element = headers[key];
            if (element?.objectDefinition?.id) ids[key] = Number(element.objectDefinition.id);
        }
    }

    return ids;
};

/**
 * Returns and object with array of properties with the field name as key
 *
 * @param properties {ObjectProperty[]}
 * @param objectIdsByField {Object}
 */
export const getObjectDefinitionByFieldName = (properties?: ObjectProperty[], objectIdsByField?: Record<string, number>) => {
    if (!properties || !objectIdsByField || !properties.length) return {};

    const list: Record<string, Record<string, ObjectProperty>> = {};

    for (const property of properties) {
        if (+property.objectDefinition.id) {
            const field = Object.entries(objectIdsByField).find(([key, value]) => value === +property.objectDefinition.id);
            if (field)
                list[field[0]] = {
                    ...(list[field[0]] || {}),
                    [property.name]: property
                };
        }
    }

    return list;
};

export const generatePortalOrderPayload = (
    headers?: IRecordFieldsList,
    objectDefinitions?: Record<string, Record<string, ObjectProperty>>,
    data?: Record<string, any>
) => {
    if (!headers || !objectDefinitions || !data) return {};
    const objectFields = Object.fromEntries(
        Object.entries(data).filter(([key, val]) => objectDefinitions?.[key] && Object.values(val).some((el) => el || el === ''))
    );
    const { additionalFieldHeaders } = splitHeaders(headers);
    const objectsToCreate: Record<string, PropertyValuePayload[]> = {};

    for (const key in objectFields) {
        if (Object.prototype.hasOwnProperty.call(objectFields, key)) {
            const values: Record<string, string> = objectFields[key];
            const payload: PropertyValuePayload[] = Object.entries(values)
                .filter(([_valueKey, val]) => val || val === '')
                .map(([valueKey, value]) => {
                    const propertyId = +objectDefinitions?.[key][valueKey]?.id;
                    if (!propertyId) return null;

                    return { propertyId, value: String(value) };
                })
                .filter((item) => item !== null) as PropertyValuePayload[];

            objectsToCreate[key] = payload;
        }
    }
    const validFields = Object.keys(data)
        .filter(
            (key) =>
                (additionalFieldHeaders[key]?.dataType !== 'object' && data[key] !== undefined) ||
                (additionalFieldHeaders[key]?.dataType === 'object' && !!objectFields[key])
        )
        .filter((key) => Object.keys(additionalFieldHeaders).includes(key))
        .filter((key) => {
            const value = getFormattedValue(data[key], additionalFieldHeaders[key]);
            return value !== undefined;
        });

    const additionalFields = validFields.map((key) => {
        const aditionalFieldHeaderId = additionalFieldHeaders[key]?.id;
        return {
            tag: `;;${aditionalFieldHeaderId}`,
            value: getFormattedValue(data[key], additionalFieldHeaders[key]),
            objectValuesByProperty: additionalFieldHeaders[key]?.dataType === 'object' ? objectsToCreate[key] : undefined
        };
    });

    return { additionalFields };
};

/**
 * Generate the object what is used to reset the form of Portals
 *
 * @param headers {IRecordFieldsList}
 * @param data {IRecordHeaders}
 */
export const formatDataToForm = (headers?: IRecordFieldsList, data?: IRecordHeaders) => {
    if (!headers || !data) return {};
    const { additionalFieldHeaders } = splitHeaders(headers);
    const baseObj: Record<string, any> = {};

    const { objectFields, nonObjectFields } = splitObjectValues(data.additionalFields);
    const additionalFields = getAditionalFieldsValues(additionalFieldHeaders, nonObjectFields);
    const docksArr = [];

    for (const field of objectFields) {
        let [, , fieldId] = field.tag.split(';');
        fieldId = fieldId || field.tag.split(':')[1].split(';')[1];
        const key = getFieldName(headers, fieldId || fieldId);

        if (field.objectValue?.objectValues)
            for (const objectValue of field.objectValue?.objectValues) {
                let propertyName = objectValue.objectProperty.name;
                let propertyValue = objectValue.value;
                if (propertyName.includes('Docks Open') && propertyValue === 'true') {
                    docksArr.push(objectValue.objectProperty.id);
                    // eslint-disable-next-line
                    continue;
                }

                if (['Warehouse', 'Delivery to Site', 'Residential'].includes(propertyName) && propertyValue === 'true') {
                    propertyName = 'Destination Type';
                    propertyValue = objectValue.objectProperty.id;
                }
                if (baseObj[key]) {
                    baseObj[key][propertyName] = propertyValue;
                } else {
                    baseObj[key] = {
                        [propertyName]: propertyValue
                    };
                }
            }
    }

    if (baseObj['Shipping Information']) baseObj['Shipping Information']['Docks Open on the following Days'] = JSON.stringify(docksArr);
    return { ...baseObj, ...additionalFields };
};

/**
 * Return a object with the values separated between objects additional fields
 * and non object fields
 *
 * @param data IAditionalFields[]
 */
export const splitObjectValues = (data: IAditionalFields[]) =>
    data.reduce<{
        objectFields: IAditionalFields[];
        nonObjectFields: IAditionalFields[];
    }>(
        (acc, field) =>
            field.objectValue
                ? { ...acc, objectFields: [...acc.objectFields, field] }
                : { ...acc, nonObjectFields: [...acc.nonObjectFields, field] },
        { objectFields: [], nonObjectFields: [] }
    );
