import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Collapse,
    FormControl,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    NativeSelect,
    PopoverOrigin,
    Select,
    SelectChangeEvent,
    SxProps,
    Theme
} from '@mui/material';
import { ArrowDropDown, Clear } from '@mui/icons-material';
import { GridSingleSelect } from 'ui-component/formComponents';
import { FindListValues, FindListValuesVariables, ListValue } from 'views/backoffice/CustomLists/types';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_LIST_VALUES } from 'graphql/queries/customLists';

export interface SingleSelectDropdownProps {
    onChange: (value: string | string[]) => void;
    initialValue?: string | string[];
    label?: string;
    native?: boolean;
    fromFilterPanel?: boolean;
    multiple?: boolean;
    PopoverSx?: SxProps<Theme> | undefined;
    PopoverPropsAnchorOrigin?: PopoverOrigin | undefined;
    PopoverPropsTransformOrigin?: PopoverOrigin | undefined;
    listId?: number;
}

export const SingleSelectDropdown = ({
    onChange,
    initialValue,
    native,
    label,
    fromFilterPanel,
    multiple,
    PopoverSx,
    PopoverPropsAnchorOrigin,
    PopoverPropsTransformOrigin,
    listId
}: SingleSelectDropdownProps) => {
    const [optionSelected, setOptionSelected] = useState<string>('');
    const [optionsSelected, setOptionsSelected] = useState<string[]>([]);

    const [getListValues, { data: listValuesData }] = useLazyQuery<FindListValues, FindListValuesVariables>(QUERY_GET_LIST_VALUES);

    const mapOptionsFromValues = useCallback(
        (values: ListValue[]) => {
            const optionsMapped =
                values
                    .filter((el) => {
                        const isEnabled = el.enabled;
                        const valueIsInInitialValue =
                            (typeof initialValue === 'string' && +el.id === +initialValue) ||
                            (Array.isArray(initialValue) && initialValue.map((val) => +val).includes(+el.id));

                        return isEnabled || !isEnabled || valueIsInInitialValue;
                    })
                    .sort((a, b) => a.order - b.order) || [];
            return optionsMapped;
        },
        [initialValue]
    );
    const optionList = useMemo(() => {
        if (listValuesData) {
            return mapOptionsFromValues(listValuesData.findListValues);
        }

        return [] as ListValue[];
    }, [listValuesData, mapOptionsFromValues]);

    const handleValueChange = (event: SelectChangeEvent<string | string[]> | React.ChangeEvent<{ value: string }>) => {
        const newValue = event.target.value;
        if (multiple) setOptionsSelected(newValue as string[]);
        else setOptionSelected(newValue as string);
        onChange(newValue);
    };

    const handleClearClick = () => {
        const newValue = ['0'];
        setOptionsSelected(newValue as string[]);
        onChange(newValue);
    };

    // TODO: fix this memory leak: Can't perform a React state update on an unmounted component.
    const handleGetListValues = useCallback(async () => {
        try {
            let list: ListValue[] = [];
            if (listId) {
                const { data } = await getListValues({ variables: { data: { listId } } });
                if (data?.findListValues) list = data.findListValues;
            }
            if (list.length) {
                if (multiple) {
                    const values = list.filter((el) => initialValue?.includes(el.id));
                    setOptionsSelected(values?.map((val) => val.id || val.userValue?.id) || []);
                }
                if (!multiple) {
                    const value = list.find((el) => el.id === initialValue);
                    if (value) {
                        setOptionSelected(String(value?.id) || '');
                    } else {
                        setOptionSelected('');
                    }
                }
            }
        } catch (error) {
            console.log('error', error);
        }
    }, [getListValues, initialValue, listId, multiple]);

    useEffect(() => {
        handleGetListValues();
    }, [handleGetListValues]);

    useEffect(() => {
        if (initialValue) {
            if (multiple) setOptionsSelected(initialValue as string[]);
            else setOptionSelected(initialValue as string);
        }
    }, [initialValue, multiple]);
    return (
        <Box sx={{ minWidth: 149, width: '100%' }}>
            <FormControl fullWidth>
                {label && (
                    <InputLabel id="record-status-select" shrink>
                        {label}
                    </InputLabel>
                )}

                {fromFilterPanel && multiple && (
                    <Select
                        value={optionsSelected}
                        onChange={handleValueChange}
                        multiple
                        sx={{ pt: '3px !important' }}
                        endAdornment={
                            <IconButton
                                sx={{
                                    display: 'none'
                                }}
                                onClick={handleClearClick}
                            >
                                <Clear />
                            </IconButton>
                        }
                    >
                        {optionList?.map((el) => (
                            <MenuItem key={el.id} value={el.id}>
                                {el.value}
                            </MenuItem>
                        ))}
                    </Select>
                )}

                {fromFilterPanel && native && (
                    <NativeSelect value={optionSelected} onChange={handleValueChange}>
                        <option value=""> &nbsp;</option>
                        {optionList?.map((el) => (
                            <option key={el.id} value={el.value}>
                                {el.value}
                            </option>
                        ))}
                    </NativeSelect>
                )}

                {!fromFilterPanel && (
                    <GridSingleSelect
                        width="100%"
                        onChange={(value) => {
                            handleValueChange({ target: { value: String(value) } } as SelectChangeEvent<string | string[]>);
                        }}
                        options={
                            optionList
                                ?.filter((el) => el.enabled)
                                .map((el) => ({
                                    id: el.id,
                                    name: el.value
                                })) || []
                        }
                        // listFitContent
                        value={optionList?.find((el) => el.value === optionSelected || Number(el.id) === Number(optionSelected))?.id || ''}
                        leftIcon={<ArrowDropDown />}
                        sx={{
                            borderRadius: '8px',
                            '&:hover': { bgcolor: '#EBEEFE' },
                            bgcolor: '#F5F6F7'
                        }}
                        PopoverSx={PopoverSx}
                        PopoverPropsAnchorOrigin={PopoverPropsAnchorOrigin}
                        PopoverPropsTransformOrigin={PopoverPropsTransformOrigin}
                    />
                )}
                <Collapse>
                    <LinearProgress />
                </Collapse>
            </FormControl>
        </Box>
    );
};
