import { SystemStyleObject } from '@mui/system';

/**
 * Styles to pass to the container of the input when using
 * MUI operators
 */
export const STRING_LIKE_OR_NUMBER_DATA_TYPE_CONTAINER_SX: SystemStyleObject = {
    '& label': { display: 'none !important' },
    '& .MuiInputBase-root': {
        mt: '0 !important',
        height: '32px',
        background: 'white !important',
        borderRadius: '8px',
        p: '0 !important',
        '&::before': { border: 'none !important' },
        '&::after': { border: 'none !important' },
        '& input': { background: 'white !important', pl: '10px !important', pb: '0 !important' },
        '& input::placeholder': { color: 'transparent' }
    }
};
