import { useLazyQuery, useMutation } from '@apollo/client';
import { CancelOutlined } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Typography,
    useTheme
} from '@mui/material';
import { QUERY_FIND_RECORD_TYPES } from 'graphql/queries/recordTypes';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FindRecordType, FindRecordTypeVariables, IRecordType } from 'types/recordType';
import { ContentArea, RecordTypesTabsSection } from './components';
import { FindRecordHeaderWithFilteringVariables, IGetRecordHeadersFiltered, IRecordHeaders } from 'ui-component/records/types';
import { SelectRecordOriginEnum } from './types';
import { IUpdateRecordHeader } from 'ui-component/RecordView/types';
import { MUTATION_UPDATE_RECORD_HEADER } from 'graphql/mutations/bills';
import { QUERY_FIND_RECORD_HEADERS_WITH_FILTERING } from 'graphql/queries/bills';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

export type LinkParentRecordDialogProps = {
    open: boolean;
    onClose: (wasParentAssigned?: boolean) => void;
    recordType: Pick<IRecordType, 'id' | 'name'>;
    recordToAddParent: IRecordHeaders | null;
};

export const LinkParentRecordDialog = ({ open, onClose, recordType, recordToAddParent }: LinkParentRecordDialogProps) => {
    const tenantId = localStorage.getItem('tenant_id');
    const { palette } = useTheme();
    const dispatch = useDispatch();

    const [hasAllowedParentRecordTypes, setHasAllowedParentRecordTypes] = useState(false);
    const [selectedRecordTypeId, setSelectedRecordTypeId] = useState(0);
    const [selectedRecord, setSelectedRecord] = useState<IRecordHeaders | null>(null);
    const [initialSelectedRecord, setInitialSelectedRecord] = useState<IRecordHeaders | null>(null);

    const [findRecordTypes, { loading: loadingRecordTypes, data: recordTypeData }] = useLazyQuery<FindRecordType, FindRecordTypeVariables>(
        QUERY_FIND_RECORD_TYPES,
        {
            fetchPolicy: 'no-cache',
            onCompleted(data) {
                const list = data.findRecordType;
                if (list?.length > 0) {
                    const orderedList = [...list].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                    setSelectedRecordTypeId(+orderedList[0].id);
                    setHasAllowedParentRecordTypes(true);
                } else {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: `This record does not have any parent record types`,
                            variant: 'alert',
                            alert: { color: 'error' },
                            close: false
                        })
                    );
                    onClose();
                }
            }
        }
    );

    const [updateRecord, { loading: updatingRecord }] = useMutation<IUpdateRecordHeader>(MUTATION_UPDATE_RECORD_HEADER, {
        update(cache, { data }) {
            const updatedRecord = data?.updateRecordHeader;
            const allFields = cache.readQuery<IGetRecordHeadersFiltered, FindRecordHeaderWithFilteringVariables>({
                query: QUERY_FIND_RECORD_HEADERS_WITH_FILTERING,
                variables: {
                    data: { recordTypeId: +recordType.id, tenantId: +(tenantId || 0), filters: { ids: [Number(recordToAddParent?.id)] } },
                    pagination: { offset: 0, limit: 1 }
                }
            });

            if (!allFields || !updatedRecord) return;

            cache.writeQuery<IGetRecordHeadersFiltered, FindRecordHeaderWithFilteringVariables>({
                query: QUERY_FIND_RECORD_HEADERS_WITH_FILTERING,
                variables: {
                    data: { recordTypeId: +recordType.id, tenantId: +(tenantId || 0), filters: { ids: [Number(recordToAddParent?.id)] } },
                    pagination: { offset: 0, limit: 1 }
                },
                data: {
                    filterAndSortRecordHeader: {
                        total: allFields.filterAndSortRecordHeader.total,
                        records: allFields.filterAndSortRecordHeader.records.map((record) =>
                            Number(record.id) === Number(updatedRecord.id) ? updatedRecord : record
                        )
                    }
                }
            });
        }
    });

    const allowedRecordTypes = [...(recordTypeData?.findRecordType || [])].sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    const selectedRecordType = allowedRecordTypes.find((el) => +el.id === selectedRecordTypeId) || null;

    const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
        setSelectedRecordTypeId(newValue);
        setSelectedRecord(null);
    };

    const handleSelectRecord = (record: IRecordHeaders | null, origin: SelectRecordOriginEnum = 'GRID') => {
        setSelectedRecord(record);
        if (origin === 'GRID') setInitialSelectedRecord(record);
    };

    const handleSubmit = async () => {
        try {
            if (!selectedRecord || !recordToAddParent) return;
            await updateRecord({ variables: { data: { id: +recordToAddParent?.id, parent: +selectedRecord?.id } } });
            onClose(true);
        } catch (error) {
            console.error('Error while updating record header:', error);
        }
    };

    useEffect(() => {
        if (open) {
            findRecordTypes({ variables: { data: { childrenRecordTypes: [+recordType.id], hasAllowedChildren: true } } });
        }
    }, [open, findRecordTypes, recordType.id]);

    return (
        <Dialog
            open={open && hasAllowedParentRecordTypes}
            onClose={updatingRecord ? undefined : () => onClose()}
            fullWidth
            maxWidth="lg"
            PaperProps={{ sx: { borderRadius: '8px', py: 0 } }}
        >
            <DialogTitle sx={{ height: '64px', bgcolor: palette.primary[300] }}>
                <Grid container justifyContent="space-between">
                    <Grid item xs>
                        <Typography fontSize="24px" fontWeight={500} color="black">
                            <FormattedMessage id="hierarchy.linkParentRecordDialogTitle" /> {recordType.name}
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <IconButton color="secondary" onClick={() => onClose()}>
                            <CancelOutlined />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent sx={{ overflowY: 'hidden', height: '80vh', p: 0 }}>
                {loadingRecordTypes ? (
                    <LinearProgress sx={{ width: '100%' }} />
                ) : (
                    <Grid container sx={{ height: '100%', maxWidth: '100%' }} flexWrap="nowrap">
                        <RecordTypesTabsSection
                            selectedRecordTypeId={selectedRecordTypeId}
                            handleChangeTab={handleChangeTab}
                            allowedRecordTypes={allowedRecordTypes}
                        />
                        <ContentArea
                            key={selectedRecordTypeId}
                            recordType={selectedRecordType}
                            initialSelectedRecord={initialSelectedRecord}
                            selectedRecord={selectedRecord}
                            onSelectRecord={handleSelectRecord}
                            recordToAddParent={recordToAddParent}
                            allowedRecordTypeIds={allowedRecordTypes.map((el) => +el.id)}
                        />
                    </Grid>
                )}
            </DialogContent>
            <DialogActions sx={{ px: '24px', display: 'flex', justifyContent: 'flex-end', height: '64px', bgcolor: palette.primary[300] }}>
                <Button
                    onClick={() => onClose()}
                    disabled={updatingRecord}
                    sx={{ width: '80px', height: '32px', borderRadius: '8px' }}
                    color="secondary"
                    variant="outlined"
                >
                    <Typography fontSize="14px" color="secondary">
                        <FormattedMessage id="cancel" />
                    </Typography>
                </Button>
                <LoadingButton
                    loading={updatingRecord}
                    disabled={!selectedRecord}
                    onClick={handleSubmit}
                    sx={{ width: '102px', height: '32px', p: 0, borderRadius: '8px' }}
                    color="secondary"
                    variant="contained"
                >
                    <Typography fontSize="14px">
                        <FormattedMessage id="hierarchy.linkParentRecordDialogLink" />
                    </Typography>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
