import { Box, Button, Grid, Typography } from '@mui/material';
import { SuccessIcon } from './SuccessIcon';

export type SuccessMessageProps = {
    onAction: () => void;
    orderId?: string;
    fromSalesOrderView?: boolean;
};
export const SuccessMessage = ({ onAction, orderId, fromSalesOrderView }: SuccessMessageProps) => (
    <Box sx={{ width: '100%', height: '100%', background: 'white' }}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <Grid item xs={4} container sx={{ flexDirection: 'column' }} alignItems="center">
                <SuccessIcon />
                <Typography fontSize="34px" align="center" color="secondary" variant="h2" fontWeight={700}>
                    {fromSalesOrderView ? 'Order saved successfully!' : 'Order transmitted successfully!'}
                </Typography>
                <Typography fontSize="14px" align="center" variant="h2" fontWeight={400}>
                    {orderId}
                </Typography>
                {fromSalesOrderView ? (
                    <Button
                        variant="outlined"
                        onClick={onAction}
                        color="secondary"
                        sx={{ borderRadius: '8px', width: '90px', mt: 1, height: '40px' }}
                    >
                        Close
                    </Button>
                ) : (
                    <Button
                        variant="outlined"
                        onClick={onAction}
                        color="secondary"
                        sx={{ borderRadius: '8px', minWidth: '90px', mt: 1, height: '40px' }}
                    >
                        Create new order
                    </Button>
                )}
            </Grid>
        </Grid>
    </Box>
);
