import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Export } from 'ui-component/icons';
import { IRecordHeaders } from 'ui-component/records/types';

export type HierarchyQuickViewProps = {
    record: IRecordHeaders;
    onOpenFullHierarchy: () => void;
};

export const HierarchyQuickView = ({ record, onOpenFullHierarchy }: HierarchyQuickViewProps) => {
    const { palette } = useTheme();
    const recordName = `${record.recordType.name}-${record.id}`;

    const getHierarchyArr = () => {
        const arr = [];

        if (record.parent) {
            arr.push([`${record.parent.recordType.name}-${record.id}`]);
            arr.push([`${record.parent.recordType.name}-${record.id}`, recordName]);
        } else {
            arr.push([recordName]);
        }

        if (record.children) {
            for (const child of record.children) arr.push([recordName, `${child.recordType.name}-${child.id}`]);
        }

        return arr;
    };

    return (
        <Grid container direction="column" wrap="nowrap" sx={{ height: '100%' }}>
            <Grid item xs="auto">
                <Box
                    component={Grid}
                    container
                    alignItems="center"
                    sx={{ py: '6px', px: '8px', height: '40px', bgcolor: palette.primary[300] }}
                >
                    <Grid item xs>
                        <Typography fontSize="16px" fontWeight={700}>
                            <FormattedMessage id="preview" />
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <Button
                            variant="outlined"
                            color="secondary"
                            sx={{ height: '28px', width: '183px', borderRadius: '8px', display: 'flex', alignItems: 'center' }}
                            onClick={onOpenFullHierarchy}
                        >
                            <Grid item xs="auto" sx={{ mr: '8px' }}>
                                <Export sx={{ fontSize: '16px', verticalAlign: 'middle' }} />
                            </Grid>
                            <Grid item xs="auto">
                                <FormattedMessage id="hierarchy.viewFullHierarchy" />
                            </Grid>
                        </Button>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs sx={{ px: '8px', py: '4px' }}>
                <Paper elevation={0} sx={{ height: '100%', borderRadius: '8px', border: 'solid 1px', borderColor: palette.primary[500] }}>
                    <Grid container direction="column" wrap="nowrap" sx={{ height: '100%' }}>
                        {getHierarchyArr().map((item, idx) => {
                            const marginLeft = 20;
                            const level = item.length - 1;
                            const isParent = item.length === 1 && item[level] !== recordName;
                            const isCurrent = item[level] === recordName;
                            return (
                                <Grid item key={JSON.stringify(item)} xs="auto">
                                    <Box
                                        component={Grid}
                                        container
                                        alignItems="center"
                                        sx={{
                                            height: '28px',
                                            borderTopLeftRadius: idx === 0 ? '8px' : 0,
                                            borderTopRightRadius: idx === 0 ? '8px' : 0,
                                            borderBottomLeftRadius: idx === getHierarchyArr().length - 1 ? '8px' : 0,
                                            borderBottomRightRadius: idx === getHierarchyArr().length - 1 ? '8px' : 0,
                                            bgcolor: isCurrent ? palette.secondary.light : 'white',
                                            paddingLeft: `${level === 0 ? 0 : marginLeft * level - 1}px`
                                        }}
                                    >
                                        <Grid item xs="auto">
                                            <Box sx={{ width: '24px' }}>
                                                {(isParent || (isCurrent && record.children?.length)) && (
                                                    <KeyboardArrowDown fontSize="small" />
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography
                                                color="secondary"
                                                fontSize="14px"
                                                fontWeight={400}
                                                sx={{ textDecoration: 'underline' }}
                                            >
                                                {item[level]}
                                            </Typography>
                                        </Grid>
                                        {item[level] === recordName && (
                                            <Grid item xs="auto">
                                                <Typography sx={{ width: '33px' }} fontSize="9px" fontWeight={500} color="black">
                                                    <FormattedMessage id="hierarchy.thisRecord" />
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};
