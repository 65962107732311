import { useMutation } from '@apollo/client';
import { MUTATION_UPDATE_RECORD_HEADER } from 'graphql/mutations/bills';
import { QUERY_GET_RECORD_HEADERS } from 'graphql/queries/bills';
import { IGetRecordHeaders, RecordHeadersVariables } from 'ui-component/records/types';
import { IUpdateRecordHeader } from 'ui-component/RecordView/types';

export const useUpdateRecord = (recordId: string, recordTypeId: string) => {
    const [updateRecord, { loading: updatingRecord }] = useMutation<IUpdateRecordHeader>(MUTATION_UPDATE_RECORD_HEADER, {
        update(cache, { data }) {
            const updatedRecord = data?.updateRecordHeader;
            const allFields = cache.readQuery<IGetRecordHeaders, RecordHeadersVariables>({
                query: QUERY_GET_RECORD_HEADERS,
                variables: {
                    data: { id: Number(recordId), recordType: Number(recordTypeId) },
                    pagination: { offset: 0, limit: 1 }
                }
            });

            if (!allFields || !updatedRecord) return;

            cache.writeQuery<IGetRecordHeaders, RecordHeadersVariables>({
                query: QUERY_GET_RECORD_HEADERS,
                variables: {
                    data: { id: Number(recordId), recordType: Number(recordTypeId) },
                    pagination: { offset: 0, limit: 1 }
                },
                data: {
                    findRecordHeader: {
                        total: allFields.findRecordHeader.total,
                        records: allFields.findRecordHeader.records.map((record) =>
                            Number(record.id) === Number(updatedRecord.id) ? updatedRecord : record
                        )
                    }
                }
            });
        }
    });

    return { updateRecord, updatingRecord };
};
