import { SvgIcon, SvgIconProps } from '@mui/material';

export const Hierarchy = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="custom-icon">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.375 3.75H5.625C5.32663 3.75 5.04048 3.86853 4.8295 4.0795C4.61853 4.29048 4.5 4.57663 4.5 4.875V7.125C4.5 7.746 5.004 8.25 5.625 8.25H18.375C18.6734 8.25 18.9595 8.13147 19.1705 7.9205C19.3815 7.70952 19.5 7.42337 19.5 7.125V4.875C19.5 4.57663 19.3815 4.29048 19.1705 4.0795C18.9595 3.86853 18.6734 3.75 18.375 3.75ZM18.375 10.5H7.5V15.195C7.5 16.23 8.34 17.07 9.375 17.07H10.5V16.875C10.5 15.9799 10.8556 15.1215 11.4885 14.4885C12.1215 13.8556 12.9799 13.5 13.875 13.5H18.375C19.2701 13.5 20.1285 13.8556 20.7615 14.4885C21.3944 15.1215 21.75 15.9799 21.75 16.875V19.125C21.75 20.0201 21.3944 20.8785 20.7615 21.5115C20.1285 22.1444 19.2701 22.5 18.375 22.5H13.875C13.0137 22.4999 12.1851 22.1705 11.5587 21.5793C10.9324 20.9881 10.5558 20.1798 10.506 19.32H9.375C8.28098 19.32 7.23177 18.8854 6.45818 18.1118C5.6846 17.3382 5.25 16.289 5.25 15.195V10.479C4.42498 10.3868 3.6629 9.99369 3.10946 9.37494C2.55601 8.75618 2.25003 7.95516 2.25 7.125V4.875C2.25 3.97989 2.60558 3.12145 3.23851 2.48851C3.87145 1.85558 4.72989 1.5 5.625 1.5H18.375C19.2701 1.5 20.1285 1.85558 20.7615 2.48851C21.3944 3.12145 21.75 3.97989 21.75 4.875V7.125C21.75 8.02011 21.3944 8.87855 20.7615 9.51149C20.1285 10.1444 19.2701 10.5 18.375 10.5ZM13.875 15.75H18.375C18.6734 15.75 18.9595 15.8685 19.1705 16.0795C19.3815 16.2905 19.5 16.5766 19.5 16.875V19.125C19.5 19.4234 19.3815 19.7095 19.1705 19.9205C18.9595 20.1315 18.6734 20.25 18.375 20.25H13.875C13.5766 20.25 13.2905 20.1315 13.0795 19.9205C12.8685 19.7095 12.75 19.4234 12.75 19.125V16.875C12.75 16.5766 12.8685 16.2905 13.0795 16.0795C13.2905 15.8685 13.5766 15.75 13.875 15.75Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
