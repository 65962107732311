import { IRecordType } from 'types/recordType';
import { IAditionalFields } from 'ui-component/records/types';
import { ObjectProperty } from 'views/backoffice/CustomObjects/types';

export type DataFocusView = {
    id: string;
    name: string;
    form: Pick<CustomForm, 'id' | 'recordType'>;
    enabled: boolean;
};

export type CustomForm = {
    id: string;
    recordType: Pick<IRecordType, 'id'>;
};

export type FindAllDataFocusView = {
    findAllDataFocusViewsSaas: DataFocusView[];
};

export type FindManyDataFocusView = {
    findDataFocusViewsMany: DataFocusView[];
};

export type FindManyDataFocusViewVariables = {
    data: {
        tenantId?: number;
        rolesId?: number;
        tenantIds?: number[];
        rolesIds?: number[];
        name?: string;
        ids?: number[];
        id?: number;
        formIds?: number[];
        formId?: number;
    };
};

export type DataFocusViewStore = {
    list: DataFocusView[];
    selected?: DataFocusView;
    defaultPreference?: UserDataFocusViewPreference | null;
};

export type FindAllUserDataFocusViewPreference = {
    findAllUserDataFocusViewPreferences: UserDataFocusViewPreference[];
};

export type FindUserDataFocusViewPreference = {
    findUserDataFocusViewPreferencesMany: UserDataFocusViewPreference[];
};

export type FindUserDataFocusViewPreferenceVariables = {
    data: {
        dataFocusViewId?: number;
        dataFocusViewIds?: number[];
        id?: number;
        ids?: number[];
        userIds?: number[];
        userId?: number;
        tenantIds?: number[];
        tenantId?: number;
        recordTypeIds?: number[];
        recordTypeId?: number;
    };
};

export type UserDataFocusViewPreference = {
    id: string;
    dataFocusView: Pick<DataFocusView, 'id'>;
    recordType: Pick<IRecordType, 'id'>;
};

export type CreateUserDataFocusViewPreference = {
    createUserDataFocusViewPreferences: UserDataFocusViewPreference;
};

export type CreateUserDataFocusViewPreferenceVariables = {
    data: {
        dataFocusViewId: number;
        recordTypeId: number;
        tenantId: number;
        userId: number;
    };
};

export type UpdateUserDataFocusViewPreference = {
    updateUserDataFocusViewPreferences: UserDataFocusViewPreference;
};

export type UpdateUserDataFocusViewPreferenceVariables = {
    data: Partial<CreateUserDataFocusViewPreferenceVariables['data']> & { id: number };
};

export type FindFormSections = {
    findFormSectionsMany: FormSection[];
};

export type FindFormSectionsVariables = {
    data: {
        formId?: number;
        sectionFieldIds?: number[];
        sectionFieldId?: number;
        parentIds?: number[];
        parentId?: number;
        name?: string;
        ids?: number[];
        id?: number;
        formIds?: number[];
    };
};

export type FormSection = {
    columns: number;
    id: string;
    isCollapsible: boolean;
    name: string;
    order: number;
    renderCondition: string;
    parent: Pick<FormSection, 'id'> | null;
    specialComponent: string[] | null;
    sectionFields: FormSectionField[];
};

export type FormSectionWithChildren = FormSection & { children?: FormSection[] };

export type FormSectionField = {
    baseField: string;
    defaultValue: any;
    enabled: boolean;
    isRequired: boolean;
    isClearable: boolean;
    name: string;
    objectProperty: Pick<ObjectProperty, 'id' | 'name'>;
    order: number;
    specialValue: any;
    recordAdditionalFieldsByType: Pick<IAditionalFields, 'id'> & { name: string };
};

export type FieldConditional = {
    id: string;
    name: string;
    condition: {
        id: string;
        operator: FieldConditionalIfOperatorEnum;
        field: string;
        sectionId: string;
        objectProperty?: string;
        idField?: string;
        value?: string;
    }[];
    linkOperator: FieldConditionalLinkOperatorEnum;
    then: {
        id: string;
        operator: FieldConditionalThenOperatorEnum;
        field: string;
        sectionId: string;
        objectProperty?: string;
        idField?: string;
    }[];
    otherwise: {
        id: string;
        operator: FieldConditionalOtherwiseOperatorEnum;
        field: string;
        sectionId: string;
        objectProperty?: string;
        idField?: string;
    };
};

export const FIELD_CONDITIONAL_LINK_OPERATOR_LIST = {
    AND: 'And',
    OR: 'Or'
} as const;

export const FIELD_CONDITIONAL_IF_OPERATOR_LIST = {
    IS_NOT_EMPTY: 'is not empty',
    IS_EQUAL_TO: 'is equal to',
    IS_NOT_EQUAL_TO: 'is not equal to',
    IS_LOWER_THAN: 'is lower than',
    IS_GREATER_THAN: 'is greater than',
    IS_LOWER_OR_EQUAL_TO: 'is lower or equal to',
    IS_GREATER_OR_EQUAL_TO: 'is greater or equal to',
    CONTAINS: 'contains',
    DOES_NOT_CONTAINS: 'does not contain',
    IS_TRUE: 'is true',
    IS_FALSE: 'is false'
} as const;

export const FIELD_CONDITIONAL_THEN_OPERATOR_LIST = {
    APPEARS: 'appears',
    DISABLE_FIELD: 'disable field',
    ENABLED_FIELD: 'enable field',
    HIDDEN: 'hidden',
    MAKE_REQUIRED: 'make required'
} as const;

export const FIELD_CONDITIONAL_OTHERWISE_OPERATOR_LIST = {
    APPEARS: 'appears',
    DISABLE_FIELD: 'disable field',
    ENABLED_FIELD: 'enable field',
    HIDDEN: 'hidden',
    MAKE_REQUIRED: 'make required'
} as const;

export type FieldConditionalLinkOperatorEnum = keyof typeof FIELD_CONDITIONAL_LINK_OPERATOR_LIST;
export type FieldConditionalIfOperatorEnum = keyof typeof FIELD_CONDITIONAL_IF_OPERATOR_LIST;
export type FieldConditionalThenOperatorEnum = keyof typeof FIELD_CONDITIONAL_THEN_OPERATOR_LIST;
export type FieldConditionalOtherwiseOperatorEnum = keyof typeof FIELD_CONDITIONAL_OTHERWISE_OPERATOR_LIST;
