import { SvgIcon, SvgIconProps } from '@mui/material';

export const PinToRight = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M22 22L16.1667 16.0541M16.1667 16.0541L19.7211 12.7991C20.2631 12.3028 20.1026 11.4109 19.4215 11.1348L14.5501 9.15996C14.338 9.07395 14.1617 8.91775 14.0509 8.71744L10.9673 3.14551C10.648 2.5686 9.86756 2.45318 9.39498 2.913L2.93193 9.20137C2.46131 9.65926 2.55222 10.4388 3.11557 10.7761L8.9218 14.2526C9.11629 14.369 9.26545 14.5481 9.34481 14.7604L10.9255 18.9899C11.1737 19.6539 12.0148 19.8561 12.5376 19.3773L16.1667 16.0541Z"
                stroke="#858585"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    </SvgIcon>
);
