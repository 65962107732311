import { useLazyQuery } from '@apollo/client';
import { QUERY_FIND_RECORD_HEADERS_WITH_FILTERING, QUERY_GET_RECORD_FIELDS, QUERY_FIND_ROLE_ACCESS } from 'graphql/queries/bills';
import { filterNotAllowedFields } from 'ui-component/records/utils';
import useMyRole from 'hooks/useMyRole';
import { useEffect, useMemo } from 'react';
import {
    FindRoleAccessVariables,
    IFindRoleAccess,
    IGetRecordFields,
    IRecordFieldWithFieldName,
    FindRecordHeaderWithFilteringVariables,
    IGetRecordHeadersFiltered
} from 'ui-component/records/types';

export type useRecordDataProps = {
    recordTypeId: number;
    recordId?: number;
};

export const useRecordData = ({ recordTypeId, recordId }: useRecordDataProps) => {
    const roleId = useMyRole();
    const tenantId = localStorage.getItem('tenant_id');
    const [getHeaderData, { data: headerData, loading: loadingHeaderData }] = useLazyQuery<IGetRecordFields>(QUERY_GET_RECORD_FIELDS, {
        variables: { recordType: Number(recordTypeId) },
        fetchPolicy: 'no-cache'
    });

    const [getRecordData, { data: recordData, loading: loadingRecordData }] = useLazyQuery<
        IGetRecordHeadersFiltered,
        FindRecordHeaderWithFilteringVariables
    >(QUERY_FIND_RECORD_HEADERS_WITH_FILTERING, {
        variables: {
            pagination: { offset: 0, limit: 1 },
            data: { recordTypeId, tenantId: +(tenantId || 0), filters: { ids: [Number(recordId)] } }
        }
    });

    const [getFieldAccess, { data: fieldAccessData, loading: loadingFieldAccess }] = useLazyQuery<IFindRoleAccess, FindRoleAccessVariables>(
        QUERY_FIND_ROLE_ACCESS,
        {
            variables: { data: { recordTypes: [Number(recordTypeId)], roles: [roleId as number] } },
            fetchPolicy: 'no-cache'
        }
    );

    const loading = loadingHeaderData || loadingRecordData || loadingFieldAccess;

    const fieldAccess = useMemo(() => fieldAccessData?.findRoleAccessToRecordFields || [], [fieldAccessData]);
    const headers = useMemo(() => headerData?.getSaasRecordFieldsByRecordTypes, [headerData?.getSaasRecordFieldsByRecordTypes]);

    const allowedFieldHeaders = useMemo(
        () =>
            headers ? Object.fromEntries(Object.entries(headers).filter(([key]) => filterNotAllowedFields(fieldAccess)(key))) : undefined,
        [fieldAccess, headers]
    );

    const headersWithFieldName: Record<string, IRecordFieldWithFieldName> | undefined = useMemo(
        () =>
            allowedFieldHeaders
                ? Object.fromEntries(Object.entries(allowedFieldHeaders).map(([key, value]) => [key, { ...value, fieldName: key }]))
                : undefined,
        [allowedFieldHeaders]
    );

    const data = recordData?.filterAndSortRecordHeader.records[0];

    useEffect(() => {
        if (recordTypeId) getHeaderData();
        if (recordTypeId) {
            getFieldAccess();
        }
        if (recordId) getRecordData();
    }, [recordTypeId, getHeaderData, recordId, getRecordData, getFieldAccess]);

    return { loading, headers, headersWithFieldName, allowedFieldHeaders, data, fieldAccess };
};
