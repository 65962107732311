import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Typography } from '@mui/material';
import useMyMenuItems from 'hooks/useMyMenuItems';
import { Link } from 'react-router-dom';

export type HierarchyBreadcrumbLevel = { id: string; name: string; recordTypeId: number; recordTypeName: string };

export type HierarchyBreadcrumbProps = {
    recordTypeName: string;
    levels: HierarchyBreadcrumbLevel[];
};

export const HierarchyBreadcrumb = ({ recordTypeName, levels }: HierarchyBreadcrumbProps) => {
    const lastTwoItems = levels.slice(-2);
    // const allButLastTwoItems = levels.slice(0, -2);
    const { getMenuItemByRecordTypeId } = useMyMenuItems();

    const getRecordTypeRoute = (recordTypeId: number) => {
        if (!getMenuItemByRecordTypeId) return '';
        const menuItem = getMenuItemByRecordTypeId(recordTypeId);
        return menuItem || '';
    };

    return (
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb" sx={{ width: 'calc(100% - 280px)' }}>
            {levels.length === 1 && getRecordTypeRoute && (
                <Typography
                    component={Link}
                    to={getRecordTypeRoute(levels[0].recordTypeId)}
                    fontSize="20px"
                    fontWeight={300}
                    sx={({ palette }) => ({ color: palette.primary[400], textDecoration: 'none' })}
                >
                    {recordTypeName}
                </Typography>
            )}
            {levels.length === 2 && (
                <Typography
                    component={Link}
                    to={getRecordTypeRoute(levels[0].recordTypeId)}
                    fontSize="20px"
                    fontWeight={300}
                    sx={({ palette }) => ({ color: palette.primary[400], textDecoration: 'none' })}
                >
                    {levels[0].recordTypeName}
                </Typography>
            )}
            {lastTwoItems.map((level, index) => {
                const isCurrent = lastTwoItems.length === index + 1;
                return (
                    <Typography
                        key={index}
                        fontSize="20px"
                        component={isCurrent ? 'p' : 'a'}
                        href={isCurrent ? undefined : `./?recordId=${level.id}&recordType=${level.recordTypeId}`}
                        rel="noreferrer"
                        fontWeight={isCurrent ? 500 : 300}
                        sx={({ palette }) => ({
                            textDecoration: 'none',
                            color: isCurrent ? palette.secondary.main : palette.primary[400],
                            '&:hover': {
                                textDecoration: isCurrent ? undefined : 'underline'
                            }
                        })}
                    >
                        {level.name}
                    </Typography>
                );
            })}
        </Breadcrumbs>
    );
};
