import { Box, Grid, Typography } from '@mui/material';
import useMyDropdowns from 'hooks/useMyDropdowns';
import { HierarchySection } from 'ui-component/customFormsComponents';
import { FormInlineInput } from 'ui-component/formComponents/Sections/FormInlineInput';
import { FieldDataTypeEnum, IRecordFieldWithFieldName, IRecordHeaders, IRoleAccess } from 'ui-component/records/types';
import { getEditableField, NON_EDITABLE_FIELDS } from 'ui-component/records/utils';
import { getDropdownOptionList } from 'ui-component/RecordView/utils';
import { ListValue } from 'views/backoffice/CustomLists/types';
import { ObjectProperty } from 'views/backoffice/CustomObjects/types';
import {
    FIELD_CONDITIONAL_THEN_OPERATOR_LIST,
    FieldConditional,
    FieldConditionalThenOperatorEnum,
    FormSectionWithChildren
} from 'views/DataFocusView/types';
import { filterFieldsEnabledByRecordType, orderArrayByAttribute } from 'views/DataFocusView/utils';

export type SectionRenderProps = {
    recordId?: string;
    recordData?: IRecordHeaders;
    section: FormSectionWithChildren;
    headers: Record<string, IRecordFieldWithFieldName>;
    recordTypeId: string;
    listValuesByListId: Record<number, ListValue[]>;
    propertiesById: Record<number, ObjectProperty>;
    propertyByFieldName: Record<string, Record<string, ObjectProperty>>;
    renderConditions?: FieldConditional[];
    fullfilledConditionsIds?: string[];
    fieldAccessList: IRoleAccess[];
    hideNonEditableFields?: boolean;
};

export const SectionRender = ({
    recordId,
    recordData,
    section,
    headers,
    listValuesByListId,
    recordTypeId,
    propertiesById,
    propertyByFieldName,
    renderConditions,
    fullfilledConditionsIds,
    fieldAccessList,
    hideNonEditableFields
}: SectionRenderProps) => {
    const { problemCodeList, recordStatusList, usersList, targetTenantList } = useMyDropdowns({ recordTypeId: +recordTypeId });

    const orderedSectionFields = orderArrayByAttribute(section.sectionFields);

    const getFieldThenOrOtherwiseConditions = (fieldName: string, objectPropertyName?: string) =>
        renderConditions?.filter(({ then, otherwise }) => {
            const isProperty = !!objectPropertyName;

            if (
                isProperty &&
                (then.some((el) => el.field === fieldName && objectPropertyName === el.objectProperty) ||
                    (otherwise.field === fieldName && objectPropertyName === otherwise.objectProperty))
            ) {
                return true;
            }

            if (!isProperty && (then.some((el) => el.field === fieldName) || otherwise.field === fieldName)) {
                return true;
            }

            return false;
        });

    const filteredSectionFields = orderedSectionFields
        .filter(({ baseField, recordAdditionalFieldsByType }) => {
            const fieldDef = headers[baseField || recordAdditionalFieldsByType?.name];

            if (!fieldDef) return true;

            const isNotEditable = NON_EDITABLE_FIELDS.includes(fieldDef?.fieldName as (typeof NON_EDITABLE_FIELDS)[number]);
            return hideNonEditableFields ? !isNotEditable : true;
        })
        .filter(filterFieldsEnabledByRecordType(headers, propertyByFieldName))
        // USED TO FILTER OUT THE FIELDS WITH HIDDEN CONDITION
        .filter(({ baseField, recordAdditionalFieldsByType, objectProperty }) => {
            const isField = !!baseField || !!recordAdditionalFieldsByType;
            const fieldDef = headers[baseField || recordAdditionalFieldsByType?.name];
            const propertyDef = objectProperty ? propertiesById[+objectProperty.id] : undefined;

            const usedConditions = getFieldThenOrOtherwiseConditions(
                isField ? fieldDef.fieldName : propertyDef?.objectDefinition.name || '',
                propertyDef?.name
            );

            const usedAndFullfiledConditions = usedConditions?.filter((el) => fullfilledConditionsIds?.includes(el.id)) || [];

            const usedAndNonFullfiledConditions = usedConditions?.filter((el) => !fullfilledConditionsIds?.includes(el.id)) || [];

            const actionsByConditions: (typeof FIELD_CONDITIONAL_THEN_OPERATOR_LIST)[FieldConditionalThenOperatorEnum][] = [];

            for (const { then } of usedAndFullfiledConditions) {
                const thenAppliedToThisField = then.filter((el) => {
                    const isProperty = !!propertyDef?.name;
                    return !isProperty ? el.field === fieldDef?.fieldName || '' : el.objectProperty === propertyDef?.name;
                });

                actionsByConditions.push(...thenAppliedToThisField.map((el) => FIELD_CONDITIONAL_THEN_OPERATOR_LIST[el.operator]));
            }

            for (const { otherwise } of usedAndNonFullfiledConditions) {
                const otherwiseAppliedToThisField =
                    otherwise.field === (fieldDef?.fieldName || '') || otherwise.objectProperty === (propertyDef?.name || '');
                if (otherwiseAppliedToThisField) actionsByConditions.push(FIELD_CONDITIONAL_THEN_OPERATOR_LIST[otherwise.operator]);
            }

            return !actionsByConditions.includes(FIELD_CONDITIONAL_THEN_OPERATOR_LIST.HIDDEN);
        });

    if (section.specialComponent?.includes('HIERARCHY') && recordData && recordId)
        return <HierarchySection recordId={recordId} initialRecord={recordData} />;

    if (filteredSectionFields.length === 0) return null;

    return (
        <Box sx={{ mb: '12px', px: '24px', py: '8px', borderRadius: '8px', border: 'solid 1px #EBEEFE' }}>
            <Typography fontSize="20px" fontWeight={500} sx={{ lineHeight: '160%', mb: '8px' }} color="secondary">
                {section.name}
            </Typography>
            <Grid container spacing={2}>
                {orderedSectionFields
                    .filter(filterFieldsEnabledByRecordType(headers, propertyByFieldName))
                    .map(({ name, baseField, recordAdditionalFieldsByType, objectProperty, isRequired, defaultValue }) => {
                        const isField = !!baseField || !!recordAdditionalFieldsByType;
                        const fieldDef = headers[baseField || recordAdditionalFieldsByType?.name];
                        const propertyDef = objectProperty ? propertiesById[+objectProperty.id] : undefined;
                        const inputName = isField ? fieldDef.fieldName : `${propertyDef?.objectDefinition.name}.${propertyDef?.name}`;
                        const listTypeId = fieldDef?.listType?.id;
                        const options = listTypeId
                            ? listValuesByListId[+listTypeId]
                            : getDropdownOptionList(baseField as any, [], recordStatusList, problemCodeList, usersList, targetTenantList);

                        const usedConditions = getFieldThenOrOtherwiseConditions(
                            isField ? fieldDef.fieldName : propertyDef?.objectDefinition.name || '',
                            propertyDef?.name
                        );

                        const usedAndFullfiledConditions = usedConditions?.filter((el) => fullfilledConditionsIds?.includes(el.id)) || [];

                        const usedAndNonFullfiledConditions =
                            usedConditions?.filter((el) => !fullfilledConditionsIds?.includes(el.id)) || [];

                        const actionsByConditions: (typeof FIELD_CONDITIONAL_THEN_OPERATOR_LIST)[FieldConditionalThenOperatorEnum][] = [];

                        for (const { then } of usedAndFullfiledConditions) {
                            const thenAppliedToThisField = then.filter((el) => {
                                const isProperty = !!propertyDef?.name;

                                if (!isProperty) {
                                    return el.field === fieldDef?.fieldName || '';
                                }
                                return false;
                            });

                            actionsByConditions.push(
                                ...thenAppliedToThisField.map((el) => FIELD_CONDITIONAL_THEN_OPERATOR_LIST[el.operator])
                            );
                        }

                        for (const { otherwise } of usedAndNonFullfiledConditions) {
                            const otherwiseAppliedToThisField = otherwise.field === fieldDef?.fieldName || '';

                            if (otherwiseAppliedToThisField)
                                actionsByConditions.push(FIELD_CONDITIONAL_THEN_OPERATOR_LIST[otherwise.operator]);
                        }

                        if (actionsByConditions.includes(FIELD_CONDITIONAL_THEN_OPERATOR_LIST.HIDDEN)) return null;

                        return (
                            <Grid item xs={section.columns}>
                                {isField && (
                                    <FormInlineInput
                                        label={name}
                                        isFormField
                                        options={options}
                                        name={inputName}
                                        fieldDef={fieldDef}
                                        disabled={
                                            !getEditableField(fieldDef.fieldName, fieldAccessList) ||
                                            actionsByConditions.includes(FIELD_CONDITIONAL_THEN_OPERATOR_LIST.DISABLE_FIELD) ||
                                            NON_EDITABLE_FIELDS.includes(fieldDef.fieldName as (typeof NON_EDITABLE_FIELDS)[number])
                                        }
                                        isCustomList={!!listTypeId}
                                        isRequired={
                                            fieldDef.required ||
                                            isRequired ||
                                            actionsByConditions.includes(FIELD_CONDITIONAL_THEN_OPERATOR_LIST.MAKE_REQUIRED)
                                        }
                                        noWrap
                                        defaultValue={defaultValue === '""' ? '' : defaultValue}
                                    />
                                )}{' '}
                                {propertyDef && (
                                    <FormInlineInput
                                        label={name}
                                        isFormField
                                        fieldDef={{
                                            dataType: propertyDef.dataType as FieldDataTypeEnum,
                                            id: propertyDef.id,
                                            description: '',
                                            gridEditable: false,
                                            required: propertyDef.isRequired,
                                            headerField: false,
                                            visibility: 'standard'
                                        }}
                                        name={inputName}
                                        isCustomList={false}
                                        isRequired={propertyDef?.isRequired || isRequired}
                                        noWrap
                                        isObjectProperty
                                    />
                                )}
                            </Grid>
                        );
                    })}

                {!!section.children?.length && (
                    <Grid item container xs={12} spacing={2}>
                        {section.children.map((childSection) => (
                            <Grid item xs={section.columns} key={childSection.id}>
                                <SectionRender
                                    section={childSection}
                                    renderConditions={renderConditions}
                                    fullfilledConditionsIds={fullfilledConditionsIds}
                                    headers={headers}
                                    recordTypeId={recordTypeId}
                                    propertiesById={propertiesById}
                                    propertyByFieldName={propertyByFieldName}
                                    fieldAccessList={fieldAccessList}
                                    listValuesByListId={listValuesByListId}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};
