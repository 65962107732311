import { gql } from '@apollo/client';

export const QUERY_FIND_MANY_DATA_FOCUS_VIEW = gql`
    query FindDataFocusViewsMany($data: FindDataFocusViewInput!) {
        findDataFocusViewsMany(data: $data) {
            id
            form {
                id
                recordType {
                    id
                }
            }
            enabled
            name
        }
    }
`;

export const QUERY_FIND_ALL_DATA_FOCUS_VIEW = gql`
    query FindAllDataFocusViewsSaas {
        findAllDataFocusViewsSaas {
            id
            form {
                id
                recordType {
                    id
                }
            }
            enabled
            name
        }
    }
`;

export const QUERY_FIND_ALL_USER_DATA_FOCUS_VIEW_PREFERENCE = gql`
    query FindAllUserDataFocusViewPreferences {
        findAllUserDataFocusViewPreferences {
            id
            dataFocusView {
                id
            }
            recordType {
                id
            }
        }
    }
`;

export const QUERY_FIND_USER_DATA_FOCUS_VIEW_PREFERENCE = gql`
    query FindUserDataFocusViewPreferencesMany($data: FindUserDataFocusViewPreferencesInput!) {
        findUserDataFocusViewPreferencesMany(data: $data) {
            id
            dataFocusView {
                id
            }
            recordType {
                id
            }
        }
    }
`;

export const QUERY_FIND_FORM_SECTIONS = gql`
    query FindFormSectionsMany($data: FindFormSectionInput!) {
        findFormSectionsMany(data: $data) {
            columns
            id
            isCollapsible
            name
            order
            renderCondition
            specialComponent
            parent {
                id
            }
            sectionFields {
                baseField
                defaultValue
                enabled
                isRequired
                isClearable
                name
                objectProperty {
                    name
                    id
                }
                order
                specialValue
                recordAdditionalFieldsByType {
                    id
                    name
                }
            }
        }
    }
`;
