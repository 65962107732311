import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { MainArea, FlexibleArea } from 'views/DocumentViewer/components';
import { RecordGridWithSelection } from './RecordGridWithSelection';
import { IRecordType } from 'types/recordType';
import { IRecordHeaders } from 'ui-component/records/types';
import { useIntl } from 'react-intl';
import { HierarchyPreview } from './HierarchyPreview';
import { SelectRecordOriginEnum } from '../types';

export type CONTENT_AREA = 'ONLY_GRID' | 'ONLY_HIERARCHY' | 'SPLIT';
export const getLayoutPositionHeight = (module: Omit<CONTENT_AREA, 'SPLIT'>, currentState: CONTENT_AREA) => {
    switch (currentState) {
        case 'ONLY_GRID':
            return module === 'ONLY_GRID' ? '100%' : 0;
        case 'ONLY_HIERARCHY':
            return module === 'ONLY_HIERARCHY' ? '100%' : 0;
        default:
            return '50%';
    }
};

export type ContentAreaProps = {
    recordType: IRecordType | null;
    onSelectRecord: (record: IRecordHeaders | null, origin?: SelectRecordOriginEnum) => void;
    selectedRecord: IRecordHeaders | null;
    initialSelectedRecord: IRecordHeaders | null;
    recordToAddParent: IRecordHeaders | null;
    allowedRecordTypeIds: number[];
};

export const ContentArea = ({
    recordType,
    onSelectRecord,
    selectedRecord,
    initialSelectedRecord,
    recordToAddParent,
    allowedRecordTypeIds
}: ContentAreaProps) => {
    const intl = useIntl();
    const [selectedArea, setSelectedArea] = useState<CONTENT_AREA>('ONLY_GRID');

    const hierarchySectionTitle = `${intl.formatMessage({ id: 'hierarchy.hierarchyOf' })} ${initialSelectedRecord?.recordType.name}- ${
        initialSelectedRecord?.id
    }`;
    return (
        <Grid item xs="auto" sx={{ width: 'calc(100% - 140px) !important' }}>
            <Box sx={{ height: '100%', py: 2, px: 1 }}>
                {selectedArea !== 'ONLY_HIERARCHY' && (
                    <Typography variant="h5" color="#37326E" fontSize={20} fontWeight={500} sx={{ mb: 2 }}>
                        {recordType?.name}
                    </Typography>
                )}
                <MainArea fullWidth height="calc(100% - 27px)" sx={{ bgcolor: 'white' }}>
                    <RecordGridWithSelection
                        recordType={recordType}
                        onSelectRecord={(newRecord) => {
                            onSelectRecord(newRecord);
                            setSelectedArea('SPLIT');
                        }}
                        selectedRecord={selectedRecord}
                        height={getLayoutPositionHeight('ONLY_GRID', selectedArea)}
                    />

                    <FlexibleArea
                        title={hierarchySectionTitle}
                        fullHeight={selectedArea === 'ONLY_HIERARCHY'}
                        toggleFullHeight={() => setSelectedArea((p) => (p === 'ONLY_HIERARCHY' ? 'SPLIT' : 'ONLY_HIERARCHY'))}
                        height={getLayoutPositionHeight('ONLY_HIERARCHY', selectedArea)}
                        contentSx={{ pb: 0 }}
                    >
                        <HierarchyPreview
                            initialSelectedRecord={initialSelectedRecord}
                            selectedRecord={selectedRecord}
                            onSelectRecord={onSelectRecord}
                            recordToAddParent={recordToAddParent}
                            allowedRecordTypeIds={allowedRecordTypeIds}
                        />
                    </FlexibleArea>
                </MainArea>
            </Box>
        </Grid>
    );
};
